import { useState, useMemo, useEffect, useCallback, Fragment, useRef } from 'react'
import { useForm, Controller, useFormContext, useFormState } from 'react-hook-form'
import { Button } from "@/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { ChevronDown, ChevronRight, X, Loader2 } from 'lucide-react'
import { SpaceData, TreeNode, BuildingNode, LevelNode, AreaNode } from '../types/spaces'
import { isEqual } from 'lodash';
import { Building } from '@app.raytd.com/store'
import { Floor } from '@app.raytd.com/store'
import { toast } from 'sonner'
import { useTestFormContext } from '@/app/features/reports/components/test-form/context'
import { ScrollArea } from '@/components/ui/scroll-area'

const noSelectionClass = "border-b border-gray-200 dark:border-gray-700 pb-2 mb-2 last:border-b-0 last:pb-0 last:mb-0";

type Selection = {
  buildingId: number | null;
  levelId: number | null;
  areaId: number | null;
}

interface SearchResult {
  node: TreeNode;
  path: string[];
}

interface GroupedResults {
  [key: string]: SearchResult[];
}

interface SelectorState {
  path: TreeNode[];
  previousPath?: TreeNode[];
  search: string;
  isOpen: boolean;
  initialSelection: Selection
  selection: Selection;
  pendingSelection: Selection;
}

const initialState: SelectorState = {
  path: [],
  previousPath: [],
  search: '',
  isOpen: false,
  selection: {
    buildingId: null,
    levelId: null,
    areaId: null
  },
  pendingSelection: {
    buildingId: null,
    levelId: null,
    areaId: null
  },
  initialSelection: {
    buildingId: null,
    levelId: null,
    areaId: null
  }
};

interface SpaceSelectorProps {
  spaces: BuildingNode[];
  onSelect?: (building_id: number | null, level_id: number | null, area_id: number | null) => void;
  disabled?: boolean;
  defaultValues?: {
    building_id: number | null;
    level_id: number | null;
    area_id: number | null;
  };
  isLoading?: boolean;
  onCreateBuilding?: (path: TreeNode[], building: string) => void;
  onCreateLevel?: (path: Building, level: string) => void;
  onCreateArea?: (path: Floor, area: string) => void;
  canCreate?: boolean;
}

// Type guard helper
const isNodeWithChildren = (node: TreeNode): node is BuildingNode | LevelNode => {
  return 'children' in node && Array.isArray(node.children);
};


export function SpaceSelector({
  spaces,
  onSelect,
  defaultValues,
  disabled = false,
  isLoading = false,
  onCreateBuilding,
  onCreateLevel,
  onCreateArea,
  canCreate = false
}: SpaceSelectorProps) {
  const { control, setValue, watch } = useFormContext();
  const [isCreating, setIsCreating] = useState(false);
  const isCreatingRef = useRef(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    isCreatingRef.current = isCreating;
  }, [isCreating]);

  // console.debug('spaceSelector defaultValues', defaultValues);

  const [state, setState] = useState<SelectorState>({
    ...initialState,
    selection: {
      buildingId: defaultValues?.building_id ?? null,
      levelId: defaultValues?.level_id ?? null,
      areaId: defaultValues?.area_id ?? null
    },
    initialSelection: {
      buildingId: defaultValues?.building_id ?? null,
      levelId: defaultValues?.level_id ?? null,
      areaId: defaultValues?.area_id ?? null
    }
  });

  const building_id = watch('building_id');
  const level_id = watch('level_id');
  const area_id = watch('area_id');

  useEffect(() => {

    let mounted = true;

    const { buildingId, levelId, areaId } = state.selection;


    const updateFormValues = async () => {
      if (!mounted) return;

      console.debug('spaceSelector updateFormValues remounted initial:', { buildingId, levelId, areaId, isCreating: isCreatingRef.current });

      if (isCreatingRef.current || isEqual(state.initialSelection, state.selection)) {
        console.debug('spaceSelector updateFormValues: Initial selection matches current selection:', state);
        return;
      }

      try {
        // console.debug('spaceSelector updateFormValues: Updating form values:', { buildingId, levelId, areaId });
        await Promise.all([
          setValue('building_id', buildingId, { shouldDirty: true }),
          setValue('level_id', levelId, { shouldDirty: true }),
          setValue('area_id', areaId, { shouldDirty: true })
        ]);

        if (mounted) {
          onSelect?.(buildingId, levelId, areaId);
        }
      } catch (error) {
        console.error('Error updating form values:', error);
      }
    };

    updateFormValues();

    // Cleanup function to prevent updates after unmount
    return () => {
      mounted = false;
    };
  }, [state.selection, state.initialSelection, setValue, onSelect]);

  useEffect(() => {
    let mounted = true;

    const restoreState = async () => {
      if (!mounted || !spaces?.length) return;
      if (isCreatingRef.current) return;

      // console.debug('spaceSelector restoreState:', { building_id, level_id, area_id });

      try {
        const newPath: TreeNode[] = [];
        const initialSelection = {
          buildingId: null,
          levelId: null,
          areaId: null
        };


        if (building_id) {
          const building = spaces.find(node => node.building_id === building_id);
          if (building) {
            newPath.push(building);
            initialSelection.buildingId = building.building_id;

            if (level_id && building.children) {
              const level = building.children.find(node => node.level_id === level_id);
              if (level) {
                newPath.push(level);
                initialSelection.levelId = level.level_id;

                if (area_id && level.children) {
                  const area = level.children.find(node => node.area_id === area_id);
                  if (area) {
                    newPath.push(area);
                    initialSelection.areaId = area.area_id;
                  }
                }
              }
            }
          }
        }

        if (mounted) {
          setState(prev => ({
            ...prev,
            path: newPath,
            previousPath: newPath,
            initialSelection: initialSelection,
            selection: initialSelection,
            isOpen: false,
            search: ''
          }));
        }
      } catch (error) {
        console.error('Error restoring state:', error);
        if (mounted) {
          setState(prev => ({
            ...prev,
            path: [],
            selection: initialState.selection,
            pendingSelection: initialState.selection
          }));
        }
      }
    };

    restoreState();

    return () => {
      mounted = false;
    };
  }, [building_id, level_id, area_id, spaces]);

  // useEffect(() => {
  //   if (!isLoading && spaces.length > 0 && !building_id && !level_id && !area_id) {
  //     // Set the first available space as default if none is selected
  //     // const firstBuilding = spaces[0];
  //     // const firstLevel = firstBuilding.buildings[0];
  //     // const firstArea = firstLevel.levels[0];

  //     // setValue('building_id', firstBuilding.building_id);
  //     // setValue('level_id', firstLevel.level_id);
  //     // setValue('area_id', firstArea.area_id);
  //     // onSelect && onSelect(firstBuilding.building_id, firstLevel.level_id, firstArea.area_id);
  //   }
  // }, [isLoading, spaces, building_id, level_id, area_id, setValue, onSelect]);


  const filteredSpaces = useMemo(() => {

    if (!state.search || state.search.length < 2 || !Array.isArray(spaces)) {
      return [];
    }

    const searchLower = state.search.toLowerCase().trim();
    const MAX_RESULTS = 50;
    const results: { node: TreeNode; path: string[] }[] = [];

    const searchNodes = (nodes: TreeNode[], parentPath: string[] = []) => {
      for (const node of nodes) {
        if (results.length >= MAX_RESULTS) break;

        try {
          const currentPath = [...parentPath, node.title];
          const matchesSearch = node.title?.toLowerCase().includes(searchLower);

          if (matchesSearch) {
            results.push({
              node,
              path: currentPath
            });
          }

          if ('children' in node && Array.isArray(node.children) && node.children.length > 0) {
            searchNodes(node.children, currentPath);
          }
        } catch (error) {
          console.error('Error processing node in search:', error);
          continue;
        }
      }
    };

    try {

      //if there is a path, start the search from the last node in the path
      if (state.path.length > 0) {
        searchNodes(state.path[state.path.length - 1].children, state.path.map(node => node.title));
      } else {
        searchNodes(spaces);
      }

      return results;
    } catch (error) {
      console.error('Error in filteredSpaces:', error);
      return [];
    }
  }, [spaces, state.search, state.path]);
  // const resetSelection = useCallback(() => {
  //   setPath([]);
  //   setValue('building_id', null);
  //   setValue('level_id', null);
  //   setValue('area_id', null);
  //   onSelect && onSelect(null, null, null);
  //   setOpen(false);
  // }, [setValue, onSelect]);

  const resetSelection = useCallback(() => {
    console.debug('resetSelection:', state);
    setState(prev => ({
      ...prev,
      path: [],
      isOpen: false,
      selection: {
        buildingId: null,
        levelId: null,
        areaId: null
      },
      search: ''
    }));
  }, []);

  const goToLevel = useCallback((index: number) => {

    setState(prev => {

      const newPath = state.path.slice(0, index);
      const lastNode = newPath[newPath.length - 1];

      //console.debug('goToLevel:', { path: [...state.path], newPath, lastNode });


      console.debug('goToLevel:', { index, state });


      return ({
        ...prev,
        path: newPath,
        // pendingSelection: {
        //   //buildingId: lastNode?.building_id ?? null,
        //   buildingId: index === 0 ? null : lastSelection?.buildingId,
        // //  levelId: lastNode && 'level_id' in lastNode ? lastNode.level_id : null,
        //   levelId: index === 1 ? null :  lastSelection?.levelId,
        //   //areaId: lastNode && 'area_id' in lastNode ? lastNode.area_id : null
        //   areaId: null
        // }
      })
    });

    inputRef.current?.focus();
  }, [state.path, state.pendingSelection]);

  const handleSearch = useCallback((value: string) => {
    setState(prev => ({
      ...prev,
      search: value
    }));
  }, []);

  const handleSelect = useCallback((node: TreeNode | null) => {

    console.debug('handleSelect:', { node, state });

    const currentDepth = state.path.length;

    if (!node) {

      const newSelection = {
        buildingId: currentDepth >= 1 ? state.pendingSelection.buildingId : null,
        levelId: currentDepth >= 2 ? state.pendingSelection.levelId : null,
        areaId: null
      };

      setState(prev => ({
        ...prev,
        path: [],
        pendingSelection: newSelection,
        isOpen: false,
        selection: newSelection,
        search: ''
      }));

      return;
    }

    const newPath = [...state.path, node];

    const newPendingSelection = {
      buildingId: node.building_id,
      levelId: 'level_id' in node ? node.level_id : null,
      areaId: 'area_id' in node ? node.area_id : null
    };

    setState(prev => ({
      ...prev,
      path: newPath,
      isOpen: !('area_id' in node),
      pendingSelection: newPendingSelection,
      // Only update actual selection if area is selected or popover is closing
      selection: 'area_id' in node ? newPendingSelection : prev.selection,
      search: ''
    }));

    setTimeout(() => {
      inputRef.current?.focus();
    }, 20);

  }, [state.path, state.pendingSelection]);

  const handleCreate = useCallback(async (type: 'building' | 'level' | 'area', title: string) => {
    if (!canCreate) return;

    try {
      let newNode;

      switch (type) {
        case 'building':
          if (onCreateBuilding && state.path) {
            setIsCreating(true);
            newNode = await onCreateBuilding(undefined, title);
          }
          break;

        case 'level':
          if (onCreateLevel && state.path) {
            newNode = await onCreateLevel((state.path[0].original ?? state.path[0]) as Building, title);
          }
          break;

        case 'area':
          if (onCreateArea && state.path) {
            newNode = await onCreateArea((state.path[1].original ?? state.path[1]) as Floor, title);
          }
          break;
      }

      console.debug('handleCreate:', { newNode });
      if (newNode) {
        setState(prev => ({
          ...prev,
          search: ''
        }));
        setTimeout(() => {
          // Clear search after creation
          handleSelect(newNode);
          setIsCreating(false);
        }, 1000);
      } else {
        toast.error(`Error creating ${type}`);
        setIsCreating(false);
      }



    } catch (error) {
      console.error(`Error creating new ${type}:`, error);
      // Here you might want to show an error message to the user
      toast.error(`Error creating new ${type}`);
      setIsCreating(false);
    }
  }, [canCreate, onCreateBuilding, onCreateLevel, onCreateArea, state.path, state.pendingSelection]);

  const renderSearchResults = useMemo(() => {
    // console.debug('renderSearchResults:', { filteredSpaces, state });
    if (filteredSpaces.length === 0) {
      const currentLevel = state.path.length;

      // console.debug('renderSearchResults create new:', { currentLevel, state });

      // Early return for create new options
      return currentLevel <= 2 ? (
        <CommandGroup heading="Create New">
          <CommandItem
            onSelect={() => handleCreate(
              currentLevel === 0 ? 'building' :
                currentLevel === 1 ? 'level' : 'area',
              state.search
            )}
            value={state.search}
            className="text-blue-500 hover:text-blue-600"
          >
            + Create new {currentLevel === 0 ? 'Building' :
              currentLevel === 1 ? 'Level' : 'Space'} "{state.search}"
            {currentLevel > 0 && ` in ${state.path[currentLevel - 1].title}`}
          </CommandItem>
        </CommandGroup>
      ) : null;
    }

    // Improved grouping with type safety
    const groupedResults = filteredSpaces.reduce((acc, { node, path }) => {
      const [buildingName, levelName] = path;

      // Initialize building levels array if it doesn't exist
      if (!acc[buildingName]) {
        acc[buildingName] = {
          levels: new Set<string>(),
          rooms: {} as Record<string, Array<{ node: TreeNode; path: string[] }>>
        };
      }

      // Add level to the set
      acc[buildingName].levels.add(levelName);

      // Initialize and add rooms for this building+level combination
      const roomGroupKey = levelName ? `${buildingName} > ${levelName}` : buildingName;
      if (!acc[buildingName].rooms[roomGroupKey]) {
        acc[buildingName].rooms[roomGroupKey] = [];
      }
      acc[buildingName].rooms[roomGroupKey].push({ node, path });

      return acc;
    }, {} as Record<string, {
      levels: Set<string>,
      rooms: Record<string, Array<{ node: TreeNode; path: string[] }>>
    }>);

    return (
      <>
        {/* Buildings group */}
        <CommandGroup heading="Buildings">
          {Object.entries(groupedResults).map(([buildingName, building]) => {
            const buildingNode = state.path.find(node => node.title === buildingName);
            if (!buildingNode) return null;
            return (
              <CommandItem
                key={buildingNode.id}
                onSelect={() => handleSelect(buildingNode)}
              >
                {buildingName}
              </CommandItem>
            );
          })}
        </CommandGroup>

        {/* Levels and Rooms groups */}
        {Object.entries(groupedResults).map(([buildingName, building]) => (
          <Fragment key={buildingName}>
            {Object.entries(building.rooms).map(([groupKey, rooms]) => (
              <CommandGroup key={groupKey} heading={groupKey}>
                {rooms.map(({ node }) => (
                  <CommandItem
                    key={node.id}
                    onSelect={() => handleSelect(node)}
                  >
                    {node.title}
                  </CommandItem>
                ))}
              </CommandGroup>
            ))}
          </Fragment>
        ))}
      </>
    );


  }, [filteredSpaces, state.path, state.search, handleSelect, handleCreate]);

  const renderContent = useMemo(() => {

    if (isCreating) {
      return <CommandEmpty><Loader2 className="h-4 w-4 animate-spin" /> Creating...</CommandEmpty>;
    }

    if (state.search) {
      // console.debug('renderContent - search:', { state });
      return renderSearchResults;
    }

    if (!spaces || !Array.isArray(spaces)) {
      return <CommandEmpty>No spaces available</CommandEmpty>;
    }

    try {
      const currentLevel = state.path.length;
      let currentNodes: TreeNode[] = [];
      let nextLevelKey: string;
      let noSelectionText: string;

      switch (currentLevel) {
        case 0:
          currentNodes = spaces;
          nextLevelKey = 'Building';
          noSelectionText = 'No Building';
          break;
        case 1:
          currentNodes = state.path[0]?.children || [];
          nextLevelKey = 'Level';
          noSelectionText = 'No Level';
          break;
        case 2:
          currentNodes = state.path[1]?.children || [];
          nextLevelKey = 'Space';
          noSelectionText = 'No Space';
          break;
        default:
          return <CommandEmpty>Invalid navigation level</CommandEmpty>;
      }

      // console.debug('renderContent:', { currentLevel, currentNodes, nextLevelKey, noSelectionText });

      // if (!currentNodes.length) {

      //   // return <CommandEmpty>No {nextLevelKey.toLowerCase()}s available</CommandEmpty>;
      //   return (
      //     <CommandGroup heading={`Select ${nextLevelKey}`}>
      //            <CommandItem
      //       onSelect={() => handleSelect(null)}
      //       className={noSelectionClass}
      //       data-testid={`no-selection-${nextLevelKey.toLowerCase()}`}
      //     >
      //       ({noSelectionText})
      //     </CommandItem>
      //     <CommandEmpty>No {nextLevelKey.toLowerCase()}s available</CommandEmpty>
      //     </CommandGroup>
      //   )
      // }

      return (
        <CommandGroup heading={`Select ${nextLevelKey}`}>
          <CommandItem
            onSelect={() => handleSelect(null)}
            className={noSelectionClass}
            data-testid={`no-selection-${nextLevelKey.toLowerCase()}`}
          >
            ({noSelectionText})
          </CommandItem>
          {currentNodes?.length === 0 ? (
            <CommandItem disabled>No {nextLevelKey.toLowerCase()}s available. Type to create a new level.</CommandItem>
          ) :
            currentNodes.map((node) => (
              <CommandItem
                key={node.id}
                onSelect={() => handleSelect(node)}
                className="flex justify-between items-center"
                data-testid={`node-${node.id}`}
              >
                <span className="truncate">{node.title}</span>
                {isNodeWithChildren(node) && node.children.length > 0 && (
                  <ChevronRight className="h-4 w-4 flex-shrink-0 opacity-50" />
                )}
              </CommandItem>
            ))}
        </CommandGroup>
      );
    } catch (error) {
      console.error('Error rendering content:', error);
      return <CommandEmpty>An error occurred while rendering content</CommandEmpty>;
    }
  }, [spaces, state.search, state.path, handleSelect, renderSearchResults]);

  // useEffect(() => {
  //   console.debug('Search state:', {
  //     search: state.search,
  //     filteredSpaces,
  //     isOpen: state.isOpen
  //   });
  // }, [state.search, filteredSpaces, state.isOpen]);

  const handleOpen = useCallback((newOpen: boolean) => {
    console.debug('handleOpen:', newOpen, state);
    setState(prev => ({
      ...prev,
      isOpen: newOpen,
      search: newOpen ? prev.search : '',
      previousPath: newOpen ? prev.path : prev.previousPath,
      path: newOpen ? [] : prev.previousPath,
      // Update selection when closing the popover
      selection: !newOpen ? prev.pendingSelection : prev.selection,
      // Reset pending selection when opening
      pendingSelection: newOpen ? prev.selection : prev.pendingSelection
    }));
  }, []);

  const renderFixedHeader = useMemo(() => {
    if (state.search || !state.path.length) return null;

    return (
      <div
        className="sticky top-0 bg-white dark:bg-gray-800 p-2 border-b flex items-center justify-between text-sm text-muted-foreground"
        role="navigation"
        aria-label="Navigation breadcrumb"
      >
        <div className="flex items-center space-x-1 overflow-x-auto">
          {state.path.map((node, index) => (
            <span
              key={`path-${node.id}-${index}`}
              className="flex items-center min-w-0"
            >
              {index > 0 && (
                <ChevronRight
                  className="h-4 w-4 mx-1 flex-shrink-0 text-muted-foreground"
                  aria-hidden="true"
                />
              )}
              <button
                onClick={() => goToLevel(index)}
                className="hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2 rounded px-1 truncate"
                aria-label={`Go to ${node.title} level`}
              >
                {node.title}
              </button>
            </span>
          ))}
        </div>
        <Button
          variant="ghost"
          size="icon"
          onClick={resetSelection}
          className="h-6 w-6 flex-shrink-0"
          aria-label="Reset selection"
        >
          <X className="h-4 w-4" />
        </Button>
      </div>
    );
  }, [state.search, state.path, goToLevel, resetSelection]);

  const getDisplayValue = useMemo(() => {

    if (isLoading) return "Loading...";
    if (!state.path.length) {
      return <div>(No Building)</div>;
    }

    if (isCreatingRef.current) return "Creating...";

    // console.debug('getDisplayValue:', { path: { ...state.path }, ...state.selection, isCreating: isCreatingRef.current });

    try {
      return (
        <div className="flex flex-col items-start gap-2">
          {state.path.map((node, index) => {
            // Type guard for better type safety
            const isLastNode = index === state.path.length - 1;

            // Truncate long titles
            const title = node.title.length > 30
              ? `${node.title.slice(0, 27)}...`
              : node.title;

            if (index === 0 && !state.selection.levelId) {
              return (
                <Fragment key={`path-${index}`}>
                  <div>{node.title}</div>
                  {!state.isOpen && <div>(No Level)</div>}
                </Fragment>
              );
            }

            if (index === 1 && !state.selection.areaId) {
              return (
                <Fragment key={`path-${index}`}>
                  <div>{node.title}</div>
                  {!state.isOpen && <div>(No Space)</div>}
                </Fragment>
              );
            }

            return (
              <div key={`path-${index}`}>
                {title}
              </div>
            );
          })}
        </div>
      );

    } catch (error) {
      console.error('Error generating display value:', error);
      return <div>(Error)</div>;
    }
  }, [isLoading, state.path, state.selection, state.isOpen]);


  // if (!spaces || !Array.isArray(spaces) || spaces.length === 0) {
  //   return (
  //     <Button
  //       variant="outline"
  //       role="combobox"
  //       className="h-full w-full justify-between"
  //       disabled
  //     >
  //       No spaces available
  //     </Button>
  //   );
  // }

  // useEffect(() => {
  //   console.debug('spaceSelector state:', { state });
  // }, [state]);

  const { viewportRef } = useTestFormContext();

  return (
    <div className="space-y-2">
      <Popover open={state.isOpen} onOpenChange={handleOpen} modal={true}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={state.isOpen}
            className="h-full w-full justify-between"
            onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleOpen(true); }}
            disabled={disabled}
          >
            <div className="truncate">{getDisplayValue}</div>
            <ChevronDown className="h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0"
          onOpenAutoFocus={e => e.preventDefault()}
          modal={true}
          sticky="always"
          updatePositionStrategy="always"
          collisionPadding={10}
          collisionBoundary={viewportRef?.current}
        >
          <Command>
            {isLoading ? (
              <div className="flex items-center justify-center p-6 space-x-2">
                <Loader2 className="h-4 w-4 animate-spin" />
                <span className="text-sm text-muted-foreground">
                  {"Loading spaces..."}
                </span>
              </div>
            ) : (
              <>
                <CommandInput
                  placeholder="Search spaces..."
                  value={state.search}
                  onValueChange={handleSearch}
                  className="w-full"
                  aria-label="Search spaces"
                  disabled={isLoading}
                  autoFocus={true}
                  ref={inputRef}

                />

                {renderFixedHeader}
                <ScrollArea className="max-h-[300px] overflow-y-auto">
                  {renderContent}
                </ScrollArea>
              </>
            )}
          </Command>
        </PopoverContent>
      </Popover>
      <div className="">
        <Controller
          name="building_id"
          control={control}
          defaultValue={null}
          render={({ field }) => (
            <input type="hidden" {...field} value={field.value ?? ''} />
          )}
        />
        <Controller
          name="level_id"
          control={control}
          defaultValue={null}
          render={({ field }) => (
            <input type="hidden" {...field} value={field.value ?? ''} />
          )}
        />
        <Controller
          name="area_id"
          control={control}
          defaultValue={null}
          render={({ field }) => (
            <input type="hidden" {...field} value={field.value ?? ''} />
          )}
        />
      </div>
    </div>
  );
}
