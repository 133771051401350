import { useNav } from "@/app/features/profile/NavContext"
import ReportEditModeToggle from "@/app/features/reports/components/edit-mode-toggle"
import { useReportContext } from "@/app/features/reports/contexts/report-context"
import BusyButton from "@/components/raytd/busy-button"
import { SubPageLayout } from "@/components/raytd/sub-page-layout"
import { useUnsavedChanges } from "@/hooks/useUnsavedChanges"
import { useCallback, useEffect } from 'react'
import { toast } from "sonner"
import { ManagedCustomSectionContainer } from './components/custom-section-container'
import { HeaderSection } from "./components/header-section"
import { InspectedAssetsSection } from "./components/inspected-assets-section"
import { PreparedSection } from "./components/prepared-section"
import { RatingSystemSection } from "./components/rating-system-section"
import { ReportSectionProvider, useReportSectionPage } from './components/report-section-context'
import { SectionText } from './components/section-text'
interface IntroductionEditorProps {
}

function IntroductionEditor({  }: IntroductionEditorProps) {
    const { report: reportData, isLoading, isEditable } = useReportContext();
    const { sectionManager, editMode } = useReportSectionPage();
    const { sections, saveSections, isBusy, isDirty } = sectionManager;

    const reset = useCallback(() => {
        console.log('reset');
    }, []);

    const { AlertDialogComponent } = useUnsavedChanges(isDirty, reset, false);

    useEffect(() => {
        console.debug('Report data:', reportData)
    }, [reportData])

    const { setNavButtons } = useNav();

    const onSubmit = useCallback(async () => {
        console.log('submit', sections);
        try {
            await saveSections();
            toast.success('Page content saved');
        } catch (error) {
            console.error('Error saving sections:', error);
            toast.error('Error saving page content');
        }

    }, [sections]);

    useEffect(() => {
        setNavButtons(
                editMode && <BusyButton
                    onClick={onSubmit}
                    disabled={!isDirty}
                    isBusy={isBusy}
                    busyText="Saving..."
            >
                Save
                </BusyButton>
        );

        // Clean up function to reset buttons when component unmounts
        return () => setNavButtons(null);
    }, [onSubmit, isDirty, isBusy, editMode]);

    if (isLoading) {
        return <div>Loading...</div>
    }

    return (
        <SubPageLayout title="Introduction">
            <SubPageLayout.Header>
                <ReportEditModeToggle />
            </SubPageLayout.Header>

            <HeaderSection report={reportData as any} editMode={editMode} />

            <SectionText
                position="introduction-header"
                className="my-2"
            />

            <ManagedCustomSectionContainer
                position="introduction-after-header"
            />

            <PreparedSection
                client={reportData.client}
                authors={reportData.collaborators || []} editMode={editMode}
            />

            <SectionText
                position="introduction-prepared"
            />

            <ManagedCustomSectionContainer
                position="introduction-after-prepared"
            />

            <InspectedAssetsSection
                assets={reportData.assets as any || []}
            />

            <SectionText
                position="introduction-assets"
            />

            <ManagedCustomSectionContainer
                position="introduction-after-assets"
            />

            <RatingSystemSection
                report={reportData as any}
            />

            <ManagedCustomSectionContainer
                position="introduction-after-rating"
            />

            <ManagedCustomSectionContainer
                position="introduction-conclusion"
            />

            <AlertDialogComponent />

        </SubPageLayout>
    )
}


export default function Page() {
    return (
        <ReportSectionProvider
            page="introduction"
        >
            <IntroductionEditor />
        </ReportSectionProvider >
    )

};
