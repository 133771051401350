import { ReportItem } from "@/app/features/reports/utils/entities";

const collectAllGroups = (items: ReportItem[], allIds = new Set<string>(), includeContentGroups = true) => {
    items.forEach(item => {
        if (!includeContentGroups && item.content?.length > 0) {
        } else {
            allIds.add(item.id);
        }
        if (item?.children?.length > 0) {
            collectAllGroups(item.children, allIds, includeContentGroups);
        }

    });
    return allIds;
};

export default collectAllGroups;