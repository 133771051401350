
import { FlattenedItem, ReportItem, ReportItemContent } from './entities';


const createFlattenedItem = (
    item: ReportItem | ReportItemContent,
    scores: ScoreResult,
    isExpanded: boolean,
    level = 0,
    isContent: boolean,
    currentParents: string[]

): FlattenedItem => ({
    id: item.id,
    title: item.title,
    score: scores.totalScore > 0 ? (scores.totalScore / scores.averageCount).toFixed(2) : undefined,
    level,
    hasChildren: !isContent,
    hasContent: 'content' in item && Boolean(item.content?.length),
    length: ('children' in item && item.children?.length) || ('content' in item && item.content?.length),
    content: isContent,
    isExpanded,
    count: scores.count,
    ratingTypes: Array.from(scores.ratingTypes),
    testCase: isContent ? (item as ReportItemContent).testCase : undefined,
    parents: currentParents,
    missingCount: scores.missingCount,
    fastFillCount: scores.fastFillCount,
    nonFinalCount: scores.nonFinalCount,
    excludedCount: scores.excludedCount,
    includedCount: scores.averageCount,
});


const createEmptyNode = (parentId: string, level: number): FlattenedItem => ({
    id: `${parentId}-empty`,
    title: 'No tests',
    score: '',
    level,
    hasChildren: false,
    hasContent: false,
    content: false,
    isEmptyNode: true,
    ratingTypes: [],
    length: 0,
});

const isReportItem = (item: any): item is ReportItem => {
    return 'id' in item && 'title' in item;
};

const hasContent = (item: ReportItem): boolean => {
    return 'content' in item && Array.isArray(item.content) && item.content.length > 0;
};

const hasChildren = (item: ReportItem): boolean => {
    return 'children' in item && Array.isArray(item.children) && item.children.length > 0;
};

interface ScoreResult {
    totalScore: number; // Sum of valid scores
    count: number; // Number of items
    averageCount: number; // Number of items with valid scores
    ratingTypes: Set<string>;
    missingCount: number; // Number of items with missing data
    fastFillCount: number; // Number of items with fast fill errors
    excludedCount: number; // Number of items manually excluded
    nonFinalCount: number; // Number of items not final
}

const createInitialScoreResult = (): ScoreResult => ({
    totalScore: 0,
    count: 0,
    averageCount: 0,
    missingCount: 0,
    fastFillCount: 0,
    nonFinalCount: 0,
    excludedCount: 0,
    ratingTypes: new Set<string>()
});

const accumulateScores = (scores, contentScores) => {
    scores.totalScore += contentScores.totalScore;
    scores.count += contentScores.count;
    scores.averageCount += contentScores.averageCount;
    scores.missingCount += contentScores.missingCount;
    scores.fastFillCount += contentScores.fastFillCount;
    scores.nonFinalCount += contentScores.nonFinalCount;
    scores.excludedCount += contentScores.excludedCount;
    contentScores.ratingTypes.forEach(type => scores.ratingTypes.add(type));
};

const getContentScores = (content: ReportItemContent[]): ScoreResult => {
    const scores = createInitialScoreResult();

    content.forEach(contentItem => {
        const { score, testCase } = contentItem;
        scores.count++;

        // check if excluded
        const included = testCase?.exclusionReason === 'included';

        if (included && score !== null && score >= 0 && score <= 5) {
            scores.totalScore += score;
            scores.averageCount++;
        }

        if (included && testCase?.ratingType) {
            scores.ratingTypes.add(testCase.ratingType);
        }

        if (testCase?.exclusionReason === 'missing') {
            scores.missingCount++;
        } else if (testCase?.exclusionReason === 'fastfill') {
            scores.fastFillCount++;
        } else if (testCase?.exclusionReason === 'status') {
            scores.nonFinalCount++;
        } else if (testCase?.exclusionReason === 'excluded') {
            scores.excludedCount++;
        }

    });

    return scores;
};


const globalScoreCache = new WeakMap<ReportItem, ScoreResult>();

const calculateScores = (item: ReportItem): ScoreResult => {
    // Check cache first
    if (globalScoreCache.has(item)) {
        return globalScoreCache.get(item)!;
    }

    const scores = createInitialScoreResult();

    // Calculate content scores if present
    if (item.content?.length) {
        accumulateScores(scores, getContentScores(item.content));
    }

    // Calculate children scores if present
    if (item.children?.length) {
        for (const child of item.children) {
            accumulateScores(scores, calculateScores(child));
        }
    }

    // Cache and return result
    globalScoreCache.set(item, scores);
    return scores;
};


const calculateFlattenedIndex = (
    data: ReportItem[], 
    targetId: string | number,
    expandedIds: Set<string>
): number => {
    let currentIndex = 0;
    let foundIndex = -1;

    const traverse = (
        items: ReportItem[],
        level = 0,
        isContent = false
    ): boolean => {
        for (const item of items) {
            // Check if this is our target
            if (item.id === String(targetId)) {
                foundIndex = currentIndex;
                return true;
            }
            currentIndex++;

            // If this item is expanded, traverse its children and content
            if (expandedIds.has(item.id)) {
                // Check children
                if (hasChildren(item)) {
                    if (traverse(item.children, level + 1, false)) {
                        return true;
                    }
                }

                // Check content
                if (hasContent(item)) {
                    const content = item.content as ReportItemContent[];
                    if (traverse(content as unknown as ReportItem[], level + 1, true)) {
                        return true;
                    }
                }

                // Add empty node if no children or content
                if (!hasContent(item) && !hasChildren(item)) {
                    currentIndex++;
                }
            }
        }
        return false;
    };

    traverse(data);
    return foundIndex;
};

export { accumulateScores, calculateFlattenedIndex, calculateScores, createEmptyNode, createFlattenedItem, createInitialScoreResult, getContentScores, hasChildren, hasContent, isReportItem };
