import { useCallback, useEffect, useMemo, useState } from 'react';
import { TextSection } from '../types/report';
import { rAPI, useAppDispatch, useGetReportQuery, useUpsertReportSectionsMutation } from '@app.raytd.com/store';
import { useReportContext } from '@/app/features/reports/contexts/report-context';
import { ReportEntity, ReportSection } from 'store/src/lib/services/types';
import { TElement } from '@udecode/plate-common';

export type SectionPosition =
    | 'afterTestDistribution'
    | 'beforeTestDistribution'
    | 'summary'
    | 'conclusion';

type SectionManagerProps = {
    sections: TextSection[];
    addSection: (newSection: TextSection) => void;
    updateSectionsByPosition: (position: SectionPosition, newSections: TextSection[]) => void;
    removeSection: (sectionId: string) => void;
    getSectionsByPosition: (position: SectionPosition) => TextSection[];
    updateSectionContent: (sectionId: string, content: string) => void;
    moveSectionPosition: (sectionId: string, newPosition: SectionPosition) => void;
    reorderSections: (position: SectionPosition, startIndex: number, endIndex: number) => void;
    bulkUpdateSections: (newSections: TextSection[]) => void;
    saveSections: () => Promise<void>;
    isBusy: boolean;
    isDirty: boolean;
};

export function useSectionManager(report: ReportEntity, key: string): SectionManagerProps {
    const [sections, setSections] = useState<TextSection[]>([]);
    const [originalSections, setOriginalSections] = useState<TextSection[]>([]);
    const dispatch = useAppDispatch();

    const [upsertSections, { isLoading: isSaving }] = useUpsertReportSectionsMutation({
        fixedCacheKey: `reportSections-${report?.id}`
    });
   
    if (!key) {
        throw new Error('key is required');
    }

    useEffect(() => {
        console.debug('sections', sections);
    }, [sections]);

    // useEffect(() => {
    //     if (report?.sections) {
    //       const filteredSections = report.sections
    //         .filter(section => section.position.startsWith(key))
    //         .map(section => ({
    //           ...section,
    //           content: section.content || ''
    //         }));
            
    //       // Only update if sections have actually changed
    //       if (JSON.stringify(filteredSections) !== JSON.stringify(sections)) {
    //         setSections(filteredSections);
    //         setOriginalSections(filteredSections);
    //       }
    //     }
    //   }, [report, key, sections]);

    useEffect(() => {

        if (report) {
            const filteredSections = report.sections?.filter(
                section => section.position.startsWith(key)
            ).map(section => normaliseSections(section));
            console.debug('filteredSections', filteredSections);
            setSections(filteredSections);
            setOriginalSections(filteredSections);
        }
    }, [report, key]);

    const isDirty = useMemo(() => {
        if (sections?.length !== originalSections?.length) return true;

        return JSON.stringify(sections) !== JSON.stringify(originalSections);

    }, [sections, originalSections]);

    const addSection = useCallback((newSection: TextSection) => {
        console.log('reportId', report?.id);
        setSections(prev => [...prev, {
            ...newSection,
            report_id: Number(report?.id)
        }]);
    }, [report?.id]);

    const updateSectionsByPosition = useCallback((position: SectionPosition, newSections: TextSection[]) => {
        setSections(prev => [
            ...prev.filter(section => section.position !== position),
            ...newSections.map(section => ({ ...section, report_id: Number(report?.id) }))
        ]);
    }, [report?.id]);

    const removeSection = useCallback((sectionId: string) => {
        setSections(prev => prev.filter(section => section.id !== sectionId));
    }, []);

    const getSectionsByPosition = useCallback((position: SectionPosition) => {
        return sections.filter(section => section.position === position);
    }, [sections]);

    const updateSectionContent = useCallback((sectionId: string, content: string) => {
        console.debug('updateSectionContent', sectionId, content);
        setSections(prev =>
            prev.map(section =>
                section.id === sectionId
                    ? { ...section, content, deleted: content === '' }
                    : section
            )
        );
    }, []);

    const moveSectionPosition = useCallback((sectionId: string, newPosition: SectionPosition) => {
        setSections(prev =>
            prev.map(section =>
                section.id === sectionId
                    ? { ...section, position: newPosition }
                    : section
            )
        );
    }, []);

    const reorderSections = useCallback((position: SectionPosition, startIndex: number, endIndex: number) => {
        setSections(prev => {
            const positionSections = prev.filter(section => section.position === position);
            const otherSections = prev.filter(section => section.position !== position);

            const [removed] = positionSections.splice(startIndex, 1);
            positionSections.splice(endIndex, 0, removed);

            return [...otherSections, ...positionSections];
        });
    }, []);

    const bulkUpdateSections = useCallback((newSections: TextSection[]) => {
        setSections(newSections);
    }, []);

    const saveSections = useCallback(async () => {
        try {
            const updatedSections = await upsertSections({
                reportId: Number(report?.id),
                sections
            }).unwrap();

            // Optimistically update the cache
            dispatch(
                rAPI.util.updateQueryData('getReport', report?.id?.toString() ?? '', (draft) => {
                    draft.sections = updatedSections;
                })
            );

            bulkUpdateSections(updatedSections);
            setOriginalSections(updatedSections);
        } catch (error) {
            console.error('Failed to save sections:', error);
            throw error;
        }
    }, [sections, upsertSections, report?.id, bulkUpdateSections, dispatch]);

    return {
        sections,
        addSection,
        updateSectionsByPosition,
        removeSection,
        getSectionsByPosition,
        updateSectionContent,
        moveSectionPosition,
        reorderSections,
        bulkUpdateSections,
        saveSections,
        isBusy: isSaving,
        isDirty
    };
}


const normalizeNode = (node) => {
    if (typeof node.text === 'undefined' && !node.children) {
      // Handle case where text is missing entirely
      return { ...node, text: '' };
    }
    
    if (node.text === null) {
      // Replace null text with empty string
      return { ...node, text: '' };
    }
    
    if (node.children) {
      // Recursively normalize children
      return {
        ...node,
        children: node.children.map(child => 
          normalizeNode(child)
        )
      };
    }
    
    return node;
  };

const normaliseSections = (sections: ReportSection) => {
    return {
        ...sections,
        content: (sections.content as unknown as TElement[])?.map(node => normalizeNode(node))
    } as unknown as ReportSection  ;
}
