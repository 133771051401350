import FullScreenAlert from "@/components/raytd/full-screen-alert";
import { AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";

export function ReportLinkExpiredAlert() {
    return (
        <FullScreenAlert buttons={
            <>
                <Button variant="default">
                    <Link to="/login">
                        Login
                    </Link>
                </Button>
                <Button variant="outlinelight">
                    <a href="https://www.raytd.com">
                        Go to Raytd
                    </a>
                </Button>
            </>
        }>
            <AlertTitle className="text-lg font-medium mb-6">
                The shareable link for this report is no longer active.
            </AlertTitle>
            <AlertDescription>
                If you have previously saved this report saved to your account, you may have been provided access to a new revision.
            </AlertDescription>
        </FullScreenAlert>
    )
}

