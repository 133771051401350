import { NavLink, TopNavBar } from "@/app/features/profile/TopNavBar";
import { useReportContext } from "@/app/features/reports/contexts/report-context";
import { cn } from "@/lib/utils";
import React from "react";

const PublishedReportHeader: React.FC<{ compact?: boolean }> = ({ compact }) => {

    const { report } = useReportContext();

    return (
        <header
            className={cn("flex items-center gap-4 border-none",
                "justify-start px-1 overflow-hidden",
                "transition-all duration-400 ease-in-out",
                compact ? "bg-white shadow-md h-[0px]" : "h-[60px]"
            )}>
            <div className="flex items-center">
                <img src="/raytd_beta_logo.png" alt="logo" className="h-10 w-auto" />
            </div>
            <TopNavBar className="px-10 rounded-lg bg-zinc-50" >
                <NavLink to="introduction" visible={report?.section_config?.includeIntroduction}>
                    Introduction
                </NavLink>
                <NavLink to="summary" visible={report?.section_config?.includeSummary}>
                    Summary
                </NavLink>
                <NavLink to="results" visible={report?.section_config?.includeResults}>
                    Results
                </NavLink>
                <NavLink to="terms" visible={report?.section_config?.includeTerms}>
                    Terms & Conditions
                </NavLink>
            </TopNavBar>
            {/* <BreadcrumbNavigation /> */}
        </header>
    );
};

export default PublishedReportHeader;