import { Button } from "@/components/ui/button"
import { Trash2, GripVertical } from 'lucide-react'
import { TextEditor } from './text-editor'
import { PlateViewer } from "@/components/editor/plate-editor";
import { forwardRef } from "react";

interface CustomSectionProps {
  id: string;
  content: string;
  onDelete: () => void;
  onUpdate: (id: string, newContent: string) => void;
  onEdit: (editing: boolean) => void;
  editMode: boolean;
  handleRef: React.RefObject<HTMLDivElement>;
}

export const CustomSection = forwardRef<HTMLDivElement, CustomSectionProps>(
  ({ id, content, onDelete, onUpdate, editMode, onEdit, handleRef }, ref) => {
    if (!editMode) {
      return <div className="py-4 border-b border-zinc-200">
        <PlateViewer initialValue={content} />
      </div>;
    }

    console.debug('CustomSection', { editMode, handleRef });

    return (
      <div className="flex items-center space-x-4 p-4 bg-white rounded-lg">
        {editMode && handleRef && (
          <div ref={handleRef} className="cursor-move h-5 w-5">
            <GripVertical className="h-5 w-5 text-zinc-400" />
          </div>
        )}
        <div className="flex-grow">
          <TextEditor
            ref={ref}
            initialContent={content}
            onSave={(newContent) => onUpdate(id, newContent)}
            editMode={editMode}
            onEdit={onEdit}
          />
        </div>
        <Button
          variant="outline"
          size="sm"
          onClick={onDelete}
        >
          <Trash2 className="h-4 w-4" />
        </Button>
      </div>
    )
  }
)

CustomSection.displayName = 'CustomSection'

