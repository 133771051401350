"use client"

import type React from "react"

import { useCallback, useState } from "react"
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import type { FormProps, LoginFormData } from "../types/welcome"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form"
import { LoginCredentials } from "@app.raytd.com/store"
import { useNavigate } from "react-router-dom"
import { useLogin } from "@app.raytd.com/ui"
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
import { Loader2 } from "lucide-react"

const loginFormSchema = z.object({
  email: z.string().email("Please enter a valid email address"),
  password: z.string().min(6, "Password must be at least 6 characters"),
})

interface LoginFormProps {
  onLoginSuccess: (data: LoginFormData) => void
}

export function LoginForm({ onLoginSuccess }: LoginFormProps) {
  const form = useForm<LoginFormData>({
    resolver: zodResolver(loginFormSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  })

  const { login, error: loginError, isLoading: isLoginLoading } = useLogin();
  const [error, setError] = useState<string | null>(null)

  const handleLoginSubmit = useCallback(async (data: LoginCredentials) => {
    const success = await login(data);
    if (success) {
      onLoginSuccess(data)
    } else {
      console.error("Failed to login:", loginError)
      setError(loginError)
    }
  }, [login, onLoginSuccess, loginError])

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleLoginSubmit)} className="space-y-4 px-6 pb-6">
        {error && (
          <Alert variant="default">
            <AlertTitle>Login Error</AlertTitle>
            <AlertDescription>
              {error}
            </AlertDescription>
          </Alert>
        )}
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input placeholder="Enter your email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Password</FormLabel>
              <FormControl>
                <Input type="password" placeholder="Enter your password" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit" className="w-full" disabled={isLoginLoading}>
          {isLoginLoading ? <><Loader2 className="mr-2 h-4 w-4 animate-spin" /> Logging in...</> : "Log In"}
        </Button>
      </form>
    </Form>
  )
}

