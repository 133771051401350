import { useTestFormContext } from './test-form/context'
import useTestStatus from "../hooks/use-test-status"
import { getStatusLabel, getStatusRequiresValidation, getStatusVariant, TEST_STATUSES } from "../utils/testStatus"
import { Button } from "@/components/ui/button"
import { cn } from "@/lib/utils"
import React, { useCallback, useMemo } from "react"
import type { TestStatus } from "../types/test-form"

interface StatusIndicatorProps {
  // onStatusChange: (status: TestStatus) => Promise<void> | void
}

export const StatusIndicator: React.FC<StatusIndicatorProps> = ({
  // currentStatus,
  // onStatusChange,
  // saveStatus,
}) => {

  const { isStatusDisabled, currentStatus } = useTestStatus();

  const { isFormValid, onStatusChange } = useTestFormContext();

  const buttonClassNames = useMemo(() =>
    TEST_STATUSES.reduce((acc, status) => ({
      ...acc,
      [status]: cn(
        "capitalize",
        getStatusVariant(status),
        currentStatus === status ? "ring-none ring-offset-0" : "",
        getStatusRequiresValidation(status) && !isFormValid
          ? "opacity-50 cursor-not-allowed text-red-500 bg-zinc-200"
          : "",
      )
    }), {} as Record<TestStatus, string>),
    [currentStatus, isFormValid, getStatusVariant]
  );

  const handleStatusChange = useCallback(async (status: TestStatus) => {
    try {
      await onStatusChange?.(status);
    } catch (err) {
      console.error('Failed to change status:', err);
      // You might want to add proper error handling here
    }
  }, [onStatusChange]);

  return (
    <section aria-label="Status Selection" className="relative">
      <div className="flex items-center gap-2">
        {TEST_STATUSES.map((status) => (
          <Button
            key={status}
            variant="outline"
            className={buttonClassNames[status]}
            onClick={() => handleStatusChange(status)}
            disabled={isStatusDisabled(status)}
            aria-label={`Set status to ${getStatusLabel(status)}`}
            aria-current={currentStatus === status ? 'true' : 'false'}
          >
            {getStatusLabel(status)}
          </Button>
        ))}
      </div>
    </section>
  )
}

