import { useAuth } from "@/app/_components"
import { SignupPage } from "@/app/features/auth/SignupPage"
// import { PublishedReport } from "@/app/features/report/PublishedReport"
import { PublicReportLayoutContent } from "@/app/features/reports/viewer"
import LoadingComponent from "@/app/features/reports/published/loading"
import { api, authActions, isAPIError, LoginCredentials, setSharedReportAccess, useAppDispatch, useCreateTokenForReportMutation, useGetReportByUuidQuery, useSaveReportLinkMutation } from "@app.raytd.com/store";
import { NotFound } from "./components/NotFound"

import { skipToken } from "@reduxjs/toolkit/query"
import { useCallback, useEffect, useMemo, useState } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { Navigate, Route, Routes, useNavigate, useParams } from "react-router-dom"
import { WelcomeLayout } from "./components/WelcomeLayout"
import ReportErrorAlert from "@/app/features/reports/published/report-error-alert";
import { ReportErrorFallback } from "@/app/features/reports/components/report-error-fallback";
import { ReportLinkExpiredAlert } from "@/app/features/reports/published/report-token-expired";
import { useLogin } from "@app.raytd.com/ui";
import { toast } from "sonner";


export function PublicReportViewer() {
    const { uuid } = useParams()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { isAuthenticated } = useAuth()

    const { login, error: loginError, isLoading: isLoginLoading } = useLogin();

    // State to track if we have a valid token
    const [hasToken, setHasToken] = useState<boolean>(false)
    const [showUserForm, setShowUserForm] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)

    // Create token mutation
    const [createTokenForReport, { isLoading: isCreatingToken }] = useCreateTokenForReportMutation()

    // Get report query
    const {
        data: report,
        isLoading: isValidatingReport,
        error: reportError,
        isError: isReportError,
        refetch: refetchReport
    } = useGetReportByUuidQuery(
        uuid ? { uuid } : skipToken,
        {
            skip: !uuid,
        }
    )

    useEffect(() => {
        const storedToken = sessionStorage.getItem('sharedReportToken')
        const storedReportData = sessionStorage.getItem('sharedReportData')

        if (storedToken && storedReportData) {
            console.debug("storedToken", storedToken, isAuthenticated)

            //are they authenticated ? if not we need to fix that.

            // if (!isAuthenticated) {
            //     dispatch(authActions.loginWithToken(storedToken)).then(() => {
            //         console.debug("loginWithToken", isAuthenticated)
            //     })
            //     .catch((error) => {
            //         console.error("loginWithToken", error)
            //     })
            // } else {
            // Restore from session storage
            dispatch(setSharedReportAccess(JSON.parse(storedReportData)))
            setHasToken(true)

            // }

        } else {
            // No token in storage, we'll need to validate the UUID first
            setHasToken(false)
        }
    }, [dispatch, uuid, isAuthenticated])

    useEffect(() => {
        if (isReportError && reportError && isAPIError(reportError) &&
            reportError.status === 401 && reportError.data?.errorCode === "NO_TOKEN_PROVIDED") {
            setShowUserForm(true)
        }
    }, [reportError])

    const handleGuestSubmit = async (data: { email: string; name: string }) => {
        try {
            const response = await createTokenForReport({
                uuid: uuid as string,
                name: data.name,
                email: data.email
            }).unwrap()

            // Store token data
            const tokenData = {
                token: response.token,
                report_id: response.report_id,
                revision_id: response.revision_id
            }

            // Save to session storage
            sessionStorage.setItem('sharedReportToken', response.token)
            sessionStorage.setItem('sharedReportData', JSON.stringify(tokenData))

            // Update Redux state
            dispatch(setSharedReportAccess(tokenData))

            // Update component state
            setHasToken(true)
            setShowUserForm(false)
            await refetchReport()
        } catch (error) {
            console.error("Failed to submit guest form:", error)
        }
    }

    const [saveReportLink, { isLoading: isSavingReportLink }] = useSaveReportLinkMutation();

    const handleLoginSuccess = useCallback(async (data: LoginCredentials) => {
        try {
            const response = await refetchReport();
            const report = response.data?.report;
            const revision = response.data?.revision;
            console.debug('handleLoginSuccess', { response, report, revision     })
            try {
                await saveReportLink(Number(revision?.id)).unwrap();
                toast.success('Report saved');
            } catch (error) {
                console.error("Failed to save report link:", error);
                toast.error('Failed to save report link');
            }

            setShowUserForm(false)
            navigate(`/reports/${report?.id}/published/${revision?.id}`)
        } catch (error) {
            console.error("Failed to save report link:", error)
        }
    }, [navigate, saveReportLink, refetchReport])

    // Determine what to render based on current state
    const renderContent = useMemo(() => {
        // Show loading state while validating report or creating token
        if (isValidatingReport || isCreatingToken || isSavingReportLink) {
            return <LoadingComponent isLoading={true} />
        }

        // Handle errors
        if (reportError && isAPIError(reportError)) {
            // Not found error
            if (reportError.status === 404) {
                if (reportError.data?.errorCode === 'REPORT_LINK_EXPIRED') {
                    return <ReportLinkExpiredAlert />
                }
                return <ReportErrorAlert message="Report not found" />
            }

            // If we need to show the user form (401 with NO_TOKEN_PROVIDED)
            if (showUserForm) {
                return (
                    <WelcomeLayout
                        onGuestSubmit={handleGuestSubmit}
                        onLoginSuccess={handleLoginSuccess}
                        onCreateAccount={() => handleLoginSuccess(undefined)}
                    />
                )
            }

            // Other errors
            return <ReportErrorAlert message="An unexpected error occurred" />
        }

        // If we have a report, show it
        if (report && !showUserForm) {
            if (isAuthenticated) {
                // Navigate to the report
                navigate(`/reports/${report.report.id}/published/${report.revision.id}`)
                return null
            }

            return <PublicReportLayoutContent report={report.report} revision={report.revision} />
        }

        // Fallback - should not reach here in normal flow
        return <div>Something went wrong</div>
    }, [isValidatingReport, isCreatingToken, reportError, showUserForm, report, isAuthenticated, navigate, isSavingReportLink])

    const handleError = (error: Error, info: { componentStack: string }) => {
        console.error('Report viewer error:', error)
        console.error('Component stack:', info.componentStack)
    }

    return (
        <ErrorBoundary
            FallbackComponent={ReportErrorFallback}
            onError={handleError}
            onReset={() => {
                // Reset any state that might have caused the error
                setHasToken(false)
                setShowUserForm(false)
                refetchReport()
            }}
        >
            <Routes>
                <Route
                    path="/*"
                    element={renderContent}
                >
                    <Route path="signup" element={<SignupPage />} />
                </Route>
            </Routes>
        </ErrorBoundary>
    )
} 