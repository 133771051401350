import { useTestFormContext } from '@/app/features/reports/components/test-form/context'
import { FastFillIcon } from '@/components/raytd/icons'
import { Button } from "@/components/ui/button"
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList, CommandSeparator } from "@/components/ui/command"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { ScrollArea } from '@/components/ui/scroll-area'
import { Textarea } from "@/components/ui/textarea"
import { PlusIcon } from 'lucide-react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Control, useController, useFormContext } from 'react-hook-form'

interface FastFillItem {
  id: number | string;
  description: string
}

interface FastFillTextAreaProps {
  name: string;
  control: Control<any>;
  placeholder?: string;
  fastFills: FastFillItem[];
  enableFastFill?: boolean;
  disabled?: boolean;
  onCreateOption?: (value: string) => void;
  allowAdd?: boolean;
  className?: string;
}

export function FastFillText({
  name,
  control,
  placeholder,
  fastFills,
  enableFastFill = false,
  disabled,
  className,
  onCreateOption,
  allowAdd = false
}: FastFillTextAreaProps) {

  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error }
  } = useController({
    name,
    control,
  });

  const methods = useFormContext();
  const { onFastFillChanged } = useTestFormContext();

  const [open, setOpen] = useState(false)
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const [inputValue, setInputValue] = useState('');

  const handleOnChange = useCallback((newValue: string) => {
    onChange(newValue);
    // Only trigger fast fill if it is enabled
    enableFastFill && onFastFillChanged(name);
    methods.trigger();
  }, [onChange, methods.trigger, name, onFastFillChanged, enableFastFill]);

  useEffect(() => {
    if (!open) {
      setInputValue('')
    }
  }, [open]);

  const insertTextAtCursor = useCallback((text: string) => {
    console.debug('insertTextAtCursor', textareaRef?.current);
    if (!textareaRef.current) return

    const textarea = textareaRef.current
    const start = textarea.selectionStart
    const end = textarea.selectionEnd
    const currentValue = textarea.value

    // Create new value with inserted text
    const newValue = currentValue.substring(0, start) + text + currentValue.substring(end)
    handleOnChange(newValue);

    // Focus and set cursor position immediately
    requestAnimationFrame(() => {
      if (textareaRef.current) {
        const newCursorPosition = start + text.length
        textareaRef.current.focus()
        textareaRef.current.setSelectionRange(newCursorPosition, newCursorPosition)
      }
    })
  }, [onChange, methods.trigger, name])

  const handleFastFillSelect = useCallback((text: string) => {
    setOpen(false)
    insertTextAtCursor(text)
  }, [insertTextAtCursor]);

  const { viewportRef } = useTestFormContext();
  const triggerRef = useRef<HTMLButtonElement>(null);
  const mainRef = useRef<HTMLDivElement>(null);

  const handleOpenChange = useCallback((open: boolean) => {
    setOpen(open)
    if (!open) {
      setInputValue('')
    }
    // if (viewportRef?.current) {
    //   if (open) {
    //     // Create overlay with spotlight effect
    //     const overlay = document.createElement('div')
    //     overlay.className = 'fixed inset-0 transition-opacity'
    //     overlay.style.cssText = `
    //       background: radial-gradient(
    //         circle at center,
    //         transparent 10%,
    //         rgba(0, 0, 0, 0.4) 10%
    //       );
    //       pointer-events: none;
    //       z-index: 40;
    //     `
    //     viewportRef.current.appendChild(overlay)

    //     if (mainRef.current) {
    //       mainRef.current.style.position = 'relative'
    //       mainRef.current.style.zIndex = '50'
    //     }
    //   } else {
    //     // Remove overlay when closing
    //     const overlay = viewportRef.current.querySelector('.transition-opacity')
    //     overlay?.remove()

    //     if (mainRef.current) {
    //       mainRef.current.style.position = ''
    //       mainRef.current.style.zIndex = ''
    //     }
    //   }
    // }
  }, [setOpen, setInputValue, viewportRef])



  const filteredFastFills = useMemo(() => {
    return fastFills?.filter((item) => item.description.toLowerCase().includes(inputValue.toLowerCase()))
  }, [fastFills, inputValue])


  return (
    <div className="space-y-0 border border-input rounded-md" ref={mainRef}>
      <div className="w-full">
        <Textarea
          ref={(e) => {
            ref(e)
            textareaRef.current = e
          }}
          id={name}
          value={value}
          onChange={(e) => handleOnChange(e.target.value)}
          onBlur={onBlur}
          placeholder={placeholder}
          disabled={disabled}
          className={`min-h-[100px] border-none focus-visible:ring-0 ${className}`}
        />
      </div>
      {enableFastFill && (
        <div className="flex justify-start bg-zinc-100">
          <Popover
            open={open}
            onOpenChange={handleOpenChange}
            modal={true}
          >
            <PopoverTrigger asChild ref={triggerRef}>
              <Button
                variant="ghost"
                role="combobox"
                aria-expanded={open}
                className="w-full justify-start p-0"
                onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleOpenChange(true); }}
                disabled={disabled}
              >
                <FastFillIcon className="mx-2 h-4 w-4 shrink-0 opacity-50" />
                Insert Fast Fill
              </Button>
            </PopoverTrigger>
            <PopoverContent
              className="w-[var(--radix-popover-trigger-width)] p-0 h-[200px]"
              modal={true}
              sticky="always"
              collisionPadding={10}
              collisionBoundary={viewportRef?.current}
            // style={{
            //   maxHeight: '80vh',
            // }}
            // onOpenAutoFocus={e => { e.preventDefault(); e.stopPropagation() }}
            >
              <Command
                shouldFilter={false}
              >
                <CommandInput
                  placeholder={allowAdd ? 'Search or start typing to create a new Fast Fill' : "Search..."}
                  className="h-9"
                  value={inputValue}
                  onValueChange={setInputValue}

                />
                {/* <CommandEmpty>No fast fill items available</CommandEmpty> */}
                <ScrollArea className="h-full" type="auto">
                  <CommandGroup heading={inputValue ? `Search results` : "Fast Fills"} value={'options'}>
                    {filteredFastFills?.map((item) => (
                      <CommandItem
                        key={`fast-fill-${item.id}`}
                        onSelect={() => handleFastFillSelect(item.description)}
                        className="cursor-pointer"
                        value={item.description}
                      >
                        {item.description}
                      </CommandItem>
                    ))}
                    {filteredFastFills?.length === 0 && inputValue && (
                      <CommandItem value={`no-results-${inputValue}`} disabled>No results found.</CommandItem>
                    )}
                  </CommandGroup>
                  <CommandSeparator alwaysRender />
                  {allowAdd && inputValue && (
                    <CommandGroup heading="Create New" forceMount>
                      <CommandItem
                        forceMount
                        key={`create-${inputValue}`}
                        value={`${inputValue}`}
                        onSelect={() => onCreateOption(inputValue)}>
                        <PlusIcon className="h-4 w-4 text-muted-foreground" />
                        Create "{inputValue}"
                      </CommandItem>
                    </CommandGroup>
                  )}
                </ScrollArea>

              </Command>
            </PopoverContent>
          </Popover>
        </div>
      )}
    </div>
  )
}

FastFillText.displayName = 'FastFillText';