import { useTestFormContext } from '@/app/features/reports/components/test-form/context';
import { TestStatus } from '@/app/features/reports/types/test-form';
import { TEST_STATUS_CONFIG } from '@/app/features/reports/utils/testStatus';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { useWatch } from 'react-hook-form';

const useTestStatus = () => {
  const { isFormValid, isRequiredFieldsFilled } = useTestFormContext();

  console.debug('isFormValid', isFormValid);
  console.debug('isRequiredFieldsFilled', isRequiredFieldsFilled);

  const isStatusDisabled = useCallback(
    (status: TestStatus): boolean => {
      const config = TEST_STATUS_CONFIG[status.toLowerCase()];
      return config.requiresValidation ? !isFormValid : !isRequiredFieldsFilled;
    },
    [isFormValid, isRequiredFieldsFilled]
  );

  const { control } = useFormContext();

  const currentStatus = useWatch({
    control,
    name: 'status',
  });

  const isFinalRestricted = useCallback(
    () => {
      return isStatusDisabled('published');
    },
    [isStatusDisabled]
  );

  return {
    isStatusDisabled,
    currentStatus,
    isFinalRestricted,
  };
};

export default useTestStatus;
