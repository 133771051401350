import { Outlet } from "react-router-dom"
import WelcomeCard from "../welcome-card"

interface WelcomeLayoutProps {
  onGuestSubmit: (data: { email: string; name: string }) => void
  onLoginSuccess: (data: { email: string; password: string }) => void
  onCreateAccount: () => void
}

export function WelcomeLayout({
  onGuestSubmit,
  onLoginSuccess,
  onCreateAccount,
}: WelcomeLayoutProps) {
  return (
    <div className="flex items-center justify-center min-h-screen bg-zinc-50 p-4">
      <div className="w-full max-w-2xl">
        <Outlet />
        <WelcomeCard
          onGuestSubmit={onGuestSubmit}
          onLoginSuccess={onLoginSuccess}
          onCreateAccount={onCreateAccount}
        />
      </div>
    </div>
  )
} 