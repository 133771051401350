import React from 'react';
import { TestCountVariant } from '@/components/raytd/test-count-pill';
import AuthorBadge from '@/components/raytd/user-avatar';
import { Button } from '@/components/ui/button';
import { Tooltip, TooltipTrigger } from '@/components/ui/tooltip';
import { Assessment } from '@app.raytd.com/store';
import { TooltipContent } from '@radix-ui/react-tooltip';
import { useReportViewContext } from '../report-viewer-context';
import { useTestTileContext } from './context';
import DebugInfo from './debug-info';
import ExclusionToggle from './exclusion-toggle';
import FastFillAcceptedToggle from './fast-fill-toggle';
import StatusBadges from './save-status-badges';


const AssessmentBadge: React.FC<{ assessment: Assessment }> = ({ assessment }) => {
    return (
        <Tooltip>
            <TooltipTrigger>
                <div className="text-sm bg-zinc-200 text-zinc-500 px-2 py-1 max-w-48 rounded-sm truncate">
                    {assessment?.title}
                </div>
            </TooltipTrigger>
            <TooltipContent className="bg-black text-white p-2 rounded-md text-sm">
                {assessment?.title}
            </TooltipContent>
        </Tooltip>
    );
};


type EditToolsProps = {
    onEdit?: (id: number) => void;
    assessment: Assessment;
}

const TestTileEditTools: React.FC<EditToolsProps> = ({ onEdit, assessment }) => {

    const { item, hasFastFillErrors, hasUnmatchedSegments } = useTestTileContext();
    const { isEditMode } = useReportViewContext();

    if (!isEditMode) {
        return null;
    }

    return (
        <div className="bg-zinc-50 border-t px-4 py-2">
            <div className="flex flex-row justify-between items-center gap-2">
                <div className="flex items-center space-x-2">
                    <Button
                        variant="outlinelight"
                        size='sm'
                        className="text-zinc-500"
                        aria-label="Edit Test Item"
                        onClick={() => onEdit?.(item.assessmentTestId)}
                    >Edit</Button>
                    <ExclusionToggle />
                    <FastFillAcceptedToggle visible={hasUnmatchedSegments} className="bg-zinc-100 rounded-md py-1 px-2 shadow-[inset_0_2px_8px_rgba(0,0,0,0.1)]" />
                    {process.env.NODE_ENV === 'development' && (
                        <DebugInfo
                            exclusionReason={item.exclusionReason}
                            status={item.status}
                            id={item.assessmentTestId}
                        />
                    )}
                </div>
                <div className="flex flex-row items-center gap-2 justify-end">
                    <AssessmentBadge assessment={assessment} />
                    <AuthorBadge item={item.author} />
                    <StatusBadges status={item.status as TestCountVariant} />
                </div>
            </div>
        </div>
    )
}

TestTileEditTools.displayName = 'TestTileEditTools';

export default TestTileEditTools;