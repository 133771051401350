import React, { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

type BreadcrumbContextType = {
  setBreadcrumbName: (path: string, name: string) => void;
  getBreadcrumbName: (path: string) => string | undefined;
  getMatchingPaths: (paths: string[]) => { [key: string]: string };
};

const BreadcrumbContext = createContext<BreadcrumbContextType | undefined>(undefined);

const normalizePath = (path: string) => {
  return path.endsWith('/') ? path.slice(0, -1) : path;
};

export const BreadcrumbProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [breadcrumbNames, setBreadcrumbNames] = useState<Record<string, string>>({});

  const setBreadcrumbName = useCallback((path: string, name: string) => {
    const normalizedPath = normalizePath(path);
    setBreadcrumbNames(prev => ({ ...prev, [normalizedPath]: name }));
  }, []);

  const getBreadcrumbName = useCallback((path: string) => {
    const normalizedPath = normalizePath(path);
    const matchingPath = Object.keys(breadcrumbNames).find(key => normalizedPath.startsWith(key));
    return matchingPath ? breadcrumbNames[matchingPath] : undefined;
  }, [breadcrumbNames]);

  const getMatchingPaths = useCallback((paths: string[]) => {
    const normalizedPaths = paths.map(normalizePath);
    const breadcrumbEntries = Object.entries(breadcrumbNames);

    return normalizedPaths.reduce((matches, path) => {
      breadcrumbEntries.forEach(([key, value]) => {
        if (path.startsWith(key)) {
          matches[key] = value;
        }
      });
      return matches;
    }, {} as Record<string, string>);
  }, [breadcrumbNames]);


  const contextValue = useMemo(() => ({
    setBreadcrumbName,
    setBreadcrumbNames,
    getBreadcrumbName,
    getMatchingPaths
  }), [setBreadcrumbName, setBreadcrumbNames, getBreadcrumbName, getMatchingPaths]);

  return (
    <BreadcrumbContext.Provider value={contextValue}>
      {children}
    </BreadcrumbContext.Provider>
  );
};

export const useBreadcrumb = () => {
  const context = useContext(BreadcrumbContext);
  if (context === undefined) {
    throw new Error('useBreadcrumb must be used within a BreadcrumbProvider');
  }
  return context;
};