import { z } from "zod"

// Schema for form validation
export const shareReportSchema = z.object({
  first_name: z.string().min(1, "First name is required"),
  last_name: z.string().min(1, "Last name is required"),
  email: z.string().email("Please enter a valid email address"),
  allow_share: z.boolean().default(false),
  // allow_export: z.enum(["Yes", "No"]).default("Yes"),
})

// Type derived from the schema
export type ShareReportFormValues = z.infer<typeof shareReportSchema>

// Props for the ShareReportModal component
export interface ShareReportModalProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (data: ShareReportFormValues) => Promise<void>
}

// Response type for the share operation
export interface ShareReportResponse {
  success: boolean
  message: string
}

