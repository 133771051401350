'use client';

import React, { useRef } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { Plate } from '@udecode/plate-common/react';

import { SettingsDialog } from '@/components/editor/settings';
import { useCreateEditor, useCreateViewer } from '@/components/editor/use-create-editor';
import { Editor, EditorContainer } from '@/components/plate-ui/editor';
import { TElement } from '@udecode/plate-common';
import { useCallback } from 'react';

type BlockEditorProps = {
  onChange?: (value: TElement[]) => void;
  initialValue: TElement[];
  onBlur?: () => void;
};

export type BlockEditorRef = HTMLDivElement & {
  //convertToHtml: () => Promise<string>;
};

const PlateEditor = React.forwardRef<BlockEditorRef, BlockEditorProps>(
  ({ initialValue, onChange }, ref) => {

    const editor = useCreateEditor(initialValue);
    const editorRef = useRef<HTMLDivElement>(null);

    const handleChange = useCallback(({
      editor,
      value
    }) => {
      if (onChange) {
        requestAnimationFrame(() => onChange(value));

      }
    }, [onChange]);

    return (
      <DndProvider backend={HTML5Backend}>
        <Plate
          editor={editor}
          onValueChange={handleChange}
        >
          <EditorContainer>
            <Editor variant="raytd" ref={editorRef} />
          </EditorContainer>

          <SettingsDialog />
        </Plate>
      </DndProvider>
    );
  });

export default PlateEditor;

export const PlateViewer = ({ initialValue }) => {
  const editor = useCreateViewer(initialValue);

  return (
    <Plate
      editor={editor}
    >
      <EditorContainer variant='ghost'>
        <Editor variant="none" readOnly />
      </EditorContainer>
    </Plate>
  );
}