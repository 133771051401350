import { ReportTestItem } from '@/app/features/reports/utils/entities';
import { TestItem } from '../types/report';
import { Element } from '../types/report';
export interface ProcessedTestData {
  totalTests: number;
  averageScore: number | null;
  medianScore: number | null;
  ratingTypes: string[];
  distinctCounts: {
    assetIds: number;
    testSpecificationIds: number;
    testSuiteIds: number;
    elements: number;
    elementGroups: number;
    elementIds: number;
    elementNames: number;
    buildingIds: number;
    levelIds: number;
    areaIds: number;
  };
  scoreRangeStats: {
    lowestScore: number | null;
    lowestScoreCount: number;
    highestScore: number | null;
    highestScoreCount: number;
  };
  testCounts: {
    testsWithRating: number;
    otherTests: number;
  };
  testDistribution: {
    [key: string]: {
      count: number;
      scaleTypes: {
        [key: string]: number;
      };
    };
  };
}

/**
 * Calculates a unique identifier for an element group based on the first two levels of its path
 * 
 * @param element - The element object containing path and tree_id information
 * @returns A string identifier in the format "level0Id-level1Id" or null if invalid
 * 
 * The function:
 * - Returns null if element or tree_id is missing
 * - Returns null if first path level exists but second doesn't
 * - Combines IDs from first two path levels to create group identifier
 */
function calculateElementGroup(element: Element | null): string | null {
  if (!element || !element.tree_id) return null;

  console.debug('calculateElementGroup', element);

  if (!element.path[0] && element.path[1]) return null;

  return `${element.path[0].id}-${element.path[1].id}`;

}

export function processTestData(testItems: ReportTestItem[] = []): ProcessedTestData {
  if (!Array.isArray(testItems)) {
    console.warn('Invalid input to processTestData: expected an array, got', typeof testItems);
    testItems = [];
  }

  // console.debug('Processing test data:', testItems);

  const totalTests = testItems.length;
  const validScores = testItems.filter(item => item.score !== null).map(item => item.score);

  const ratingTypes = Array.from(new Set(testItems.map(item => item.ratingType).filter(Boolean) as string[]));

  const averageScore = validScores.length > 0
    ? validScores.reduce((sum, score) => sum + score, 0) / validScores.length
    : null;

  const medianScore = validScores.length > 0
    ? validScores.sort((a, b) => a - b)[Math.floor(validScores.length / 2)]
    : null;

  // Calculate distinct counts using Set
  const distinctCounts = {
    assetIds: new Set(testItems.map(item => item.assetId)).size,
    testSpecificationIds: new Set(testItems.map(item => item.testSpecificationId)).size,
    testSuiteIds: new Set(testItems.map(item => item.testSpecification?.testSuiteId).filter(Boolean)).size,
    elementIds: new Set(testItems.map(item => item.element_id).filter(Boolean)).size,
    elementNames: new Set(testItems.map(item => item.element_name).filter(Boolean)).size,
    buildingIds: new Set(testItems.map(item => item.buildingId).filter(Boolean)).size,
    levelIds: new Set(testItems.map(item => item.levelId).filter(Boolean)).size,
    areaIds: new Set(testItems.map(item => item.areaId).filter(Boolean)).size,
    elements: new Set(testItems.map(item => item.element?.id).filter(Boolean)).size,
    elementGroups: new Set(testItems.map(item => calculateElementGroup(item.element)).filter(Boolean)).size
  };

  const testCounts = {
    testsWithRating: testItems.filter(item => item.testSpecification?.type !== 'generic').length,
    otherTests: testItems.filter(item => item.testSpecification?.type === 'generic').length,
  };

  const testDistribution: ProcessedTestData['testDistribution'] = {
    'very_poor': { count: 0, scaleTypes: {} },
    'poor': { count: 0, scaleTypes: {} },
    'fair': { count: 0, scaleTypes: {} },
    'good': { count: 0, scaleTypes: {} },
    'very_good': { count: 0, scaleTypes: {} },
    'no_rating': { count: 0, scaleTypes: {} },
  };

  testItems.forEach(item => {
    const rating = item.result || 'no_rating';
    const scaleType = item.ratingType || 'unknown';
    
    testDistribution[rating].count++;
    testDistribution[rating].scaleTypes[scaleType] = (testDistribution[rating].scaleTypes[scaleType] || 0) + 1;
  });

  const validRangeScores = testItems
    .filter(item => item.score !== null && item.score >= 1 && item.score <= 5)
    .map(item => item.score!);

  const scoreRangeStats = {
    lowestScore: validRangeScores.length > 0 ? Math.min(...validRangeScores) : null,
    highestScore: validRangeScores.length > 0 ? Math.max(...validRangeScores) : null,
    lowestScoreCount: 0,
    highestScoreCount: 0
  };

  if (scoreRangeStats.lowestScore !== null) {
    scoreRangeStats.lowestScoreCount = validRangeScores.filter(
      score => score === scoreRangeStats.lowestScore
    ).length;
    scoreRangeStats.highestScoreCount = validRangeScores.filter(
      score => score === scoreRangeStats.highestScore
    ).length;
  }

  return {
    totalTests,
    averageScore,
    medianScore,
    ratingTypes,
    distinctCounts,
    testCounts,
    testDistribution,
    scoreRangeStats
  };
}