import { useForm } from 'react-hook-form';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { zodResolver } from '@hookform/resolvers/zod';
import { SignupFormData, Step1Data, Step1Schema } from './schema';
import { FormField, FormItem, FormLabel, FormControl, FormMessage, Form } from '@/components/ui/form';
import { useCallback, useEffect } from 'react';

interface Step1FormProps {
    onComplete: (data: Step1Data) => Promise<void>;
    prefill: SignupFormData;
}

const Step1Form: React.FC<Step1FormProps> = ({
    onComplete,
    prefill
}) => {
    const form = useForm<Step1Data>({
        resolver: zodResolver(Step1Schema),
        defaultValues: {
            first_name: '',
            last_name: '',
            email: '',
        },
        mode: 'onBlur'
    });

    useEffect(() => {
        form.reset({
            first_name: prefill?.first_name || '',
            last_name: prefill?.last_name || '',
            email: prefill?.email || '',
        });
    }, [prefill, form]);

    const onSubmit = useCallback((data: Step1Data) => {
        onComplete(data);
    }, [onComplete]);

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <div className="flex space-x-4 mb-4">
                    <FormField
                        control={form.control}
                        name="first_name"
                        render={({ field }) => (
                            <FormItem className="flex-1">
                                <FormLabel className="sr-only">First Name</FormLabel>
                                <FormControl>
                                    <Input placeholder="First Name" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="last_name"
                        render={({ field }) => (
                            <FormItem className="flex-1">
                                <FormLabel className="sr-only">Last Name</FormLabel>
                                <FormControl>
                                    <Input placeholder="Last Name" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                        <FormItem className="mb-4">
                            <FormLabel className="sr-only">Email address</FormLabel>
                            <FormControl>
                                <Input type="email" placeholder="Email address" {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <Button type="submit" className="w-full">Next</Button>
            </form>
        </Form>
    );
}

export default Step1Form;