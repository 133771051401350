import calculateFilterCounts from '@/app/features/reports/utils/calculateFilterCounts';
import { ReportTestItem } from '@/app/features/reports/utils/entities';
import { useCallback, useMemo, useState } from 'react';
import {
  FilterCounts,
  filterNames,
  Filters,
  FilterTypes,
  SelectedFilters,
} from '../types/filters';
import {
  createCompositeFilter,
  createElementNameFilter,
  createElementIdFilter,
  createFilter,
  createResultFilter,
  createStatusFilter,
  createTestSuiteFilter,
  Filter,
  filterByLocation,
  createElementFilter,
  createExclusionFilter,
  createSearchFilter,
} from './filters';
import convertTestsToSpaces from './convertTestsToSpaces';
import convertTestsToTestSuiteFilters from './convertTestsToTestSuiteFilters';
import convertTestsToRatingFilters from './convertTestsToRatingFilters';
import convertSinglePropertyToFilters from './convertSinglePropertyToFilters';
import { ReportEntity } from 'store/src/lib/services/types';
import { FilterState, getFacetCounts } from './facets';
import { convertTestsToElementFacets } from './convertTestsToElementFacets';
import convertTestsToExclusionFacets from '@/app/features/reports/utils/convertTestsToExclusionFacets';

const filterItems = (
  items: ReportTestItem[],
  selectedFilters: SelectedFilters,
  filterInput: string
) => {
  if (!items?.length) return [];

  const activeFilters: Filter[] = [];

  // Status filter
  if (selectedFilters.status[0]) {
    const statusSet = new Set(
      selectedFilters.status[0].split(',').map((s) => s.toLowerCase())
    );
    activeFilters.push(createStatusFilter(statusSet));
  }

  // Element name filter
  if (selectedFilters.elementName.length > 0) {
    const elementSet = new Set(
      selectedFilters.elementName[0].split(',').map((s) => s.toLowerCase())
    );
    activeFilters.push(createElementNameFilter(elementSet));
  }

  // Element id filter
  if (selectedFilters.elementId[0]) {
    const elementSet = new Set(
      selectedFilters.elementId[0].split(',').map((s) => s.toLowerCase())
    );
    activeFilters.push(createElementIdFilter(elementSet));
  }

  // Test suite filter
  if (selectedFilters.testSuite[0]) {
    const suiteSet = new Set(selectedFilters.testSuite[0].split(','));
    activeFilters.push(createTestSuiteFilter(suiteSet));
  }

  // Rating type filter
  if (selectedFilters.rating[0]) {
    const resultSet = new Set(selectedFilters.rating[0].split(','));
    activeFilters.push(createResultFilter(resultSet));
  }

  if (selectedFilters.element[0]) {
    const elementSet = new Set(
      selectedFilters.element[0].split(',').map((s) => s.toLowerCase())
    );
    activeFilters.push(createElementFilter(elementSet));
  }

  if (selectedFilters.excluded[0]) {
    const exlusionSet = new Set(
      selectedFilters.excluded[0].split(',').map((s) => s.toLowerCase())
    );

    activeFilters.push(createExclusionFilter(exlusionSet));
  }

  // Location filters
  if (selectedFilters.space[0]) {
    const locationFilters = selectedFilters.space[0]
      .split(',')
      .map((location) => filterByLocation(location));

    activeFilters.push(
      createFilter({
        matches: (item) =>
          locationFilters.some((filter) => filter.matches(item)),
      })
    );
  }

  if (filterInput) {
    activeFilters.push(createSearchFilter(filterInput));
  }

  // If no filters are active, return all items
  if (activeFilters.length === 0) {
    return items;
  }

  // Create and apply composite filter
  const compositeFilter = createCompositeFilter(activeFilters);
  return items.filter((item) => compositeFilter.matches(item));
};

const defaultValues: SelectedFilters = filterNames.reduce((acc, filterName) => {
  acc[filterName] = [];
  return acc;
}, {} as SelectedFilters);

export { defaultValues as defaultInitialFilters };

const defaultTestCounts = {
  total: 0,
  published: 0,
  qa: 0,
  draft: 0,
  archived: 0,
  missingData: 0,
  fastFill: 0,
};

const useReportItemFilters = (
  testItems: ReportTestItem[],
  report: ReportEntity,
  initialFilters?: SelectedFilters
) => {
  // const [filterCounts, setFilterCounts] = useState({})
  // const [filteredCounts, setFilteredCounts] = useState({})

  console.debug('initialFilters 2', initialFilters, report);

  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>(
    initialFilters || {
      ...defaultValues,
    }
  );

  const [filterInput, setFilterInput] = useState<string>('');

  const filteredItems = useMemo(
    () => filterItems(testItems, selectedFilters, filterInput),
    [testItems, selectedFilters, filterInput]
  );

  //@ts-expect-error
  const state: FilterState = useMemo(() => {
    if (!testItems?.length) {
      return {
        filteredItems: [],
        allItems: [],
        counts: {
          filtered: {
            ...defaultValues,
            tests: { ...defaultTestCounts },
          },
          total: {
            ...defaultValues,
            tests: { ...defaultTestCounts },
          },
        },
      };
    }

    return {
      filteredItems: filteredItems,
      allItems: testItems,
      counts: calculateFilterCounts(filteredItems, testItems),
    };
  }, [filteredItems, testItems]);

  const updateFilters = useCallback((newFilters: typeof selectedFilters) => {
    setSelectedFilters(newFilters);
  }, []);

  const removeFilter = useCallback(
    (category: FilterTypes, filter: string) => {
      if (filterNames.includes(category)) {
        const updatedFilterList = selectedFilters[category]?.[0]
          .split(',')
          .filter((f) => f !== filter)
          .join(',');

        updateFilters({
          ...selectedFilters,
          [category]: updatedFilterList ? [updatedFilterList] : [],
        });
        return;
      }

      updateFilters({
        ...selectedFilters,
        [category]: selectedFilters[category as string].filter(
          (f) => f !== filter
        ),
      });
    },
    [selectedFilters]
  );

  process.env.NODE_ENV === 'development' &&
    console.debug('selectedFilters', { selectedFilters, state });

  const filtersActive = useMemo(
    () =>
      Object.values(selectedFilters).some(
        (filterGroup) => filterGroup.length > 0
      ),
    [selectedFilters]
  );

  //@ts-expect-error
  const filters: Filters = useMemo(() => {
    if (!testItems?.length || !report) return { ...defaultValues };

    const spaces = convertTestsToSpaces(
      filteredItems,
      testItems,
      getFacetCounts('space', state)
    );

    const testSuiteFilters = convertTestsToTestSuiteFilters(testItems);

    const ratingFilters = convertTestsToRatingFilters(
      filteredItems,
      testItems,
      report?.testSuites,
      getFacetCounts('rating', state)
    );

    const elementFilters = convertTestsToElementFacets(
      filteredItems,
      testItems,
      getFacetCounts('element', state)
    );

    const exclusionFilters = convertTestsToExclusionFacets(
      filteredItems,
      testItems,
      getFacetCounts('excluded', state)
    );

    const statusFilters = convertSinglePropertyToFilters(testItems, {
      key: 'status',
      idProperty: 'status',
      nameProperty: 'status',
      customOrder: ['published', 'qa', 'draft', 'archived'],
      displayValue: (value) =>
        ({
          published: 'Final',
          qa: 'QA',
          draft: 'Draft',
          archived: 'Archived',
        }[value?.toLowerCase()] || value),
    });

    const elementNameFilters = convertSinglePropertyToFilters(testItems, {
      key: 'elementName',
      idProperty: 'element_name',
      nameProperty: 'element_name',
      emptyTitle: '(No Element Name)',
    });

    const elementId = convertSinglePropertyToFilters(testItems, {
      key: 'elementId',
      idProperty: 'element_id',
      nameProperty: 'element_id',
      emptyTitle: '(No Element ID)',
    });

    return {
      space: spaces,
      testSuite: testSuiteFilters,
      rating: ratingFilters,
      status: statusFilters,
      elementName: elementNameFilters,
      elementId: elementId,
      element: elementFilters,
      excluded: exclusionFilters,
    };
  }, [testItems, filteredItems, report]);

  //  setFilterCounts(calculateFilterCounts(initialData));
  //  setFilteredCounts(calculateFilterCounts(filteredData));

  return {
    filteredData: filteredItems,
    facets: filters,
    selectedFilters,
    setSelectedFilters,
    filteredCounts: state.counts,
    unfilteredCounts: state.counts,
    filtersActive,
    removeFilter,
    updateFilters,
    filterInput,
    setFilterInput,
  };
};

export default useReportItemFilters;
