import { TestTileSkeleton } from '@/app/features/reports/components/test-tile-skeleton';
import { useReportSelectionContext } from '@/app/features/reports/contexts/selection-context';
import { ImageWithPlaceholder } from '@/app/features/reports/v2/ImagePlaceholder';
import { NoImages } from '@/app/features/reports/v2/NoImages';
import { Checkbox } from '@/components/ui/checkbox';
import { cn } from '@/lib/utils';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useCallback, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { FlattenedItem } from '../utils/entities';
import useReportViewerContext, { useReportDisplayContext, useReportViewContext } from './report-viewer-context';
import TestItem from './test-tile';

const DummyImageItem: React.FC<{ item: FlattenedItem }> = ({ item }) => {

    if (item.testCase?.images?.length === 0) {
        return <NoImages size="lg" />;
    }

    return (
        <div className="grid p-3 mb-4 grid-cols-2 lg:grid-cols-3 gap-4">
            {item.testCase.images.map((image, index) => (
                <ImageWithPlaceholder key={index} src={image.preview_url} alt={image.uri} size="lg" />
            ))}
        </div>
    );
}

const ErrorFallback = ({ error }: { error: Error }) => {
    return (
        <div className="flex flex-row items-center justify-center">
            <div className="text-red-500">{error.message}</div>
        </div>
    );
}

interface RowContentProps {
    item: FlattenedItem;
    indent: number;
    toggleExpand: (id: string) => void;
}

const RowContent: React.FC<RowContentProps> = React.memo(({ item, indent, toggleExpand }) => {
    const { viewMode } = useReportDisplayContext();
    const { report } = useReportViewContext();
    const { showTestEditor, isUpdatingTest, updatingTestId } = useReportViewerContext();
    const { selectedItems, setSelectedItems, bulkEditMode } = useReportSelectionContext();
    const isSelected = selectedItems.has(item.id);

    const toggleSelect = useCallback(() => {
        setSelectedItems((prev: Set<string>) => {
            const next = new Set(prev);
            next.has(item.id) ? next.delete(item.id) : next.add(item.id);
            return next;
        });
    }, [item.id, setSelectedItems]);

    const testSuite = useMemo(() =>
        report?.testSuites.find(ts =>
            ts.id === item.testCase?.testSpecification?.testSuiteId
        ),
        [report?.testSuites, item.testCase?.testSpecification?.testSuiteId]
    );

    const assessment = useMemo(() =>
        report?.assessments.find(assessment =>
            assessment.id === item.testCase?.assessmentId
        ),
        [report?.assessments, item.testCase?.assessmentId]
    );

    const isUpdating = isUpdatingTest && updatingTestId === item.testCase?.assessmentTestId;

    const content = useMemo(() => {

        if (isUpdating) {
            return (
                <AnimatePresence mode="wait">
                    <motion.div
                        key="skeleton"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className="flex flex-col items-center justify-center w-full"
                    >
                        <TestTileSkeleton />
                    </motion.div>
                </AnimatePresence>
            );
        }

        return (
            <AnimatePresence mode="wait">
                <motion.div
                    key="skeleton"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    className="w-full"
                >
                    {viewMode === 'images' ?
                        <DummyImageItem item={item} /> :
                        <TestItem item={item.testCase}
                            testSuite={testSuite}
                            assessment={assessment}
                            onEdit={showTestEditor}
                        />}
                </motion.div>
            </AnimatePresence>
        );

        return <TestItem item={item.testCase}
            testSuite={testSuite}
            assessment={assessment}
            onEdit={showTestEditor}
        />;
    }, [viewMode, item, testSuite, isUpdating]);

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <div
                className={cn(
                    "w-full",
                    "flex border-b border-b-zinc-200 py-4",
                    "min-h-[320px]" // Changed m-h to min-h
                )}
            >
                {bulkEditMode ? (
                    <div className="flex w-full">
                        <div className="flex-shrink-0 pr-4">
                            <Checkbox
                                id={`bulk-edit-${item.id}`}
                                checked={isSelected}
                                onCheckedChange={toggleSelect}
                            />
                        </div>
                        <div className={cn(
                            "flex-grow",
                            !isSelected && "opacity-60"
                        )}>
                            {content}
                        </div>
                    </div>
                ) : (
                    content
                )}
            </div>
        </ErrorBoundary>
    );
});

// Add TypeScript type checking for the component
RowContent.displayName = 'RowContent';

export default RowContent;