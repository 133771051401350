import { ComparisonConfig } from '@/app/features/reports/hooks/use-unmatched-segments';
import { TestSpecification } from '@/app/features/reports/utils/entities';
import { useCallback } from 'react';
import { TestSuite } from 'store/src/lib/tests/entity';

const usePredefinedTexts = (testSuites: TestSuite[]) => {
  const createComparisonConfig = useCallback(
    (testSpecification: TestSpecification) => {
      const comparisonConfig: ComparisonConfig[] = [];

      console.log('createComparisonConfig', { testSpecification, testSuites });

      if (!testSuites || !testSpecification) return null;

      const testSuite = testSuites.find(
        (ts) =>
          ts.id ===
          (testSpecification.testSuiteId ?? testSpecification.test_suite_id)
      );

      if (!testSuite) {
        console.warn('TestSuite not found', testSpecification);
        return [];
      }

      console.log('createComparisonConfig', testSpecification, testSuite);

      if (testSuite?.type === 'compliance') {
        comparisonConfig.push(
          {
            field: 'observations',
          },
          {
            field: 'recommendations',
          }
        );
      }

      if (testSuite.custom_fields.length > 0) {
        testSuite.custom_fields
          .filter((field) => field.type === 'textarea')
          .forEach((field) => {
            if (field.data?.length > 0) {
              comparisonConfig.push({
                field: `custom.${field.field_id}`,
              });
            }
          });
      }

      console.log('comparisonConfig', testSpecification.id, comparisonConfig);

      return comparisonConfig;
    },
    [testSuites]
  );

  const getPredefinedTexts = useCallback(
    (field: string, testSpecification: TestSpecification): string[] => {
      if (!testSuites) return [];

      const testSuite = testSuites.find(
        (ts) =>
          ts.id ===
          (testSpecification.testSuiteId ?? testSpecification.test_suite_id)
      );

      if (!testSuite) {
        console.warn('TestSuite not found', testSpecification);
        return [];
      }

      if (field === 'observations') {
        const texts = testSuite?.specifications?.find(
          (spec) => spec.id === testSpecification.id
        )?.observations;
        return texts ? texts.map((obj) => obj.description) : [];
      }

      if (field === 'recommendations') {
        const texts = testSuite?.specifications?.find(
          (spec) => spec.id === testSpecification.id
        )?.recommendations;
        return texts ? texts.map((obj) => obj.description) : [];
      }

      if (field.startsWith('custom.')) {
        const customFieldId = field.split('.').pop();
        const customField = testSuite?.custom_fields.find(
          (field) => field.field_id === customFieldId
        );

        if (customField?.data?.length > 0) {
          return customField.data.map(
            (value: { displayName: string }) => value.displayName
          );
        }
      }

      return [];
    },
    [testSuites]
  );

  return { getPredefinedTexts, createComparisonConfig };
};

export default usePredefinedTexts;
