import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
import { Button } from "@/components/ui/button"
import { FallbackProps } from "react-error-boundary"

export function ReportErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
    return (
        <div className="container mx-auto py-6 px-4">
            <Alert variant="default">
                <AlertTitle>Something went wrong</AlertTitle>
                <AlertDescription>
                    <div className="mt-2">
                        There was an error loading this section of the report.
                    </div>
                    <div className="mt-4">
                        <Button
                            variant="default"
                            onClick={resetErrorBoundary}
                        >
                            Try again
                        </Button>
                    </div>
                </AlertDescription>
            </Alert>
        </div>
    )
}