import { useUpload } from '@/components/image-upload/useUpload';
import { selectUploadById, useAppSelector } from '@app.raytd.com/store';
import axios from 'axios';
import { useEffect, useState } from 'react';

interface UseUploadFileProps {
  onUploadComplete?: (file: UploadedFile) => void;
  onUploadError?: (error: unknown) => void;
  headers?: Record<string, string>;
  onUploadBegin?: (fileName: string) => void;
  onUploadProgress?: (progress: { progress: number }) => void;
  skipPolling?: boolean;
}

interface UploadedFile {
  key: string;    // Unique identifier
  url: string;    // Public URL of the uploaded file
  name: string;   // Original filename
  size: number;   // File size in bytes
  type: string;   // MIME type
}


export function useUploadFile({
  onUploadComplete,
  onUploadError,
  onUploadProgress,
  onUploadBegin
}: UseUploadFileProps = {}) {

  const {

    uploads,
    hasActiveUploads,
    handleUpload,
    removeUpload,
    toggleDeleteUpload,
    clearUploads,
    totalProgress

  } = useUpload('report-block-editor');

  const [uploadedFile, setUploadedFile] = useState<UploadedFile>();
  const [uploadingFile, setUploadingFile] = useState<File>();
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadId, setUploadId] = useState<string>();

  const currentUpload = useAppSelector((state) =>
    uploadId ? selectUploadById(state, uploadId) : undefined
  );

  useEffect(() => {
    if (currentUpload) {
      onUploadProgress?.({ progress: currentUpload.progress });

      if (currentUpload.status === 'completed' && currentUpload.mediaInfo) {
        const uploadedFile: UploadedFile = {
          key: currentUpload.mediaInfo.id.toString(),
          url: currentUpload.mediaInfo.uri,
          name: currentUpload.fileName,
          size: currentUpload.size,
          type: currentUpload.mediaInfo.mime_type
        };
        setUploadedFile(uploadedFile);
        onUploadComplete?.(uploadedFile);
        setIsUploading(false);
        setUploadingFile(undefined);
      }

      if (currentUpload.status === 'error') {
        onUploadError?.(new Error('Upload failed'));
        setIsUploading(false);
        setUploadingFile(undefined);
        removeUpload(currentUpload.id);
      }
    }
  }, [currentUpload,
    onUploadComplete,
    onUploadError,
    onUploadProgress,
    removeUpload
  ]);

  async function uploadFile(file: File) {
    try {
      setIsUploading(true);
      setUploadingFile(file);
      onUploadBegin?.(file.name);

      const newUploadId = handleUpload(file);
      setUploadId(newUploadId);

      return new Promise<UploadedFile>((resolve, reject) => {
        const checkUpload = setInterval(() => {
          const upload = uploads.find(u => u.id === newUploadId);
          if (upload?.status === 'completed' && upload.mediaInfo) {
            clearInterval(checkUpload);
            resolve({
              key: upload.mediaInfo.id.toString(),
              url: upload.mediaInfo.uri,
              name: upload.fileName,
              size: upload.size,
              type: upload.mediaInfo.mime_type
            });
          } else if (upload?.status === 'error') {
            clearInterval(checkUpload);
            reject(new Error('Upload failed'));
          }
        }, 500);
      });
    } catch (error) {
      onUploadError?.(error);
      throw error;
    }
  }


  return {
    isUploading,
    progress,
    uploadFile,
    uploadedFile,
    uploadingFile,
  };
}
