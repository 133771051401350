import { FormControl, FormDescription, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { useCallback } from "react";
import { CustomFieldProps } from '../custom-fields-container';
import { FormLabelWithIndicator } from "../form-label-with-indicator";

import {
  NumberField,
  NumberFieldInput
} from "@/components/ui/numberfield";
import { useFormContext } from "react-hook-form";
type CustomNumberFieldProps = CustomFieldProps & {
  decimalPlaces: number;
  min?: number;
  max?: number;
}

export function CustomNumberField({
  control,
  name,
  label,
  decimalPlaces,
  showDescription,
  description,
  showFormMessage,
  ...props
}: CustomNumberFieldProps) {

  const { trigger, setValue } = useFormContext();

  const handleKeyDown = useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation(); // Stop event from bubbling up
    }
  }, []);

  const handleOnChange = useCallback((value: number) => {
    console.debug('handleOnChange', name, value);
    setValue(name, value, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
    trigger();
  }, [setValue, trigger, name]);

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabelWithIndicator label={label} {...props} />
          <FormControl>
            <NumberField
              aria-label={label}
              className="w-full"
              {...field}
              onChange={(e) => handleOnChange(e)}
              formatOptions={{
                minimumFractionDigits: decimalPlaces,
                maximumFractionDigits: decimalPlaces,

              }}
              onBlur={() => {
                field.onBlur();
              }}
            >
              <NumberFieldInput
                className={"w-full rounded-md border border-input"}
                onKeyDown={handleKeyDown}
              />
            </NumberField>
          </FormControl>
          {showDescription && <FormDescription>{description}</FormDescription>}
          {showFormMessage && <FormMessage />}
        </FormItem>
      )}
    />
  );
}
