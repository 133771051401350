import { FastFillText } from "@/app/features/reports/components/fast-fill-text";
import { FormControl, FormDescription, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { CustomFieldProps } from '../custom-fields-container';
import { FormLabelWithIndicator } from "../form-label-with-indicator";

type CustomTextAreaFieldProps = CustomFieldProps & {
    options: { id: string; displayName: string }[]
    allowAdd?: boolean;
    onAddOption?: (fieldId: string, displayName: string) => Promise<{ success: boolean, field_id: string, data: any }>;
}

export function CustomTextAreaField({
    control,
    name,
    label,
    description,
    showDescription,
    info,
    required,
    options,
    isValid,
    showFormMessage,
    allowAdd = false,
    onAddOption,
}: CustomTextAreaFieldProps) {

    const enableFastFill = allowAdd || options.length > 0;

    return (
        <FormField
            control={control}
            name={name}
            render={({ field }) => (
                <FormItem>
                    <FormLabelWithIndicator
                        label={label}
                        isValid={isValid}
                        required={required}
                        info={info}
                        debugMessage={`allowAdd: ${allowAdd}`}
                    />
                    <FormControl>

                        <FastFillText
                            control={control}
                            name={name}
                            fastFills={options.map((opt) => ({ id: opt.id, description: opt.displayName })) ?? []}
                            enableFastFill={enableFastFill}
                            allowAdd={allowAdd}
                            onCreateOption={(value) => onAddOption(name, value)}
                        />

                    </FormControl>
                    {showDescription && (<FormDescription>{description}</FormDescription>)}
                    {showFormMessage && <FormMessage />}
                </FormItem>
            )}
        />
    )
}
