import { TestSuite } from 'store/src/lib/tests/entity';
import { ReportData, RatingTypes, GroupSummary } from './types';

export const ConvertResultToIndex = (result: number) => {
  
  if (result > 0 && result <= 5) {
    
    return Math.floor(result) - 1;
  } else {
    return undefined;
  }
};

export const convertAverage = (average: number) => Math.floor(isNaN(average) ? undefined : average ?? 0);

export const ConvertResultToRating = (result: number) => {
  const rating = ['very_poor', 'poor', 'fair', 'good', 'very_good'][result - 1];
  return rating;
};

export const convertRatingToScore = (rating: string) => {
  const ratings = ['very_poor', 'poor', 'fair', 'good', 'very_good'];
  const index = ratings.indexOf(rating);
  return index === -1 ? undefined : index + 1;
}

export const convertScoreToRating = (score: number): RatingScale => {
  const ratings = ['very_poor', 'poor', 'fair', 'good', 'very_good'];
  return ratings[score - 1] as RatingScale;
}

export const convertRatingToIndex = (rating: string) => {
  const score = convertRatingToScore(rating);
  return score ? score - 1 : undefined;
};

export const ratingMix = (ratingTypes: RatingTypes[]) => {
    const uniqueRatingTypes = new Set(ratingTypes)

    // Handle edge cases first
    if (uniqueRatingTypes.size === 0) return null
    if (uniqueRatingTypes.size === 1 && uniqueRatingTypes.has('generic')) return 'no_rating'

    // Remove generic type as it doesn't affect the rating mix
    uniqueRatingTypes.delete('generic')

    // Handle single rating type cases
    if (uniqueRatingTypes.size === 1) {
        const [type] = uniqueRatingTypes
        if (type === 'compliance') return 'compliance_a'
        if (type === 'compliance_b') return 'compliance_b'
        if (type === 'condition') return 'condition'
        return 'no_rating'
    }

    // Handle multiple rating type combinations
    const hasCompliance = uniqueRatingTypes.has('compliance')
    const hasComplianceB = uniqueRatingTypes.has('compliance_b')
    const hasCondition = uniqueRatingTypes.has('condition')

    const ratingCombinations: [boolean[], RatingTypes][] = [
        [[hasCompliance, hasCondition, hasComplianceB], 'mixed'],
        [[hasCompliance, hasComplianceB], 'compliance_a'],
        [[hasCondition, hasCompliance], 'mixed'],
        [[hasCondition, hasComplianceB], 'frankenstein']
    ]

    const matchedRating = ratingCombinations.find(([conditions]) => 
        conditions.every(condition => condition)
    )

    if (!matchedRating) {
        console.debug('unknown rating mix', uniqueRatingTypes)
        return 'unknown'
    }

    return matchedRating[1];
}

export const determineRatingMix = (items: ReportData[]): RatingTypes => {
  const uniqueRatingTypes = new Set(items.map((item) => item.ratingType));
   return ratingMix(Array.from(uniqueRatingTypes));
};

export const calculateAverageByRatingType = (items: ReportData[]) => {
  const groupedItems: GroupSummary[] = items.reduce((acc, item) => {
    // Find an existing group for the current item's rating type

    const groupName = ratingTypeToGroupName[item.ratingType];

    let group = acc.find((group) => group.groupName === groupName);

    // If no group exists, create a new one and add it to the accumulator
    if (!group) {
      group = {
        groupName: groupName,
        ratingType: item.ratingType,
        items: [item],
        total: 0,
        count: 0,
        average: 0,
      };
      acc.push(group);
    } else {
      group.items.push(item);
    }

    // Update the total and count for the group
    group.total += item.result ?? 0;
    group.count += 1;

    // Calculate the average for the group
    group.average = group.total / group.count;

    return acc;
  }, []);

  // groupedItems.push({
  //     groupName: 'Not Rated',
  //     ratingType: 'no_rating',
  //     total: 0,
  //     count: 0,
  //     average: 0
  // });

  return groupedItems;
};

export const calculateOverallAverage = (items: ReportData[]) => {
  return items.reduce((sum, item) => sum + item.result, 0) / items.length;
};

export const getBaseTestSuiteType = (testSuiteType: string) => {
  return testSuiteType.split('_')[0];
};

//ratingType to groupName lookup table
const ratingTypeToGroupName: Record<string, string> = {
  compliance: 'Compliance',
  compliance_a: 'Compliance',
  compliance_b: 'Compliance',
  condition: 'Condition',
  mixed: 'Mixed',
  no_rating: 'Not Rated',
};

export const getRatingGroupName = (ratingType: RatingTypes) => {
  return ratingTypeToGroupName[ratingType];
};

export const tileColorScheme = (ratingType: RatingTypes) => {
  //expected tailwind colors: bg-compliance-500, bg-condition-500 border-condition-500 border-compliance-500 text-compliance-500 text-condition-500
  return ratingType.includes('compliance') ? `compliance-500` : `condition-500`;
};

type RatingScale =
  | 'very_poor'
  | 'poor'
  | 'fair'
  | 'good'
  | 'very_good'
  | 'no_rating';

  const compliance: { [key: string]: string } = {
    very_poor: 'Very Poor',
    poor: 'Poor',
    fair: 'Fair',
    good: 'Good',
    very_good: 'Very Good',
    no_rating: 'No Rating',
  };

  const condition: { [key: string]: string } = {
    very_poor: 'Not compliant',
    poor: 'Not compliant',
    fair: 'Not compliant',
    good: 'Compliant',
    very_good: 'Compliant',
    no_rating: 'No Rating',
  };


export function getOverallRatingLabel(rating: RatingScale, type = 'compliance'): string {
    const whatType = typeof type === 'string' ? type : type[0] ?? 'compliance';
    return whatType === 'condition' ? condition[rating] : compliance[rating];

}

export const getMixedRatingLabel = (rating: RatingScale, type: string[] = ['compliance', 'condition']): string[] => {
  
  if (type.length === 1) {
    return [getOverallRatingLabel(rating, type[0])];
  }
  const labels = type
    .sort()
    .reduce((acc, curr) => {
    const label = curr === 'compliance' ? compliance[rating] : curr === 'condition' ? condition[rating] : null;
    if (label) {
      acc.push(label);
    }
    return acc;
  }, [] as string[]);

  if (rating !== 'no_rating') {
  labels.push(convertRatingToScore(rating)?.toString() + '.00 / 5.00');
  }
  
  return Array.from(new Set(labels));
}



export function getOverallRating(score: number): RatingScale {
  if (score < 2) return 'very_poor';
  if (score < 3) return 'poor';
  if (score < 4) return 'fair';
  if (score < 5) return 'good';
  if (score >= 5) return 'very_good';
  return 'no_rating';
}

export const getRatingLabel = (testSuite: TestSuite, rating: string) => {
  const ratingType = testSuite?.ratingScale[`${rating}_label`];
  return ratingType ?? rating;
}
