import React, { createContext, useCallback, useMemo, useState } from 'react';
import { ReportHeaderModes, ReportViewModes } from '../types';

export interface ReportDisplayContext {
    viewMode: ReportViewModes;
    headerMode: ReportHeaderModes;
    switchHeaders: () => void;
    isHeaderFixed: boolean;
    setIsHeaderFixed: (fixed: boolean) => void;
}

const defaultReportDisplayContext: ReportDisplayContext = {
    viewMode: 'details',
    headerMode: 'normal',
    switchHeaders: () => {
        throw new Error('switchHeaders was called before being initialized by ReportDisplayProvider');
    },
    isHeaderFixed: false,
    setIsHeaderFixed: (fixed: boolean) => {
        throw new Error('setIsHeaderFixed was called before being initialized by ReportDisplayProvider');
    }
}

export const ReportDisplayContext = createContext<ReportDisplayContext>(defaultReportDisplayContext);

interface ReportDisplayProviderProps {
    children: React.ReactNode;
}

const ReportDisplayProvider: React.FC<ReportDisplayProviderProps> = ({ children }) => {
    const [viewMode, setViewMode] = useState<ReportViewModes>('details');
    const [headerMode, setHeaderMode] = useState<ReportHeaderModes>('normal');
    const [isHeaderFixed, setIsHeaderFixed] = useState(false);

    const switchHeaderMode = useCallback(() => {
        setHeaderMode(prev => prev === 'normal' ? 'switch' : 'normal');
    }, []);

    const value = useMemo(() => ({
        viewMode,
        setViewMode,
        headerMode,
        switchHeaders: switchHeaderMode,
        isHeaderFixed,
        setIsHeaderFixed
    }), [viewMode, headerMode, isHeaderFixed, switchHeaderMode]);

    return (
        <ReportDisplayContext.Provider value={value}>
            {children}
        </ReportDisplayContext.Provider>
    );
};

export default ReportDisplayProvider;