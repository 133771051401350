import { useReportContext } from "@/app/features/reports/contexts/report-context";
import EditModeToggle from "@/components/raytd/edit-mode-toggle";

const ReportEditModeToggle: React.FC = () => {
    const { isEditable, editMode, setEditMode } = useReportContext();

    if (!isEditable) {
        return null;
    }

    return <EditModeToggle editMode={editMode} setEditMode={setEditMode} />
}

ReportEditModeToggle.displayName = 'ReportEditModeToggle';

export default ReportEditModeToggle;
