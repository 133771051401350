import convertElementToTree from '../utils/convertElementsToTree';
import convertScoreToTree from '../utils/convertScoreToTree';

import { useCallback, useContext, useEffect } from 'react';
import { ReportGroupModes } from '../types';
import convertSpacesToTree from '../utils/convertSpacesToTree';
import convertTestSpecificationToTree from '../utils/convertTestsToTree';
import { ReportTestItem } from '../utils/entities';
import useReportItemFilters from '../utils/useReportItemsFilter';

import { ReportDataProviderContext } from '@/app/features/reports/contexts/data-context';
import useLiveReportData from '@/app/features/reports/hooks/use-live-report-data';
import { SelectedFilters } from '@/app/features/reports/types/filters';

//@TODO: Move this to the types folder
export type ReportFilters = Pick<
  ReturnType<typeof useReportItemFilters>,
  | 'selectedFilters'
  | 'facets'
  | 'filteredCounts'
  | 'unfilteredCounts'
  | 'filtersActive'
  | 'setSelectedFilters'
  | 'updateFilters'
  | 'removeFilter'
  | 'filterInput'
  | 'setFilterInput'
>;

export type useReportDataResult = ReturnType<typeof useLiveReportData>;

export const useReportData = (
  reportId: string,
  includedTestsOnly = false,
  initialFilters?: SelectedFilters
) => {
  const provider = useContext(ReportDataProviderContext);

  const {
    data,
    bookmarks,
    missingData,
    missingSegments,
    missingDataCount,
    enrichedTestItems,
    report,
    testSuites,
    isLoading: isDataLoading,
    error,
    totalDataCount,
  } = provider.useReportData(reportId, includedTestsOnly);

  const {
    selectedFilters,
    facets,
    filteredData, // data that could be filtered by selectedFilters
    filteredCounts,
    unfilteredCounts,
    filtersActive,
    setSelectedFilters,
    updateFilters,
    removeFilter,
    filterInput,
    setFilterInput,
  } = useReportItemFilters(enrichedTestItems, report, initialFilters);

  const updateGroupedData = useCallback(
    (groupMode: ReportGroupModes, data: ReportTestItem[]) => {
      switch (groupMode) {
        case 'element':
          return convertElementToTree(data);
        case 'location':
          return convertSpacesToTree(data);
        case 'test':
          return convertTestSpecificationToTree(data);
        case 'rating':
          return convertScoreToTree(data, report?.testSuites);
        default:
          console.error('Unknown group mode', groupMode);
          return data;
      }
    },
    [report?.testSuites]
  );

  return {
    data,
    testSuites,
    bookmarks,
    missingSegments,
    missingDataCount,
    report,
    enrichedTestItems,
    missingData,
    filteredData,
    totalDataCount,
    isLoading: isDataLoading,
    error: error,
    filters: {
      selectedFilters,
      facets,
      filteredCounts,
      unfilteredCounts,
      filtersActive,
      setSelectedFilters,
      updateFilters,
      removeFilter,
      filterInput,
      setFilterInput,
    },
    updateGroupedData,
  };
};

export default useReportData;
