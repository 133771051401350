import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { ReportAuthor } from '../types/report'
import { Client } from 'store/src/lib/services/types';
import AuthorBadge from '@/components/raytd/user-avatar';
import { Address } from 'store/src/lib/asset';
import Loading from '@/app/features/userOnboarding/components/loading';
import { Skeleton } from '@/components/ui/skeleton';

interface PreparedSectionProps {
  client?: Client;
  authors?: ReportAuthor[];
  editMode: boolean;
}

export function PreparedSection({ client, authors = [], editMode }: PreparedSectionProps) {
  const primaryAuthor = authors.find(author => author.isPrimary);
  const secondaryAuthors = authors
    .filter(author => !author.isPrimary)
    .sort((a, b) => a.name.localeCompare(b.name));


  if (!client) {
    return (
      <div className="h-20 flex flex-row w-full gap-4">
        <Skeleton className="h-16 w-16" />
        <Skeleton className="h-16 w-full" />
      </div>
    )
  }

  return (
    <section className="grid grid-cols-1 md:grid-cols-[300px_1fr] gap-6">
      <div className="space-y-4 max-w-[300px]">

        <h3 className="font-medium mb-4">Prepared for</h3>

        <Avatar className="h-24 w-24">
          <AvatarImage
            src={client.logo?.thumbnail_url || "/placeholder.svg?height=80&width=80"}
            alt={`${client.organisation_name || 'Client'} logo`}
            className='object-cover'
          />
          <AvatarFallback>{client.organisation_name[0]}</AvatarFallback>
        </Avatar>

        <p className="font-medium">{client?.organisation_name || ''}</p>

        <div className="h-12 flex flex-col">
          <p className="font-semibold">{`${client?.first_name || ''} ${client?.last_name || ''}`}</p>
          <p className="text-sm text-primary">{client?.position || ''}</p>
        </div>

        <AddressCard address={client?.address} />

        <div className="h-12 flex flex-col">
          <p className="text-sm">{client?.email || ''}</p>
          <p className="text-sm">{client?.phone || ''}</p>
        </div>
      </div>

      <div>
        <h3 className="font-medium mb-4">Prepared by</h3>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {primaryAuthor && (
            <AuthorCard key={primaryAuthor.id} author={primaryAuthor} isPrimary={true} editMode={editMode} />
          )}
          {secondaryAuthors.map(author => (
            <AuthorCard key={author.id} author={author} isPrimary={false} editMode={editMode} />
          ))}
        </div>
      </div>
    </section>
  )
}


interface AuthorCardProps {
  author: ReportAuthor;
  isPrimary: boolean;
  editMode: boolean;
}

function AuthorCard({ author, isPrimary, editMode }: AuthorCardProps) {
  return (
    <div className="space-y-4 flex flex-col items-start">

      <AuthorBadge item={author} size='large' />

      <div className="flex font-medium">{author.company?.title}</div>

      <div className="h-12 flex flex-col">
        <p className="font-semibold">{author.name}</p>
        <p className="text-sm text-primary">{author.position_title ?? ''}</p>
      </div>

      <p className="text-sm">{author.company.name}</p>

      <AddressCard address={author.company?.address} />

      <div className="h-12 flex flex-col">
        <p className="text-sm">{author.email}</p>
        <p className="text-sm">{author.mobile_number}</p>
      </div>
    </div>
  )
}

const AddressCard = ({ address }: { address: Partial<Address> }) => {
  return (
    <div className="h-12 flex flex-col space-y-0.5">
      {address && (
        <>
          <span className="text-sm">{address.street}</span>
          <span className="text-sm">{`${address.suburb}, ${address.state} ${address.postcode}`}</span>
        </>
      )}
    </div>
  )
}