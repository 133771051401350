import BulkStatusChangeDialog from '@/app/features/reports/components/bulk-change-dialog';
import useReportViewerContext, { useReportViewContext } from '@/app/features/reports/components/report-viewer-context';
import { useReportSelectionContext } from '@/app/features/reports/contexts/selection-context';
import { TestStatus } from '@/app/features/reports/types/test-form';
import { ReportTestItem } from '@/app/features/reports/utils/entities';
import { Button } from '@/components/ui/button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { cn } from '@/lib/utils';
import { useBulkUpdateTestItemsMutation, useUpdateReportTestItemMutation } from '@app.raytd.com/store';
import { ChevronDown, ListOrderedIcon, X } from 'lucide-react';
import { useCallback, useState } from 'react';
import { toast } from 'sonner';

interface BulkEditDropdownProps {
    // onStatusChange: (status: TestStatus) => void;
    filteredData: ReportTestItem[];
    updateTestStatus: (testId: string, status: TestStatus) => Promise<void>;
}

const BulkEditDropdown = ({
    // onStatusChange,
    filteredData,
    updateTestStatus
}: BulkEditDropdownProps) => {
    const { selectedItems, setSelectedItems, bulkEditMode, setBulkEditMode } = useReportSelectionContext();

    const [bulkStatusDialogOpen, setBulkStatusDialogOpen] = useState(false);
    const [bulkStatusToApply, setBulkStatusToApply] = useState<TestStatus | null>(null);
    const [eligibleTests, setEligibleTests] = useState<Array<{ id: string; title: string }>>([]);
    const [ineligibleTests, setIneligibleTests] = useState<Array<{ id: string; title: string; reason: string }>>([]);

    const { reportId } = useReportViewerContext();

    // Update your handleBulkStatusChange function
    const handleBulkStatusChange = useCallback(async (status: TestStatus) => {
        // Get the test details for selected items
        const selectedTests = filteredData.filter(test => selectedItems.has(test.assessmentTestId.toString()));

        // Separate tests into eligible and ineligible
        const { eligibleTests, ineligibleTests } = selectedTests.reduce((acc, test) => {
            // Check if test can be changed to final status
            const canChangeFinal = test.exclusionReason === 'included' &&
                test.score !== null &&
                test.score >= 0;

            if (status === 'published' && !canChangeFinal) {
                acc.ineligibleTests.push({
                    id: test.assessmentTestId.toString(),
                    title: `Test ${test.assessmentTestId}`,
                    reason: test.exclusionReason === 'included'
                        ? 'Missing score'
                        : `Currently ${test.exclusionReason}`
                });
            } else {
                acc.eligibleTests.push({
                    id: test.assessmentTestId.toString(),
                    title: `Test ${test.assessmentTestId}`
                });
            }
            return acc;
        }, {
            eligibleTests: [],
            ineligibleTests: []
        });

        // Set the state for the dialog
        setEligibleTests(eligibleTests);
        setIneligibleTests(ineligibleTests);
        setBulkStatusToApply(status);
        setBulkStatusDialogOpen(true);
    }, [selectedItems, filteredData]);

    const [bulkUpdateTestItems, { isLoading: isUpdatingReportTest }] = useBulkUpdateTestItemsMutation();

    const handleUpdateTestStatus = useCallback(async (testIds: number[], status: TestStatus) => {
        const response = await bulkUpdateTestItems({
            reportId: reportId,
            testItems: testIds,
            status: status.slice(0, 1).toUpperCase() + status.slice(1) as 'Draft' | 'Qa' | 'Published' | 'Archived'
        }).unwrap();
        
        return response;
    }, [bulkUpdateTestItems, reportId]);

    const handleBulkStatusConfirm = async () => {
        try {
            // Update all eligible tests
            // await Promise.all(
            //     eligibleTests.map(test => handleUpdateTestStatus(test.id, bulkStatusToApply))
            // );
            const response = await handleUpdateTestStatus(eligibleTests.map(test => parseInt(test.id)), bulkStatusToApply);
            console.debug('handleBulkStatusConfirm', response);

            // Clear selection and close dialog
            setSelectedItems(new Set());
            setBulkEditMode(false);
            setBulkStatusDialogOpen(false);

            toast.success(`Successfully updated ${eligibleTests.length} tests`);

            if (ineligibleTests.length > 0) {
                toast.warning(`${ineligibleTests.length} tests could not be updated`);
            }
        } catch (error) {
            console.error('Failed to update status:', error);
            toast.error('Failed to update status');
        }
    };

    if (!bulkEditMode || selectedItems.size === 0) {
        return (
            <Button
                variant='outlinelight'
                size='default'
                onClick={() => setBulkEditMode(prev => !prev)}
                className={cn(
                    bulkEditMode && "bg-primary text-primary-foreground hover:bg-primary/90"
                )}
            >
                <ListOrderedIcon className="h-4 w-4 mr-1" />
                Edit
            </Button>
        );
    }

    return (
        <>
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <Button
                        variant='outlinelight'
                        size='default'
                        className={cn(
                            "bg-primary text-primary-foreground hover:bg-primary/90"
                        )}
                    >
                        <ListOrderedIcon className="h-4 w-4 mr-1" />
                        {`(${selectedItems.size}) Selected`}
                        <ChevronDown className="h-4 w-4 ml-1" />
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                    <DropdownMenuGroup>
                        <DropdownMenuItem onClick={() => handleBulkStatusChange('archived')}>
                            <ListOrderedIcon className="h-4 w-4 mr-2" />
                            Change to Archive
                        </DropdownMenuItem>
                        <DropdownMenuItem onClick={() => handleBulkStatusChange('draft')}>
                            <ListOrderedIcon className="h-4 w-4 mr-2" />
                            Change to Draft
                        </DropdownMenuItem>
                        <DropdownMenuItem onClick={() => handleBulkStatusChange('qa')}>
                            <ListOrderedIcon className="h-4 w-4 mr-2" />
                            Change to QA
                        </DropdownMenuItem>
                        <DropdownMenuItem onClick={() => handleBulkStatusChange('published')}>
                            <ListOrderedIcon className="h-4 w-4 mr-2" />
                            Change to Final
                        </DropdownMenuItem>
                    </DropdownMenuGroup>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem
                        onClick={() => setSelectedItems(new Set())}
                        className="text-destructive"
                    >
                        <X className="h-4 w-4 mr-2" />
                        Clear Selection
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>

            <BulkStatusChangeDialog
                isOpen={bulkStatusDialogOpen}
                onClose={() => setBulkStatusDialogOpen(false)}
                eligibleTests={eligibleTests}
                ineligibleTests={ineligibleTests}
                status={bulkStatusToApply}
                onConfirm={handleBulkStatusConfirm}
            />
        </>
    );

};

export default BulkEditDropdown;