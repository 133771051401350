import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { useFormContext, useWatch } from "react-hook-form"
import { Button } from "@/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { FormControl, FormField, FormItem, FormLabel } from "@/components/ui/form"
import { useTestSpecificationData } from '../hooks/useTestSpecifications'
import { TestSpecification, TestSuites } from '../types/test-specification'
import { ChevronDown, ChevronRight, X, Loader2, MapPinPlusIcon } from 'lucide-react';
import { cn } from '@/lib/utils'
import { sentenceCase } from 'change-case';
import TextWithBreaks from '@/components/raytd/convert-line-break'
import { select } from 'slate'

const getTestSuiteTypeBorderVariant = (type: string) => {
  switch (type) {
    case 'compliance':
      return 'border-l-compliance-500';
    case 'condition':
      return 'border-l-condition-500';
    case 'generic':
      return 'border-l-zinc-500';
    default:
      return '';
  }
}

const getGroupedByType = (testSuites: TestSuites[]) => {
  return testSuites.reduce((acc, suite) => {
    const type = suite.type === 'generic' ? 'general' : suite.type;
    if (!acc[type]) {
      acc[type] = [];
    }
    acc[type].push(suite.title?.trim());
    return acc;
  }, {} as Record<string, string[]>);
};

interface SelectionState {
  suite: string | null;
  category: string | null;
  subcategory: string | null;
  step: 'suite' | 'category' | 'subcategory' | 'test';
}

type FoundSpec = {
  suite: TestSuites;
  specification: TestSpecification;
} | undefined;

interface TestSpecificationSelectorProps {
  name: string;
  label: string;
  testSuites: TestSuites[];
  labelComponent?: React.ReactNode;
  onSelect?: (value: number | null) => void;
  onChange?: (value: number) => void; // Added onChange prop
}

export const TestSpecificationSelector: React.FC<TestSpecificationSelectorProps> = React.memo(({ name, label, testSuites, labelComponent, onSelect, onChange }: TestSpecificationSelectorProps) => {
  const { control, watch } = useFormContext();
  const organizedData = useTestSpecificationData(testSuites);
  //const [step, setStep] = useState<'suite' | 'category' | 'subcategory' | 'test'>('suite');
  //  const [selectedSuite, setSelectedSuite] = useState<string | null>(null);
  //const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  //const [selectedSubcategory, setSelectedSubcategory] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  const [selection, setSelection] = useState<SelectionState>({
    suite: null,
    category: null,
    subcategory: null,
    step: 'suite'
  });


  // const initialValue = watch(name);
  const initialValue = useWatch({
    control,
    name
  });

  const getSelectedTest = useCallback((specId: number) => {
    return testSuites.reduce<FoundSpec>((found, suite) => {
      if (found) return found;
      const specification = suite.specifications.find(spec => spec.id === Number(specId));
      return specification ? { suite, specification } : undefined;
    }, undefined);
  }, [testSuites]);

  const updateSelection = useCallback((selectedTestId: number | null) => {
    if (selectedTestId === null) {
      // setSelectedSuite(null);
      // setSelectedCategory(null);
      // setSelectedSubcategory(null);
      // setStep('suite');

      setSelection({
        suite: null,
        category: null,
        subcategory: null,
        step: 'suite'
      });

    } else {
      // const selectedTest = testSpecifications.find(spec => spec.id === Number(newValue));
      const selectedTest = testSuites.reduce<FoundSpec>((found, suite) => {
        if (found) return found;
        const specification = suite.specifications.find(spec => spec.id === Number(selectedTestId));
        return specification ? { suite, specification } : undefined;
      }, undefined);

      if (selectedTest) {
        setSelection({
          suite: selectedTest.suite.title || null,
          category: selectedTest.specification.category || null,
          subcategory: selectedTest.specification.subcategory || null,
          step: 'test'
        });
        onSelect(Number(selectedTestId));
      }
    }
  }, [testSuites, onSelect]);

  useEffect(() => {
    if (initialValue !== undefined) {
      updateSelection(initialValue);
    } else {
      updateSelection(null);
    }
  }, [initialValue, updateSelection]);

  const filteredTestSpecifications = useMemo(() => {
    if (!search) return [];

    return testSuites
      .flatMap(suite => suite.specifications)
      .filter(spec => spec.label?.toLowerCase().includes(search.toLowerCase()));
  }, [testSuites, search]);

  const groupedSearchResults = useMemo(() => {
    const grouped: { [key: string]: TestSpecification[] } = {};

    filteredTestSpecifications.forEach(spec => {
      const testSuite = testSuites.find(suite => suite.id === spec.test_suite_id);
      let key;
      if (testSuite.type !== 'condition') {
        key = `${testSuite.title} > ${spec.category} > ${spec.subcategory}`;
      } else {
        key = `${testSuite.title}`;
      }
      if (!grouped[key]) {
        grouped[key] = [];
      }
      grouped[key].push(spec);
    });
    return grouped;
  }, [testSuites, filteredTestSpecifications]);

  const goToTestSuites = useCallback(() => {
    setSelection({
      suite: null,
      category: null,
      subcategory: null,
      step: 'suite'
    });
  }, []);

  const goToCategory = useCallback(() => {
    if (selection?.suite) {
      setSelection(prev => ({
        ...prev,
        category: null,
        subcategory: null,
        step: 'category'
      }));
    }
  }, [selection?.suite]);
  // const goToCategory = useCallback(() => {
  //   if (selectedSuite) {
  //     setStep('category');
  //     setSelectedCategory(null);
  //     setSelectedSubcategory(null);
  //   }
  // }, [selectedSuite]);

  const goToSubcategory = useCallback(() => {
    if (selection?.suite && selection?.category) {
      setSelection(prev => ({
        ...prev,
        subcategory: null,
        step: 'subcategory'
      }));
    }
  }, [selection?.suite, selection?.category]);
  // const goToSubcategory = useCallback(() => {
  //   if (selectedSuite && selectedCategory) {
  //     setStep('subcategory');
  //     setSelectedSubcategory(null);
  //   }
  // }, [selectedSuite, selectedCategory]);

  const handleSelect = useCallback((value: number) => {
    setOpen(false);
    onChange && onChange(value); // Updated to call onChange
    onSelect && onSelect(Number(value) || null);
  }, [onChange, onSelect]);

  const autoSelectSingleChild = useCallback(async (currentSuite: string, currentCategory: string | null = null, currentSubcategory: string | null = null, depth: number = 0) => {
    if (depth >= 4) return; // Prevent infinite loops by limiting depth

    if (!currentSuite) return;

    console.debug('autoSelectSingleChild', { currentSuite, currentCategory, currentSubcategory, depth });

    // Check current level and get next options
    let nextOptions: string[] = [];
    if (!currentCategory) {
      // At suite level, get categories
      nextOptions = Object.keys(organizedData[currentSuite] || {});
    } else if (!currentSubcategory) {
      // At category level, get subcategories
      nextOptions = Object.keys(organizedData[currentSuite][currentCategory] || {});
    } else {
      // At subcategory level, get tests
      nextOptions = (organizedData[currentSuite][currentCategory][currentSubcategory] || [])
        .map(test => test.label);
    }

    // If exactly one option exists, auto-select it
    if (nextOptions.length === 1) {
      if (!currentCategory) {
        setSelection(prev => ({
          ...prev,
          category: nextOptions[0],
          step: 'subcategory'
        }));
        // Recursively check next level
        await autoSelectSingleChild(currentSuite, nextOptions[0], null, depth + 1);
      } else if (!currentSubcategory) {
        setSelection(prev => ({
          ...prev,
          subcategory: nextOptions[0],
          step: 'test'
        }));
        // Recursively check next level
        await autoSelectSingleChild(currentSuite, currentCategory, nextOptions[0], depth + 1);
      } else {
        // At test level, select the test
        const test = organizedData[currentSuite][currentCategory][currentSubcategory][0];
        onChange && onChange(test.id);
        handleSelect(test.id);
      }
    }
  }, [organizedData, onChange, handleSelect]);


  const renderSearchResults = useCallback((onChange: (value: number) => void) => (
    <>
      {Object.entries(groupedSearchResults).map(([path, specs]) => (
        <CommandGroup key={path} heading={path}>
          {specs.map((spec) => (
            <CommandItem
              key={spec.id}
              onSelect={() => {
                onChange(spec.id);
                handleSelect(spec.id);
              }}
            >
              {spec.label}
            </CommandItem>
          ))}
        </CommandGroup>
      ))}
    </>
  ), [groupedSearchResults, handleSelect]);

  const groupedByType = useMemo(() =>
    getGroupedByType(testSuites),
    [testSuites]
  );

  const renderContent = useCallback((onChange: (value: number) => void) => {
    // console.log('TestSpecificationSelector renderContent called');
    if (search) return renderSearchResults(onChange);

    const { suite, category, subcategory, step } = selection;

    switch (step) {
      case 'suite':
        return (
          <>
            {Object.keys(groupedByType).sort().map((type) => (
              <CommandGroup
                key={type}
                heading={sentenceCase(type)}
                className={cn(
                  "border-l-2 border-l-green-500 ml-2 rounded-none mb-0.5 my-1",
                  getTestSuiteTypeBorderVariant(type)
                )}
              >
                {groupedByType[type].sort().map((suiteId) => (
                  <CommandItem
                    key={suiteId}
                    className=''
                    onSelect={() => {
                      setSelection(prev => ({
                        ...prev,
                        suite: suiteId,
                        step: 'category'
                      }));
                      autoSelectSingleChild(suiteId);
                    }}
                  >
                    {suiteId}
                  </CommandItem>
                ))}
              </CommandGroup>
            ))}
          </>
        );
      case 'category':
        return suite && organizedData[suite] ? (
          <CommandGroup heading="Select Category">
            {Object.keys(organizedData[suite]).sort().map((category) => (
              <CommandItem
                key={category}
                onSelect={() => {
                  setSelection(prev => ({
                    ...prev,
                    category: category,
                    step: 'subcategory'
                  }));
                  autoSelectSingleChild(suite, category);

                }}
              >
                {category}
              </CommandItem>
            ))}
          </CommandGroup>
        ) : null;
      case 'subcategory':
        return (suite && category && organizedData[suite][category]) ? (
          <CommandGroup heading="Select Subcategory">
            {Object.keys(organizedData[suite][category]).sort().map((subcategory) => (
              <CommandItem
                key={subcategory}
                onSelect={() => {
                  setSelection(prev => ({
                    ...prev,
                    subcategory: subcategory,
                    step: 'test'
                  }));
                  autoSelectSingleChild(suite, category, subcategory);

                }}
              >
                {subcategory}
              </CommandItem>
            ))}
          </CommandGroup>
        ) : null;
      case 'test':
        return (suite && category && subcategory &&
          organizedData[suite][category][subcategory]) ? (
          <CommandGroup heading="Select Test">
            {organizedData[suite][category][subcategory].map((test) => (
              <CommandItem
                key={test.id}
                onSelect={() => {
                  onChange(test.id);
                  handleSelect(test.id);
                }}
                className="flex flex-col justify-center items-start border-b border-b-muted"
              >
                <div>{test.label}</div>
                <div className="text-xs text-zinc-500">{test.description}</div>

              </CommandItem>
            ))}
          </CommandGroup>
        ) : null;
    }
  }, [
    search,
    selection,
    organizedData,
    renderSearchResults,
    handleSelect,
    groupedByType
  ]);

  const renderFixedHeader = useCallback(() => {
    if (search) return null;

    const { suite, category, subcategory } = selection;

    let path = [];
    if (suite) {
      path.push(`${suite}`);
    }
    if (category) {
      path.push(category);
    }
    if (subcategory) {
      path.push(subcategory);
    }

    if (path.length === 0) return null;

    return (
      <div className="sticky top-0 bg-white dark:bg-gray-800 p-2 border-b flex items-center justify-between text-xs text-muted-foreground">
        <div className="flex items-center space-x-1">
          {path.map((item, index) => (
            <span key={item} className="flex items-center">
              {index > 0 && <ChevronRight className="h-4 w-4 mx-1" />}
              <button
                onClick={() => {
                  if (index === 0) goToTestSuites();
                  else if (index === 1) goToCategory();
                  else if (index === 2) goToSubcategory();
                }}
                className="hover:underline focus:outline-none"
              >
                {item}
              </button>
            </span>
          ))}
        </div>
        <Button
          variant="ghost"
          size="icon"
          onClick={goToTestSuites}
          className="h-6 w-6"
        >
          <X className="h-4 w-4" />
        </Button>
      </div>
    );
  }, [search, selection, goToTestSuites, goToCategory, goToSubcategory]);

  const renderDisplayValue = useCallback(() => {
    if (initialValue) {
      const selectedSpec = getSelectedTest(initialValue);
      if (!selectedSpec) {
        return "Select Test Specification";
      }
      //return selectedSpec ? selectedSpec.specification.label : "Select Test Specification";
      return <div className={cn("flex flex-col items-start space-y-0.5 border-l-2 pl-2", getTestSuiteTypeBorderVariant(selectedSpec.suite.type))}>
        <div className="text-sm text-muted-foreground">{selectedSpec.suite.title}</div>

        {selectedSpec.suite.type === 'compliance' && (
          <>
            <div className="text-sm text-muted-foreground">{selectedSpec.specification.category}</div>
            <div className="text-sm text-muted-foreground">{selectedSpec.specification.subcategory}</div>
            <div className="text-base font-medium">{selectedSpec.specification.label}</div>
          </>
        )}
        {selectedSpec.suite.type === 'condition' && (
          <div className="text-base font-medium">Condition Test</div>
        )}

        {selectedSpec.suite.type === 'generic' && (
          <div className="text-base font-medium">General Test</div>
        )}
        {
          selectedSpec.suite.type === 'compliance' && (
            <div className="text-zinc-600 font-light text-sm text-wrap text-left">
              <TextWithBreaks>
                {selectedSpec.specification.description}
              </TextWithBreaks>
            </div>
          )
        }
      </div >;

    } else {
      return "Select Test Specification";
    }
  }, [initialValue, getSelectedTest]);

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          {labelComponent || <FormLabel>{label}</FormLabel>}
          <FormControl>
            <Popover open={open} onOpenChange={setOpen}>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  role="combobox"
                  aria-expanded={open}
                  className="h-full w-full justify-between"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setOpen(true);
                  }}
                >
                  {/* {initialValue
                    ? (() => {
                      // const selectedSpec = testSuites.find(spec => spec.id === Number(value));
                      const selectedSpec = getSelectedTest(initialValue);
                      return selectedSpec ? selectedSpec.specification.label : "Select Test Specification";
                    })()
                    : "Select Test Specification"} */}
                  {renderDisplayValue()}
                  <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </PopoverTrigger>
              <PopoverContent
                className="min-w-[400px] w-[var(--radix-popover-trigger-width)] p-0 popover-content"
                side="bottom"
                align="start"
                modal={true}
                onOpenAutoFocus={e => e.preventDefault()}
              >
                <Command className="max-h-[300px] overflow-y-auto">
                  <CommandInput
                    placeholder="Search test specifications..."
                    value={search}
                    onValueChange={setSearch}
                    className="w-full"
                    aria-label="Search spaces"
                  />
                  {renderFixedHeader()}
                  <CommandList>
                    <CommandEmpty>No test specification found.</CommandEmpty>
                    {renderContent(field.onChange)}
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
          </FormControl>
        </FormItem>
      )}
    />
  );
});

export default TestSpecificationSelector;

