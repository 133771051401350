import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';
import { Toaster } from 'sonner';
import { BreadcrumbProvider } from '@/app/layout2/BreadcrumbContext';
import { PhotoViewerProvider } from '@/app/contexts/PhotoViewerContext';
import { AlertProvider } from '@/app/contexts/AlertContext';
import { set } from 'date-fns';
import { cn } from '@/lib/utils';
import { test } from '@playwright/test';

type MainPageProps = PropsWithChildren & {
  className?: string;
}

export const MainPage: React.FC<MainPageProps> = ({ children, className }) => {

  const { isCompact, setScrollingElement } = useAppLayoutContext();
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (elementRef.current) {
      setScrollingElement(elementRef.current);
    }

    return () => {
      setScrollingElement(null);
    };
  }, [setScrollingElement]);


  return (
    <main

      className={cn("h-full flex flex-1 flex-col",
        "border-t border-l rounded-tl-2xl border-r rounded-tr-2xl bg-white",
        "transition-all duration-300 ease-in-out", // Add smooth transitions
        "gap-4 lg:gap-6",
        "py-2 px-4 lg:p-6",
        //isCompact ? "p-4 lg:p-6" : "p-4"
        className
      )}>
      <div
        ref={elementRef}
        className="h-full"
        test-id="main-page"
      >
        {children}
      </div>
    </main>
  )
}

export const MainPagewithColumns: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <main className="flex-1 grid grid-cols-2 gap-4 lg:gap-6">
      {children}
    </main>
  )
}

export interface PageLayoutContextType {
  showSidebar: boolean;
  showHeader: boolean;
  mainPageElement: HTMLDivElement | null;
  isCompact: boolean;
  setScrollingElement: (el: HTMLDivElement | null) => void;
}

export const PageLayoutContext = React.createContext<PageLayoutContextType | undefined>(undefined);

export const useAppLayoutContext = () => {
  if (!React.useContext(PageLayoutContext)) {
    throw new Error('useAppLayoutContext must be used within a AppLayout');
  }

  return React.useContext(PageLayoutContext);
};

const AppLayout: React.FC = () => {

  const [showSidebar, setShowSidebar] = React.useState(true);
  const [showHeader, setShowHeader] = React.useState(true);
  //const mainPageElement = React.useRef<HTMLDivElement>(null);
  const mainPageRef = React.useRef<HTMLDivElement>(null);
  const [forceRender, setForceRender] = useState(0);
  const [isCompact, setIsCompact] = useState(false);


  useEffect(() => {
    console.debug('Switching to compact mode');
  }, [isCompact]);

  const handleScroll = useCallback((element: HTMLDivElement) => {
    console.debug('Scrolling', element.scrollTop);
    setIsCompact(element.scrollTop > 100);
  }, []);

  useEffect(() => {
    const currentElement = mainPageRef.current;
    if (!currentElement) return;

    const scrollListener = () => handleScroll(currentElement);
    currentElement.addEventListener('scroll', scrollListener);
    return () => currentElement.removeEventListener('scroll', scrollListener);
  }, [handleScroll]);


  // Memoize context value to prevent unnecessary re-renders
  const contextValue = useMemo(() => ({
    showSidebar: true,
    showHeader: true,
    mainPageElement: mainPageRef.current,
    setScrollingElement: (el: HTMLDivElement | null) => {
      if (mainPageRef.current === el || el === null) return;
      console.debug('Setting scrolling element', { el, current: mainPageRef.current });
      mainPageRef.current = el;
    },
    isCompact
  }),
    [showSidebar, showHeader, isCompact]
  );


  return (
    <PageLayoutContext.Provider value={contextValue}>
      <div className="grid min-h-screen max-w-screen-2xl mx-auto bg-muted/50
          md:grid-cols-[220px_1fr] 
          lg:grid-cols-[200px_1fr] ">
        <Sidebar />
        <div className="min-h-full flex flex-col" >
          <BreadcrumbProvider>
            <Header compact={isCompact} />
            <Toaster />
            <main className="flex-grow">
              <PhotoViewerProvider>
                <AlertProvider>
                  <Outlet />
                </AlertProvider>
              </PhotoViewerProvider>
            </main>
          </BreadcrumbProvider>
        </div>
      </div>
    </PageLayoutContext.Provider>
  );
};

export default AppLayout;
