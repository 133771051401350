import { FormControl, FormDescription, FormField, FormItem, FormMessage } from "@/components/ui/form"
import { Control, useFormContext } from "react-hook-form"
import { FormLabelWithIndicator } from "../form-label-with-indicator"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Checkbox } from "@/components/ui/checkbox"
import { MultiSelect } from "@/components/ui/multi-select"
import { SelectField } from "@/app/features/reports/components/select-field"
import { useMemo, useCallback } from "react"
import { CustomFieldProps } from '../custom-fields-container';
import { Plus } from "lucide-react"
import { Button } from "@/components/ui/button"
type CustomSelectFieldProps = CustomFieldProps & {
  options: { id: string; displayName: string }[]
  selectionType: 'single' | 'multiple';
  allowAdd?: boolean;
  onAddOption?: (fieldId: string, displayName: string) => Promise<{ success: boolean, field_id: string, data: any }>;
}

export function CustomSelectField({
  control,
  name,
  label,
  description,
  showDescription,
  info,
  required,
  placeholder,
  options,
  selectionType,
  isValid,
  allowAdd = false,
  showFormMessage = false,
  onAddOption,
}: CustomSelectFieldProps) {

  const customFieldOptions = useMemo(() => {
    return options.map((option) => ({ value: option.id, label: option.displayName }))
  }, [options])

  const { trigger, setValue } = useFormContext();

  const handleOnChange = useCallback((value: any) => {
    console.debug('handleOnChange', name, value);
    setValue(name, value, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
    trigger();
  }, [setValue, trigger, name]);

  const handleOnCreateOption = useCallback(async (value: string) => {
    console.debug('handleOnCreateOption', name, value);
    return await onAddOption?.(name, value);
  }, [onAddOption, name]);

  // if (selectionType === 'multiple') {
  return (
    <SelectField
      control={control}
      //@ts-expect-error
      name={name}
      label={label}
      info={info}
      isValid={isValid}
      options={customFieldOptions}
      required={required}
      placeholder={placeholder}
      disabled={false}
      description={description}
      className="w-full"
      multiple={selectionType === 'multiple'}
      allowAdd={allowAdd}
      onSelect={handleOnChange}
      onCreateOption={handleOnCreateOption}
    />
  )
  // }

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabelWithIndicator label={label} isValid={isValid} required={required} info={info} />
          <Select
            onValueChange={handleOnChange}
            value={field.value}
            disabled={field.disabled}
          >
            <FormControl>
              <SelectTrigger>
                <SelectValue placeholder={placeholder} />
              </SelectTrigger>
            </FormControl>
            <SelectContent>
              {customFieldOptions.length === 0 ? (
                <SelectItem value="empty" disabled>
                  No options available
                </SelectItem>
              ) : (
                <>
                  {customFieldOptions.map((option) => (
                    <SelectItem
                      key={option.value}
                      value={option.value}
                      className="cursor-pointer"
                    >
                      {option.label}
                    </SelectItem>
                  ))}
                  {allowAdd && (
                    <Button
                      variant="ghost"
                      className="w-full justify-start h-8 px-2 py-1 text-sm"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        // Add your logic to handle adding new option
                        // This could open a modal or prompt
                        onAddOption?.(name, field.value);
                      }}
                    >
                      <Plus className="mr-2 h-4 w-4" />
                      Add new option
                    </Button>
                  )}
                </>
              )}
            </SelectContent>
          </Select>
          {showDescription && (<FormDescription>{description}</FormDescription>)}
          {showFormMessage && <FormMessage />}
        </FormItem>
      )}
    />
  )
}

