import { useSaveReportLinkMutation } from "@app.raytd.com/store"
import { useState } from "react"

import { toast } from "sonner"
import { useCallback } from "react"

interface UseSaveReportProps {
    revisionId: number
  }
  
  interface UseSaveReportReturn {
    handleSave: () => Promise<void>
    isSaving: boolean
  }
  
  export function useSaveReport({ 
    revisionId
  }: UseSaveReportProps): UseSaveReportReturn {

    const [saveReportLink, { isLoading: isSavingReportLink }] = useSaveReportLinkMutation();

    const handleSave = useCallback(async () => {
        console.debug('handleSave', revisionId);
        try {
            await saveReportLink(Number(revisionId)).unwrap();
            toast.success('Report link saved');
        } catch (error) {
            toast.error('Failed to save report link');
        }
    }, [revisionId, saveReportLink]);
  
  
    return {
      handleSave,
      isSaving: isSavingReportLink
    }
  }