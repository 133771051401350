import { cn } from '@/lib/utils';
import React from 'react';
import { BookMark, ScrollPosition } from '../types';
import { useReportViewContext } from './report-viewer-context';

type BookmarkScrollbarProps = {
    bookmarks: BookMark[];
    onBookmarkClick: (bookmark: BookMark) => void;
    scrollPosition: ScrollPosition;
}

const BookmarkScrollbar: React.FC<BookmarkScrollbarProps> = React.memo(({ bookmarks, onBookmarkClick, scrollPosition }) => {

    const { isEditMode } = useReportViewContext();

    if (!isEditMode) {
        return null;
    }

    return (

        <div className="fixed w-4 bg-yellow-200 top-[130px] right-0 h-full">

            {
                bookmarks.map((bookmark, index) => {
                    // const relativePosition = (bookmark.index / filteredData.length) * 100;
                    //console.debug('bookmark', bookmark);
                    return (
                        (<div key={`${bookmark.type}-${bookmark.title}`}
                            // className="absolute top-[50%] right-0 transform translate-y-[-50%]"
                            className={cn("absolute h-0.5  cursor-pointer",
                                bookmark.type === 'fastfill' && 'bg-tests-fastfill w-2 z-10',
                                bookmark.type === 'missing' && 'bg-red-400 w-4'
                            )}
                            // style={{ top: `${relativePosition}px` }}
                            // style={{ top: isHeaderFixed ? `calc(${bookmark.position}vh + 130px)` : `${bookmark.position}vh` }}
                            style={{ top: `${bookmark.position}vh` }}
                            onClick={() => onBookmarkClick(bookmark)}

                        >
                            {/* <button className="w-4 h-1 bg-red-700" onClick={() => console.debug('scroll to', bookmark.index)}>{bookmark.title}</button> */}
                        </div>)
                    );
                })
            }

            {/* Position Marker */}
            <div className="absolute 
                w-0 h-0 -left-3 
                border-t-[10px] border-t-transparent
                border-l-[15px] border-l-zinc-700
                border-b-[10px] border-b-transparent
                bg-transparent
                -mt-[9px]
                "
                style={{ top: `calc(${scrollPosition.relativePositions.startIndex}vh)` }}
            >
            </div>


        </div>

    )
});

export default BookmarkScrollbar;
