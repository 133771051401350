import { Badge } from "@/components/ui/badge"
import { ProcessedTestData } from '../utils/processTestData'
import { SectionText } from "@/app/features/reports/components/section-text"
import { KPIMetric } from "@/app/features/reports/components/dashboard-kpi-metric";
import { BigKPIMetric } from './dashboard-kpi-metric';
import Rating from '../Rating';
import { RatingMixedScore } from "@/components/raytd/rating-tool";

type SectionProps = {
    filteredData: ProcessedTestData | null;
    unfilteredData: ProcessedTestData | null;
    filtersActive: boolean;
}

export function SummaryHighlightsSection({ filteredData, unfilteredData, filtersActive }: SectionProps) {
    if (!unfilteredData) {
        return (
            <section className="space-y-6">
                <div className="flex items-center justify-between">
                    <h2 className="text-xl font-semibold">Highlights</h2>
                    <Badge variant="secondary">No Data Available</Badge>
                </div>
                <p>No test distribution data available. Please ensure that test data is properly loaded.</p>
            </section>
        );
    }

    return (
        <section className="space-y-6 mb-6 border-b border-zinc-100 pb-6">
            <div className="flex items-center justify-between">
                <h2 className="text-xl font-semibold">Highlights</h2>
                {filtersActive && <Badge variant="secondary">Filter Applied</Badge>}
            </div>

            <SectionText
                position="summary-highlights-before"
            />

            {/* <RatingTestDistributionGrid filteredData={filteredData} unfilteredData={unfilteredData} filtersActive={filtersActive} /> */}
            <MetricGrid
                filteredData={filteredData}

            />

            <SectionText
                position="summary-highlights-after"
            />

        </section>
    )
}

const MetricGrid = ({ filteredData: data }: Pick<SectionProps, 'filteredData'>) => {
    console.debug('MetricGrid', data);
    return (
        <div className="flex flex-col space-y-6">
            <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
                <KPIMetric title="Assets" value={data.distinctCounts.assetIds ?? 0} />
                <KPIMetric title="Buildings" value={data.distinctCounts.buildingIds ?? 0} />
                <KPIMetric title="Levels" value={data.distinctCounts.levelIds ?? 0} />
                <KPIMetric title="Spaces" value={data.distinctCounts.areaIds ?? 0} />

                <KPIMetric title="Element Groups" value={data.distinctCounts.elementGroups ?? 0} />
                <KPIMetric title="Elements" value={data.distinctCounts.elements ?? 0} />
                <KPIMetric title="Element Identifiers" value={data.distinctCounts.elementIds ?? 0} />
                <KPIMetric title="Element Names" value={data.distinctCounts.elementNames ?? 0} />

                <KPIMetric title="Test Suites" value={data.distinctCounts.testSuiteIds ?? 0} />
                <KPIMetric title="Test Specifications" value={data.distinctCounts.testSpecificationIds ?? 0} />
                <KPIMetric title="Tests with Rating" value={data.testCounts.testsWithRating ?? 0} />
                <KPIMetric title="Tests with No Rating" value={data.testCounts.otherTests ?? 0} />
            </div>

            <div className="grid grid-cols-2 gap-4">
                <BigKPIMetric
                    title="Average Test Rating"
                    value={data.averageScore !== null ? data.averageScore?.toFixed(2) : 'N/A'}
                    outOf={"5.00"}
                >
                    <RatingMixedScore score={data.averageScore} type={data.ratingTypes as any} width={260} height={60} />
                    {/* <Rating selected={Math.floor(data.averageScore) - 1} type="compliance" width={260} height={60} /> */}
                </BigKPIMetric>
                <BigKPIMetric
                    title="Median Test Rating"
                    value={data.medianScore !== null ? data.medianScore?.toFixed(2) : 'N/A'}
                    outOf={"5.00"}
                >
                    {/* <Rating selected={Math.floor(data.medianScore) - 1} type="compliance" width={260} height={60} /> */}
                    <RatingMixedScore score={data.medianScore} type={data.ratingTypes as any} width={260} height={60} />
                </BigKPIMetric>

                <BigKPIMetric
                    title={`Lowest Test Rating (${data.scoreRangeStats.lowestScoreCount})`}
                    value={data.scoreRangeStats.lowestScore?.toFixed(2)}
                    outOf={"5.00"}
                >
                    {/* <Rating selected={Math.floor(data.scoreRangeStats.lowestScore) - 1} type="compliance" width={260} height={60} /> */}
                    <RatingMixedScore score={data.scoreRangeStats.lowestScore} type={data.ratingTypes as any} width={260} height={60} />

                </BigKPIMetric>

                <BigKPIMetric
                    title={`Highest Test Rating (${data.scoreRangeStats.highestScoreCount})`}
                    value={data.scoreRangeStats.highestScore?.toFixed(2)}
                    outOf={"5.00"}
                >
                    {/* <Rating selected={Math.floor(data.scoreRangeStats.lowestScore) - 1} type="compliance" width={260} height={60} /> */}
                    <RatingMixedScore score={data.scoreRangeStats.highestScore} type={data.ratingTypes as any} width={260} height={60} />
                </BigKPIMetric>

            </div>
        </div>
    )
}