import { api } from '@app.raytd.com/store';
import { getStoredAuth } from '@app.raytd.com/store';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

declare global {
    interface Window {
        Pusher: typeof Pusher;
    }
}

window.Pusher = Pusher;

let echo: Echo<any> | null = null;

export const initializeEcho = () => {
  const auth = getStoredAuth();
  
  if (!auth?.token) {
    console.warn('Echo initialization failed: No auth token');
    return null;
  }

  window.Pusher = Pusher;
  
  echo = new Echo({
    broadcaster: 'pusher',
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
    forceTLS: true,
    authorizer: (channel, options) => ({
      authorize: (socketId, callback) => {
        api.post(' api/broadcasting/auth', {
          socket_id: socketId,
          channel_name: channel.name
        })
        .then(response => {
          callback(false, response.body);
        })
        .catch(error => {
          callback(true, error);
        });
      }
    })
  });

  return echo;
};

export const getEcho = () => {
  if (!echo) {
    return initializeEcho();
  }
  return echo;
};