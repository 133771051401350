
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { createOrganisationSchema, createOrgData, Step1Data } from '@/app/features/auth/signup/schema';
import BusyButton from '@/components/raytd/busy-button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { api, authActions, useAppDispatch } from '@app.raytd.com/store';
import { zodResolver } from '@hookform/resolvers/zod';
import APIError from 'store/src/lib/api/apiError';

const LoginError = ({ error }: { error: string }) => {

    if (!error) {
        return null;
    }

    return (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <span className="block sm:inline" data-testid="login-error-message">{error}</span>
        </div>
    );
}

function Step4({ onSuccess }: { onSuccess: () => void }) {

    const dispatch = useAppDispatch();
    const [busy, setBusy] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const { register, handleSubmit, formState: { errors } } = useForm<createOrgData>({
        resolver: zodResolver(createOrganisationSchema),
        defaultValues: {
            title: '',
        },
        mode: 'onBlur'
    });

    const onSubmit = async (data: createOrgData) => {
        console.log('Create new organisation', data);
        setBusy(true);
        setError(null);
        try {
            const orgResponse = await api.post('/api/v1/organisations/join', {
                title: data.title
            });
            console.log('Organisation created', orgResponse);
            await dispatch(authActions.getUser()).unwrap(); //pull in the company created.
            onSuccess();

        } catch (error) {
            console.error('Failed to create organisation', error);
            setError((error as APIError)?.message);
        } finally {
            setBusy(false);
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {error && <LoginError error={error} />}
            <div className="mb-4">
                <Label htmlFor="organisation" className="sr-only">Organisation Name</Label>
                <Input
                    id="organisation"
                    type="text"
                    placeholder="Organisation Name"
                    {...register('title', { required: true })}
                />
                {errors.title && <p className="text-red-600 text-sm">Organisation name is required</p>}
            </div>
            <BusyButton isBusy={busy} type="submit" className="w-full">Create</BusyButton>
        </form>
    )
}

export default Step4;