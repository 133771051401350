import { TestFormValues } from "@/app/features/reports/types/test-form";

import { useMemo } from "react";

import { useState } from "react";

import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { toast } from "sonner";
import { v4 as uuid } from 'uuid';
import { TestSuites } from "@/app/features/reports/types/test-specification"
import { TestSpecification } from "@/app/features/reports/types/test-specification";
import { Portal } from "@radix-ui/react-dialog";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog";

import { Save, List, Settings } from 'lucide-react';
import { TestCase, TestSuite } from "store/src/lib/tests/entity";


interface SavedFormState {
    label: string;
    timestamp: string;
    state: any;
    formId: string;
    testSuiteId: number;
    testSpecificationId: number;
}

interface TestFormDebuggerProps {
    methods: UseFormReturn<TestFormValues>;
    //useCallback((initialData: Partial<TestFormValues & { formId: string; }> = {}) => {

    resetForm: (state: any, options: { preserveDirtyState?: boolean }) => void;
    formId: string;
    selectedTestSuite: {
        suite: TestSuite;
        specification: TestCase;
    };
}

const TestFormDebugger = ({ methods, resetForm, formId, selectedTestSuite }: TestFormDebuggerProps) => {
    const [savedStates, setSavedStates] = useState<SavedFormState[]>([]);
    const [isStatesDialogOpen, setIsStatesDialogOpen] = useState(false);

    // Load saved states on mount
    useEffect(() => {
        const stored = localStorage.getItem('test-form-debug-states');
        if (stored) {
            setSavedStates(JSON.parse(stored));
        }
    }, []);

    // Filter saved states by test suite
    const relevantSavedStates = useMemo(() => {
        return savedStates.filter(state =>
            state.testSuiteId === selectedTestSuite?.suite?.id &&
            state.testSpecificationId === selectedTestSuite?.specification?.id
        );
    }, [savedStates, selectedTestSuite]);

    const saveCurrentState = () => {
        const label = window.prompt('Enter a label for this state:');
        if (!label) return;

        const newState = {
            label,
            timestamp: new Date().toISOString(),
            state: methods.getValues(),
            formId: formId,
            testSuiteId: selectedTestSuite.suite.id,
            testSpecificationId: selectedTestSuite.specification.id
        };

        const updatedStates = [...savedStates, newState];
        localStorage.setItem('test-form-debug-states', JSON.stringify(updatedStates));
        setSavedStates(updatedStates);
        toast.success('Form state saved');
    };

    const restoreState = (savedState: SavedFormState) => {
        // Generate a new formId for this restoration
        const newFormId = uuid();

        console.debug('restoring state', savedState.state);

        // Merge the saved state with a new formId
        const restoredState = {
            // ...savedState.state,
            formId: newFormId
        };

        setTimeout(() => {
            resetForm(restoredState, { preserveDirtyState: true });
            setTimeout(() => {
                //loop the fields and set the values
                Object.keys(savedState.state).forEach(key => {
                    methods.setValue(key as keyof TestFormValues, savedState.state[key], { shouldDirty: true, shouldValidate: true });
                });
            }, 100);
        }, 10);
    };


    const deleteState = (index: number) => {
        const updatedStates = savedStates.filter((_, i) => i !== index);
        localStorage.setItem('test-form-debug-states', JSON.stringify(updatedStates));
        setSavedStates(updatedStates);
        toast.success('State deleted');
    };

    const updateExistingState = (index: number, savedState: SavedFormState) => {

        const updatedState = {
            ...savedState,
            timestamp: new Date().toISOString(),
            state: methods.getValues(),
        };

        const updatedStates = savedStates.map((state, i) =>
            i === index ? updatedState : state
        );

        localStorage.setItem('test-form-debug-states', JSON.stringify(updatedStates));
        setSavedStates(updatedStates);
        toast.success('Debug state updated');
    };


    if (process.env.NODE_ENV !== 'development') return null;

    return (
        <div
            className="fixed -right-20 bg-slate-800 text-white rounded-lg shadow-lg top-0"
            style={{
                position: 'fixed',
                zIndex: 9999,
            }}
        >
            <div className="flex flex-col gap-2 p-2">
                <button
                    onClick={saveCurrentState}
                    className="p-2 hover:bg-slate-700 rounded-lg transition-colors"
                    title="Save current state"
                >
                    <Save size={20} />
                </button>

                <button
                    onClick={() => setIsStatesDialogOpen(true)}
                    className="p-2 hover:bg-slate-700 rounded-lg transition-colors"
                    title="View saved states"
                >
                    <List size={20} />
                </button>
            </div>

            <Dialog open={isStatesDialogOpen} onOpenChange={setIsStatesDialogOpen}>
                <DialogContent className="max-w-md z-[201]">
                    <DialogHeader>
                        <DialogTitle>Saved Form States</DialogTitle>
                    </DialogHeader>

                    <div className="max-h-[60vh] overflow-y-auto">
                        {relevantSavedStates.length === 0 ? (
                            <div className="text-center text-muted-foreground py-4">
                                No saved states for this test suite
                            </div>
                        ) : (
                            relevantSavedStates.map((saved, index) => (
                                <div key={index} className="border-t border-slate-200 py-2">
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <div className="font-medium">{saved.label}</div>
                                            <div className="text-sm text-muted-foreground">
                                                {new Date(saved.timestamp).toLocaleString()}
                                            </div>
                                        </div>
                                        <div className="space-x-2">
                                            <button
                                                onClick={() => {
                                                    restoreState(saved);
                                                    setIsStatesDialogOpen(false);
                                                }}
                                                className="px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600"
                                            >
                                                Restore
                                            </button>
                                            <button
                                                onClick={() => updateExistingState(index, saved)}
                                                className="px-3 py-1 bg-yellow-500 text-white rounded hover:bg-yellow-600"
                                            >
                                                Update
                                            </button>
                                            <button
                                                onClick={() => deleteState(index)}
                                                className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default TestFormDebugger;