import React, { useCallback, useEffect, useMemo } from 'react';
import { cn } from '@/lib/utils';
import HighlightText from '@/app/features/reports/components/test-tile/highlight-text';
import { ImageRow } from '@/app/features/reports/v2/ImageGrid';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/motion/accordion';
import TextWithBreaks from '@/components/raytd/convert-line-break';
import { Assessment } from '@app.raytd.com/store';
import { TestSuite } from 'store/src/lib/tests/entity';
import { getRatingLabel } from '../ratingHelpers';
import { ReportTestItem } from '../utils/entities';
import useReportViewerContext, { useReportViewContext } from './report-viewer-context';
import { TestItemHeader } from './test-tile-header';
import { TestTileContext } from './test-tile/context';
import CustomFieldContainer from './test-tile/custom-fields';
import TileExpander from './test-tile/expander';
import FastFillText from './test-tile/fast-fill-text';
import TextTile from './test-tile/text-with-label';
import TestTileEditTools from './test-tile/edit-toolbar';
import { useReportGroupingContext } from '@/app/features/reports/contexts/grouping-context';

const TestItemContent: React.FC<{
    item: ReportTestItem;
    testSuite: TestSuite;
    isScrolling: boolean;
    isTileExpanded: boolean;
    handleExpandTile: () => void;
}> = React.memo(({ item, testSuite, isScrolling, isTileExpanded, handleExpandTile }) => (
    <div className="flex-1">
        <div className="flex flex-row gap-4 px-4 mb-4">
            <div className="flex-1 grid grid-cols-2 gap-4">
                <FastFillText field_id='observations' label="Observations" item={item} />
                <FastFillText field_id='recommendations' label="Recommendations" item={item} />
                {!isScrolling && (
                    <Accordion
                        className='flex w-full flex-col divide-y divide-zinc-200 dark:divide-zinc-700 col-span-2'
                        expandedValue={isTileExpanded ? `test-item-${item.assessmentTestId}` : undefined}
                    >
                        <AccordionItem value={`test-item-${item.assessmentTestId}`} className='w-full'>

                            <AccordionContent>

                                <div className=' grid grid-cols-2 gap-4 mb-4'>
                                    {item.testSpecification.type === 'compliance' ? (
                                        <TextTile title="Test Specification" newLines>
                                            {item.testSpecification.description}
                                        </TextTile>
                                    ) : (
                                        <TextTile title="">&nbsp;</TextTile>
                                    )}
                                    <TextTile title="Test Result">
                                        <span className="font-medium"><HighlightText>{getRatingLabel(testSuite, item.result)}</HighlightText></span>
                                        <TextWithBreaks className="font-light">{item.resultDescription}</TextWithBreaks>
                                    </TextTile>
                                </div>
                                <div className='col-span-2'>
                                    <CustomFieldContainer item={item} testSuite={testSuite} />
                                </div>
                            </AccordionContent>
                            <AccordionTrigger className='w-full py-0.5 text-left text-zinc-950 dark:text-zinc-50'>
                                <div className="col-span-2">
                                    <TileExpander handleExpandTile={handleExpandTile} isTileExpanded={isTileExpanded} />
                                </div>
                            </AccordionTrigger>
                        </AccordionItem>
                    </Accordion>
                )}
            </div>
        </div>
        {!isScrolling && (
            <div className="min-h-20 flex mb-2 px-4">
                <ImageRow
                    className="flex-1"
                    media={item?.images ?? []}
                    imageSize="sm"
                    maxPhotos={50}
                />
            </div>
        )}
    </div>
));


type TestItemProps = {
    item: ReportTestItem;
    testSuite: TestSuite;
    assessment: Assessment;
    onEdit?: (id: number) => void;
}

const TestItem: React.FC<TestItemProps> = ({ item, testSuite, assessment, onEdit }) => {

    const { missingData } = useReportViewContext();
    const { isScrolling, showMissingData, showFastFillQA, isUpdatingTest, updatingTestId } = useReportViewerContext();
    const { expandedTiles, setExpandedTiles } = useReportGroupingContext();

    //console.log('TestItem missingData', missingData);

    // if (isUpdatingTest && updatingTestId === item.assessmentTestId) {
    //     return <TestTileSkeleton />;
    // }

    const handleExpandTile = useCallback(() => {
        setExpandedTiles(prev => {
            const next = new Set(prev);
            if (next.has(item.assessmentTestId)) {
                next.delete(item.assessmentTestId);
            } else {
                next.add(item.assessmentTestId);
            }
            return next;
        });
    }, [item?.assessmentTestId, setExpandedTiles]);

    const isTileExpanded = expandedTiles.has(item.assessmentTestId);

    useEffect(() => {
        if (!isTileExpanded) return;

        const element = document.getElementById(`test-item-${item.assessmentTestId}`);
        element?.scrollIntoView({ behavior: 'smooth', block: 'start' });

    }, [isTileExpanded, item?.assessmentTestId]);

    const tileValidationErrors = (missingData.get(item.assessmentTestId) || {});
    const hasMissingData = Object.keys(tileValidationErrors).length > 0;
    const exclusionToggleOperable = item.exclusionReason === 'included' || item.exclusionReason === 'excluded';

    //console.debug('missing data', item.assessmentTestId, showMissingData, tileValidationErrors, hasMissingData, missingData);

    const contextValues = useMemo(() => ({
        item,
        testSuite,
        missingData: tileValidationErrors, //showMissingData,// && tileValidationErrors,
        hasMissingData: hasMissingData,
        showMissingData: showMissingData,
        exclusionToggleOperable,
        hasFastFillErrors: item.hasFastFillQA, // has errors whether they are approved or not.
        showFastFillQA: showFastFillQA,
        hasUnmatchedSegments: item.hasUnmatchedSegments,
        status: item.status,
        exclusionReason: item.exclusionReason
    }), [
        item,
        testSuite,
        showMissingData,
        showFastFillQA,
        tileValidationErrors,
        hasMissingData,
        exclusionToggleOperable
    ]);

    if (!item) {
        return null;
    }

    return (
        <TestTileContext.Provider value={contextValues}>
            <div className={cn(
                "flex-1 flex flex-col border-zinc-200 border-l-2 pl-2",
                {
                    'border-l-compliance-500': item.ratingType.includes('compliance'),
                    'border-l-condition-500': item.ratingType.includes('condition'),
                    'border-l-zinc-500': item.ratingType.includes('generic'),
                }

            )}>
                <TestItemHeader item={item} />

                <TestItemContent
                    item={item}
                    testSuite={testSuite}
                    isScrolling={isScrolling}
                    isTileExpanded={isTileExpanded}
                    handleExpandTile={handleExpandTile}
                />

                <TestTileEditTools onEdit={onEdit} assessment={assessment} />

            </div>
        </TestTileContext.Provider>
    );
}


export default TestItem;
