import { useCallback, useState } from 'react';
import { Button } from '@/components/ui/button';

interface Step3Props {
    completeSignUp: () => Promise<void>
}

const Step3 = ({ completeSignUp }: Step3Props) => {

    const [showMessage, setShowMessage] = useState(false);

    // we need to create a new organisation in the name of the user;
    const handleFinishSetup = useCallback(async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        await completeSignUp()
    }, [completeSignUp])

    return (
        <div className="flex-col flex space-y-6">
            {!showMessage ? (
                <Button
                    variant="default"
                    className="w-full"
                    onClick={() => setShowMessage(true)}
                >
                    Join an existing Organisation
                </Button>
            ) : (
                <div className="bg-gray-100 p-4 rounded-lg text-sm text-gray-600 space-y-4">
                    <p>To join an existing Organisation you need to finish setting up your account and be invited.</p>
                    <p className="font-medium">Ask a colleague to send you an invitation from their Raytd account.</p>
                    <Button
                        variant="outline"
                        className="w-full"
                        onClick={handleFinishSetup}
                    >
                        Finish account setup
                    </Button>
                </div>
            )}
        </div>
    )
};

export default Step3;