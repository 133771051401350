import { useRapidQA } from "@/app/features/reports/components/rapidqa/context";
import { cn } from "@/lib/utils";
import Highlighter from 'react-highlight-words';
import { useTestTileContext } from "@/app/features/reports/components/test-tile/context";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useValidation, ValidationProvider } from "./validation-context";

interface FieldContainerProps extends React.HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode
    Component?: React.ComponentType<{ field_id: string; isFastFill?: boolean }>
    rightComponent?: React.ReactNode
    showRightComponent?: boolean
    field_id?: string
    isFastFill?: boolean
}

const FieldContainer = ({
    children,
    Component: ComponentProp = RapidQAValidator,
    rightComponent,
    showRightComponent,
    field_id,
    isFastFill
}: FieldContainerProps) => {

    const Component = isFastFill ? FastFillValidator : ComponentProp;


    if (!showRightComponent) {
        return children;
    }

    return (
        <div className="flex flex-row gap-8 border-b border-zinc-100 py-2">
            <div className="flex-1 items-center">
                {children}
            </div>
            <div className="flex-1 items-center flex-row flex">
                <ValidationProvider field_id={field_id}>
                    {rightComponent ? rightComponent : <Component field_id={field_id} isFastFill={isFastFill} />}
                </ValidationProvider>
            </div>
        </div>

    )

};

const getFieldValue = (field_id: string, values: any) => {

    const isCustomField = field_id?.startsWith('custom.');
    const fieldIdWithPrefix = isCustomField ? field_id.replace('custom.', '') : field_id;
    return isCustomField ? values['custom']?.[fieldIdWithPrefix] : values[field_id];
}


const RapidQAValidator = React.memo(({ field_id: baseFieldId, isFastFill }: { field_id: string, isFastFill: boolean }) => {
    const { field_id, isValid, isVisible, isDirty, isRequired, isTouched, fieldError, fieldValue, showMessage } = useValidation();
    console.debug(baseFieldId, { isValid, isDirty, isRequired, isTouched, fieldError, fieldValue, showMessage });

    if (!isVisible) {
        return null;
    }

    return (
        <>
            {showMessage && (
                <div className="flex flex-row gap-2 h-full flex-1 pt-6 pb-2">
                    <ValidationLine isValid={isValid} />
                    <div className="flex-1 flex flex-row">
                        <ValidationStatus />
                    </div>
                </div>
            )}
            <DebugContent className={cn(!showMessage && "flex-1")} />
        </>
    )
});

export default FieldContainer;

const FastFillValidator = ({ field_id: baseFieldId }: { field_id: string }) => {
    const { isVisible, isDirty } = useValidation();
    const { getFieldUnmatchedSegments } = useRapidQA();
    const { getValues } = useFormContext();
    const { hasFastFillErrors } = useTestTileContext();

    const value = getValues();

    const fieldValue = getFieldValue(baseFieldId, value);

    const unmatchedSegments = getFieldUnmatchedSegments(baseFieldId, {
        ...value
    });

    const hasUnmatchedSegments = unmatchedSegments.length > 0;

    if (!hasUnmatchedSegments && !isDirty) {
        return null;
    }

    if (!hasUnmatchedSegments || !hasFastFillErrors) {
        return (
            <div className="flex flex-row gap-2 h-full pt-7 pb-0 flex-1 opacity-30">
                <div className='w-1 h-full bg-zinc-200' />
                <div className="flex flex-col gap-2 flex-1">
                    {
                        hasUnmatchedSegments && !hasFastFillErrors && (
                            <div className="font-medium text-zinc-500 text-sm">The text does not match a pre-approved Fast Fill response.</div>
                        )
                    }
                    {
                        hasUnmatchedSegments && (
                            <div className="font-medium text-zinc-500 text-sm">Fast Fill is accepted.</div>
                        )
                    }
                    {/* <div className="text-zinc-500 text-sm">The field is up to date.</div> */}
                </div>
            </div>
        )
    }

    return (
        <div className="flex flex-row gap-2 h-full pt-7 pb-0 flex-1">
            <div className='w-1 h-full bg-tests-fastfill' />
            {/* {unmatchedSegments.length} */}
            <div className="flex flex-col gap-2 flex-1">
                <div className="font-medium text-tests-fastfill text-sm">The highlighted text does not match a pre-approved Fast Fill response.</div>
                <div className="text-tests-fastfill text-sm">Ammend or accept the highlighted text.</div>
                <div style={{ whiteSpace: 'pre-wrap' }} className="flex-1 flex border border-zinc-200 rounded-md p-2">
                    <Highlighter
                        highlightStyle={{ backgroundColor: 'rgba(140, 65, 208, 0.25)' }} // 25% opacity
                        searchWords={unmatchedSegments || []}
                        textToHighlight={fieldValue.replace(/\\n/g, '\n').trim()}
                        autoEscape={true}
                        className="text-sm"
                    />
                </div>
            </div>
            <DebugContent />
        </div>
    )

}


const ValidationLine = ({ isValid }: { isValid: boolean }) => {
    return (
        <div className={cn("w-1 h-full",
            isValid ? "bg-zinc-200" : "bg-red-500")
        } />
    )
}

interface ValidationStatusProps {

}

const ValidationStatus = ({ }: ValidationStatusProps) => {

    const { isValid, isRequired, fieldError } = useValidation();

    return (
        <div className={cn("flex-1 flex flex-col gap-0.5",
            isValid && "text-zinc-500 opacity-30",
            !isValid && "text-red-500"
        )}>

            {isRequired && <div className="text-sm ">This field is compulsory</div>}
            {fieldError && <div className="text-sm ">{fieldError}</div>}
        </div>

    )
};



interface DebugContentProps {
    className?: string;
}

const DebugContent = React.memo(({ className }: DebugContentProps) => {

    if (process.env.NODE_ENV !== 'development') {
        return null;
    }

    const { isValid, isTouched, isDirty, isRequired, fieldError } = useValidation();


    return (
        <div className={cn("flex flex-row gap-2 justify-end opacity-50 w-24", className)}>
            <div className="flex flex-col text-xs text-zinc-400 justify-end w-24">
                <div className="flex flex-row gap-2 justify-between">
                    <div>isValid</div>
                    <div>{isValid ? "true" : "false"}</div>
                </div>
                <div className="flex flex-row gap-2 justify-between">
                    <div>errors</div>
                    <div className="truncate">{fieldError}</div>
                </div>
                <div className="flex flex-row gap-2 justify-between">
                    <div>isTouched</div>
                    <div>{isTouched ? "true" : "false"}</div>
                </div>
                <div className="flex flex-row gap-2 justify-between">
                    <div>isDirty</div>
                    <div>{isDirty ? "true" : "false"}</div>
                </div>
                <div className="flex flex-row gap-2 justify-between">
                    <div>isRequired</div>
                    <div>{isRequired ? "true" : "false"}</div>
                </div>
            </div>
        </div>

    )
});