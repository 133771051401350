import React, { createContext, useMemo, useState } from 'react';

interface ReportSelectionContext {
    bulkEditMode: boolean;
    setBulkEditMode: React.Dispatch<React.SetStateAction<boolean>>;
    selectedItems: Set<string>;
    setSelectedItems: React.Dispatch<React.SetStateAction<Set<string>>>;
}

const ReportSelectionContext = createContext<ReportSelectionContext>(null);

export const useReportSelectionContext = () => {
    return React.useContext(ReportSelectionContext);
}

interface ReportSelectionProviderProps {
    children: React.ReactNode;
}

export const ReportSelectionProvider: React.FC<ReportSelectionProviderProps> = ({ children }) => {
    const [bulkEditMode, setBulkEditMode] = useState(false);
    const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());

    const value = useMemo(() => ({
        bulkEditMode,
        setBulkEditMode,
        selectedItems,
        setSelectedItems
    }), [bulkEditMode, selectedItems]);

    return (
        <ReportSelectionContext.Provider value={value}>
            {children}
        </ReportSelectionContext.Provider>
    );
};

ReportSelectionProvider.displayName = 'ReportSelectionProvider';