import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useNav } from './NavContext';
import { cn } from "@/lib/utils";

type NavLinkProps = {
    to: string;
    children: React.ReactNode;
    active?: boolean;
    disabled?: boolean;
    visible?: boolean;
    className?: string;
};

export const NavLink: React.FC<NavLinkProps> = React.memo(({ to, children, className, disabled = false, visible = true }) => {
    const location = useLocation();

    if (!visible) {
        return null;
    }

    //const isActive = location.pathname === to; // Check if the current route matches
    //const isActive = location.pathname.endsWith(to);
    const isActive = location.pathname.includes(to) && location.pathname.slice(-to.length) === to;

    return (
        <Link
            to={to}
            aria-disabled={disabled} 
            className={cn("hover:text-black", {
                "text-black font-semibold": isActive,
                "text-zinc-500": !isActive,
            },className)}
        >
            {children}
        </Link>
    );
});

NavLink.displayName = 'NavLink';

export const TopNavBar = React.memo(({children, className}: {children: React.ReactNode, className?: string}) => {
    const { navButtons } = useNav();

    return (
        <nav className={cn("flex items-center bg-white border-b h-10", className)}>
            {/* Left side - Logo or Navigation Links */}
            <div className="flex items-center space-x-5 flex-1">
                {children}
            </div>
            <div className="flex space-x-2 justify-center items-center">
                {navButtons}
            </div>

        </nav>
    );
});

TopNavBar.displayName = 'TopNavBar';
