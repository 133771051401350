import { ReportItem } from "../utils/entities";
import collectAllGroups from "./collectAllGroups";

const countAllGroups = (items: ReportItem[]): number => {
    const allIDs = collectAllGroups(items, new Set<string>(), true);
    return allIDs.size;
};

const countAllHeaders = (items: ReportItem[]): number => {
    const allIDs = collectAllGroups(items, new Set<string>(), false);
    return allIDs.size;
};

export { countAllGroups, countAllHeaders };