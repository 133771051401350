
import TreeView, { TreeViewItem } from "@/components/raytd/tree-view";
import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { ChevronsUpDown } from "lucide-react";
import React, { useCallback, useMemo, useState } from "react";


const customIconMap = {
    region: <ChevronsUpDown className="h-4 w-4 text-purple-500" />,
    store: <ChevronsUpDown className="h-4 w-4 text-blue-500" />,
    department: <ChevronsUpDown className="h-4 w-4 text-green-500" />,
    item: <ChevronsUpDown className="h-4 w-4 text-orange-500" />,
};

const TreeFilterPill = ({
    label,
    data,
    onSelectionChange,
    onCheckChange,
    selectedIds,
    setSelectedIds
}: {
    label?: string;
    data: TreeViewItem[];
    onSelectionChange?: (items: TreeViewItem[], ids: Set<string>) => void;
    onCheckChange?: (item: TreeViewItem, checked: boolean) => void;
    selectedIds?: Set<string>;
    setSelectedIds?: React.Dispatch<React.SetStateAction<Set<string>>>;
}) => {
    const [open, setOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState<TreeViewItem[]>([]);
    const selectedCount = useMemo(() => Array.from(selectedIds)?.length, [selectedIds]);

    const handleSelectionChange = useCallback((items: TreeViewItem[], ids: Set<string>) => {
        setSelectedItems(items);
        setSelectedIds(ids);
        onSelectionChange?.(items, ids);
    }, [onSelectionChange, setSelectedIds]);

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <Button variant="outline" className={cn("h-8 flex flex-row gap-2 justify-between bg-transparent",
                    selectedCount > 0 && "bg-primary/10 text-primary"
                )}>
                    <span className="truncate">{label} <span className="bg-primary/10 text-primary rounded-md px-1.5 py-0.5 text-xs">{selectedCount ? `${selectedCount}` : 'all'}</span></span>
                    <ChevronsUpDown className="h-4 w-4 opacity-50 shrink-0" />
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[600px] p-0" align="start">
                <TreeView
                    data={data}
                    showCheckboxes={true}
                    iconMap={customIconMap}
                    showExpandAll={false}
                    onCheckChange={onCheckChange}
                    onSelectionChange={handleSelectionChange}
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                />
            </PopoverContent>
        </Popover>
    );
};

export default TreeFilterPill;