import React from "react";

import RowContent from "@/app/features/reports/components/report-row-content";
import ReportView from "@/app/features/reports/ReportView";
import { ExpandedItemsState, ReportGroupModes, ReportHeaderModes, ReportViewModes } from "@/app/features/reports/types";
import { Revision } from "@/app/features/reports/types/revision";
import collectAllGroups from "@/app/features/reports/utils/collectAllGroups";
import TreeFilterPill from "@/components/raytd/tree-filter-pill";
import { TreeViewItem } from "@/components/raytd/tree-view";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Toggle } from "@/components/ui/toggle";
import { TooltipProvider } from "@/components/ui/tooltip";
import { useGetPublishedReportQuery, useGetReportItemsQuery, useGetReportQuery, useGetReportTestSuitesQuery, useGetTestItemQuery, usePublishReportMutation } from "@app.raytd.com/store";
import { skipToken } from "@reduxjs/toolkit/query";
import { Building2, CopyMinusIcon, CopyPlusIcon, Folder, FolderOpen, Globe } from "lucide-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import UploadMonitor from "../_components/UploadMonitor";
import { ReportProviders, ReportViewerContext, useReportViewContext } from '../features/reports/components/report-viewer-context';
import TestEditDialog from '../features/reports/components/test-edit-dialog';
import convertSpacesToTree from '../features/reports/utils/convertSpacesToTree';
import { ReportItem, ReportTestItem } from '../features/reports/utils/entities';
import AuthorTimestamp from "@/app/features/reports/components/author-timestamp";
import LoadingComponent from "@/app/features/reports/components/publish/loading-state";
import PublishSummary from "@/app/features/reports/components/publish/publish-report-summary";
import PublishedReportMeta from "@/app/features/reports/components/published-report-meta";
import { ReportDataProvider } from "@/app/features/reports/contexts/data-context";
import { publishedReportDataProvider } from "@/app/features/reports/hooks/use-published-report-data";
import { DescriptionDetail, DescriptionGroup, DescriptionList, DescriptionTerm } from "@/components/ui/description-list";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { PublishedReport } from "store/src/lib/services/types";

function filterBySpace(item: ReportTestItem, selectedId: Set<string>): unknown {
    // {asset_id, building_id, level_id, room_id} = split selectedId by .
    // console.debug('filterBySpace', item, selectedId);

    if (!selectedId) {
        return true;
    }

    const selectedIds = Array.from(selectedId);

    //we need to test against all selectedIds


    const isMatch = selectedIds.reduce((acc, id) => {
        const idParts = id.split('.');
        const [asset_id, building_id, level_id, room_id] = idParts;

        //match all defined parts; if null then match any
        if (asset_id !== null && item.assetId !== Number(asset_id)) {
            return false;
        }

        if (building_id === null || item.buildingId === Number(building_id)) {
            return true;
        }

        if (level_id === null || item.levelId === Number(level_id)) {
            return true;
        }

        if (room_id === null || item.areaId === Number(room_id)) {
            return true;
        }

        return acc || false;

    }, false);

    return isMatch;

}

const FilterTreeTest = () => {
    const { data: reportTestItems, isLoading: isLoadingReportItems } = useGetReportItemsQuery("13");
    const [selectedItems, setSelectedItems] = useState<TreeViewItem[]>([]);
    const [selectedIds, setSelectedIds] = useState<Set<string>>(new Set());

    const handleSelectionChange = useCallback((selectedItems: TreeViewItem[], selectedIds: Set<string>) => {
        console.debug('handleSelectionChange', selectedItems, selectedIds);
        setSelectedItems(selectedItems);
        setSelectedIds(selectedIds);
    }, []);

    const spaceTree = useMemo(() => {
        if (reportTestItems) {
            const spaces = convertSpacesToTree(reportTestItems as unknown as ReportTestItem[]);

            // Recursively rename 'title' to 'name' and update IDs in the tree
            const processTree = (items: any[], parentId: string = ''): any[] => {
                return items.map((item, index) => {
                    const newItem = { ...item };

                    // Rename title to name
                    if ('title' in newItem) {
                        newItem.name = newItem.title;
                        delete newItem.title;
                    }

                    if ('children' in newItem && newItem.children?.length === 0) {
                        delete newItem.children;
                    }

                    // Create new concatenated ID
                    let itemIdStr = String(item.id);

                    if (itemIdStr.includes('No')) {
                        itemIdStr = 'null';
                    }

                    // const itemIdStr = String(item.id);
                    const parentSegments = parentId ? parentId?.split('.') : [];
                    const itemSegments = itemIdStr?.split('.');

                    // Remove any segments from itemSegments that already exist in parentSegments
                    const uniqueItemSegments = itemSegments.filter(segment =>
                        !parentSegments.includes(segment)
                    );

                    const newId = parentId
                        ? `${parentId}.${uniqueItemSegments.join('.')}`
                        : itemIdStr;
                    newItem.id = newId;

                    // Process children recursively if they exist
                    if (newItem.children?.length) {
                        newItem.children = processTree(newItem.children, newId);
                    }

                    return newItem;
                });
            };

            const processedTree = processTree(spaces);
            console.debug('spaces', processedTree);
            return processedTree;
        }
        return [];
    }, [reportTestItems]);

    const customIconMap = {
        region: <Globe className="h-4 w-4 text-purple-500" />,
        store: <Folder className="h-4 w-4 text-blue-500" />,
        department: <FolderOpen className="h-4 w-4 text-green-500" />,
        item: <Building2 className="h-4 w-4 text-orange-500" />,
    };

    const handleCheckChange = (item: TreeViewItem, checked: boolean) => {
        console.log(`Item ${item.name} checked:`, checked);
    }

    const filteredItems = useMemo(() => {
        const filteredItems = reportTestItems?.filter(item => filterBySpace(item as unknown as ReportTestItem, selectedIds));
        console.debug('filteredItems', filteredItems);
        return filteredItems;


    }, [reportTestItems, selectedIds]);

    // const selectedIds = useMemo(() => {
    //     return selectedItems.map(item => item.id);
    // }, [selectedItems]);

    if (isLoadingReportItems) {
        return <div>Loading...</div>
    }

    if (!spaceTree || spaceTree.length === 0) {
        return (
            <div className="flex flex-row gap-4 mb-2 px-3 py-3 bg-white rounded-lg">
                <div>No data</div>
            </div>
        )
    }

    return (
        <div className="flex flex-row gap-4 mb-2 rounded-lg">
            <div className="flex flex-row gap-4 px-3 py-3 bg-white rounded-lg flex-1">
                {/* <TreeView
                    className="flex-1 w-[300px]"
                    data={spaceTree as unknown as TreeViewItem[]}
                    title="Tree View Demo"
                    showCheckboxes={true}
                    iconMap={customIconMap}
                    showExpandAll={false}
                    onCheckChange={handleCheckChange}
                    onSelectionChange={handleSelectionChange}
                /> */}
                <TreeFilterPill
                    label='Spaces'
                    data={spaceTree as unknown as TreeViewItem[]}
                    onSelectionChange={handleSelectionChange}
                    onCheckChange={handleCheckChange}
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                />
            </div>
            <div className="flex flex-col gap-4 px-3 py-3 bg-white rounded-lg flex-1 text-sm">
                <div className="whitespace-pre-wrap">{JSON.stringify(Array.from(selectedIds))}</div>
                <div>Selected Items: {selectedItems?.length}</div>
                <div>Report Items: {reportTestItems?.length}</div>
                <div>Filtered Items: {filteredItems?.length}</div>
            </div>
        </div>
    )

}


const TestEditingDialog = () => {

    const { data: report } = useGetReportQuery("13");
    //2165
    const [selectedId, setSelectedId] = useState<number | null>(null);
    const [isTestEditorOpen, setTestEditorIsOpen] = useState(false);
    const { data: testSuites, isLoading: isLoadingSpecifications } = useGetReportTestSuitesQuery(
        report?.id ? Number(report.id) : skipToken
    );

    const inspections = report?.assessments || []
    const assets = report?.assets || []
    const { data: testItem, refetch } = useGetTestItemQuery(
        selectedId ? selectedId : skipToken
    );

    const [editingTest, setEditingTest] = useState();

    useEffect(() => {
        if (testItem && selectedId) {
            setEditingTest(testItem);
            setTestEditorIsOpen(true);
        }
    }, [testItem, selectedId]);

    const handleEditorClose = useCallback(() => {
        setTestEditorIsOpen(false);
        setEditingTest(null);
        setSelectedId(null);
    }, []);


    if (!report || isLoadingSpecifications || !testSuites || !inspections || !assets) {
        return <div>Loading...</div>
    }

    return (
        <div>
            <TooltipProvider>
                <h1>Test Editing Dialog</h1>
                {/* 2322 */}
                <div className="flex flex-row gap-4 mb-2 px-3 py-3 bg-white border border-gray-100 rounded-lg">
                    <Button onClick={() => setSelectedId(2393)}>Open Test Editor</Button>
                    <Button onClick={() => setSelectedId(2322)}>Open Test Editor</Button>
                    <Button onClick={() => { setSelectedId(null); setTestEditorIsOpen(true); }}>Create Test</Button>
                </div>

                <TestEditDialog
                    isOpen={isTestEditorOpen}
                    onClose={handleEditorClose}
                    isLoading={false}
                    testItem={editingTest}
                    inspections={inspections}
                    assets={assets}
                    testSuites={testSuites as any}
                    onSubmit={function (data: any): void {
                        // handleEditorClose();
                    }}
                />
            </TooltipProvider>
        </div>
    )

}

export const findFirstContent = (items: ReportItem[]): any | undefined => {
    const traverse = (item: ReportItem): any | undefined => {
        // Check if content exists and has elements
        if (item.content?.length) {
            return item.content[0];
        }

        // Check children recursively
        if (item.children?.length) {
            for (const child of item.children) {
                const result = traverse(child);
                if (result !== undefined) {
                    return result;
                }
            }
        }

        return undefined;
    };

    // Traverse each root item
    for (const item of items) {
        const result = traverse(item);
        if (result !== undefined) {
            return result;
        }
    }

    return undefined;
};

const TestTileTest = () => {
    const { data: report } = useGetReportQuery("13");
    const [selectedId, setSelectedId] = useState<number | null>(2322);


    const { data: items, isLoading } = useGetReportItemsQuery("13", {
        selectFromResult: ({ data, isLoading }) => ({
            data: data?.find(item => item.assessmentTestId === selectedId),
            isLoading
        }),
    });

    console.debug('TestTile report, items', { report, items })

    const [data, setData] = useState<ReportTestItem>(null);

    useEffect(() => {
        if (items) {
            const flattened = convertSpacesToTree([items] as unknown as ReportTestItem[]);
            const content = findFirstContent(flattened);

            console.debug('flattened', content);
            // if (flattened?.length > 0) {
            //     // setData(flattened[0].content[0].testCase);
            //     setData(flattened[0].content[0]);
            // }else {
            setData(content)
            // }
        }
    }, [items]);


    // const [activeItems, setActiveItems] = useState<string[]>([])
    const [expandedItems, setExpandedItems] = useState<ExpandedItemsState>({
        element: new Set(),
        location: new Set(),
        test: new Set(),
        rating: new Set()
    });

    const [headerMode, setHeaderMode] = useState<ReportHeaderModes>('normal');
    const [groupMode, setGroupMode] = useState<ReportGroupModes>('location');
    const [allExpanded, setAllExpanded] = useState<true | false | 'partial'>(false);
    const totalGroups = useRef<number>(0);

    const [viewMode, setViewMode] = useState<ReportViewModes>('details');

    const expandedIds = expandedItems[groupMode];

    const collapseAll = () => {
        setExpandedItems((prev) => ({
            ...prev,
            [groupMode]: new Set(),
        }));
    };

    const expandAll = useCallback(() => {

        if (allExpanded === true) {
            collapseAll();
            return;
        }

        //@ts-expect-error
        const allIds = collectAllGroups(data);

        setExpandedItems((prev) => ({
            ...prev,
            [groupMode]: allIds,
        }));
    }, [data, groupMode, allExpanded]);


    const switchHeaderMode = useCallback(() => {
        setHeaderMode((prev) => prev === 'normal' ? 'switch' : 'normal');
    }, []);

    const setGroupExpandedItems = useCallback((value: Set<string>) => {
        setExpandedItems((prev) => ({
            ...prev,
            [groupMode]: value,
        }));
    }, [groupMode]);

    // const testSuite = useMemo(() =>
    //     report?.testSuites.find(ts =>
    //         ts.id === item.testCase?.testSpecification?.testSuiteId
    //     ),
    //     [report?.testSuites, item.testCase?.testSpecification?.testSuiteId]
    // );

    // const assessment = useMemo(() =>
    //     report?.assessments.find(assessment =>
    //         assessment.id === item.testCase?.assessmentId
    //     ),
    //     [report?.assessments, item.testCase?.assessmentId]
    // );

    if (isLoading) {
        return <div>Loading...</div>
    }

    if (!data) {
        return <div>No Test data</div>
    }

    if (data) {

        return (
            <ReportViewerContext.Provider
                value={{
                    //@ts-expect-error
                    data,
                    report,
                    activeItems: [], setActiveItems: () => { },
                    expandedItems: expandedIds,
                    setExpandedItems: setGroupExpandedItems,
                    expandAll, collapseAll,
                    headerRefs: { current: {} }, contentRef: { current: null },
                    viewMode,
                    switchHeaders: switchHeaderMode,
                    headerMode,
                    showTestEditor: undefined,
                }}>
                <div className="p-0 flex flex-col gap-4 my-6">
                    <h1 className="font-medium text-lg">Test Tile</h1>
                    <div className="flex flex-row gap-4">
                        <Button onClick={expandAll}>Expand All</Button>
                        <Button onClick={collapseAll}>Collapse All</Button>
                        <Toggle
                            aria-label="Switch Header Mode"
                            variant="outline"
                            onPressedChange={switchHeaderMode}
                        >Switch Header Mode</Toggle>
                    </div>
                    <div className="p-6 bg-white">
                        <RowContent
                            //@ts-expect-error
                            item={data}
                        />
                    </div>
                </div>
            </ReportViewerContext.Provider>
        )
    }


    // return (
    //     <div>
    //         <h1>Test Tile</h1>
    //         <TestItem item={item} testSuite={testSuite} assessment={assessment} />
    //     </div>
    // )
}

interface ShowWidgetProps {
    initialShow?: boolean;
    children: React.ReactNode;
    label?: string;
}

const ShowWidget = ({ initialShow = false, children, label = 'Show' }: ShowWidgetProps) => {

    const [show, setShow] = useState<boolean>(initialShow);

    return (
        <div className={cn("w-full flex flex-col gap-6",
            show && "bg-white p-4 rounded-lg"
        )}>
            <div className="flex flex-row gap-6 items-center">
                <Button size="icon" variant="outline" onClick={() => setShow(!show)}>{show ? <CopyMinusIcon className="w-4 h-4 text-zinc-500" /> : <CopyPlusIcon className="w-4 h-4 text-zinc-500" />}</Button>
                <div className="font-medium text-lg">{label}</div>
            </div>
            <div className="pl-16">
                {show && children}

            </div>
        </div>
    )
}

const ReportRevision = ({ revision }: { revision: Revision }) => {
    return (
        <div className="p-4 bg-zinc-50 rounded-lg">
            <h2 className="font-medium text-lg">Report Revision</h2>
            <DescriptionList>
                <DescriptionGroup>
                    <AuthorTimestamp timestamp={revision.created_at} author={revision.creator} label="Created At" />
                </DescriptionGroup>
                <DescriptionGroup>
                    <DescriptionTerm>Revision</DescriptionTerm>
                    <DescriptionDetail>{revision.id}</DescriptionDetail>
                </DescriptionGroup>
                <DescriptionGroup>
                    <DescriptionTerm>Status</DescriptionTerm>
                    <DescriptionDetail>{revision.status}</DescriptionDetail>
                </DescriptionGroup>
            </DescriptionList>
        </div>
    )
}


interface PublishSummaryTestProps {
}

const PublishSummaryTest: React.FC<PublishSummaryTestProps> = () => {
    const { report, data, filteredData, filters: { filteredCounts } } = useReportViewContext();
    const [publishReport, { isLoading: isPublishing }] = usePublishReportMutation();

    const [publishedReportRevision, setPublishedReportRevision] = useState<Revision | null>(null);
    const [publishedReport, setPublishedReport] = useState<PublishedReport | null>(null);

    const [isBusy, setBusy] = useState(false);

    const handlePublish = useCallback(async () => {
        try {
            setBusy(true);
            console.debug('handlePublish', { report, filteredData });

            const publishedReport = await publishReport({
                id: Number(report.id),
                body: {
                    report: report,
                    items: filteredData as any,
                    notes: '',
                    publishOption: 'supersede',
                    revision: {
                        id: 0,
                        created_at: new Date().toISOString(),
                        //@ts-expect-error
                        creator: {
                            id: 0,
                            name: 'Test User'
                        }
                    }
                }
            }).unwrap();

            console.debug('publishedReport', publishedReport);

            setPublishedReportRevision(publishedReport.revision);
            setPublishedReport(publishedReport.published);

            setTimeout(() => {
                setBusy(false);
            }, 1000);

        } catch (error) {
            console.error('handlePublish error', error);
            setBusy(false);
        }
    }, [report, filteredData]);

    //calculate the numbner of included tests
    const includedTests = filteredCounts?.tests?.included;
    const excludedTests = filteredCounts?.tests?.total - includedTests;

    return (
        <div className="flex flex-col gap-4">
            <h1>Publish</h1>
            <div className="flex flex-row gap-2 items-center">
                <div>{report?.title}</div>
                <Badge variant="outline">{report?.id}</Badge>
            </div>

            <PublishSummary />

            <div className="flex flex-col gap-2">
                {isPublishing && <div className="animate-spin rounded-full h-4 w-4 border-2 border-zinc-300 border-t-zinc-600" />}
                {isPublishing ? 'Publishing...' : ''}
            </div>
            {publishedReportRevision && <ReportRevision revision={publishedReportRevision} />}
            {publishedReport && <PublishedReportMeta report={publishedReport} />}
            <Button onClick={handlePublish} disabled={isPublishing}>{isPublishing ?
                <div className="flex items-center gap-2">
                    {isBusy && <div className="animate-spin rounded-full h-4 w-4 border-2 border-zinc-300 border-t-zinc-600" />}
                    {isBusy ? 'Publishing...' : 'Publish'}
                </div> : 'Publish'}
            </Button>
        </div>
    )
}



const PublishTestDialog = () => {
    return (
        <div className="p-6 bg-white rounded-lg">
            <ReportProviders
                reportId="13"
                LoadingComponent={LoadingComponent}
            >
                <div>
                    <PublishSummaryTest />
                </div>
            </ReportProviders>
        </div>
    )
}

const PublishedReportViewer = ({ reportRevisionId }: { reportRevisionId: number }) => {

    return (
        <ReportDataProvider provider={publishedReportDataProvider}>
            <ReportView reportId={reportRevisionId.toString()} initialEditMode={false} />
        </ReportDataProvider>
    );
}

const PublishedReportViewerTest = () => {
    const [selectedId, setSelectedId] = useState<number | null>(null);
    const [reportRevisionId, setReportRevisionId] = useState<number | null>(null);
    // const { data: report } = useGetReportQuery(reportId ? reportId.toString() : skipToken);
    const { data: publishedReport, isLoading: isLoadingPublishedReport, refetch } = useGetPublishedReportQuery(reportRevisionId ? reportRevisionId : skipToken);

    const handleLoadReport = useCallback(() => {
        if (selectedId) {
            setReportRevisionId(selectedId);
        }
        if (reportRevisionId === selectedId) {
            refetch();
        }
    }, [selectedId, reportRevisionId]);

    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-row gap-2 items-center">
                <div className="font-medium text-sm">Published Report ID</div>
                <Input type="number" className="w-32" value={selectedId} onChange={(e) => setSelectedId(Number(e.target.value))} />
                <Button onClick={handleLoadReport}>Load Report</Button>
            </div>
            {isLoadingPublishedReport && <div className="animate-spin rounded-full h-4 w-4 border-2 border-zinc-300 border-t-zinc-600" />}
            {publishedReport && <PublishedReportMeta report={publishedReport?.published} />}
            {publishedReport && reportRevisionId && (
                <PublishedReportViewer reportRevisionId={reportRevisionId} />
            )}

        </div>
    )
}

export default function Page() {

    return (
        <TooltipProvider>

            <div className="p-8 flex flex-col gap-4">
                <div className="text-lg font-medium">Testing Features Page</div>
                {/* <MissingData /> */}
                {/* <TextEnrichments /> */}
                {/* <TextComparisonField /> */}
                <ShowWidget initialShow={false} label="Test Editing Dialog">
                    <TestEditingDialog />
                </ShowWidget>

                <ShowWidget initialShow={false} label="Filter Tree">
                    <FilterTreeTest />
                </ShowWidget>

                <ShowWidget initialShow={false} label="Publish Test Dialog">
                    <PublishTestDialog />
                </ShowWidget>

                <ShowWidget initialShow={false} label="Published Report Viewer">
                    <PublishedReportViewerTest />
                </ShowWidget>

                {/* <TestTileTest /> */}

                {/* <ProfileForm />



            
            <UploadForm formId='8afa46af-8c52-475c-ada9-283b7f7e19d4' /> */}
                <UploadMonitor />

            </div>
        </TooltipProvider>
    );
}