import { TestStatus } from '@/app/features/reports/types/test-form';

export const TEST_STATUSES = ['archived', 'draft', 'qa', 'published'] as const;

interface StatusConfig {
  label: string;
  variant: string;
  requiresValidation: boolean;
}

export const getStatusLabel = (status: TestStatus): string => {
  return TEST_STATUS_CONFIG[status]?.label ?? 'Inactive';
};

export const TEST_STATUS_CONFIG: Record<TestStatus, StatusConfig> = {
  archived: {
    label: 'Archived',
    variant: 'bg-white text-zinc-500 border-zinc-400',
    requiresValidation: false,
  },
  draft: {
    label: 'Draft',
    variant: 'bg-white text-zinc-950 border border-zinc-950',
    requiresValidation: false,
  },
  qa: {
    label: 'QA',
    variant: 'bg-zinc-500 text-white',
    requiresValidation: true,
  },
  published: {
    label: 'Final',
    variant: 'bg-black text-white',
    requiresValidation: true,
  },
} as const;

export const getStatusVariant = (status: TestStatus): string => {
  return TEST_STATUS_CONFIG[status]?.variant ?? 'border-zinc-300 bg-transparent text-transparent';
};

export const getStatusRequiresValidation = (status: TestStatus): boolean => {
  return TEST_STATUS_CONFIG[status]?.requiresValidation ?? false;
};