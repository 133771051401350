import { Card, CardContent } from "@/components/ui/card"
import { cn } from "@/lib/utils"
import { PropsWithChildren } from "react"
import NumberFlow from "@number-flow/react"
interface KPIMetricProps {
  title: string
  value: number | string
  outOf?: string | number
  className?: string
  children?: React.ReactNode
}

function KPIText({ title, value, outOf }: KPIMetricProps) {
  if (value === null) return null;

  return (

    <div className="p-0 flex-1 flex flex-col items-center justify-center h-full">
      <div className="space-y-2 text-center">
        <p className="text-base text-muted-foreground">{title}</p>
        <div className="text-4xl font-bold tracking-tight">
          {value}
          {outOf && <span className="text-sm text-muted-foreground pb-1 tracking-normal pl-1">/{outOf}</span>}
        </div>
      </div>
    </div>
  )
}

export function KPIMetric({ title, value, className }: KPIMetricProps) {
  return (
    <Card className={cn('border-none p-6 bg-zinc-100 rounded-sm', className)}>
      <KPIText title={title} value={value} />
    </Card>
  )
}

export function BigKPIMetric({ title, value, outOf, className, children }: KPIMetricProps) {

  return (
    <Card className={cn('border-none p-6 bg-zinc-100 rounded-sm', className)}>
      <div className="flex flex-row justify-center h-full">

        <div className="p-0 flex-1 flex flex-col items-center justify-center h-full">

          {value !== null && (

            <div className="space-y-2 text-center">
              <p className="text-base text-muted-foreground">{title}</p>
              <div className="text-4xl font-bold tracking-tight flex flex-row justify-end items-end"><p>{value}</p>
                {outOf && <span className="text-sm text-muted-foreground pb-1 tracking-normal pl-1">/{outOf}</span>}
              </div>
            </div>
          )}

        </div>

        <div className="flex flex-1 items-center justify-center">
          {children}
        </div>
      </div>
    </Card>
  );

};