import { ReportContext } from "@/app/features/reports/contexts/report-context";
import { useGetPublishedReportQuery } from "@app.raytd.com/store";
import React, { useCallback, useMemo, useState } from "react";
import { ReportEntity, ReportRevision } from "store/src/lib/services/types";

/**
 * This provider is used to provide the report and revision to the report context.
 * It also sets the report id to the revision id which is expected by the usePublishedReportData hook.
 */

const PublishedReportProvider = ({ revisionId, children }: { revisionId: number, children: React.ReactNode }) => {

    const {
        data: publishedReportData,
        isLoading: isDataLoading,
        error: publishedReportError,
      } = useGetPublishedReportQuery(Number(revisionId));

    const editMode = false;
    console.debug('PublishedReportProvider', publishedReportData);

    const handleSetEditMode = useCallback((editMode: boolean) => {
        // Published reports cannot be edited
        return;
    }, []);

    const value = useMemo(() => ({
        report: {
            ...publishedReportData?.published?.report,
          //  id: revisionId.toString() // want to avoid this as i is causing issues elsewhere.
        },

        isLoading: isDataLoading,
        error: publishedReportError,
        isEditable: false,
        editMode,
        setEditMode: handleSetEditMode,
        revisionId: revisionId?.toString(),
        isPublished: true,
        publishedReportData: publishedReportData,
        permissions: publishedReportData?.permissions,
        revision: publishedReportData?.revision
    }), [publishedReportData, editMode, handleSetEditMode, revisionId]);

    if (!publishedReportData) {
        return null;
    }

    return <ReportContext.Provider
        value={value}
        key={revisionId?.toString()}
    >
        {children}
    </ReportContext.Provider>;
}

export default PublishedReportProvider;