import { cn } from "@udecode/cn";
import { memo } from "react";

type PillVariants = 'missing' | 'fastfill' | 'excluded' | 'status'; 

const CountPill = memo(({ count, type, className }: { count: number, type: PillVariants, className?:string }) => (
    <div className={cn(className, `w-6 h-6 flex rounded-full border border-transparent text-transparent items-center text-xs justify-center`,
        type === 'missing' && count > 0 && 'bg-tests-error text-white text-[0.7rem]',
        type === 'fastfill' && count > 0 && ' bg-tests-missing text-white text-[0.7rem]',
        type === 'excluded' && count > 0 && 'bg-zinc-500 text-white text-[0.7rem]',
        type === 'status' && count > 0 && 'bg-orange-500 text-white text-[0.7rem]',
        count === 0 && 'bg-transparent text-transparent'
    )}>
        {count > 0 ? count : '&nbsp;'}
    </div>
));


export default CountPill;