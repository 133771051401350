import { useRef, useEffect } from 'react';
import { cn } from '@/lib/utils';
import { useReportDisplayContext } from './report-viewer-context';
import { useAppLayoutContext } from '@/app/layout2/AppLayout';

type StickyHeaderProps = {
    children: React.ReactNode;
}

const StickyHeader: React.FC<StickyHeaderProps> = ({ children }) => {

    const headerRef = useRef<HTMLElement>(null);
    const sentinelRef = useRef<HTMLDivElement>(null);

    const { isHeaderFixed, setIsHeaderFixed } = useReportDisplayContext();
    const { showSidebar } = useAppLayoutContext();

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                // When intersection ratio is less than 1, element is partially hidden
                // console.debug('toolbar entry', entry.intersectionRatio, entry.isIntersecting);
                setIsHeaderFixed(!entry.isIntersecting);
            },
            {
                threshold: [.3], // Observe when element is fully visible
                rootMargin: "220px 0px 0px 0px" // Trigger slightly before element starts moving
            }
        );

        if (sentinelRef.current) {
            observer.observe(sentinelRef.current);
        }

        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        const targetDiv = document.querySelector('[data-viewport-type="window"]');
        if (targetDiv) {
            targetDiv.className = isHeaderFixed ? 'header-fixed' : 'header-default';
        }
    }, [isHeaderFixed]);

    // VirtualisedItemList adjust the header height to avoid layout shift; GListComponent adjust the margin-top to avoid layout shift
    return (
        <>
            {/* Sentinel element to detect scroll position */}
            <div ref={sentinelRef} className="absolute h-[1px] top-0" />

            <header
                ref={headerRef}
                className={cn(
                    // "w-full",
                    // "max-w-screen-2xl mx-auto",
                    "max-w-screen-2xl mx-auto",
                    "transition-all duration-300 ease-in-out transform-gpu",
                    !isHeaderFixed && "relative bg-white w-full",
                    isHeaderFixed && "sticky top-0 translate-y-0 bg-muted/50 z-50 bg-white border-b-2 border-zinc-200",
                    isHeaderFixed && showSidebar && "",
                    isHeaderFixed && !showSidebar && "w-full left-0",
                )}
            >
                <div className="flex items-center justify-between w-full flex-col bg-white pt-2 rounded-xl mt-2">
                    <div className="w-full mx-auto h-full bg-white max-w-screen-xl">
                    {children}
                </div>

                </div>
            </header>
        </>
    );
};

export default StickyHeader;