import { hasStatusCode, isAPIError, useGetReportQuery } from "@app.raytd.com/store";
import { createContext, useCallback, useContext, useMemo, useState } from "react";
import { ReportEntity, ReportRevision, PublishedReport, ReportPermissions } from "store/src/lib/services/types";
import ErrorCard from "../components/error-card";
import { useNavigate } from "react-router-dom";

export interface BaseReportContext {
    report: ReportEntity | null;
    isLoading: boolean;
    error: unknown;
    isEditable: boolean;
    editMode: boolean;
    setEditMode: (editMode: boolean) => void;
    revisionId: string | null;
    isPublished: boolean;
    publishedReportData?: {
        published: PublishedReport;
        revision: ReportRevision;
    } | null;
    permissions?: ReportPermissions;
    revision: ReportRevision | null;
}

export const ReportContext = createContext<BaseReportContext>({
    report: null,
    isLoading: false,
    error: null,
    isEditable: false,
    editMode: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setEditMode: () => { },
    revisionId: null,
    isPublished: false,
    publishedReportData: null,
    permissions: null,
    revision: null
});

interface ReportProviderProps {
    reportId: string;
    children: React.ReactNode;
}

export const ReportProvider = ({ reportId, children }: ReportProviderProps) => {
    const { data: report, isLoading, error } = useGetReportQuery(reportId);

    const isEditable = report?.hasDraftRevision ?? false;

    const [editMode, setEditMode] = useState(isEditable);

    const handleSetEditMode = useCallback((editMode: boolean) => {
        setEditMode(editMode);
    }, [setEditMode]);

    const value = useMemo(() => ({
        report,
        isLoading,
        error,
        isEditable,
        editMode,
        setEditMode: handleSetEditMode,
        isPublished: false,
        revisionId: null,
        revision: report?.revisions?.[0] ?? null
    }), [report, isLoading, error, editMode, handleSetEditMode, isEditable]);

    const navigate = useNavigate();

    if (error) {
        
        console.error('Error fetching report', error);

        if (hasStatusCode(error) && error.status === 403) {
            return (
                <ErrorCard
                    title="Access Denied"
                    message="You do not have permission to access this report."
                    action={() => navigate(-1)}
                />
            );
        }

        return <div>Error fetching report</div>;
    }

    return <ReportContext.Provider
        value={value}
        key={reportId}
    >
        {children}
    </ReportContext.Provider>;
};

export const useReportContext = () => {
    const context = useContext(ReportContext);
    if (!context) {
        throw new Error('useReport must be used within a ReportProvider');
    }
    return context;
};

