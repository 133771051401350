import { Loader2 } from "lucide-react";

const LoadingComponent = ({ isLoading }: { isLoading?: boolean }) => {
    return (
        <div className="p-6 bg-white rounded-lg flex flex-row gap-4 items-center">
            <Loader2 className="animate-spin rounded-full h-4 w-4" />
            <div>Loading report...</div>
        </div>
    )
}

export default LoadingComponent;