import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';
import { cn } from '@/lib/utils';
import React, { useCallback, useEffect, useState } from 'react';
import { ReportTestItem } from '../../utils/entities';
import useReportViewerContext from '../report-viewer-context';
import { useToggleProvider } from './toggle-context';
import { toast } from 'sonner';

const toggleVariants = {
    included: 'bg-zinc-600 text-white data-[state=checked]:bg-zinc-600',
    excluded: 'bg-white border-zinc-500 text-white data-[state=checked]:bg-zinc-600 data-[state=unchecked]:bg-white',
    fastfill: 'text-white data-[state=unchecked]:bg-transparent data-[state=checked]:bg-tests-missing data-[state=unchecked]:border-tests-missing',
    missing: 'text-white data-[state=unchecked]:bg-transparent data-[state=unchecked]:border-tests-error',
    status: 'text-white data-[state=unchecked]:bg-transparent data-[state=unchecked]:border-tests-status',
    accepted: 'text-white data-[state=unchecked]:bg-transparent data-[state=unchecked]:border-tests-missing',
}

const thumbVariants = {
    included: 'bg-white',
    excluded: 'bg-tests-excluded',
    fastfill: 'bg-zinc-200 data-[state=unchecked]:bg-tests-missing',
    missing: 'bg-tests-error',
    status: 'bg-tests-status'
}

const TestItemToggle: React.FC<{
    label: string;
    offLabel?: string;
    switchType: 'include' | 'fast_fill_accepted';
    disabled?: boolean;
    item: ReportTestItem;
    variant?: string;
    value?: boolean;
}> = ({ label: onLabel, offLabel, switchType: switchName, disabled, item, variant, value }) => {

    const { toggleSwitch, isLoading } = useToggleProvider();
    const { setUpdatingTest } = useReportViewerContext();
    const [localChecked, setLocalChecked] = useState(value ?? item?.switches[switchName]);

    useEffect(() => {
        setLocalChecked(value ?? item?.switches?.[switchName]);
    }, [value, item?.switches?.[switchName]]);

    // item.assessmentTestId === 2322 && console.info('test item toggle', { item, switchName, value: item.switches });

    const handleChange = useCallback(async () => {
        setLocalChecked(!localChecked);
        try {
            setUpdatingTest(item?.assessmentTestId);
            await toggleSwitch(switchName, item);

        } catch (error) {
            setLocalChecked(localChecked); // Revert on error
            setUpdatingTest(null);
            console.error('Failed to toggle switch:', error);
            toast.error('There was an error updating the switch');
        }
    }, [toggleSwitch, item, switchName]);

    const switchId = `switch-${switchName}-${item?.assessmentTestId}`;

    if (!item) {
        return null;
    }

    return (
        <div className="flex flex-row gap-2 items-center">
            <Switch
                id={switchId}
                checked={localChecked}
                onCheckedChange={handleChange}
                disabled={disabled || isLoading}
                aria-labelledby={`${switchId}-label`}
                className={cn('', toggleVariants[variant])}
                thumbClassName={cn(thumbVariants[variant])}
            />
            <Label id={`${switchId}-label`} htmlFor={switchId} className="text-sm font-medium">
                {localChecked ? onLabel : (offLabel ?? onLabel)}
            </Label>
        </div>
    );
};

export default TestItemToggle;