import React, { createContext, useContext } from "react"
import { SelectedTestSuite } from "@/app/features/reports/types/selected-test-suite";
import { type TestStatus } from '@/app/features/reports/types/test-form'

const TestFormContext = createContext<{
    formId: string;
    isFormDirty: boolean;
    isFormValid: boolean;
    isFormReady: boolean;
    isRequiredFieldsFilled: boolean;
    isExistingTest: boolean;
    selectedTestSuite: SelectedTestSuite | null;
    viewportRef: React.RefObject<HTMLDivElement>;
    getFieldVisibility: (fieldName: string) => boolean;
    getFieldRequirement: (fieldName: string) => boolean;
    onStatusChange: (status: TestStatus) => Promise<void> | void;
    onFastFillChanged: (field_id: string) => void;
}>({
    formId: undefined,
    isFormDirty: false,
    isFormValid: false,
    isFormReady: false,
    isRequiredFieldsFilled: false,
    isExistingTest: false,
    selectedTestSuite: null,
    viewportRef: null,
    getFieldVisibility: () => true,
    getFieldRequirement: () => true,
    onStatusChange: () => { },
    onFastFillChanged: () => { }
});

export const useTestFormContext = () => {
    const context = useContext(TestFormContext);
    if (!context) {
        throw new Error('useTestFormContext must be used within a TestEditForm');
    }
    return context;
}

export default TestFormContext;