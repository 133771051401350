
import { TestTileSkeleton } from '@/app/features/reports/components/test-tile-skeleton';
import { calculateFlattenedIndex, calculateScores, createEmptyNode, createFlattenedItem, hasChildren, hasContent } from '@/app/features/reports/utils/utils';
import { cn } from '@/lib/utils';
import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { GroupedVirtuoso, ListProps } from 'react-virtuoso';
import RowHeader from './components/report-group-header';
import RowContent from './components/report-row-content';
import useReportViewerContext, { useReportDisplayContext, useReportViewContext } from './components/report-viewer-context';
import { ScrollPosition } from './types';
import { FlattenedItem, ReportItem, ReportItemContent } from './utils/entities';
import sortContentBy, { determineSortOrder } from './utils/sorters';
import { useReportGroupingContext } from './contexts/grouping-context';


const ScrollSeekPlaceholder = ({ height, type, index, context }) => {

    return (
        (<div
            style={{
                height,
                padding: "8px",
                boxSizing: "border-box",
                overflow: "hidden",
            }}
        >
            <TestTileSkeleton />
        </div>)
    );
}

const ListHeader = ({ children }) => {

    const { isHeaderFixed } = useReportDisplayContext();

    return (
        <div className={cn(
            "bg-zinc-300 w-full sticky z-[1] transition-all duration-300 ease-in-out",
            isHeaderFixed && "top-[120px]")}>
            {children}
        </div>
    )
}


const GListComponent = React.forwardRef<HTMLDivElement, ListProps>(({
    children,
    style,
    ...props
}, ref) => {

    const { isHeaderFixed } = useReportDisplayContext();

    // const marginTop = isHeaderFixed ? '126px' : '0px';
    const marginTop = isHeaderFixed ? '120px' : '0px';
    // const marginTop = '0px';

    return <div {...props} style={{ ...style, marginTop }} className="transition-transform duration-300 ease-in" ref={ref}>
        {children}
    </div>
});


interface VirtualizedNestedListProps {
    width?: number,
    height?: number,
    setIsScrolling: (scrolling: boolean) => void;
    setScrollPosition: (position: ScrollPosition) => void;
    scrollParent?: HTMLDivElement;
}

const VirtualizedNestedList = React.forwardRef<HTMLDivElement, VirtualizedNestedListProps>(({
    width, height,
    setIsScrolling,
    setScrollPosition,
    scrollParent
}, ref) => {

    const [scrollContainer, setScrollContainer] = useState<HTMLDivElement | null>(null);

    useEffect(() => {
        if (scrollParent) {
            setScrollContainer(scrollParent);
        }
    }, [scrollParent]);

    const [flattenedItems, setFlattenedItems] = useState<FlattenedItem[]>([]);

    const { data,
        filters,
        groupMode,
    } = useReportViewContext();

    const { viewMode } = useReportDisplayContext();

    const { visibleTestCount } = useReportViewContext();
    const { setUpdatingTest } = useReportViewerContext();
    const { expandedItems: expandedIds, setExpandedItems: setExpandedIds
    } = useReportGroupingContext();

    const { headerMode } = useReportDisplayContext();

    const flattenData = useMemo(() => {

        const flatten = (
            items: ReportItem[],
            level: number = 0,
            isContent = false,
            parents: readonly string[] = []
        ): FlattenedItem[] => {
            const result: FlattenedItem[] = [];
            const sortOrder = determineSortOrder(groupMode, headerMode);

            for (const item of items) {
                const isExpanded = expandedIds.has(item.id);
                const scores = calculateScores(item);
                const currentParents = isContent
                    ? parents
                    : Object.freeze([...parents, item.title]);

                result.push(createFlattenedItem(
                    item,
                    scores,
                    isExpanded,
                    level,
                    isContent,
                    //@ts-expect-error
                    currentParents
                ));

                if (isExpanded) {
                    if (hasChildren(item)) {
                        result.push(...flatten(
                            item.children!,
                            level + 1,
                            false,
                            currentParents
                        ));
                    }

                    if (hasContent(item)) {
                        const sortedContent = sortContentBy(item.content as ReportItemContent[], sortOrder);
                        result.push(...flatten(
                            //@ts-expect-error
                            sortedContent as unknown as ReportItemContent[],
                            level + 1,
                            true,
                            currentParents
                        ));
                    }

                    if (!hasContent(item) && !hasChildren(item)) {
                        result.push(createEmptyNode(item.id, level + 1));
                    }
                }
            }

            return result;
        };

        return flatten;

    }, [expandedIds, groupMode, headerMode]);

    const [scrollingRange, setScrollingRange] = useState<[number, number]>([0, 0]);

    const [visibleRange, setVisibleRange] = useState({
        startIndex: 0,
        endIndex: 0,
    })

    const handleSetScrollRange = useCallback((range) => {
        setVisibleRange(range);

        const { startIndex, endIndex } = range;

        const total = filters?.filteredCounts?.filtered?.total || 0;

        const relativePositions = {
            startIndex: startIndex / total * 100,
            endIndex: endIndex / total * 100
        };

        setScrollPosition({
            range,
            relativePositions
        });

    }, [filters?.filteredCounts]);

    // const [expandedIds, setExpandedIds] = useState<Set<string>>(new Set());
    // const listRef = useRef<any>(null);

    const internalRef = useRef<any>(null);

    const findTestPath = useCallback((items: ReportItem[], testId: number): string[] => {
        const path: string[] = [];

        const find = (items: ReportItem[]): boolean => {
            for (const item of items) {
                // Check if this item contains the test in its content
                if (item.content?.some(content => content.id === String(testId))) {
                    path.push(item.id);
                    return true;
                }

                // Check children if they exist
                if (item.children && find(item.children)) {
                    path.push(item.id);
                    return true;
                }
            }
            return false;
        };

        find(items);
        return path.reverse(); // Reverse to get root-to-leaf order
    }, []);

    const calculateOffset = useCallback((index: number) => {
        return 400;
    }, []);

    const scrollToTest = useCallback((id: number) => {
        // Find the path to the test
        console.debug('scrollToTest', { flattenedItems, expandedIds });

        const index = flattenedItems.findIndex(item => item.id === String(id));
        if (index >= 0) {
            console.debug('scrollToTest index 1', { index, id });
            internalRef.current?.scrollToIndex({ index: index, align: 'start', offset: calculateOffset(index) });
            return;
        }

        const path = findTestPath(data, id);

        // Expand all groups in the path
        const newExpandedIds = new Set(expandedIds);
        path.forEach(groupId => {
            newExpandedIds.add(groupId);
        });

        // Update expanded items if there are new items to expand
        if (path.length > 0) {
            setExpandedIds(newExpandedIds);

            // Use requestAnimationFrame to wait for next render
            requestAnimationFrame(() => {
                const index = calculateFlattenedIndex(data, id, newExpandedIds);
                console.debug('scrollToTest index 2', { index, id });
                if (index !== -1) {
                    internalRef.current?.scrollToIndex({ index: index, align: 'start', offset: calculateOffset(index) });
                }
            });
        }
    }, [data, expandedIds, setExpandedIds, flattenedItems]);

    const { groups, groupCounts, allCollapsed } = useMemo(() => {
        const groups: string[] = [];
        const groupCounts: number[] = [];

        // Start with assumption all are collapsed
        let allCollapsed = true;

        // Use reduce instead of forEach for better performance
        return flattenedItems.reduce((acc, group) => {
            if (!group.content) {  // isGroup check
                groups.push(group.id);

                // Update allCollapsed flag
                if (group.isExpanded) {
                    acc.allCollapsed = false;
                }

                // Calculate count only if expanded and has content
                const itemCount = (group.isExpanded && group.hasContent) ? group.length : 0;
                groupCounts.push(itemCount);
            }

            return { groups, groupCounts, allCollapsed };
        }, { groups, groupCounts, allCollapsed });
    }, [flattenedItems]);

    useEffect(() => {
        console.time('flattenData');
        setFlattenedItems(flattenData(data));
        console.timeEnd('flattenData');
        console.debug('flattenedItems', flattenedItems);
        setUpdatingTest(null);
        // listRef.current?.resetAfterIndex(0);
    }, [data, expandedIds, flattenData, viewMode]);

    const toggleExpand = useCallback((id: string) => {
        const next = new Set(expandedIds);
        if (next.has(id)) {
            next.delete(id);
        }
        else {
            next.add(id);
        }
        setExpandedIds(next);
    }, [expandedIds, setExpandedIds]);
    // Expose specific methods/properties to parent
    useImperativeHandle(ref, () => {
        // Cast to HTMLDivElement to match expected type
        const element = internalRef.current as unknown as HTMLDivElement;
        return {
            ...element, // Spread all HTMLDivElement properties
            scrollToIndex: (index: number) => {
                internalRef.current?.scrollToIndex(index);
            },
            scrollToTest: (id: number) => {
                scrollToTest(id);
            },
            customMethod: () => {
                // Do something with internalRef
            }
        };
    }, [scrollToTest]);

    return (
        <GroupedVirtuoso
            useWindowScroll={true}
            ref={internalRef}
            // style={{ height, width }}
            style={{ flex: 1 }}
            isScrolling={setIsScrolling}
            rangeChanged={handleSetScrollRange}
            overscan={20}
            customScrollParent={scrollContainer}
            data={flattenedItems}
            groupCounts={groupCounts}
            groupContent={index => {
                // console.debug('groupContent', { index, groups, group: flattenedItems[index] });
                const item = flattenedItems.filter(item => !item.content)[index];
                // if (item.content || item.isExpanded) {
                //     return null;
                // } 
                if ((!item.content)) {
                    return (
                        (<RowHeader
                            item={item}
                            indent={item.level * 24}
                            toggleExpand={toggleExpand}
                            active={false}
                            className="bg-white"
                        />)
                        // <div
                        //     style={{
                        //         paddingLeft: `${item.level * 24}px`,
                        //     }}
                        //     className="bg-zinc-200 py-6"
                        // >{item.level === 0 ? 'Building - ' : item.level === 1 ? 'Level - ' : 'Area - '} {item.title} ({item.length}, {item.count})</div>
                    );
                }
            }}

            // itemContent={(index, item) => (
            //     <div className="relative" data-index={index}>
            //         <ItemContent item={item} index={index} />
            //     </div>
            // )}
            itemContent={(index, groupIndex) => {

                // console.debug('itemContent', { index, groupIndex, flattenedItems });

                //const item = flattenedItems.filter(item => item.groupId === )[index];
                // const groupId = groups[groupIndex];
                // const item = flattenedItems
                //     .filter(item => item.groupId === groupId)[index];
                const item = flattenedItems.filter(item => item.content)[index];
                if (!item || !item.content) {
                    return null;
                }
                // console.debug('itemContent inner', { index, groupIndex, item });
                // return <div>Item {groupIndex} {index}: {groupId}</div>
                return (
                    <div className="relative w-full" data-index={index}>
                        <RowContent
                            item={item}
                            indent={item.level * 24}
                            toggleExpand={toggleExpand}
                        />
                        {/* <div
                            style={{
                                paddingLeft: `${item.level * 24}px`,
                            }}
                        >- Item: {item.title} ({item.id}) - {index}
                        </div> */}
                    </div>
                );
            }}

            components={{
                ScrollSeekPlaceholder,
                TopItemList: ListHeader,
                List: GListComponent
            }}
            scrollSeekConfiguration={{
                enter: (velocity) => Math.abs(velocity) > 2000,
                exit: (velocity) => {
                    const shouldExit = Math.abs(velocity) < 10;
                    if (shouldExit) {
                        setScrollingRange([null, null]);
                    }
                    return shouldExit;
                },
                change: (_velocity, { startIndex, endIndex }) => setScrollingRange([startIndex, endIndex])
            }}

        // computeItemKey={(index, item) => item.id}
        />
    );

});

export default VirtualizedNestedList;