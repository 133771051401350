'use client'

import { useState, useMemo, useEffect, useCallback } from 'react'
import { useForm, Controller, useFormContext } from 'react-hook-form'
import { Button } from "@/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { ChevronDown, ChevronRight, EllipsisIcon, Loader2, X } from 'lucide-react'
import { ClassificationTree } from '@app.raytd.com/store'
import { toast } from 'sonner'
import { useTestFormContext } from '@/app/features/reports/components/test-form/context'

export interface ClassificationItem {
  id: number;
  name: string;
  parent_id: number | null;
  level: number;
  children: ClassificationItem[];
  path?: string[];
  classification_tree_id?: number;
}

interface TreeNode {
  id: number;
  parent_id: number;
  original: ClassificationItem;
  name: string;
  isOpen: boolean;
  key: 'category' | 'subcategory' | 'type' | 'subtype';
  childCount: number;
  children?: TreeNode[];
}

interface SelectorState {
  path: TreeNode[];
  previousPath?: TreeNode[];
  search: string;
  isOpen: boolean;
  selection: Selection;
  pendingSelection: Selection;
  initialSelection: Selection;
}

const LEVEL_KEYS = {
  0: 'Element',
  1: 'Sub Element',
  2: 'Sub Element',
  3: 'Sub Element'
} as const;

const MAX_DEPTH = 4;

interface ElementItemProps {
  element: ClassificationItem;
  path: TreeNode[];
  onSelect: (element: ClassificationItem) => void;
}

const SearchResultItem = ({
  element,
  path: oldPath,
  onSelect,
}: ElementItemProps) => {
  const pathWithoutLast = element.path?.slice(0, -1) || [];
  const lastTwoNodes = pathWithoutLast.slice(-2);
  const showEllipsis = pathWithoutLast.length > 2;
  if (!element) return null;

  // console.debug('SearchResultItem', { element, oldPath, lastTwoNodes });

  return (
    <CommandItem
      key={`node-${element.id}`}
      value={element.name}
      onSelect={() => onSelect(element)}
    >
      {showEllipsis && (
        <>
          <EllipsisIcon className="h-4 w-4 text-muted-foreground" />
          <ChevronRight className="h-4 w-4 text-muted-foreground" />
        </>
      )}
      {lastTwoNodes.map((node) => (
        <>
          <span className="font-medium">{node}</span>
          <ChevronRight className="h-4 w-4 text-muted-foreground" />
        </>
      ))}
      <span className="font-medium">{element.name}</span>
    </CommandItem>
  );
};

const findInElements = (
  elements: ClassificationItem[],
  targetId: number,
  parentPath: string[] = []
): TreeNode[] | null => {
  for (const element of elements) {
    // Current path includes current element's name
    const currentPath = [...parentPath, element.name];

    if (element.id === targetId) {
      return [{
        ...element,
        path: currentPath
      } as unknown as TreeNode];
    }

    if (element.children) {
      const childPath = findInElements(element.children, targetId, currentPath);
      if (childPath) {
        const node = {
          ...element,
          path: currentPath
        };
        return [node as unknown as TreeNode, ...childPath];
      }
    }
  }
  return null;
};

const findNodePath = (groups: ClassificationTree[], targetId: number): { group: ClassificationTree, path: TreeNode[] } | null => {
  for (const group of groups) {
    if (group.id === targetId) {
      return {
        group,
        path: [{ id: group.id, name: group.name } as TreeNode]
      };
    }

    const elementPath = findInElements(group.elements, targetId, [group.name]);
    if (elementPath) {
      return { group, path: elementPath };
    }
  }
  return null;
};

interface ElementSelectorProps {
  elements: ClassificationTree[];
  onSelect?: (id: number | null) => void;
  name: string;
  isLoading?: boolean;
  onCreateElement?: (type: 'item' | 'tree', node: ClassificationItem | ClassificationTree, title: string) => Promise<TreeNode>;
  canCreate?: boolean;
}

export function ElementSelector({
  elements: elementGroups,
  onSelect,
  name,
  isLoading = false,
  onCreateElement,
  canCreate = true
}: ElementSelectorProps) {
  const formContext = useFormContext();
  const { control, setValue, watch } = formContext || useForm();
  const [selectedTree, setSelectedTree] = useState<ClassificationTree | null>(null);
  const [path, setPath] = useState<TreeNode[]>([]);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  const elements = useMemo(() => {
    if (!selectedTree) return [];

    // Helper function to add path to nodes recursively
    const addPathToNodes = (
      nodes: ClassificationItem[],
      parentPath: string[] = []
    ): ClassificationItem[] => {
      return nodes.map(node => {
        const currentPath = [...parentPath, node.name];

        return {
          ...node,
          path: currentPath,
          children: node.children
            ? addPathToNodes(node.children, currentPath)
            : []
        };
      });
    };

    // console.debug('elements updated', { selectedTree });
    return addPathToNodes(selectedTree.elements, [selectedTree.name]);
  }, [selectedTree]);


  const filteredElements = useMemo(() => {
    if (!search) return [];
    const searchLower = search.toLowerCase();

    const currentLevel = path.length > 0 ? path[path.length - 1] : { children: elements } as unknown as TreeNode;

    // console.debug('filteredElements', { search, searchLower, path, elements, currentLevel });

    const searchNodes = (nodes: ClassificationItem[]): ClassificationItem[] => {
      return nodes.flatMap(node => {
        const matchesSearch = node.name.toLowerCase().includes(searchLower);

        // Create array of matches
        const matches: ClassificationItem[] = [];

        // Add current node if it matches
        if (matchesSearch) {
          matches.push({
            ...node,
            children: [] // Remove children from results
          });
        }

        // Recursively search children and add their matches
        if (node.children) {
          matches.push(...searchNodes(node.children));
        }

        return matches;
      });
    };

    return searchNodes(elements);
  }, [elements, search, path]);

  const selectedValue = watch(name);

  useEffect(() => {
    if (!selectedValue) {
      setSelectedTree(null);
      setPath([]);
      return;
    }

    const result = findNodePath(elementGroups, Number(selectedValue));
    // console.debug('selectedValue / elementGroups / findNodePath result', { selectedValue, result });
    if (!result) return;


    setSelectedTree(result.group);
    setPath(result.path);

  }, [selectedValue, elementGroups]);

  const resetSelection = useCallback(() => {
    setSelectedTree(null);
    setPath([]);
    setValue(name, null);
    onSelect && onSelect(null);
    //setOpen(false);
  }, [name, onSelect]);

  const renderTreeSelection = useCallback(() => (
    <CommandGroup heading="Select Element Group">
      {elementGroups?.map((tree) => (
        <CommandItem
          key={`tree-${tree.id}`}
          value={`${tree.id}`}
          onSelect={() => {
            setSelectedTree(tree);
            setPath([]);
          }}
        >
          {tree.name}
          {tree.status === 'archived' && (
            <span className="ml-2 text-xs text-muted-foreground">(Archived)</span>
          )}
        </CommandItem>
      ))}
    </CommandGroup>
  ), [elementGroups]);


  const goToLevel = useCallback((index: number) => {
    console.debug('goToLevel', { index, path, newPath: path.slice(0, index) });
    setPath(prev => prev.slice(0, index));
  }, []);

  const [isCreating, setIsCreating] = useState(false);

  const handleSelect = useCallback((element: ClassificationItem) => {
    const path = findNodePath(elementGroups, element.id);
    console.debug('handleSelect', { element, path });
    setPath(path?.path || []);
    setValue(name, element.id);
    onSelect && onSelect(element.id);
    setSearch('');
    if (element.path?.length > 4) {
      setOpen(false);
    }
  }, [name, onSelect, elementGroups]);


  useEffect(() => {
    // console.debug('path updated', { path });
    if (path.length === 4) {
      setOpen(false);
    }
  }, [path]);


  const handleCreate = useCallback(async (type: 'item' | 'tree', node: TreeNode, title: string) => {
    if (!canCreate) return;
    console.debug('handleCreate', { node, title });
    try {
      setIsCreating(true);

      const newElement = await onCreateElement?.(
        type,
        type === 'item' ? node as unknown as ClassificationItem : node as unknown as ClassificationTree,
        title
      );
      console.debug('handleCreate newElement', { newElement });

      setTimeout(() => {
        setIsCreating(false);
        handleSelect(newElement as unknown as ClassificationItem);
        toast.success(`Created ${type} "${title}"`);
      }, 500);

    } catch (error) {
      console.error('handleCreate error', error);
      setIsCreating(false);
      toast.error(`Error creating ${type} "${title}"`);
    }

  }, [canCreate, onCreateElement, handleSelect]);

  const renderSearchResults = useCallback(() => {

    const isMaxLevel = path.length === MAX_DEPTH;

    const currentLevel = path.length > 0 ? path[path.length - 1] : { children: elements } as unknown as TreeNode;

    const nextLevelKey = LEVEL_KEYS[path.length as keyof typeof LEVEL_KEYS] || 'Element';

    // check if the search string is an element at the current path
    const elementExistsAtPath = currentLevel.children?.some(child =>
      child.name.toLowerCase() === search.toLowerCase()
    );


    const elementsInCurrentPath: ClassificationItem[] = [];
    const elementsOutsidePath: ClassificationItem[] = [];

    filteredElements.forEach((element) => {
      // Check if the element's path includes all current path nodes


      // const isInCurrentPath = path.every((pathNode, index) =>
      //   element.path?.[index] === pathNode.name
      // );
      const isInCurrentPath = element.parent_id === currentLevel.id;
      // console.debug('renderSearchResults 1', { element: element, path, isInCurrentPath });

      if (isInCurrentPath) {
        elementsInCurrentPath.push(element);
      } else {
        elementsOutsidePath.push(element);
      }
    });

    // console.debug('renderSearchResults 2', { elementExistsAtPath, path, filteredElements, currentLevel, elementsInCurrentPath, elementsOutsidePath });

    return (
      <>
        <CommandGroup heading="Path Search Results">


          {elementsInCurrentPath.map((element) => {
            console.debug('renderSearchResults 3', { element });
            return (
              <SearchResultItem
                key={element.id}
                element={element}
                path={path}
                onSelect={handleSelect}
              />
            )
          })}
        </CommandGroup>

        <CommandGroup heading="All Search Results">
          {elementsInCurrentPath.length === 0 && elementsOutsidePath.length === 0 && (
            <CommandItem value={`no-results-${search}`} disabled>No results found.</CommandItem>
          )}

          {elementsOutsidePath.length > 0 && (
            <>
              <CommandSeparator />
              {elementsOutsidePath.map((element) => (
                <SearchResultItem
                  key={element.id}
                  element={element}
                  path={path}
                  onSelect={handleSelect}
                />
              ))}
            </>
          )}
        </CommandGroup>


        {!elementExistsAtPath && !isMaxLevel && (
          <CommandGroup heading={`Create new ${nextLevelKey} in ${currentLevel?.name ?? selectedTree?.name}`}>
            <CommandSeparator />
            <CommandItem
              onSelect={() => handleCreate(currentLevel.id ? 'item' : 'tree', (currentLevel.id ? currentLevel : selectedTree) as TreeNode, search)}
              key={`create-${search}`}
              value={`Create new "${search}"`}
              className="font-medium"
            >
              + Create "{search}"
            </CommandItem>
          </CommandGroup>
        )}
      </>
    )
  }, [filteredElements, setValue, name, onSelect, handleCreate, path, selectedTree]);

  // const handleSelect = useCallback((node: TreeNode) => {
  //   console.debug('element-selector handleSelect', { node });
  //   setPath(prev => [...prev, node]);
  //   setValue(name, node.id);
  //   onSelect && onSelect(node.id);
  // }, [name, onSelect]);

  const renderContent = useMemo(() => {
    // console.debug('renderContent', { search, selectedTree, path, elements, isCreating });

    if (isCreating) {
      return <CommandItem disabled><Loader2 className="h-4 w-4 animate-spin" />Creating...</CommandItem>
    }

    if (search) return renderSearchResults();

    if (path.length > 3) {
      return <CommandItem disabled>You have reached the maximum depth of 4.</CommandItem>
    }

    if (!selectedTree) return renderTreeSelection();

    const currentLevel = path.length > 0 ? path[path.length - 1] : { children: elements } as unknown as TreeNode;
    const nextLevelKey = LEVEL_KEYS[path.length as keyof typeof LEVEL_KEYS] || 'Element';
    const currentLevelKey = LEVEL_KEYS[path.length - 1 as keyof typeof LEVEL_KEYS] || 'Element';
    const isMaxLevel = path.length >= (MAX_DEPTH - 1);

    // console.debug('renderContent 2', { currentLevel, nextLevelKey, currentLevelKey, isMaxLevel, path });

    return (
      <>
        {path.length > 0 && (
          <CommandItem
            value={currentLevel.id.toString()}
            onSelect={() => {
              setValue(name, currentLevel.id);
              setOpen(false);
              onSelect && onSelect(currentLevel.id);
            }}
            className="font-medium border-b"
          >
            {currentLevel.name}<span className="text-xs text-muted-foreground">(Select this {currentLevelKey})</span>
          </CommandItem>

        )}
        <CommandSeparator />
        <CommandGroup heading={`Select ${nextLevelKey}`}>

          {(currentLevel?.children || []).length === 0 && (
            <CommandItem disabled>Type to create a new {nextLevelKey}.</CommandItem>
          )}

          {(currentLevel.children || []).map((node) => (
            <CommandItem
              key={node.id}
              value={`node-${node.id.toString()}`}
              onSelect={() => {
                console.debug('element-selector selected element node', node, { isMaxLevel, path });
                // if (node.children && node.children.length > 0 && path.length < MAX_DEPTH) {
                if (!isMaxLevel) {
                  console.debug('renderContent 3', { node });

                  setPath(prev => [...prev, node]);
                } else {
                  setPath(prev => [...prev, node]);
                  setValue(name, node.id);
                  setOpen(false);
                  onSelect && onSelect(node.id);
                }
              }}
            >
              {node.name}
              {node.childCount > 0 && path.length < MAX_DEPTH && (
                <span className="ml-auto text-xs text-muted-foreground">
                  {node.childCount} item{node.childCount !== 1 ? 's' : ''}
                </span>
              )}
            </CommandItem>
          ))}

        </CommandGroup>
      </>
    );
  }, [path, elements, search, filteredElements, handleSelect, renderSearchResults, renderTreeSelection, selectedTree, isCreating]);

  // const getElementById = useCallback((id: number): ClassificationItem | null => {
  //   for (const node of elementGroups) {
  //     if (node.id === id) return node;
  //     if (node.children) {
  //       const childNode = getElementById(id, node.children);
  //       if (childNode) return childNode;
  //     }
  //   }
  //   return null;
  // }, [elementGroups]);

  const renderDisplayValue = useCallback((nodeId) => {

    // console.debug('element renderDisplayValue', { nodeId, path, search });

    //if (search) return `Search results for "${search}"`;
    if (path.length === 0) return 'Select Element';
    return (
      <div className="flex flex-col items-start gap-1 font-normal">
        <span className="font-medium">{selectedTree?.name}</span>
        <div className="flex flex-col items-start gap-1">
          {path.slice(0, -1).map(node => (
            <span key={node.id} className="flex items-center">
              <span className="text-muted-foreground font-normal">{node.name}</span>
              <ChevronRight className="h-4 w-4 mx-1 text-muted-foreground" />
            </span>
          ))}
        </div>
        <span className="font-medium">{path[path.length - 1].name}</span>
      </div>)
  }, [path, search]);

  const { viewportRef } = useTestFormContext(); 

  const renderFixedHeader = useMemo(() => {
    // if (search) return null;
    // if (path.length === 0) return null;
    if (!selectedTree) return null;

    return (
      <div className="sticky top-0 bg-white dark:bg-gray-800 p-2 border-b flex items-center justify-between text-xs text-muted-foreground">

        <div className="flex items-center space-x-1 flex-wrap gap-2">
          {selectedTree && (
            <div className="flex flex-row gap-1 items-center">
              <div className="font-medium flex flex-row bg-zinc-100 rounded-md p-1 gap-1 items-center hover:cursor-pointer hover:text-black"
                onClick={resetSelection}
              >
                {selectedTree.name}
                <X className="h-4 w-4" />
              </div>
              <ChevronRight className="h-4 w-4 mx-1" />
            </div>
          )}

          {path.map((node, index) => (
            <div key={node.id} className="flex items-center">
              {index > 0 && <ChevronRight className="h-4 w-4 mx-1" />}
              <span key={node.id} className="flex items-center bg-zinc-100 rounded-md p-1 ">
                <button
                  onClick={() => goToLevel(index)}
                  className="focus:outline-none flex flex-row items-center gap-1 hover:text-black"
                >
                  {node.name}
                  <X className="h-4 w-4" />
                </button>
              </span>
            </div>
          ))}
        </div>
        <Button
          variant="ghost"
          size="icon"
          onClick={resetSelection}
          className="h-6 w-6"
        >
          <X className="h-4 w-4" />
        </Button>
      </div>
    );
  }, [path, search]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        // const displayValue = selectedTree 
        //   ? `${selectedTree.name} > ${path.map(node => <div>{node.name}</div>)}`
        //   : "Select Classification";

        return (
          <Popover open={open} onOpenChange={setOpen} modal={true}>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                role="combobox"
                aria-expanded={open}
                className="w-full h-full justify-between"
              >
                <span className="truncate">{renderDisplayValue(field.value)}</span>
                <ChevronDown className="h-4 w-4 shrink-0 opacity-50" />
              </Button>
            </PopoverTrigger>
            <PopoverContent
              className="w-min-[400px] w-[var(--radix-popover-trigger-width)] p-0"
              onOpenAutoFocus={e => e.preventDefault()}
              modal={true}
              style={{
                maxHeight: '80vh',
              }}
              sticky="always"
              collisionPadding={40}
              collisionBoundary={viewportRef?.current}
            >
              <Command >
                {selectedTree && (
                  <CommandInput
                    placeholder="Search elements..."
                    value={search}
                    onValueChange={setSearch}
                    disabled={isLoading}
                    autoFocus={true}

                  />
                )}
                {renderFixedHeader}
                <CommandList className="max-h-[400px] overflow-y-auto">
                  {renderContent}
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>
        );
      }}
    />
  );
}

