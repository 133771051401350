import { ReportFilters } from "@/app/features/reports/hooks/useReportData";
import { CollapseAllIcon, ExpandAllIcon } from "@/components/raytd/icons";
import OptionToggle from "@/components/raytd/option-toggle";
import { Button } from "@/components/ui/button";
import { Toggle } from "@/components/ui/toggle";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { ArrowLeftRight, Maximize2Icon, Minimize2Icon } from "lucide-react";
import { ReportGroupModes, ReportViewModes } from "../types";
import { FilterSection } from "./filter-section";
import { useReportDisplayContext, useReportViewContext } from "./report-viewer-context";
import { useReportGroupingContext } from "../contexts/grouping-context";
// Reusable tooltip wrapper component
const WithTooltip = ({ children, tooltip }: { children: React.ReactNode, tooltip: string }) => (
    <Tooltip>
        <TooltipTrigger asChild>
            {children}
        </TooltipTrigger>
        <TooltipContent aria-label={tooltip}>
            <p>{tooltip}</p>
        </TooltipContent>
    </Tooltip>
);

// Reusable tooltip button component with better TypeScript typing
interface TooltipButtonProps extends Omit<React.ComponentProps<typeof Button>, 'onClick' | 'children'> {
    tooltip: string;
    onClick: () => void;
    icon: React.ComponentType<{ className?: string }>;
}

const TooltipButton = ({
    tooltip,
    onClick,
    icon: Icon,
    ...props
}: TooltipButtonProps) => (
    <WithTooltip tooltip={tooltip}>
        <Button
            variant='outlinelight'
            aria-label={tooltip}
            size='icon'
            onClick={onClick}
            {...props}
        >
            <Icon className="h-4 w-4 text-zinc-600" />
        </Button>
    </WithTooltip>
);

const Divider = () => (
    <div className="h-full w-px bg-zinc-200" aria-hidden="true" />
);

interface ReportToolbarProps {
    setViewMode: (viewMode: ReportViewModes) => void;
    filters: ReportFilters;
}

const VIEW_OPTIONS = [
    { description: 'Details', value: 'details' },
    { description: 'Images', value: 'images' }
] as const;

const GROUP_OPTIONS = [
    { description: 'Location', value: 'location' },
    { description: 'Element', value: 'element' },
    { description: 'Test', value: 'test' },
    { description: 'Rating', value: 'rating' }
] as const;

const ReportToolbar: React.FC<ReportToolbarProps> = ({
    setViewMode,
    filters
}) => {
    const { groupMode, setGroupMode } = useReportViewContext();
    const { viewMode, switchHeaders } = useReportDisplayContext();
    const { 
        expandAll, 
        collapseAll, 
        expandAllTiles, 
        collapseAllTiles 
    } = useReportGroupingContext();

    const handleViewModeChange = (value: string) => {
        setViewMode(value as ReportViewModes);
    };

    const handleGroupModeChange = (value: string) => {
        setGroupMode(value as ReportGroupModes);
    };

    return (
        <div
            className={cn(
                "flex flex-row items-center",
                "gap-4 mb-2 pb-2",
                "border-b border-zinc-200",
                "transition-all duration-200"
            )}
        >
            <OptionToggle
                options={VIEW_OPTIONS}
                value={viewMode}
                onChange={handleViewModeChange}
            />

            <OptionToggle
                options={GROUP_OPTIONS}
                value={groupMode}
                onChange={handleGroupModeChange}
            />

            <div className="flex gap-2">
                <TooltipButton
                    tooltip="Expand All"
                    onClick={expandAll}
                    icon={ExpandAllIcon}
                />
                <TooltipButton
                    tooltip="Collapse All"
                    onClick={collapseAll}
                    icon={CollapseAllIcon}
                />
                <Divider />
                <TooltipButton
                    tooltip="Expand All Tiles"
                    onClick={expandAllTiles}
                    icon={Maximize2Icon}
                />
                <TooltipButton
                    tooltip="Collapse All Tiles"
                    onClick={collapseAllTiles}
                    icon={Minimize2Icon}
                />
            </div>

            <Divider />

            <WithTooltip tooltip="Switch Headers">
                <Toggle
                    aria-label="Switch Headers"
                    variant="primary"
                    onPressedChange={switchHeaders}
                >
                    <ArrowLeftRight className="h-4 w-4" />
                </Toggle>
            </WithTooltip>

            <div className="flex-1" />

            <FilterSection showSearch={false} />
        </div>
    );
};

export default ReportToolbar;