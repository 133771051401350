import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
import { AlertCircle, XCircle } from "lucide-react"
import React from "react";

interface ErrorAlertProps {
  title?: string;
  description: React.ReactElement | string | null;
}

export default function ErrorAlert({ title = "Error", description }: ErrorAlertProps = { description: "An error occurred. Please try again." }) {
  return (
    <Alert variant="destructive">
      <AlertCircle className="h-4 w-4" />
      <AlertTitle >{title}</AlertTitle>
      <AlertDescription>
        {description}
      </AlertDescription>
    </Alert>
  )
}