import useReportQueries from './use-report-queries';
import useEnrichedTestItems from './use-enrich-test-items';
import useReportStats from './use-report-stats';
import { ReportTestItem } from '../utils/entities';
import useValidationResults from '../utils/useValidationResults';
import { useMemo } from 'react';

const useLiveReportData = (reportId: string, includedTestsOnly = false) => {
  const {
    allTestItems,
    report,
    testSuites,
    isLoading: isDataLoading,
    error,
  } = useReportQueries(reportId);

  const { missingData, isValidationLoading } = useValidationResults(
    allTestItems as unknown as ReportTestItem[],
    testSuites
  );

  const enrichedTestItems = useEnrichedTestItems(
    allTestItems,
    testSuites,
    missingData,
    includedTestsOnly
  );

  const testSuitesWithInUse = useMemo(() => testSuites?.map(testSuite => ({
    ...testSuite,
    inUse: enrichedTestItems.filter(item => item.exclusionReason === 'included').some(
      testItem => testItem.testSpecification?.testSuiteId === testSuite.id
    )
  })), [testSuites, enrichedTestItems]);

  console.debug('testSuitesWithInUse', { testSuitesWithInUse: testSuitesWithInUse?.filter(testSuite => testSuite.inUse) });

  const { bookmarks, missingSegments, missingDataCount } = useReportStats(
    enrichedTestItems,
    missingData
  );

  return {
    data: enrichedTestItems, // data that could be filtered by includedTestsOnly
    totalDataCount: allTestItems?.length ?? 0,
    report,
    error,
    testSuites: testSuitesWithInUse,
    isLoading: isDataLoading || isValidationLoading,
    bookmarks,
    missingSegments,
    missingDataCount,
    enrichedTestItems,
    missingData,
  };
};

export default useLiveReportData;
