import { isEqual } from 'lodash';

/**
 * Interface representing a single registration issue, showing the default and current values
 */
interface RegistrationIssue {
  default: any; // The original default value
  current: any; // The current form value
}

/**
 * Interface for mapping field names to their registration issues
 */
interface RegistrationIssues {
  [key: string]: RegistrationIssue | string;
}

/**
 * Utility function to find registration issues in a React Hook Form setup
 * 
 * This compares the original defaultValues passed to useForm() with the current form values
 * to detect any fields that:
 * 1. Are missing from the current form state (unregistered fields)
 * 2. Have values that don't match their defaults (potentially indicating registration issues)
 *
 * @param defaultValues - The original default values passed to useForm()
 * @param currentValues - The current form values from form.getValues()
 * @returns An object mapping field names to their registration issues
 *
 * Common usage:
 * ```ts
 * const form = useForm({ defaultValues: {...} });
 * const issues = findRegistrationIssues(form.formState.defaultValues, form.getValues());
 * ```
 */
const findRegistrationIssues = (
  defaultValues: Record<string, any>, 
  currentValues: Record<string, any>
): RegistrationIssues => {
  const issues: RegistrationIssues = {};
  
  Object.keys(defaultValues).forEach((key) => {
    // Check if field exists in current form state
    if (!(key in currentValues)) {
      issues[key] = "Field not registered";
    } 
    // Check if current value matches default value using deep comparison
    else if (!isEqual(defaultValues[key], currentValues[key])) {
      issues[key] = {
        default: defaultValues[key],
        current: currentValues[key]
      };
    }
  });
  
  return issues;
};

export default findRegistrationIssues;