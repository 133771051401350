import PageWithTopBarNavigation from "@/app/features/profile/PageWithTopBarNavigation"
import { TopNavBar } from "@/app/features/profile/TopNavBar"
import { MainPage } from "@/app/layout2/AppLayout"
import { Skeleton } from "@/components/ui/skeleton"
import ReportConfigureLoadingScreen from '../components/configure-loading-skeleton'
import React from "react"

// Array of skeleton widths for nav items
const NAV_SKELETON_WIDTHS = ['w-16', 'w-20', 'w-18', 'w-16', 'w-16', 'w-32', 'w-16', 'w-28']

function ReportLoadingSkeleton() {
    return (
        <MainPage>
            <PageWithTopBarNavigation>
                <TopNavBar>
                    {NAV_SKELETON_WIDTHS.map((width, i) => (
                        <Skeleton key={i} className={`${width} h-6`} />
                    ))}
                </TopNavBar>
                <div className="container mx-auto py-6 px-4">
                    <ReportConfigureLoadingScreen title="Report Loading..." />
                </div>
            </PageWithTopBarNavigation>
        </MainPage>
    )
}

export default React.memo(ReportLoadingSkeleton)