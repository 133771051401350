import { useReportContext } from '@/app/features/reports/contexts/report-context';
import { Report } from '../types/report';

interface HeaderSectionProps {
    report: Report;
    editMode: boolean;
}

export function HeaderSection({ report, editMode }: HeaderSectionProps) {

    console.debug('report header section', report);
    const { isPublished, isEditable, revision } = useReportContext();
    console.debug('revision header section', { isPublished, revision });

    const formattedDate = isPublished ?
        new Date(revision.updated_at).toLocaleDateString('en-AU', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }) : revision?.status === 'draft' ? '(Draft)' : null;

    // const revision = report.revisions?.[0]?.version || '(Not Published)';
    const revisionText = revision?.version || '(Not Published)';

    return (
        <section className="mb-4">
            <div className="space-y-2">
                <div className="space-y-0.5">
                    <p className="text-sm text-muted-foreground">Report</p>
                    <h2 className="text-lg">{report.title}</h2>
                </div>

                {formattedDate && (
                    <div className="space-y-2">
                        <div className="flex gap-12">
                            <div className="space-y-0.5">
                                <p className="text-sm text-muted-foreground">Revision</p>
                                <p className="text-sm font-medium">{revisionText}</p>
                            </div>
                            <div className="space-y-0.5">
                                <p className="text-sm text-muted-foreground">Date</p>
                                <p className="text-sm font-medium">{formattedDate}</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </section>
    )
}
