import { DescriptionDetail, DescriptionGroup, DescriptionList, DescriptionTerm } from "@/components/ui/description-list";
import AuthorTimestamp from "@/app/features/reports/components/author-timestamp";
import { PublishedReport } from "store/src/lib/services/types";

const PublishedReportMeta = ({ report }: { report: PublishedReport }) => {

    const totalItems = report.items.length;
    //@ts-expect-error exclusionReason is not typed on ReportTestItem
    const includedItems = report.items.filter(item => item.exclusionReason === 'included').length;
    const excludedItems = totalItems - includedItems;

    return (
        <div className="p-4 bg-zinc-50 rounded-lg space-y-4">
            <h2 className="font-medium text-lg">Published Report</h2>
            <DescriptionGroup>
                <AuthorTimestamp timestamp={report.created_at} author={report.creator} label="Published by" />
            </DescriptionGroup>
            <div className="flex flex-row gap-6">
                <DescriptionList className="gap-2">

                    <DescriptionGroup>
                        <DescriptionTerm>Total Items</DescriptionTerm>
                        <DescriptionDetail>{totalItems}</DescriptionDetail>
                        <DescriptionTerm>Published Items</DescriptionTerm>
                        <DescriptionDetail>{includedItems}</DescriptionDetail>
                        <DescriptionTerm>Excluded Items</DescriptionTerm>
                        <DescriptionDetail>{excludedItems}</DescriptionDetail>
                    </DescriptionGroup>
                </DescriptionList>

                <DescriptionList>
                    <DescriptionGroup>
                        <DescriptionTerm>Report ID</DescriptionTerm>
                        <DescriptionDetail>{report?.report?.id}</DescriptionDetail>
                        <DescriptionTerm>Revision ID</DescriptionTerm>
                        <DescriptionDetail>{report?.report_revision_id}</DescriptionDetail>
                        <DescriptionTerm>Published ID</DescriptionTerm>
                        <DescriptionDetail>{report?.id}</DescriptionDetail>
                    </DescriptionGroup>
                </DescriptionList>
            </div>
        </div>
    )
}

export default PublishedReportMeta;

PublishedReportMeta.displayName = "PublishedReportMeta";