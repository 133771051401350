import { useNav } from '@/app/features/profile/NavContext'
import ReportEditModeToggle from '@/app/features/reports/components/edit-mode-toggle'
import { ReportSectionProvider, useReportSectionPage } from '@/app/features/reports/components/report-section-context'
import { SummaryHighlightsSection } from '@/app/features/reports/components/summary-highlights'
import { ReportTestItem } from '@/app/features/reports/utils/entities'
import BusyButton from '@/components/raytd/busy-button'
import ErrorState from '@/components/raytd/error-state'
import LoadingState from '@/components/raytd/loading-state'
import { SubPageLayout } from '@/components/raytd/sub-page-layout'
import { useUnsavedChanges } from '@/hooks/useUnsavedChanges'
import { useGetReportItemsQuery, useGetReportQuery } from '@app.raytd.com/store'
import { PlateController } from '@udecode/plate-common/react'
import { Suspense, useCallback, useEffect, useState } from 'react'
import { toast } from 'sonner'
import { ManagedCustomSectionContainer } from './components/custom-section-container'
import { FilterSection } from "./components/filter-section"
import { TestDistributionSection } from "./components/test-distribution-section"
import { ProcessedTestData, processTestData } from './utils/processTestData'
import useReportItemFilters from './utils/useReportItemsFilter'
import { ReportProviders } from '@/app/features/reports/components/report-viewer-context'

interface SummaryEditorProps {
    reportId: number;
    initialData?: ReportTestItem[];
}

function SummaryEditor({ reportId, initialData = [] }: SummaryEditorProps) {
    const { sectionManager } = useReportSectionPage();
    const { sections, saveSections, isBusy, isDirty } = sectionManager;
    const { data: report, error: reportError, isLoading: reportLoading } = useGetReportQuery(reportId.toString());

    const reset = useCallback(() => {
        console.log('reset');
    }, []);

    const { AlertDialogComponent } = useUnsavedChanges(isDirty, reset, false);


    const [processedData, setProcessedData] = useState<ProcessedTestData | null>(null)
    const [unfilteredProcessedData, setUnfilteredProcessedData] = useState<ProcessedTestData | null>(null);

    const { 
        selectedFilters, 
        facets,
        filteredData,
        filteredCounts,
        unfilteredCounts,
        filtersActive,
        setSelectedFilters,
        updateFilters,
        removeFilter
     } = useReportItemFilters(initialData, report);


    const { setNavButtons } = useNav();

    const onSubmit = useCallback(async () => {
        console.log('submit', sections);
        try {
            await saveSections();
            toast.success('Page content saved');
        } catch (error) {
            console.error('Error saving sections:', error);
            toast.error('Error saving page content');
        }

    }, [sections]);

    useEffect(() => {
        setNavButtons(
            <BusyButton
                onClick={onSubmit}
                disabled={!isDirty}
                isBusy={isBusy}
                busyText="Saving..."
            >
                Save
            </BusyButton>
        );

        // Clean up function to reset buttons when component unmounts
        return () => setNavButtons(null);
    }, [onSubmit, isDirty, isBusy]);

  
    

    useEffect(() => {
        const unfilteredProcessed = processTestData(initialData as unknown as ReportTestItem[]);
        setUnfilteredProcessedData(unfilteredProcessed);

        const filteredProcessed = processTestData(filteredData);
        console.log('filteredProcessed', filteredProcessed);
        setProcessedData(filteredProcessed);

    }, [initialData, filteredData]);

    console.log('render');

    return (
        <SubPageLayout title="Summary">

            <SubPageLayout.Header>
               <ReportEditModeToggle />
            </SubPageLayout.Header>

            <FilterSection
                showSearch={false}
            />

            <ManagedCustomSectionContainer
                position="summary-after-filter"
            />

            <SummaryHighlightsSection
                filteredData={processedData}
                unfilteredData={unfilteredProcessedData}
                filtersActive={filtersActive}
            />

            {/* <OverallResultSection
                data={processedData}
                filtersActive={filtersActive}
            /> */}

            <TestDistributionSection
                filteredData={processedData}
                unfilteredData={unfilteredProcessedData}
                filtersActive={filtersActive}
            />

            <ManagedCustomSectionContainer
                position="summary-after-test-distribution"
            />

            <AlertDialogComponent />

        </SubPageLayout>
    )
}

export default function SummaryPage({ reportId }: { reportId: string }) {

    const assessmentId = 79;

    const { data: testItems, error, isLoading } = useGetReportItemsQuery(reportId);

    if (isLoading) {
        return <LoadingState />
    }

    if (error) {
        return <ErrorState />
    }

    return (
        <ReportProviders reportId={reportId} initialEditMode={false}>
        <ReportSectionProvider
            page="summary"
        >
            <Suspense fallback={<LoadingState />}>
                <PlateController>
                    <SummaryEditor
                        reportId={parseInt(reportId)}
                        initialData={testItems  as unknown as ReportTestItem[]}
                    />
                </PlateController>
            </Suspense>
        </ReportSectionProvider>
        </ReportProviders>

    )
}