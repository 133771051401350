import { TestFormValues } from '@/app/features/reports/types/test-form';

const convertFormToTestItem = (testItem: TestFormValues) => {
  
  return {
    assessment_id: testItem.assessmentId,
    test_specification_id: testItem.test_specification_id,
    assetId: testItem.assetId,
    building_id: testItem.building_id,
    level_id: testItem.level_id,
    area: testItem.area_id,
    status: testItem.status,
    result: testItem.result,
    observations: testItem.observations,
    recommendations: testItem.recommendations,
    // formId: ,
    custom: testItem.custom,
    element_id: testItem.element_id,
    element_name: testItem.element_name,
    element_classification_id: testItem.element,
    images: testItem.images,
  };
};

export default convertFormToTestItem;
