import React, { useCallback , useState } from 'react';
import { useReportViewContext } from '../report-viewer-context';
import { useToggleTestSwitchMutation } from '@app.raytd.com/store';
import { ReportTestItem } from '@/app/features/reports/utils/entities';

type ToggleSwitchName = 'include' | 'fast_fill_accepted';

type ToggleContextType = {
    toggleSwitch: (switchType: ToggleSwitchName, item: ReportTestItem) => Promise<void>;
    isLoading: boolean;
};

const ToggleContext = React.createContext<ToggleContextType | undefined>(undefined);

export const useToggleProvider = () => {
    const context = React.useContext(ToggleContext);
    if (!context) {
        throw new Error('useToggle must be used within a ToggleProvider');
    }
    return context;
};

// ToggleProvider component
export const ToggleProvider: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const { report } = useReportViewContext();
    const [toggleSwitchMutation, { isLoading }] = useToggleTestSwitchMutation();
    
    const handleToggle = useCallback(async (switchType: ToggleSwitchName, item: ReportTestItem) => {

        try {
            await toggleSwitchMutation({
                reportId: report.id,
                revisionId: item.revision ? item.revision.report_revision_id.toString() : report?.revisions[0]?.id?.toString(),
                testItemId: item.assessmentTestId.toString(),
                switchName: switchType as ToggleSwitchName
            }).unwrap();
        } catch (error) {
            console.error('Failed to toggle switch:', error);
            throw error;
        }
    }, [toggleSwitchMutation, report?.id, report?.revisions]);

    const value = {
        toggleSwitch: handleToggle,
        isLoading,
    };

    return (
        <ToggleContext.Provider value={value}>
            {children}
        </ToggleContext.Provider>
    );
};
