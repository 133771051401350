import SubPageLayout from '@/app/features/organisation/SubPageLayout';
import { useTestEditor } from '@/app/features/reports/hooks/useTestEditor';
import { TestStatus } from '@/app/features/reports/types/test-form';
import VirtualizedNestedList from '@/app/features/reports/VirtualisedItemList';
import { useAppLayoutContext } from '@/app/layout2/AppLayout';
import { withLazyLoad } from '@/app/utils/withLazyLoad';
import EditModeToggle from '@/components/raytd/edit-mode-toggle';
import PresenceIndicator from '@/components/raytd/presence-indicator';
import { Button } from '@/components/ui/button';
import { useReportPresence } from '@/hooks/use-presence';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'sonner';
import BookmarkScrollbar from './components/bookmarks';
import ReportToolbar from './components/report-editing-toolbar';
import ReportFilterBar from './components/report-filter-bar';
import { ReportProviders, ReportViewerContext, useReportViewContext } from './components/report-viewer-context';
import StickyHeader from './components/sticky-header';
import { ToggleProvider } from './components/test-tile/toggle-context';
import { ReportViewModes, ScrollPosition } from './types';
import { useReportContext } from '@/app/features/reports/contexts/report-context';
import { useUpdateReportTestItemMutation } from '@app.raytd.com/store';

type ReportViewerProps = {
    reportId: string;
    initialEditMode?: boolean;
    readOnly?: boolean;
}

const ReportViewer: React.FC<ReportViewerProps> = ({ reportId, readOnly = false }) => {

    const [viewMode, setViewMode] = useState<ReportViewModes>('details');
    const {isPublished, permissions} = useReportContext();

    const LazyTestEditDialog = useMemo(
        () => withLazyLoad(
            () => import('./components/test-edit-dialog'),
            { condition: !readOnly }
        ),
        [readOnly]
    );

    const [showFastQA, setShowFastQA] = useState(false);
    const [showMissingData, setShowMissingData] = useState(false);

    const [isScrolling, setIsScrolling] = useState(false);
    const { mainPageElement } = useAppLayoutContext();

    const memoizedScrollParent = useMemo(() => mainPageElement, [mainPageElement]);

    const {
        data,
        reportTotal,
        filteredData,
        testSuites,
        visibleTestCount,
        report,
        filters,
        missingDataCount,
        missingSegments,
        bookmarks,
        isEditMode: editMode,
        setEditMode,
    } = useReportViewContext();


    // const { data: testSuites, isLoading: isLoadingSpecifications } = useGetReportTestSuitesQuery(report?.id ? Number(report?.id) : skipToken);
    const inspections = report?.assessments || []
    const assets = report?.assets || []

    const {
        isOpen,
        testItem,
        isLoading,
        openEditor,
        closeEditor
    } = useTestEditor({
        onEditorClose: () => {
            //toast.success('Test closed');
        }
    });

    const [scrollPosition, setScrollPosition] = useState<ScrollPosition>({
        range: {
            startIndex: 0,
            endIndex: 0
        },
        relativePositions: {
            startIndex: 0,
            endIndex: 0
        }
    });

    const handleScrollRangeChange = useCallback((position) => {
        setScrollPosition(position);
    }, [data]);

    const listRef = useRef<any>(null);

    const handleBookmarkClick = useCallback((bookmark) => {
        console.debug('scroll to', bookmark, listRef)
        listRef.current?.scrollToIndex(bookmark.index, 'start');
    }, []);

    const scrollToTest = useCallback((id: number) => {
        listRef.current?.scrollToTest(id);
    }, []);

    const updateTestStatus = useCallback(async (id: string, status: TestStatus) => {

        toast.success(`Updating test ${id} to status ${status}`);
    }, []);

    const handleFilterChange = useCallback((value: string) => {
        filters?.setFilterInput(value);
    }, [filters?.setFilterInput]);

    const [isUpdatingTestId, setUpdatingTestId] = useState<number | null>(null);

    const setUpdatingTest = useCallback((id: number) => {
        setUpdatingTestId(id);
    }, []);

    const clearUpdatingTest = useCallback(() => {
        setUpdatingTestId(null);
    }, []);

    useEffect(() => {
        //add the fast fill filter
        filters?.setSelectedFilters(prev => ({
            ...prev,
            excluded: showFastQA ? ['unmatchedSegments'] : []
        }));
    }, [filters?.setSelectedFilters, showFastQA]);

    const { updateStatus } = useReportPresence(reportId);

    useEffect(() => {
        updateStatus('viewing');
        
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                updateStatus('viewing');
            }
        };

        window.addEventListener('visibilitychange', handleVisibilityChange);
        
        return () => {
            window.removeEventListener('visibilitychange', handleVisibilityChange);
            updateStatus('left');
        };
    }, [reportId, updateStatus]);

    const handleEditStart = () => {
        updateStatus('editing');
    };

    // console.debug('permissions', permissions);

    return (
        (<SubPageLayout
            title="Results"
            rightComponents={
                <>
                    {!readOnly && <EditModeToggle editMode={editMode} setEditMode={setEditMode} />}
                    {!readOnly && <Button variant='default' onClick={() => openEditor(null)}>New Test</Button>}
                </>
            }
        >
            <ReportViewerContext.Provider
                value={{
                    reportId: report?.id ? Number(report?.id) : null,
                    activeItems: [], setActiveItems: () => { },
                    showFastFillQA: showFastQA,
                    showTestEditor: openEditor,
                    showMissingData: showMissingData,
                    setUpdatingTest: setUpdatingTest,
                    clearUpdatingTest: clearUpdatingTest,
                    isUpdatingTest: isUpdatingTestId !== null,
                    updatingTestId: isUpdatingTestId,
                    scrollToTest: scrollToTest,
                }}>
                <ToggleProvider>
                    <StickyHeader>
                        <ReportToolbar
                            setViewMode={setViewMode}
                            filters={filters}
                        />
                        <ReportFilterBar
                            updateTestStatus={updateTestStatus}
                            handleFilterChange={handleFilterChange}
                            showFastQA={showFastQA}
                            setShowFastQA={setShowFastQA}
                            showMissingData={showMissingData}
                            setShowMissingData={setShowMissingData}
                            missingSegments={missingSegments}
                            missingDataCount={missingDataCount}
                            scrollToTest={scrollToTest}
                        />
                    </StickyHeader>
                    <div className="flex flex-row items-center justify-between gap-4 mb-2">
                        <div>
                        {visibleTestCount} / {reportTotal}

                        </div>
                        <div className="flex-row justify-end">
                            <PresenceIndicator reportId={reportId} />
                        </div>
                    </div>

                    <div className="border-t border-zinc-900 h-full"
                    // style={{ height: 'calc(100vh - 440px)' }}
                    >
                        <VirtualizedNestedList
                            ref={listRef}
                            scrollParent={memoizedScrollParent}
                            setIsScrolling={setIsScrolling}
                            setScrollPosition={handleScrollRangeChange}
                        />

                    </div>
                    {!readOnly && (
                        <BookmarkScrollbar bookmarks={bookmarks} onBookmarkClick={handleBookmarkClick} scrollPosition={scrollPosition} />
                    )}
                </ToggleProvider>
                <LazyTestEditDialog
                    isOpen={isOpen}
                    onClose={closeEditor}
                    testItem={testItem}
                    isLoading={isLoading}
                    onSubmit={(data) => {
                        console.debug('testEditDialog onSubmit', data);
                        setUpdatingTest(data.assessmentTestId);
                    }}
                    inspections={inspections}
                    assets={assets}
                    testSuites={testSuites as any}
                />
            </ReportViewerContext.Provider >

        </SubPageLayout >)
    );
};

const App: React.FC<ReportViewerProps> = ({ reportId, initialEditMode: initialEditMode = true }) => {

    return (
        <ReportProviders reportId={reportId} initialEditMode={initialEditMode}>
            <ReportViewer reportId={reportId} readOnly={!initialEditMode} />
        </ReportProviders>
    );
}

export default App;