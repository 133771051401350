
import FullScreenAlert from "@/components/raytd/full-screen-alert";
import { AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import React from "react";
import { Link } from "react-router-dom";

const ReportErrorAlert = React.memo(({ message, children }: { message: string, children?: React.ReactNode }) => {
    return (
        <FullScreenAlert buttons={
            <>
                <Button variant="default">
                    <Link to="/login">
                        Login
                    </Link>
                </Button>
                <Button variant="outlinelight">
                    <a href="https://www.raytd.com">
                        Go to Raytd
                    </a>
                </Button>
            </>
        }>
            <AlertTitle className="text-lg font-medium mb-6">
                {message}
            </AlertTitle>
            {children && (
                <AlertDescription>
                    {children}
                </AlertDescription>
            )}
        </FullScreenAlert>
    )
});

export default ReportErrorAlert;