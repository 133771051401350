import { Alert } from "@/components/ui/alert";

interface FullScreenAlertProps {
    children: React.ReactNode;
    buttons: React.ReactNode;
    border?: boolean;
}

const FullScreenAlert = ({ children, buttons, border = false }: FullScreenAlertProps) => {

    return (
        <div className="flex justify-center items-center h-full bg-white">
            <div className="w-1/2 space-y-6">
                <Alert variant={border ? "default" : "borderless"}>
                    {children}
                </Alert>
                <div className="flex flex-row space-x-4 px-4">
                    {buttons}
                </div>
            </div>
        </div>
    )
}

export default FullScreenAlert;