import { Button } from '@/components/ui/button'
import { ReportBlockCombobox } from '../editor/plugins/report-block-combo';
import { useState } from 'react';
import { ReportBlockIcon } from '@/components/raytd/icons';
import { ToolbarButton } from '@/components/plate-ui/toolbar';


export function ReportBlockToolbarButton() {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <ToolbarButton
                pressed={isOpen}
                onClick={() => setIsOpen(true)}
                tooltip="Insert Report Block"
            >
                <ReportBlockIcon />
            </ToolbarButton>

            <ReportBlockCombobox
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
            />
        </>
    );
}