// src/hooks/useReportPresence.ts
import { useEffect, useRef } from 'react';

import {
  userJoined,
  userUpdated,
  userLeft,
  useAppDispatch,
  api,
} from '@app.raytd.com/store';

import { getEcho } from '@/lib/pusher';
import { apiURL } from '@app.raytd.com/store';
import { debounce } from 'lodash';
// export const useReportPresence = (reportId: string) => {
//     const dispatch = useDispatch();

//     useEffect(() => {
//         const channel = echo.join(`presence-report.${reportId}`)
//             .here((users: Array<any>) => {
//                 users.forEach(user => dispatch(userJoined(user)));
//             })
//             .joining((user: any) => {
//                 dispatch(userJoined(user));
//             })
//             .leaving((user: any) => {
//                 dispatch(userLeft(user.id));
//             })
//             .listen('ReportPresenceUpdated', ({ user }: any) => {
//                 dispatch(userUpdated({
//                     id: user.id,
//                     status: user.status
//                 }));
//             });

//         return () => {
//            channel.unsubscribe(); // Unsubscribe completely removes the subscription and cleans up event handlers
//            // channel.stopListeningToAll() only removes event handlers but keeps the subscription active
//         };
//     }, [reportId, dispatch]);

//     const updateStatus = async (status: 'viewing' | 'editing') => {
//         await fetch(apiURL(`/api/reports/${reportId}/presence`), {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//                 Authorization: `Bearer ${localStorage.getItem('auth_token')}`
//             },
//             body: JSON.stringify({ status })
//         });
//     };

//     return { updateStatus };
// };
// src/hooks/useReportPresence.ts

export const useReportPresence = (reportId: string) => {
  const dispatch = useAppDispatch();
  const currentStatus = useRef<'viewing' | 'editing' | 'left' | null>(null);
  const channel = useRef<any>(null);

  // Debounced status update to prevent rapid fire API calls
  const debouncedUpdateStatus = useRef(
    debounce(async (status: 'viewing' | 'editing' | 'left') => {
      if (currentStatus.current === status) return; // Skip if status hasn't changed
      currentStatus.current = status;

      await api.post(apiURL(`/api/v1/reports/${reportId}/presence`), {
        status,
      });
    }, 500)
  ).current;

  useEffect(() => {
    const echo = getEcho();
    if (!echo) return;

    // Only join if we haven't already
    if (!channel.current) {
      channel.current = echo
        .join(`presence-report.${reportId}`)
        .here((users) => {
          users.forEach((user) =>
            dispatch(
              userJoined({
                ...user,
                type: 'report',
                typeId: reportId,
              })
            )
          );
        })
        .joining((user) => {
          dispatch(
            userJoined({
              ...user,
              type: 'report',
              typeId: reportId,
            })
          );
        })
        .leaving((user) => {
          dispatch(userLeft(user.id));
        });

      // Set initial status
      debouncedUpdateStatus('viewing');
    }

    return () => {
      if (channel.current) {
        debouncedUpdateStatus.cancel(); // Cancel any pending updates
        api
          .post(apiURL(`/api/v1/reports/${reportId}/presence`), {
            status: 'left',
          })
          .catch((err) =>
            console.error('Failed to update presence status on leave:', err)
          );

        const echo = getEcho();
        if (echo) {
          echo.leave(`presence-report.${reportId}`);
        }

        channel.current = null;
      }
    };
  }, [reportId, dispatch]);

  const updateStatus = (status: 'viewing' | 'editing' | 'left') => {
    debouncedUpdateStatus(status);
  };

  return { updateStatus };
};
