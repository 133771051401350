import { MissingPlaceHolder } from '@/app/features/reports/components/test-tile/missing-value';
import { Pill } from '@/components/raytd/pill';
import { RatingScore } from '@/components/raytd/rating-tool';
import { cn } from '@/lib/utils';
import React, { memo, useCallback } from 'react';
import { RatingTypes } from '../types';
import { ElementPath } from '../types/report';
import { ReportTestItem, TestSpecification } from '../utils/entities';
import { useReportDisplayContext, useReportViewContext } from './report-viewer-context';
import { useTestTileContext } from './test-tile/context';
import HighlightText from '@/app/features/reports/components/test-tile/highlight-text';

const HeaderTile: React.FC<{
    title: string;
    subtitle: string;
    className?: string;
    isMissing?: boolean;
}> = ({ title, subtitle, className, isMissing }) => {

    if (isMissing) {
        return (
            <div className={cn(className, "space-y-0.5")}>
                <MissingPlaceHolder />
                <MissingPlaceHolder />
            </div>
        )
    }

    return (
        <div className={cn(className, "")}>
            <div className="text-xs text-zinc-600"><HighlightText>{subtitle}</HighlightText></div>
            <div className="text-zinc-900 text-base font-medium"><HighlightText>{title}</HighlightText></div>
        </div>
    )
}

interface HeaderSlotProps {
    left?: React.ReactNode;
    right?: React.ReactNode;
    bottom?: React.ReactNode;
    ratingTool?: React.ReactNode;
}

const HeaderLayout: React.FC<HeaderSlotProps> = ({ left, right, bottom, ratingTool }) => {
    const { headerMode } = useReportDisplayContext();

    const slot1 = headerMode === 'normal' ? left : right;
    const slot2 = headerMode === 'normal' ? right : left;

    return (
        <div className="min-h-[60px] flex-shrink flex flex-col px-4 h-auto mb-2">
            <div className="flex-1 flex w-full gap-4 justify-start flex-col md:flex-row">

                <div className="flex-1">{slot1}</div>

                <div className="flex-1 flex justify-between items-start">
                    <div className="flex-1 flex flex-col md:flex-row items-start justify-start gap-4">
                        {slot2}
                        <div className="w-[180px] md:w-[180px] flex flex-shrink-0 items-start justify-start md:justify-end">
                            {ratingTool}
                        </div>
                    </div>
                </div>
            </div>
            {bottom && (
                <div className="mt-2">{bottom}</div>
            )}
        </div>
    );
};

interface TestItemHeaderProps {
    item: ReportTestItem;
}

interface LocationInfoProps {
    areaDescription: string;
    assetName: string;
    buildingDescription: string;
    levelDescription: string;
}

export const LocationInfo = memo((
    {
        areaDescription,
        assetName,
        buildingDescription,
        levelDescription
    }: LocationInfoProps
) => {

    const { missingData, showMissingData } = useTestTileContext();

    const missing = !!(missingData['building_id'] || missingData['level_id'] || missingData['area_id']);

    return (
        (<div className="flex-1">
            <HeaderTile
                title={areaDescription ?? '(No Location)'}
                subtitle={[assetName, buildingDescription, levelDescription].filter(Boolean).join(', ')}
                isMissing={missing && showMissingData}
            />
        </div>)
    );
});


interface ElementInfoProps {
    elementPath: ElementPath[];
}

export const ElementInfo: React.FC<ElementInfoProps> = memo((
    {
        elementPath,
    }
) => {

    const { missingData, showMissingData } = useTestTileContext();

    if (!elementPath) {
        return null;
    }

    const title = elementPath?.[elementPath.length - 1]?.name ?? '(No Element)';

    //subtitle is the rest of the elementPath
    //remove the last element from the elementPath
    const restOfPath = elementPath?.slice(0, -1);

    const subtitle = restOfPath
        ?.map((element) => element.name)
        ?.join(', ') ?? '';

    const isMissing = !!(missingData?.['element']);

    return (
        (<div className="flex-1">
            <HeaderTile
                title={title}
                subtitle={subtitle}
                isMissing={isMissing && showMissingData}
            />
        </div>)
    );
});

interface TestInfoProps {
    testSpecification: TestSpecification;
}

export const TestInfo = memo((
    {
        testSpecification
    }: TestInfoProps
) => {

    if (!testSpecification) {
        return null;
    }

    const title = testSpecification?.type === 'condition' ? 'Condition Test'
        : testSpecification?.type === 'generic' ? 'General Test'
            : testSpecification?.label;

    return (
        (<HeaderTile
            className="flex-grow"
            title={title ?? '(No Test)'}
            subtitle={testSpecification?.type?.includes('compliance') ? [testSpecification?.test_suite_title, testSpecification?.category, testSpecification?.subcategory].join(', ') : testSpecification?.test_suite_title}
        />)
    );
});

interface ElementPillsProps {
    elementId?: string;
    elementName?: string;
}

export const ElementPills = memo(({
    elementId,
    elementName
}: ElementPillsProps) => (
    <div className="flex flex-shrink flex-row gap-4 items-end">
        {elementId && (
            <Pill variant="disabled" size="sm" className="h-6">
                <HighlightText>{elementId}</HighlightText>
            </Pill>
        )}
        {elementName && (
            <Pill variant="disabled" size="sm" className="h-6">
                <HighlightText>{elementName}</HighlightText>
            </Pill>
        )}
    </div>
));

export const TestItemHeader = memo(({ item }: TestItemHeaderProps) => {

    const { groupMode } = useReportViewContext();
    const { missingData, showMissingData } = useTestTileContext();

    if (!item) {
        return null;
    }

    const elementPath = item?.element?.path || [];

    return (
        <HeaderLayout
            left={
                groupMode === 'location' ? (
                    <ElementInfo elementPath={elementPath} />
                ) : (
                    <LocationInfo
                        areaDescription={item?.area_description}
                        assetName={item?.asset_name}
                        buildingDescription={item?.building_description}
                        levelDescription={item?.level_description}
                    />
                )
            }
            right={
                groupMode == 'location' || groupMode === 'element' ? (
                    <TestInfo
                        testSpecification={item?.testSpecification}
                    />
                ) : (
                    <ElementInfo elementPath={elementPath} />
                )
            }
            bottom={
                <ElementPills
                    elementId={item?.element_id}
                    elementName={item?.element_name}
                />
            }
            ratingTool={
                <RatingScore
                    type={item?.ratingType as RatingTypes}
                    width={210}
                    height={45}
                    score={item?.score}
                    showSelector={true}
                    isMissing={!!missingData?.['result'] && showMissingData}
                />
            }
        />
    );
});

// Add display names for better debugging
LocationInfo.displayName = 'LocationInfo';
TestInfo.displayName = 'TestInfo';
ElementPills.displayName = 'ElementPills';
TestItemHeader.displayName = 'TestItemHeader';

export default TestItemHeader;