import { useTestFormContext } from '@/app/features/reports/components/test-form/context'

import React, { useMemo } from "react";
import { useFormContext } from "react-hook-form";

interface ValidationContextType {
    field_id: string;
    isValid: boolean;
    isDirty: boolean;
    isRequired: boolean;
    isTouched: boolean;
    fieldError: string | null;
    fieldValue: any;
    showMessage: boolean;
    isVisible: boolean;
}

const ValidationContext = React.createContext<ValidationContextType | null>(null);

export const useValidation = () => {
    const context = React.useContext(ValidationContext);
    if (!context) {
        throw new Error('useValidation must be used within a ValidationProvider');
    }
    return context;
};


interface ValidationProviderProps {
    field_id: string;
    children: React.ReactNode;
}

export const ValidationProvider = ({ field_id: baseFieldId, children }: ValidationProviderProps) => {
    const { watch, formState: { errors, touchedFields, dirtyFields } } = useFormContext();
    const { getFieldVisibility, getFieldRequirement } = useTestFormContext();

    const field_id = baseFieldId === 'location' ? 'building_id' :
        baseFieldId === 'rating' ? 'result' :
            baseFieldId === 'photographs' ? 'images' : baseFieldId;

    const isCustomField = field_id?.startsWith('custom.');
    const fieldIdWithPrefix = isCustomField ? field_id.replace('custom.', '') : field_id;

    const fieldValue = watch(field_id);
    const isVisible = getFieldVisibility(baseFieldId);
    const isRequired = getFieldRequirement(baseFieldId);

    const isEmpty = fieldValue === undefined || fieldValue === null || fieldValue === "";
    const isValid = !isEmpty && !errors[field_id];
    const isTouched = touchedFields[field_id];
    const isDirty = isCustomField ? dirtyFields['custom']?.[fieldIdWithPrefix] ?? false : dirtyFields?.[field_id] ?? false;
    const defaultMessage = `Select a ${field_id} to resolve this error`;
    const baseFieldError = errors[field_id] ? String(errors[field_id]?.message) ?? defaultMessage : null;
    const fieldError = baseFieldError ? 'Add information to resolve this error' : null;

    //const showMessage = isRequired && (!isTouched && !fieldError);
    const showMessage = (isValid && !isDirty) || !isRequired ? false : true;
    // const isDirty = !isEmpty && fieldValue !== fieldValue;

    const context = useMemo(() => ({ field_id, isValid, isVisible, isTouched, isDirty, isRequired, fieldError, fieldValue, showMessage }), [field_id, isValid, isVisible, isTouched, isDirty, isRequired, fieldError, fieldValue, showMessage]);

    return (
        <ValidationContext.Provider value={context}>
            {children}
        </ValidationContext.Provider>
    )

}