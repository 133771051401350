import { Button } from '@/components/ui/button';
import { TextEditor } from './text-editor';
import { TextSection } from '../types/report';
import { v4 as uuid } from 'uuid';
import { useCallback, useState } from 'react';
import { useReportSectionPage } from './report-section-context';
import { cn } from '@/lib/utils';
import { PlateViewer } from '@/components/editor/plate-editor';

type SectionTextProps = {
    position: string;
    className?: string;
}

export function SectionText({
    position,
    className,
}: SectionTextProps) {

    const { editMode, sectionManager, isEditorActive, setIsEditorActive } = useReportSectionPage();
    const sections = sectionManager.getSectionsByPosition(position as any);

    // Always operate on the first section (if it exists)
    const section = sections[0];

    const [initialEditMode, setInitialEditMode] = useState(false);

    const handleAddSection = useCallback(() => {
        if (!section) {
            const newSection: TextSection = {
                id: uuid(),
                content: '',
                position,
            };
            sectionManager.addSection(newSection);
            if (!initialEditMode) {
                setInitialEditMode(true);
            }
        }
    }, [section, sectionManager, position]);

    const handleUpdateSection = useCallback((content: string) => {
        if (section) {
            sectionManager.updateSectionContent(section.id, content);
        }
    }, [section, sectionManager]);

    const handleRemoveSection = () => {
        if (section) {
            sectionManager.removeSection(section.id);
        }
    };

    console.debug('SectionText', { position, section });

    if (!editMode) {
        if (!section) {
            return <div className="py-2"></div>;
        } else {
            return (
                <div className="relative h-full pb-4 border-b border-zinc-200">
                    <PlateViewer initialValue={section.content} />
                </div>
            )
        }
    }

    // If we are in edit mode, show the editor

    if (!section) {
        return (
            <div className={cn(className)}>
                <Button
                    variant="ghost"
                    onClick={handleAddSection}
                    className="bg-muted text-muted-foreground text-sm hover:bg-muted/80"
                >
                    + Text
                </Button>
            </div>
        );
    }

    // Return the editor

    return (
        <div className={cn("relative", className)}>
            {section && (
                <TextEditor
                    initialContent={section.content}
                    onSave={handleUpdateSection}
                    editMode={editMode}
                    isEditing={initialEditMode}
                    onEdit={setIsEditorActive}
                />
            )}
        </div>
    );

}
