import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import collectAllGroups from '@/app/features/reports/utils/collectAllGroups';
import { countAllGroups, countAllHeaders } from '@/app/features/reports/utils/itemCountUtils';
import { ExpandedItemsState, ReportGroupModes } from '../types';
import { useReportViewContext } from '@/app/features/reports/components/report-viewer-context';

// Context for grouping and expansion state
export interface ReportGroupingContext {
    groupMode: ReportGroupModes;
    expandedItems: Set<string>;
    setExpandedItems: React.Dispatch<React.SetStateAction<Set<string>>>;
    expandedTiles: Set<number>;
    setExpandedTiles: React.Dispatch<React.SetStateAction<Set<number>>>;
    expandAll: () => void;
    collapseAll: () => void;
    allExpanded: true | false | 'partial' | 'headers';
    allTilesExpanded: boolean;
    expandAllTiles: () => void;
    collapseAllTiles: () => void;
}

export const ReportGroupingContext = createContext<ReportGroupingContext>(null!);

export const useReportGroupingContext = () => {
    const context = React.useContext(ReportGroupingContext);
    if (!context) {
        throw new Error('useReportGroupingContext must be used within a ReportGroupingProvider');
    }
    return context;
}

interface ReportGroupingProviderProps {
    children: React.ReactNode;
}

interface ReportGroupingProviderProps {
    children: React.ReactNode;
}

export const ReportGroupingProvider: React.FC<ReportGroupingProviderProps> = ({ children }) => {

    const { data, filteredData, groupMode: currentGroupMode } = useReportViewContext();

    const [expandedItems, setExpandedItems] = useState<ExpandedItemsState>({
        element: new Set(),
        location: new Set(),
        test: new Set(),
        rating: new Set()
    });
    const [expandedTiles, setExpandedTiles] = useState<Set<number>>(new Set());
    const [groupMode, setGroupMode] = useState<ReportGroupModes>('location');
    const [allExpanded, setAllExpanded] = useState<true | false | 'partial' | 'headers'>(false);

    const calculatedTotals = useMemo(() => ({
        groups: data?.length > 0 ? countAllGroups(data) : 0,
        headers: data?.length > 0 ? countAllHeaders(data) : 0,
        visibleTests: filteredData?.length || 0
    }), [data, filteredData]);

    const collapseAll = useCallback(() => {
        if (allExpanded === true) {
            if (expandedTiles.size > 0) {
                setExpandedTiles(new Set());
            } else {
                //collapse to headers

                let allIds = new Set<string>();
                allIds = collectAllGroups(data, allIds, false);
                setExpandedItems((prev) => ({
                    ...prev,
                    [groupMode]: allIds,
                }));
            }
            return;
        }

        setExpandedItems((prev) => ({
            ...prev,
            [groupMode]: new Set(),
        }));
    }, [data, groupMode, allExpanded, expandedTiles]);

    const expandAll = useCallback(() => {

        if (allExpanded === true) {
            if (calculatedTotals.visibleTests !== expandedTiles.size) {
                setExpandedTiles(new Set(filteredData.map((item) => item.assessmentTestId)));
            } else {
                collapseAll();
            }
            return;
        }

        let allIds = new Set<string>();

        if (allExpanded === false) {
            allIds = collectAllGroups(data, allIds, false);
        } else if (allExpanded === 'headers' || allExpanded === 'partial') {
            allIds = collectAllGroups(data);
        }

        setExpandedItems((prev) => ({
            ...prev,
            [groupMode]: allIds,
        }));
    }, [data, groupMode, allExpanded, calculatedTotals.visibleTests, expandedTiles]);

    const expandAllTiles = useCallback(() => {
        if (allExpanded === false) {
            expandAll();
        }
        setExpandedTiles(new Set(filteredData.map((item) => item.assessmentTestId)));
    }, [filteredData, allExpanded, expandAll]);

    const collapseAllTiles = useCallback(() => {
        setExpandedTiles(new Set());
    }, []);

    const allTilesExpanded = filteredData?.length === expandedTiles.size;

    const setGroupExpandedItems = useCallback((value: Set<string>) => {
        setExpandedItems((prev) => ({
            ...prev,
            [groupMode]: value,
        }));
    }, [groupMode]);

    useEffect(() => {
        const totalExpandedItems = expandedItems[groupMode].size;
        const expandMode =
            totalExpandedItems === 0 ? false :
                totalExpandedItems === calculatedTotals.groups ? true :
                    totalExpandedItems === calculatedTotals.headers ? 'headers' : 'partial';
        setAllExpanded(expandMode);
        console.debug('allExpanded', { expandMode, totalExpandedItems, calculatedTotals });

    }, [expandedItems, groupMode]);

    const expandedIds = expandedItems[groupMode];

    const value = useMemo(() => ({
        groupMode,
        setGroupMode,
        expandedItems: expandedItems[groupMode],
        setExpandedItems: setGroupExpandedItems,
        expandedTiles,
        setExpandedTiles,
        expandAll,
        collapseAll,
        allExpanded,
        allTilesExpanded,
        expandAllTiles,
        collapseAllTiles
    }), [groupMode, expandedItems, expandedTiles, allExpanded, allTilesExpanded]);

    return (
        <ReportGroupingContext.Provider value={value}>
            {children}
        </ReportGroupingContext.Provider>
    );
};
