import { convertAverage } from '@/app/features/reports/ratingHelpers';
import { TestsIcon } from '@/components/raytd/icons';
import { Pill } from '@/components/raytd/pill';
import { RatingMixedScore } from '@/components/raytd/rating-tool';
import { ValuePill } from '@/components/raytd/value-pill';
import { cn } from '@/lib/utils';
import { ChevronDownIcon, ChevronRight } from 'lucide-react';
import React, { memo, useCallback, useMemo } from 'react';
import { FlattenedItem } from '../utils/entities';
import CountPill from './excluded-count-pill';

interface RowHeaderProps {
    item: FlattenedItem;
    indent: number;
    toggleExpand: (id: string) => void;
    active: boolean;
    parents?: string[];
    className?: string;
}

const ScoreDisplay = memo(({ score }: { score: string }) => (
    <div className="w-10 md:w-28 flex flex-row justify-center items-center">
        <Pill
            variant="enabled"
            size="md"
            className="md:text-sm text-xs"
        >
            {score === undefined ? '' : (
                <>
                    {`${score}`}
                    <span className="md:inline hidden">&nbsp;/&nbsp;5.00</span>
                </>
            )}
        </Pill>
    </div>
));

const ParentPath = memo(({ parents, hasContents = false }: { parents: string[], hasContents?: boolean }) => (
    <>
        {parents.map((parent, index) => (
            <React.Fragment key={index}>
                {index < parents.length - 1 ? (
                    <>
                        <span className="text-xs md:text-sm lg:text-base font-normal text-zinc-800 tracking-tight hidden md:block max-w-10 md:max-w-28 truncate">{parent}</span>
                        <ChevronRight size="12" className="text-zinc-500" />
                    </>
                ) : (
                    hasContents ? (
                        <span className="text-xs md:text-sm lg:text-base font-semibold tracking-wider">{parent}</span>
                    ) : (
                        <span className="text-xs md:text-sm lg:text-base font-normal text-zinc-600">{parent}</span>
                    )
                    // <span className="font-medium">{parent}</span>
                )}
            </React.Fragment>
        ))}
    </>
));

const ExpandableContent = memo(({
    item,
    handleExpand,
    IconType
}: {
    item: FlattenedItem;
    handleExpand: (e: React.MouseEvent) => void;
    IconType: typeof ChevronDownIcon | typeof ChevronRight;
}) => (
    <button
        onClick={handleExpand}
        className="flex flex-1 items-center hover:bg-accent/10 rounded-sm group"
    >
        <div className="flex items-center">
            <IconType
                className="h-4 w-4 md:h-6 md:w-6 text-zinc-500 ml-1 group-hover:text-zinc-700"
            // expanded={item.isExpanded} 
            />
        </div>
        <h3 className="flex-1 font-medium flex flex-row items-center gap-2 px-2">
            <ParentPath parents={item.parents || []} hasContents={item.hasContent} />
        </h3>
    </button>
));

const MetricsSection = memo(({
    item
}: {
    item: FlattenedItem
}) => {
    const convertedScore = useMemo(() =>
        convertAverage(parseFloat(item.score)),
        [item.score]
    );

    const { includedCount, score, ratingTypes } = item;

    return (
        <div className="flex gap-2 items-center">
            {/* {process.env.NODE_ENV === 'development' && (
                <div className="flex gap-1 scale-90">
                    <CountPill count={item.fastFillCount} type="fastfill" className="hidden md:flex" />
                    <CountPill count={item.missingCount} type="missing" className="hidden md:flex" />
                    <CountPill count={item.nonFinalCount} type="status" className="hidden md:flex" />
                    <CountPill count={item.excludedCount} type="excluded" className="hidden md:flex" />
                </div>
            )} */}

            <div className="w-10 flex items-start">
                <ValuePill
                    iconOnly
                    size="small"
                    label="Tests"
                    icon={<TestsIcon className="h-4 w-4 text-zinc-500" />}
                    value={includedCount}
                    className="font-semibold"
                />
            </div>
            <ScoreDisplay score={score} />
            <div className="hidden md:block">
                <RatingMixedScore
                    width={140}
                    height={36}
                    type={ratingTypes as any}
                    score={convertedScore} />
            </div>
        </div>
    );
});

const RowHeader: React.FC<RowHeaderProps> = ({
    item,
    toggleExpand,
    active,
    className,
}) => {

    const showFastQA = false;

    const handleExpand = useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        toggleExpand(item.id);
    }, [item.id, toggleExpand]);

    const IconType = useMemo(() =>
        // item.level === 0 ? BuildingIcon : 
        // item.level === 1 ? LevelIcon : 
        // SpaceIcon
        item.isExpanded ? ChevronDownIcon : ChevronRight
        , [item.isExpanded]);

    return (
        <div
            className={cn(
                "w-full border-b border-b-zinc-300",
                active && "bg-yellow-50 border-t border-zinc-700",
                !active && "bg-white/5",

                className
            )}
        >
            <div className={cn(
                "flex items-center px-0 h-[42px]",
                item.hasContent && 'bg-zinc-200/75 border-t border-zinc-700',
            )}>
                {(item.hasChildren || item.content) ? (
                    <ExpandableContent
                        item={item}
                        handleExpand={handleExpand}
                        IconType={IconType}
                    />
                ) : (
                    <>
                        <span className="w-[28px]" />
                        <h3 className="flex-1 font-medium flex flex-row items-center gap-2">
                            <ParentPath parents={item.parents || []} hasContents={false} />
                        </h3>
                    </>
                )}

                {showFastQA && item.fastFillCount > 0 && (<div>{item.fastFillCount}</div>)}

                <MetricsSection
                    item={item}
                />
            </div>
        </div>
    );
};

export default memo(RowHeader);
