import { Address } from "@app.raytd.com/store";

interface AddressProps {
  address: Address | null; // Allow address to be null
  separator?: string;
  excludeCountry?: boolean;
  className?: string;
  emptyText?: string;
  twoLines?: boolean;
}

const AddressLine: React.FC<AddressProps> = ({
  address,
  separator = ', ',
  excludeCountry = false,
  className = '',
  emptyText = 'No address available',
  twoLines = false,
}) => {
  if (!address) {
    return <span className={`address-line ${className}`.trim()} title={emptyText}>{emptyText}</span>;
  }

  const addressParts = [
    address.street ?? '',
    address.suburb ?? address.city ?? '',
    address.state ?? '',
    address.postcode ?? '',
    excludeCountry ? '' : address.country ?? '',
  ].filter(Boolean); // Remove any undefined or empty strings

  // Remove duplicate entries (e.g., if suburb and city are the same)
  const uniqueAddressParts = Array.from(new Set(addressParts));

  if (!twoLines) {
  const formattedAddress = uniqueAddressParts.join(separator);

  return (
    <span className={`text-sm ${className}`.trim()} title={formattedAddress}>
      {formattedAddress || emptyText}
    </span>
  );

}

  return (
    <div className={`text-sm ${className}`.trim()}>
      <div>{uniqueAddressParts[0]}</div>
      <div>{uniqueAddressParts.splice(1).join(separator)}</div>
    </div>
  );
};

export default AddressLine;