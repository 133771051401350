import { useState, useCallback } from "react"
import { Button } from "@/components/ui/button"
import { Loader2, Share } from "lucide-react"
import { ShareReportFormValues } from "../../types/share-report"
import { ShareReportModal } from "./share-report-modal"
import { useShareReportMutation } from "@app.raytd.com/store"
import { toast } from "sonner"

interface ShareButtonProps {
  revisionId: string
  variant?: "default" | "outline" | "secondary" | "ghost" | "link" | "destructive"
  size?: "default" | "sm" | "lg" | "icon"
  className?: string
}

export function ShareButton({
  revisionId,
  variant = "default",
  size = "default",
  className,
}: ShareButtonProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [shareReport, { isLoading: isSharing }] = useShareReportMutation();

  //rtk query here;

  const handleShare = useCallback(async (data: ShareReportFormValues) => {
    // This would typically be an API call to your backend
    console.log("Sharing report", revisionId, "with", data)

    try {
      await shareReport({ revisionId, data }).unwrap();
      toast.success("Report shared successfully");
      
    } catch (error) {
      toast.error("Failed to share report");
      throw error;
    }
    
  }, [revisionId, shareReport]);

  return (
    <>
      <Button variant={variant} size={size} className={className}
        onClick={() => setIsModalOpen(true)}
        disabled={isSharing}
      >
        {isSharing ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : <Share className="mr-2 h-4 w-4" />}
        Share
      </Button>

      <ShareReportModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleShare}
      />
    </>
  )
}

