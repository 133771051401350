import { useReportViewContext } from "@/app/features/reports/components/report-viewer-context";

interface EditModeComponentProps {
    children: React.ReactNode;
}

const EditModeComponent = ({ children }: EditModeComponentProps) => {
    const { isEditMode } = useReportViewContext();

    console.debug('EditModeComponent', { isEditMode });

    if (!isEditMode) {
        return null;
    }

    return children;
}

export default EditModeComponent;