import { BuildingNode, LevelNode } from "@/app/features/reports/types/spaces"
import { Building, Floor, useAddAssetAreaMutation, useAddAssetLevelMutation, useAddBuildingMutation } from '@app.raytd.com/store'
import { useCallback } from "react"

interface UseSpaceCreationProps {
  asset_id: string | number
}

export function useSpaceCreation({ asset_id }: UseSpaceCreationProps) {
  const [createBuilding] = useAddBuildingMutation()
  const [createLevel] = useAddAssetLevelMutation()
  const [createArea] = useAddAssetAreaMutation()

  const handleCreateBuilding = useCallback(async (asset: undefined, building: string): Promise<BuildingNode> => {
    console.debug('Creating building', { building })
    const result = await createBuilding({
      description: building,
      asset_id: asset_id
    })
    console.debug('Building created', result)

    return {
      id: result.data.id,
      title: result.data.description,
      building_id: result.data.id,
      children: undefined,
      original: undefined
    }
  }, [createBuilding, asset_id])

  const handleCreateLevel = useCallback(async (building: Building, level: string): Promise<LevelNode> => {
    console.debug('Creating level', { building, level })

    const result = await createLevel({
      description: level,
      asset_id: asset_id,
      building_id: building.id,
    })

    console.debug('Level created', result)

    return {
      id: result.data.id,
      title: result.data.description,
      building_id: result.data.building_id,
      level_id: result.data.id,
      children: undefined,
      original: undefined
    }
  }, [createLevel, asset_id])

  const handleCreateArea = useCallback(async (path: Floor, area: string) => {
    console.debug('Creating area', { path, area })
    const result = await createArea({
      description: area,
      asset_id: asset_id,
      building_id: path.building_id,
      level_id: path.id,
    })
    console.debug('Area created', result)

    return {
      id: result.data.id,
      area_id: result.data.id,
      title: result.data.description,
      building_id: path.building_id,
      level_id: result.data.level_id,
      children: undefined,
      original: undefined
    }
  }, [createArea, asset_id])

  return {
    handleCreateBuilding,
    handleCreateLevel,
    handleCreateArea
  }
}