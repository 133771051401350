import { PlateElement, PlateElementProps } from '@udecode/plate/react'

export function ReportBlockElement({
  className,
  children,
  ...props
}: PlateElementProps) {
  const element = props.element as unknown as { reportData: any }
  
  return (
    <PlateElement asChild className={className} {...props}>
      <div className="report-block">
        <h3>{element.reportData.title}</h3>
        {children}
      </div>
    </PlateElement>
  )
}