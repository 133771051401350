"use client"

import * as React from "react"
import { Check, ChevronsUpDown, Loader2, PlusIcon, X } from 'lucide-react'
import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { FormLabelWithIndicator } from './form-label-with-indicator'
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form"
import { Badge } from "@/components/ui/badge"
import { ScrollArea } from "@/components/ui/scroll-area"
import { Path, PathValue, type Control } from "react-hook-form"
import { useCallback, useMemo } from "react"
import { useTestFormContext } from '@/app/features/reports/components/test-form/context'
import { toast } from "sonner"

interface Option {
  value: string;
  label: string;
}

interface SelectFieldProps<T> {
  control: Control<T>;
  name: Path<T>;
  label: string;
  options: Option[];
  required?: boolean;
  placeholder?: string;
  info?: string;
  disabled?: boolean;
  labelComponent?: React.ReactNode;
  description?: string;
  className?: string;
  isValid?: boolean;
  multiple?: boolean;
  allowAdd?: boolean;
  onCreateOption?: (value: string) => Promise<{ success: boolean, field_id: string, data: any }>;
  onSelect?: (value: any) => void;
}

export function SelectField<T>({
  control,
  name,
  label,
  options,
  required = false,
  placeholder = "Select...",
  disabled = false,
  info,
  labelComponent,
  description,
  className,
  isValid = true,
  multiple = false,
  allowAdd = true,
  onCreateOption,
  onSelect,
}: SelectFieldProps<T>) {

  const [inputValue, setInputValue] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [isCreating, setIsCreating] = React.useState(false);

  const { viewportRef } = useTestFormContext();

  const handleSelect = useCallback((value: any) => {
    console.debug('handleSelect', value);
    onSelect?.(value);
    setIsCreating(false);
  }, [onSelect]);

  const handleOpenChange = useCallback((open: boolean) => {
    if (!open) {
      setInputValue("");
    }
    setOpen(open);
  }, [setInputValue, setOpen]);

  const handleCreate = useCallback(async (fieldValue: any, inputValue: string) => {
    try {
      setIsCreating(true);
      const result = await onCreateOption(inputValue);
      toast.success('Custom option created');
      console.debug('handleCreate result', result);

      if (result.success) {
        if (multiple) {
          const currentValue = Array.isArray(fieldValue) && fieldValue.length > 0 ? fieldValue : [];
          const newValue = [...currentValue, result.field_id];
          setTimeout(() => handleSelect(newValue), 20); // delay to allow the field to update
        } else {
          setTimeout(() => handleSelect(result.field_id), 20); // delay to allow the field to update
          setOpen(false);
        }
      }
      setInputValue("");
    } catch (error) {
      console.error('handleCreate error', error);
      toast.error('Error creating custom option');
      setIsCreating(false);
    }
  }, [onCreateOption, multiple, onSelect]);

  const renderSelectedBadges = useCallback((field: any, options: Option[]) => {
    return field.value.map((value: string) => (
      <Badge
        key={value}
        variant="secondary"
        className="p-2 pr-1"
      >
        {options.find((option) => option.value === value)?.label || value}
        <Button
          variant="ghost"
          className="h-4 w-4 p-0 hover:bg-transparent ml-1"
          onClick={(e) => {
            e.stopPropagation();
            const newValue = (field.value as string[]).filter((v: string) => v !== value);
            field.onChange(newValue, { shouldValidate: true });
          }}
        >
          <X className="h-3 w-3 text-zinc-500 hover:text-zinc-900" />
        </Button>
      </Badge>
    ));
  }, []);

  const handleCreateNew = useCallback((field: any, inputValue: string) => {
    handleCreate(field, inputValue);
  }, [handleCreate, inputValue]);

  const handleClear = useCallback((field: any) => {
    field.onChange(null, { shouldValidate: true });
  }, []);

  const filteredOptions = useMemo(() => {
    return options.filter((option) => option.label.toLowerCase().includes(inputValue.toLowerCase()));
  }, [options, inputValue]);

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={className}>
          {labelComponent ||
            <FormLabelWithIndicator
              label={label}
              required={required}
              isValid={isValid}
              info={info}
            />
          }
          {allowAdd && (<div className="text-xs text-muted-foreground">Allow Add</div>)}
          <Popover modal={true} open={open} onOpenChange={handleOpenChange}>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  role="combobox"
                  disabled={disabled || isCreating}
                  className={cn(
                    "w-full justify-between h-full bg-transparent",
                    !field.value && "text-muted-foreground",
                    "hover:bg-transparent"
                  )}
                >
                  {isCreating ? (
                    <Loader2 className="h-4 w-4 animate-spin mr-2" />
                  ) : (
                    multiple ? (
                      field.value && Array.isArray(field.value) && field.value.length > 0 ? (
                        <div className="flex flex-col gap-1 items-start">
                          {renderSelectedBadges(field, options)}
                        </div>
                      ) : (
                        <span>{placeholder}</span>
                      )
                    ) : (
                      field.value ?
                        options.find((option) => option.value === field.value)?.label
                        : placeholder
                    ))}
                  {field.value ? (
                    <button
                      className="ml-2 p-0 m-0 justify-end opacity-50 hover:opacity-100"
                      onClick={(e) => { e.stopPropagation(); handleClear(field) }}>
                      <X className="h-4 w-4" />
                    </button>
                  ) : (
                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                  )}
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent
              className="w-[var(--radix-popover-trigger-width)] p-0"
              modal={true}
              sticky="always"
              collisionPadding={20}
              collisionBoundary={viewportRef?.current}
            >
              <Command shouldFilter={false}>

                <CommandInput
                  placeholder={allowAdd ? `Search or start typing to create a new item` : `Search...`}
                  value={inputValue}
                  onValueChange={setInputValue}
                  className="focus:ring-0 focus:ring-offset-0 focus:ring-zinc-300"
                />
                <ScrollArea className="max-h-[300px] overflow-y-scroll" type="auto">

                  <CommandGroup heading={inputValue ? "Search results" : "Options"} value="options">
                    {/* <ScrollArea className="max-h-[200px] overflow-y-auto" type="auto"> */}
                    {filteredOptions.map((option) => (
                      <CommandItem
                        key={option.value}
                        onSelect={() => {
                          if (multiple) {
                            const currentValue = Array.isArray(field.value) ? field.value : [];
                            const newValue = currentValue.includes(option.value)
                              ? currentValue.filter((value) => value !== option.value)
                              : [...currentValue, option.value];
                            onSelect?.(newValue);
                          } else {
                            onSelect?.(option.value);
                            setInputValue("");
                            setOpen(false);
                          }
                        }}
                      >
                        <Check
                          className={cn(
                            "mr-2 h-4 w-4",
                            multiple
                              ? Array.isArray(field.value) && field.value.includes(option.value)
                                ? "opacity-100"
                                : "opacity-0"
                              : field.value === option.value
                                ? "opacity-100"
                                : "opacity-0"
                          )}
                        />
                        {option.label}
                      </CommandItem>
                    ))}
                    {filteredOptions.length === 0 && (
                      <CommandItem value={`no-results-${inputValue}`} disabled>No results found.</CommandItem>
                    )}
                    {/* </ScrollArea> */}
                  </CommandGroup>
                  <CommandSeparator alwaysRender />
                  {allowAdd && inputValue && (
                    <CommandGroup heading="Create New">
                      <CommandItem
                        key={`create-${inputValue}`}
                        value={`${inputValue}`}
                        onSelect={() => handleCreateNew(field.value, inputValue)}>
                        <PlusIcon className="h-4 w-4 text-muted-foreground" />
                        Create "{inputValue}"
                      </CommandItem>
                    </CommandGroup>
                  )}
                </ScrollArea>
              </Command>
            </PopoverContent>
          </Popover>
        </FormItem>
      )}
    />
  )
}

