import React from 'react';
import { FastFillIcon } from '@/components/raytd/icons';
import { useTestTileContext } from './context';
import TestItemToggle from './toggle-switch';
import { cn } from '@/lib/utils';

interface FastFillAcceptedToggleProps {
    visible?: boolean
    className?: string
}

const FastFillAcceptedToggle: React.FC<FastFillAcceptedToggleProps> = React.memo(({ visible, className }) => {
    const { item } = useTestTileContext()

    if (visible !== true) {
        return null;
    }

    return (
        <div className={cn('flex flex-row gap-2 items-center', className)}>
            <FastFillIcon className='w-4 h-4' />
            <TestItemToggle
                label="Accepted"
                switchType="fast_fill_accepted"
                offLabel='Not Accepted'
                variant="fastfill"
                item={item}
            />
        </div>
    )
});

export default FastFillAcceptedToggle;