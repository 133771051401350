
import { Skeleton } from "@/components/ui/skeleton";
import React from "react";
import SubPageLayout from '../../organisation/SubPageLayout';

const ReportConfigureLoadingScreen = React.memo(({ title }: { title: string }) => {
    return (
        <SubPageLayout title={title}>

            <div className="space-y-8">
                {/* Header */}
                <Skeleton className="h-8 w-48" />

                {/* Name Field */}
                <div className="space-y-2">
                    <Skeleton className="h-4 w-24" />
                    <Skeleton className="h-10 w-full" />
                </div>

                {/* Portfolio Section */}
                <div className="space-y-2">
                    <Skeleton className="h-5 w-32" />
                    <Skeleton className="h-4 w-64" />
                </div>

                {/* Assets & Inspections Grid */}
                <div className="grid grid-cols-2 gap-4">
                    {/* Assets Column */}
                    <div className="space-y-4">
                        <Skeleton className="h-5 w-24" />
                        <Skeleton className="h-10 w-full" />
                        <div className="space-y-2">
                            {[1, 2, 3].map((i) => (
                                <Skeleton key={`asset-${i}`} className="h-16 w-full" />
                            ))}
                        </div>
                    </div>

                    {/* Inspections Column */}
                    <div className="space-y-4">
                        <Skeleton className="h-5 w-24" />
                        <Skeleton className="h-10 w-full" />
                        <div className="space-y-2">
                            {[1, 2, 3].map((i) => (
                                <Skeleton key={`inspection-${i}`} className="h-20 w-full" />
                            ))}
                        </div>
                    </div>
                </div>

                {/* Authors Section */}
                <div className="space-y-4">
                    <div className="flex justify-between items-center">
                        <Skeleton className="h-5 w-24" />
                        <Skeleton className="h-8 w-24" />
                    </div>
                    <Skeleton className="h-16 w-full" />
                </div>

                {/* Client Section */}
                <div className="space-y-4">
                    <div className="flex justify-between items-center">
                        <Skeleton className="h-5 w-24" />
                        <Skeleton className="h-8 w-16" />
                    </div>
                    <Skeleton className="h-32 w-full rounded-lg" />
                </div>

                {/* Report Sections */}
                <div className="space-y-4">
                    <Skeleton className="h-6 w-36" />
                    <div className="space-y-2">
                        {[1, 2, 3, 4, 5].map((i) => (
                            <div key={`section-${i}`} className="flex items-center justify-between">
                                <Skeleton className="h-4 w-32" />
                                <Skeleton className="h-8 w-24" />
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        </SubPageLayout>

    )
});

export default ReportConfigureLoadingScreen;