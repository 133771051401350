import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import React from 'react';
import { useTestTileContext } from './context';

interface DebugInfoProps {
    exclusionReason: string;
    status: string;
    id: number;
}

const DebugInfo: React.FC<DebugInfoProps> = React.memo(
    ({ exclusionReason, status, id }) => {
        const { item, hasFastFillErrors, hasUnmatchedSegments, missingData } = useTestTileContext();

        return (
            <Popover>
                <PopoverTrigger>
                    <div className="text-white bg-amber-500 px-2 py-1 text-xs rounded-lg cursor-pointer">
                        {exclusionReason}/{status} {id}
                    </div>
                </PopoverTrigger>
                <PopoverContent className="w-80 p-4">
                    <div className="space-y-2">
                        <h4 className="font-medium">Debug Information</h4>
                        <div className="grid grid-cols-2 gap-1 text-sm">
                            <span className="text-zinc-500">ID:</span>
                            <span>{id}</span>
                            <span className="text-zinc-500">Test Specification:</span>
                            <span>{item.testSpecification.id}</span>
                            <span className="text-zinc-500">Exclusion Reason:</span>
                            <span>{exclusionReason}</span>
                            <span className="text-zinc-500">Status:</span>
                            <span>{status}</span>
                            <span className="text-zinc-500">Fast Fill Errors:</span>
                            <span>{hasFastFillErrors ? 'Yes' : 'No'}</span>
                            <span className="text-zinc-500">Unmatched Segments:</span>
                            <span>{hasUnmatchedSegments ? 'Yes' : 'No'}</span>
                        </div>
                        {missingData && Object.keys(missingData).length > 0 && (
                            <>
                                <h4 className="font-medium pt-2">Missing Data</h4>
                                <div className="grid grid-cols-2 gap-1 text-sm">
                                    {Object.entries(missingData).map(([field, message]) => (
                                        <React.Fragment key={field}>
                                            <span className="text-zinc-500">{field}:</span>
                                            {/* @ts-ignore */}
                                            <span className="text-red-500">{message.message}</span>
                                        </React.Fragment>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                </PopoverContent>
            </Popover>
        );
    }
);

export default DebugInfo;