import { useCallback, useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";
import { Input } from "@/components/ui/input";
import { X } from "lucide-react"
import { Button } from "@/components/ui/button"

interface FilterInputProps {
    onFilterChange: (value: string) => void;
    placeholder?: string;
    className?: string;
}

const FilterInput = ({
    onFilterChange,
    placeholder = "Filter...",
    className = "h-8 w-[150px] lg:w-[250px]"
}: FilterInputProps) => {
    const [localValue, setLocalValue] = useState("");

    const debouncedCallback = useMemo(
        () =>
            debounce((value: string) => {
                onFilterChange(value);
            }, 300, { leading: false, trailing: true }),
        [onFilterChange]
    );

    // Cleanup debounce on unmount
    useEffect(() => {
        return () => {
            debouncedCallback.cancel();
        };
    }, [debouncedCallback]);

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setLocalValue(newValue);
        debouncedCallback(newValue);
    }, [debouncedCallback]);

    const handleClear = useCallback(() => {
        setLocalValue("")
        onFilterChange("")
    }, [onFilterChange])

    return (
        <div className="relative">
            <Input
                placeholder={placeholder}
                value={localValue}
                onChange={handleChange}
                className={className}
            />
            {localValue && (
                <Button
                    variant="ghost"
                    size="icon"
                    className="absolute right-1 top-1/2 -translate-y-1/2 h-6 w-6 p-0.5"
                    onClick={handleClear}
                    type="button"
                >
                    <X className="h-4 w-4 text-muted-foreground" />
                    <span className="sr-only">Clear search</span>
                </Button>
            )}
        </div>
    );
};


export default FilterInput;