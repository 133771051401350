import { FormControl, FormDescription, FormField, FormItem, FormMessage } from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { FormLabelWithIndicator } from "../form-label-with-indicator"
import { CustomFieldProps } from '../custom-fields-container';
import { useFormContext } from "react-hook-form";
import { useCallback } from "react";

type CustomTextFieldProps = CustomFieldProps & {
}

export function CustomTextField({
  control,
  name,
  label,
  description,
  showDescription,
  info,
  required,
  placeholder,
  isValid,
  showFormMessage,
}: CustomTextFieldProps) {

  const { trigger, setValue } = useFormContext();

  const handleOnChange = useCallback((value: string) => {
    console.debug('handleOnChange', name, value);
    setValue(name, value, { shouldDirty: true, shouldValidate: true, shouldTouch: true });
    // trigger();
  }, [setValue, trigger, name]);

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabelWithIndicator label={label} isValid={isValid} required={required} info={info} />
          <FormControl>
            <Input 
              placeholder={placeholder}
               {...field}
               onChange={(e) => {
                field.onChange(e); // Keep the default form behavior
                handleOnChange(e.target.value);
              }}
              onBlur={() => {
                field.onBlur();
                trigger();
              }}
                
                
                />
          </FormControl>
          {showDescription && (<FormDescription>{description}</FormDescription>)}
          {showFormMessage && <FormMessage />}
        </FormItem>
      )}
    />
  )
}

