import { cn } from '@/lib/utils';
import React from 'react';
import { useTestTileContext } from './context';
import TestItemToggle from './toggle-switch';

type ExclusionToggleProps = {
    // onToggle: (excluded: boolean) => void;
    label?: string;
    offLabel?: string;
    enableAlways?:  boolean;
}

const ExclusionToggle: React.FC<ExclusionToggleProps> = (
    {
        label = 'Included',
        offLabel = 'Excluded',
        enableAlways = false
    }
) => {
    const { exclusionReason, exclusionToggleOperable } = useTestTileContext();
    const isTestIncluded = exclusionReason === 'included';
    const { item } = useTestTileContext()

    const isDisabled = !exclusionToggleOperable && !enableAlways;

    return (
        <div className={cn('', isDisabled && 'opacity-50 pointer-events-none')}>
            <TestItemToggle
                label={label}
                offLabel={offLabel}
                switchType="include"
                variant={exclusionReason}
                disabled={isDisabled}
                item={item}
                value={isTestIncluded}
            />
        </div>
    );
};

export default ExclusionToggle;