import { useState, useCallback, useEffect } from 'react';
import { useGetTestItemQuery } from '@app.raytd.com/store';
import { skipToken } from '@reduxjs/toolkit/query';
import { TestItem } from 'store/src/lib/tests/entity';

interface UseTestEditorProps {
  onEditorClose?: () => void;
}

interface EditorState {
  isOpen: boolean;
  testId: number | null;
  test: TestItem | null;
}

export function useTestEditor({ onEditorClose }: UseTestEditorProps = {}) {
  // Combine related state into a single object
  const [editorState, setEditorState] = useState<EditorState>({
    isOpen: false,
    testId: null,
    test: null,
  });

  // Query will only run when we have a testId
  const {
    data: testItem,
    isLoading,
    isError,
    refetch,
  } = useGetTestItemQuery(editorState.testId ?? skipToken, {
    // Force cache invalidation when the query is re-run
   // refetchOnMountOrArgChange: true,
  });;

  const openEditor = useCallback(async (id: number) => {
    const isSameTest = id === editorState.testId;
    
    // First clear the state
    setEditorState(current => ({
      isOpen: true,
      testId: id,
      test: null, // Clear previous test while loading
    }));

    // Then force refetch if it's the same test
    if (isSameTest) {
      await refetch();
    }
  }, [editorState.testId, refetch]);

  const closeEditor = useCallback(() => {
    setEditorState({
      isOpen: false,
      testId: null,
      test: null,
    });
    onEditorClose?.();
  }, [onEditorClose]);

  useEffect(() => {
    console.debug('testEditor editorState', editorState);
  }, [editorState]);

  // Update test data when query completes
  useEffect(() => {
    console.debug('testEditor useEffect', testItem);
    if (testItem) {
      setEditorState((current) => ({
        ...current,
        test: testItem,
      }));
    }
  }, [testItem, isLoading, editorState?.testId]);

  return {
    isOpen: editorState.isOpen,
    testItem: editorState.test,
    isLoading,
    isError,
    openEditor,
    closeEditor,
  };
}
