import { useGetPublishedReportQuery } from '@app.raytd.com/store';
import { ReportTestItem } from '../utils/entities';
import useValidationResults from '../utils/useValidationResults';
import useEnrichedTestItems from './use-enrich-test-items';
import useReportStats from './use-report-stats';
import { ReportDataProvider } from '@/app/features/reports/contexts/data-context';
import { ReportEntity } from 'store/src/lib/services/types';
import { useEffect } from 'react';

const usePublishedReportData = (revisionId: string, includedTestsOnly = true) => {
  console.debug('usePublishedReportData', { revisionId: revisionId, includedTestsOnly });
  const {
    data: publishedReport,
    isLoading: isDataLoading,
    error,
  } = useGetPublishedReportQuery(Number(revisionId));

  const {
    items: allTestItems,
    report: { testSuites, ...report },
  } = publishedReport?.published ?? { items: [], report: { testSuites: [] } };

  useEffect(() => {
    console.debug('usePublishedReportData', {
        publishedReport
    });
  }, [publishedReport]);

  const { missingData, isValidationLoading } = useValidationResults(
    allTestItems as unknown as ReportTestItem[],
    testSuites
  );

  const enrichedTestItems = useEnrichedTestItems(
    allTestItems,
    testSuites,
    missingData,
    true // includedTestsOnly
  );

  console.debug('enrichedTestItems', { enrichedTestItems });

//   const { bookmarks, missingSegments, missingDataCount } = useReportStats(
//     enrichedTestItems,
//     missingData
//   );

  return {
    data: enrichedTestItems, // data that could be filtered by includedTestsOnly
    totalDataCount: allTestItems?.length ?? 0,
    report: report
      ? ({
          ...report,
          testSuites: testSuites,
        } as ReportEntity)
      : null,
    error,
    testSuites,
    isLoading: isDataLoading || isValidationLoading,
    // bookmarks,
    // missingSegments,
    // missingDataCount,
    bookmarks: [],
    missingSegments: 0,
    missingDataCount: 0,
    enrichedTestItems,
    missingData,
  };
};

export const publishedReportDataProvider: ReportDataProvider = {
  type: 'published',
  // @ts-ignore
  useReportData: usePublishedReportData
};

export default publishedReportDataProvider;
