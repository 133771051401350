
import { SaveReportButton } from "@/app/features/reports/components/publish/save-report-button";
import { ShareButton } from "@/app/features/reports/components/publish/share-report-button";
import { useReportContext } from "@/app/features/reports/contexts/report-context";
import IntroductionEditor from "@/app/features/reports/IntroductionPage";
import ReportLoadingSkeleton from "@/app/features/reports/published/loading-skeleton";
import ReportView from '@/app/features/reports/ReportView';
import SummaryEditor from "@/app/features/reports/SummaryPage";
import TermsPage from "@/app/features/reports/TermsPage";
import { MainPage } from "@/app/layout2/AppLayout";
import { useBreadcrumb } from "@/app/layout2/BreadcrumbContext";
import { Button } from "@/components/ui/button";
import { TooltipProvider } from "@/components/ui/tooltip";
import { ShareIcon, DownloadIcon } from "lucide-react";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

const PublishedReportLayout = () => {
    const { setBreadcrumbName } = useBreadcrumb();

    const { report, revisionId, permissions, isLoading, error } = useReportContext();

    console.debug('ReportLayoutContent', { report, revisionId, isLoading, permissions });


    useEffect(() => {
        if (report) {
            setBreadcrumbName(`/reports/${report?.id}`, report?.title);
        }
    }, [report?.id, report?.title]);

    if (isLoading) {
        return <ReportLoadingSkeleton />
    }

    if (error) {
        return <div>Error loading report</div>
    }

    return (
        <MainPage>
            <div className="absolute top-1 right-1 bg-white px-2 py-1 rounded-md flex flex-row space-x-2">

                {permissions.allow_share && (
                    <ShareButton
                        revisionId={revisionId}
                    />
                )}

                <SaveReportButton
                    revisionId={Number(revisionId)}
                    isSaved={permissions.isSaved}
                    canSave={permissions.allow_save}
                />

            </div>
            <div className="container mx-auto md:pt-6 h-full px-2 lg:px-0">
                <TooltipProvider>
                    <Routes>
                        <Route path="introduction" element={<IntroductionEditor />} />
                        <Route path="summary" element={<SummaryEditor reportId={report?.id} />} />
                        <Route path="results" element={<ReportView reportId={report?.id} initialEditMode={false} />} />
                        <Route path="terms" element={<TermsPage />} />
                        <Route path="*" element={<ReportView reportId={report?.id} initialEditMode={false} />} />
                    </Routes>
                </TooltipProvider>
            </div>
        </MainPage>
    );

}

export default PublishedReportLayout;