import { useFormContext } from "react-hook-form"
import { CustomTextField } from "./custom-fields/custom-text-field"
import { CustomNumberField } from "./custom-fields/custom-number-field"
import { CustomDateField } from "./custom-fields/custom-date-field"
import { CustomSelectField } from "./custom-fields/custom-select-field"
import { CustomToggleField } from "./custom-fields/custom-toggle-field"
import { TestSuite } from "store/src/lib/tests/entity"
import { CustomRadioField } from "@/app/features/reports/components/custom-fields/custom-radio-field"
import { CustomTextAreaField } from "@/app/features/reports/components/custom-fields/custom-textarea-field"
import { CustomCheckboxField } from "@/app/features/reports/components/custom-fields/custom-checkbox-field"
import { useCallback } from "react"
import { useAddCustomFieldOptionMutation } from "@app.raytd.com/store"
import FieldContainer from "./test-form/field-container"
export type CustomFieldProps = {
  control: any;
  name: string;
  label: string;
  description: string;
  showDescription?: boolean;
  info: string;
  required: boolean;
  placeholder?: string;
  isValid: boolean;
  showFormMessage?: boolean;
}

interface CustomFieldsContainerProps {
  testSuite: TestSuite
  name: string;
  enableRightComponent?: boolean;
}

export function CustomFieldsContainer({ name = 'custom', testSuite: testSuiteData, enableRightComponent = false }: CustomFieldsContainerProps) {
  const { control, watch, formState: { errors } } = useFormContext();

  if (!testSuiteData?.custom_fields?.length) return null;
  if (!testSuiteData || !testSuiteData.custom_fields || testSuiteData.custom_fields.length === 0) return null

  const sortedCustomFields = [...testSuiteData.custom_fields]
    .sort((a, b) => a.field_order - b.field_order);

  const getFieldValidation = useCallback((field: any) => {
    const fieldValue = watch(`${name}.${field.field_id}`);
    const fieldError = errors[name]?.[field.field_id] ?? errors[`${name}.${field.field_id}`];

    // if (field.type === 'select' && field.selection_type === 'multiple') {
    //   console.debug('custom field validation', { field, required: field.required, fieldValue, fieldError });
    //   console.debug('isvalid', fieldValue?.length > 0 && !fieldError);
    // }


    // Field is valid if it has a value and no errors
    if (field.required) {
      const fieldIsNaN = field.type === 'number' && Number.isNaN(fieldValue);
      // console.debug('custom field validation', { 
      //   label:field.label ,
      //   field, fieldValue, 
      //   name,
      //   fieldError, fieldIsNaN,
      //    errors });
      return (Array.isArray(fieldValue) ? fieldValue.length > 0 : fieldValue !== undefined && fieldValue !== null && fieldValue !== '' && !fieldIsNaN) && !fieldError;
    }
    // Optional fields are valid if they're empty or have no errors
    return !fieldValue || !fieldError;
  }, [watch, errors, name]);


  const [addCustomFieldOption, { isLoading }] = useAddCustomFieldOptionMutation();

  const handleAddOption = useCallback(async (fieldId: string, displayName: string) => {
    try {

      const fieldIdWithoutCustom = fieldId.startsWith('custom.') ? fieldId.slice(7) : fieldId;

      const result = await addCustomFieldOption({
        testSuiteId: testSuiteData.id,
        field_id: fieldIdWithoutCustom,
        displayName,
      }).unwrap();

      // Handle success
      console.log('Option added:', result.data);
      return { success: true, field_id: result.field_id, data: result.data };
    } catch (error) {
      // Handle error
      console.error('Failed to add option:', error);
      return { success: false, field_id: null, data: null };
    }
  }, [addCustomFieldOption, testSuiteData.id]);

  const renderField = useCallback((field: any) => {
    const commonProps: CustomFieldProps & { key: string; } = {
      key: field.field_id,
      control: control,
      name: `${name}.${field.field_id}`,
      label: field.label,
      description: field.description,
      info: field.info,
      required: field.required,
      placeholder: field.placeholder || undefined,
      isValid: getFieldValidation(field),
      showDescription: false,
      showFormMessage: false
    }

    switch (field.type) {
      case 'text':
        return <CustomTextField {...commonProps} />
      case 'number':
        return <CustomNumberField {...commonProps} decimalPlaces={field.decimal_places} />
      case 'date':
        return <CustomDateField {...commonProps} />
      case 'select':
        return (
          <CustomSelectField
            {...commonProps}
            options={field.data}
            selectionType={field.selection_type}
            allowAdd={field.allow_add}
            onAddOption={handleAddOption}
          />
        )
      case 'radio':
        return <CustomRadioField {...commonProps} options={field.data} />
      case 'checkbox':
        return <CustomCheckboxField {...commonProps} />
      case 'switch':
        return <CustomToggleField {...commonProps} />
      case 'textarea':
        return <CustomTextAreaField
          {...commonProps}
          options={field.data}
          allowAdd={field.allow_add}
          onAddOption={handleAddOption}
        />
      default:
        return <div key={field.field_id}>Unknown field type: {field.type}</div>
    }
  }, [control, name, getFieldValidation, handleAddOption]);

  return (
    <div className="space-y-4">
      {sortedCustomFields
        .filter(field => field.active)
        .map((field) => {
          return (
            <FieldContainer
              key={`${name}.${field.field_id}`}
              field_id={`${name}.${field.field_id}`}
              showRightComponent={enableRightComponent}
              isFastFill={field.type === 'textarea' && field.data?.length > 0}
            >
              {renderField(field)}
            </FieldContainer>
          )
        })}


    </div>
  )
}

