import { ExclusionReasons } from "@/app/features/reports/utils/entities";

const getExclusionReason = (
    hasMissingData: boolean,
    hasFastFillErrors: boolean,
    status: string,
    isExcluded: boolean
  ): ExclusionReasons => {
    if (hasMissingData) return 'missing';
    if (hasFastFillErrors) return 'fastfill';
    if (status.toLowerCase() !== 'published') return 'status';
    if (isExcluded) return 'excluded';
    return 'included';
  };

export default getExclusionReason;