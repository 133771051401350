import AddressLine from "@/app/features/assets/AddressLine";
import { SectionText } from "@/app/features/reports/components/section-text";
import { useReportContext } from '@/app/features/reports/contexts/report-context';
import { AssetEntity } from "@app.raytd.com/store";
interface InspectedAssetsSectionProps {
  assets: AssetEntity[];
}

export function InspectedAssetsSection({ assets }: InspectedAssetsSectionProps) {

  const { editMode } = useReportContext();

  return (
    <section className="mb-4">
      <h3 className="font-medium mb-4">Inspected assets</h3>

      <SectionText position="introduction-before-inspected-assets" className="mb-4" />

      <div className="flex flex-wrap gap-20">
        {assets.map((asset) => (
          <AssetCard key={asset.id} asset={asset} editMode={editMode} />
        ))}
      </div>
    </section>
  )
}

interface AssetCardProps {
  asset: AssetEntity;
  editMode: boolean;
}

function AssetCard({ asset, editMode }: AssetCardProps) {

  if (!asset) {
    return null;
  }

  return (
    <div className="">
      <img
        src={asset.imageUrl || "/placeholder.svg?height=96&width=96"}
        alt={asset.name}
        width={96}
        height={96}
        className="w-24 h-24 object-cover rounded mb-4"
      />
      <p className="font-medium">{asset.name}</p>
      {/* <p className="text-sm text-muted-foreground">{asset.address?.street}</p> */}
      <AddressLine
        twoLines
        className="text-sm text-muted-foreground"
        address={asset.address}
        excludeCountry
      />
      {/* <p className="text-sm text-muted-foreground">{`${asset.address?.suburb ?? asset.address?.city}, ${asset.address?.state} ${asset.address?.postcode}`}</p> */}
    </div>
  )
}