import React from 'react'
import { Button } from "@/components/ui/button"
import { ScrollArea } from "@/components/ui/scroll-area"
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "@/components/ui/sheet"
import { FilterItem } from '../utils/entities'
import { FilterCounts, FilterLabels, filterNames, Filters, FilterTypes, SelectedFilters } from '../types/filters'
import { HierarchicalSelector, HierarchicalSelectorProps } from './space-list-selector'

export interface GroupedItem {
  parent: string;
  children: FilterItem[];
}

export const getSelectedSpaces = (data: FilterItem[], ids: string[]): any[] => {
  const result: any[] = []
  const traverse = (items: typeof data, path: string[] = []) => {
    for (const item of items) {
      const currentPath = [...path, item.name]
      if (ids.includes(item.id)) {
        result.push({
          id: item.id,
          name: currentPath.join(' > '),
          key: item.key
        })
      }
      if (item.children) {
        traverse(item.children, currentPath)
      }
    }
  }
  traverse(data)

  return result
}

export const groupSelectedSpaces = (items: FilterItem[]): GroupedItem[] => {
  const grouped: { [key: string]: FilterItem[] } = {}
  items.forEach(item => {
    const parts = item.name.split(' > ')
    const parent = parts.slice(0, -1).join(' > ')
    const child = parts[parts.length - 1]
    if (!grouped[parent]) {
      grouped[parent] = []
    }
    grouped[parent].push({
      id: item.id,
      key: item.key,
      name: child,
    })
  })
  return Object.entries(grouped).map(([parent, children]) => ({ parent, children }))
}


// const MemoizedHierarchicalSelector = React.memo(HierarchicalSelector);

// 2. Extract filter sections to a separate component
const FilterSection: React.FC<HierarchicalSelectorProps> = React.memo(({
  name,
  description,
  ...props
}) => (
  <div className="w-full">
    <HierarchicalSelector
      name={name}
      description={description}
      {...props}
    />
  </div>
));

interface FilterDrawerProps {
  open: boolean
  onClose: () => void
  selectedFilters: SelectedFilters
  setSelectedFilters: React.Dispatch<React.SetStateAction<SelectedFilters>>
  filters: Filters,
  // filterCounts?: {
  //   [key: string]: {
  //     [key: string]: number
  //   }
  // };
  // filteredCounts?: {
  //   [key: string]: {
  //     [key: string]: number
  //   }
  // };
  filterCounts?: FilterCounts
  filteredCounts?: FilterCounts
}

export function FilterDrawer({ open, onClose, selectedFilters, setSelectedFilters, filters, filterCounts, filteredCounts }: FilterDrawerProps) {

  console.debug('render');
  const { isPublished, permissions } = useReportContext();

  const handleFilterChange = useCallback((category: FilterTypes, item: string) => {

    console.debug('handleFilterChange', { category, item });


    setSelectedFilters(prev => {
      if (filterNames.includes(category)) {
        return !item ?
          { ...prev, [category]: [] } :
          { ...prev, [category]: [item] };
      }

      const updatedFilters = new Set(prev[category]);
      updatedFilters.has(item) ?
        updatedFilters.delete(item) :
        updatedFilters.add(item);

      return { ...prev, [category]: Array.from(updatedFilters) };
    });
  }, []);

  const selectorProps = useMemo(() => ({
    selectedFilters,
    onSelectionChange: handleFilterChange,
    filterCounts,
    filteredCounts,
    data: filters
  }), [selectedFilters, handleFilterChange, filterCounts, filteredCounts, filters]);

  return (
    <Sheet open={open} onOpenChange={onClose}>
      <SheetContent className="w-[400px] sm:w-[600px] sm:max-w-full">
        <SheetHeader className="mb-6">
          <SheetTitle>Filter</SheetTitle>
        </SheetHeader>
        {!isPublished && (
          <div className="">
            <ExcludedTestsBanner tests={filterCounts?.tests} />
          </div>
        )}
        <ScrollArea className="h-[calc(100vh-160px)]">
          <div className="space-y-6 flex flex-col">

            <FilterSection
              description={FilterLabels.space}
              name="space"
              {...selectorProps}
            />

            <FilterSection
              description={FilterLabels.element}
              name="element"
              {...selectorProps}
            />

            <FilterSection
              description={FilterLabels.elementName}
              name="elementName"
              {...selectorProps}
            />

            <FilterSection
              description={FilterLabels.elementId}
              name="elementId"
              {...selectorProps}
            />


            <FilterSection
              description={FilterLabels.testSuite}
              name="testSuite"
              {...selectorProps}
              renderCustom={(item) => item.extraProps && (<TestSuiteTypeBadge size="sm" type={item.extraProps.ratingType} />)}
            />


            <FilterSection
              description={FilterLabels.rating}
              name="rating"
              {...selectorProps}
            />

            <FilterSection
              description={FilterLabels.status}
              name="status"
              {...selectorProps}

            />

            <FilterSection
              description={FilterLabels.excluded}
              name="excluded"
              {...selectorProps}

            />

          </div>
        </ScrollArea>
        <div className="mt-4">
          <Button className="w-full" onClick={onClose}>Apply Filters</Button>
        </div>
      </SheetContent>
    </Sheet>
  )
}

import { TestSuiteTypeBadge } from "@/components/raytd/test-type-pill"
import { cn } from "@/lib/utils"
import { cva, type VariantProps } from "class-variance-authority"
import { useCallback, useMemo } from "react"
import CountPill from './excluded-count-pill';
import { ExcludedTestsBanner } from './excluded-tests-badge';
import { useReportContext } from '@/app/features/reports/contexts/report-context'

const badgeVariants = cva(
  "inline-flex items-center rounded-md border px-1.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        selected: "bg-zinc-500 text-zinc-200",
        partial: "border-zinc-200 bg-transparent text-zinc-500",
        unselected: "bg-transparent bg-border-zinc-300 text-zinc-300",
      },
    },
    defaultVariants: {
      variant: "selected",
    },
  }
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
  VariantProps<typeof badgeVariants> { }

export function FilterCountBadge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  )
}

