import React, { Suspense, ComponentType } from 'react';

interface WithLazyLoadOptions {
  condition?: boolean;
  fallback?: React.ReactNode;
}

export function withLazyLoad<P extends object>(
  importFn: () => Promise<{ default: ComponentType<P> }>,
  options: WithLazyLoadOptions = {}
) {
  const LazyComponent = React.lazy(importFn);

  return function WithLazyLoad(props: P) {
    const { condition = true, fallback = null } = options;

    if (!condition) {
      return null;
    }

    return (
      <Suspense fallback={fallback}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <LazyComponent {...props} />
      </Suspense>
    );
  };
}