import NoDraftRevisionError from '@/app/features/reports/components/draft-revision-error';
import ReportViewLoadingSkeleton from '@/app/features/reports/components/loading-state';
import { ReportGroupingProvider } from '@/app/features/reports/contexts/grouping-context';
import useReportData, { ReportFilters } from '@/app/features/reports/hooks/useReportData';
import ErrorState from '@/components/raytd/error-state';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { ReportEntity } from 'store/src/lib/services/types';
import { TestSuite } from 'store/src/lib/tests/entity';
import ReportDisplayProvider, { ReportDisplayContext } from '../contexts/display-context';
import { ReportSelectionProvider } from '../contexts/selection-context';
import { BookMark, ReportGroupModes } from '../types';
import { SelectedFilters } from '../types/filters';
import { MissingData } from '../types/missing-data';
import { ReportItem, ReportTestItem } from '../utils/entities';
import { useReportContext } from '@/app/features/reports/contexts/report-context';

export const useReportDisplayContext = () => {
    return React.useContext(ReportDisplayContext);
}

type ReportViewerContextValue = {
    reportId: number | null;
    activeItems: string[];
    setActiveItems: (items: string[]) => void;
    setUpdatingTest?: (id: number) => void;
    clearUpdatingTest?: () => void;
    isUpdatingTest?: boolean;
    updatingTestId?: number;
    isScrolling?: boolean;
    showTestEditor?: (id: number) => void;
    showFastFillQA?: boolean;
    showMissingData?: boolean;
    scrollToTest?: (id: number) => void;
};

//create context for report viewer
export const ReportViewerContext = createContext<ReportViewerContextValue>(undefined);

const useReportViewerContext = () => {
    return React.useContext(ReportViewerContext);
}

export default useReportViewerContext;

// Main context for core report data and state
export interface ReportViewContext {
    filteredData: ReportTestItem[];
    testSuites: TestSuite[];
    data: ReportItem[];
    report: ReportEntity;
    reportTotal: number;
    visibleTestCount: number;
    missingData: MissingData;
    missingDataCount: number;
    missingSegments: number;
    bookmarks: BookMark[];
    isEditMode: boolean;
    setEditMode: (mode: boolean) => void;
    groupMode: ReportGroupModes;
    setGroupMode: (mode: ReportGroupModes) => void;
    isLoading: boolean;
    filters: ReportFilters; //TODO: fix this type
    updateGroupedData: (groupMode: ReportGroupModes, data: ReportTestItem[]) => ReportTestItem[] | ReportItem[];
}


export const defaultReportViewContext: ReportViewContext = {
    filteredData: [],
    data: [],
    testSuites: [],
    report: null,
    reportTotal: 0,
    visibleTestCount: 0,
    missingData: null,
    missingDataCount: 0,
    missingSegments: 0,
    bookmarks: [],
    isEditMode: false,
    setEditMode: () => { },
    groupMode: 'location',
    setGroupMode: () => { },
    isLoading: false,
    filters: null,
    updateGroupedData: () => [],
}

export const ReportViewContext = createContext<ReportViewContext>(defaultReportViewContext);

interface ReportViewProviderProps {
    children: React.ReactNode;
    reportId: string; // Pass necessary props
    initialFilters?: SelectedFilters;
    LoadingComponent?: React.ComponentType<{ isLoading?: boolean }>;
    initialEditMode?: boolean;
}

export const ReportViewProvider: React.FC<ReportViewProviderProps> = ({ 
    children, 
    reportId, 
    initialFilters, 
    initialEditMode = true,
    LoadingComponent = ReportViewLoadingSkeleton
 }) => {
    // Move relevant state and data fetching here
    //const [editMode, setEditMode] = useState(initialEditMode);

    const { editMode, setEditMode, isEditable } = useReportContext();

    useEffect(() => {
        console.debug('Updating edit mode', initialEditMode);
        setEditMode(initialEditMode && isEditable);
    }, [initialEditMode, isEditable]);

    const [groupMode, setGroupMode] = useState<ReportGroupModes>('location');
    const reportContext = useReportContext();

    const reportID = reportContext?.isPublished ? reportContext?.revisionId.toString() : String(reportId);
    
    const {
        enrichedTestItems: allData, //we only get the total number of tests
        filteredData,
        report,
        testSuites,
        isLoading,
        error,
        filters,
        bookmarks,
        missingData,
        missingDataCount,
        missingSegments,
        updateGroupedData,
    } = useReportData(reportID, !editMode, initialFilters);

    const data = useMemo(() => {
        const groupedData = updateGroupedData(groupMode, filteredData);
        return groupedData as ReportItem[];
    }, [filteredData, groupMode, updateGroupedData]);

    const value = useMemo(() => ({
        data, //the data that is displayed in the report
        filteredData, //the raw test items data that is filtered
        report,
        testSuites,
        visibleTestCount: filteredData?.length,
        reportTotal: allData?.length,
        bookmarks,
        updateGroupedData,
        filters,
        isEditMode: editMode,
        setEditMode,
        groupMode,
        setGroupMode,
        isLoading,
        error,
        missingData,
        missingDataCount,
        missingSegments,
    }), [filteredData, report, filters, editMode, groupMode, data, testSuites]);
    
    console.debug('ReportViewProvider', {
        report,
        testSuites,
        data,
        filteredData,
        filters,
    });

    if (error) {
        console.error('Error loading report', error);
        const errorObj = error as { status?: number; data?: { errorCode?: string } };
        if (errorObj?.status === 404 && errorObj?.data?.errorCode === 'NO_DRAFT_REVISION') {
            return <NoDraftRevisionError />;
        } else {
            console.error('Error loading report', error);
            return <ErrorState message="Error loading report" />;
        }
    }

    if (isLoading) {
        return <LoadingComponent isLoading={isLoading} />;
    }

    return (
        <ReportViewContext.Provider value={value}>
            {children}
        </ReportViewContext.Provider>
    );
};

export const useReportViewContext = () => {
    return React.useContext(ReportViewContext);
}

interface ReportProvidersProps {
    children: React.ReactNode;
    reportId: string;
    initialEditMode?: boolean;
    LoadingComponent?: React.ComponentType<{ isLoading?: boolean }>;
}

export const ReportProviders: React.FC<ReportProvidersProps> = ({
    reportId,
    children,
    initialEditMode = true,
    LoadingComponent = ReportViewLoadingSkeleton
}) => {
    console.log('ReportProviders initialEditMode:', initialEditMode); // Debug log


    return (
        <ReportViewProvider
            reportId={reportId}
            initialEditMode={initialEditMode}
            LoadingComponent={LoadingComponent}
        >
            <ReportDisplayProvider>
                <ReportGroupingProvider>
                    {/* <ReportFiltersProvider> */}
                    <ReportSelectionProvider>
                        {children}
                    </ReportSelectionProvider>
                    {/* </ReportFiltersProvider> */}
                </ReportGroupingProvider>
            </ReportDisplayProvider>
        </ReportViewProvider>
    );
};