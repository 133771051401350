import { useEffect, useState } from 'react';
import { BookMark } from '../types';
import { ReportTestItem } from '../utils/entities';

import { ValidationErrors } from '@/app/features/reports/types/missing-data';

function useReportStats(
  filteredData: ReportTestItem[],
  missingData: Map<number, ValidationErrors>
) {
  console.debug('useReportStats', { filteredData, missingData });
  const [stats, setStats] = useState<{
    bookmarks: BookMark[];
    missingSegments: number;
    missingDataCount: number;
  }>({
    bookmarks: [],
    missingSegments: 0,
    missingDataCount: 0,
  });

  useEffect(() => {
    console.debug('useReportStats useEffect', { filteredData, missingData });
    if (!filteredData) {
      setStats({
        bookmarks: [],
        missingSegments: 0,
        missingDataCount: 0,
      });
      return;
    }

    const missingSegments = filteredData.filter(
      (item) => item.hasFastFillQA
    ).length;
    const missingDataCount = filteredData.reduce(
      (acc, item) => (missingData.has(item.assessmentTestId) ? acc + 1 : acc),
      0
    );

    const total = filteredData.length;
    const bookmarks = filteredData.reduce((acc, item, index) => {
      const relativePosition = (index / total) * 100;
      if (item.hasUnmatchedSegments) {
        acc.push({
          index,
          title: item.assessmentTestId,
          position: relativePosition,
          type: 'fastfill',
        });
      }
      if (item.hasMissingData) {
        acc.push({
          index,
          title: item.assessmentTestId,
          position: relativePosition,
          type: 'missing',
        });
      }
      return acc;
    }, [] as BookMark[]);

    setStats((prevStats) => ({
      ...prevStats,
      bookmarks,
      missingSegments,
      missingDataCount,
    }));
  }, [filteredData, missingData]);

  return stats;
}

export default useReportStats;
