import { AlertProvider } from "@/app/contexts/AlertContext";
import { PhotoViewerProvider } from "@/app/contexts/PhotoViewerContext";
import { NavProvider } from "@/app/features/profile/NavContext";
import { ReportErrorFallback } from "@/app/features/reports/components/report-error-fallback";
import { ReportDataProvider } from "@/app/features/reports/contexts/data-context";
import { publishedReportDataProvider } from "@/app/features/reports/hooks/use-published-report-data";
import LoadingComponent from "@/app/features/reports/published/loading";
import PublishedReportProvider from "@/app/features/reports/published/published-report-provider";
import { PageLayoutContext } from "@/app/layout2/AppLayout";
import { BreadcrumbProvider } from "@/app/layout2/BreadcrumbContext";
import { CenteredCard } from "@/components/raytd/centered-content-card";
import FullScreenAlert from "@/components/raytd/full-screen-alert";
import { AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Toaster } from "@/components/ui/toaster";
import { setSharedReportAccess, useAppDispatch, useCreateTokenForReportMutation, useGetReportByUuidQuery } from "@app.raytd.com/store";
import { skipToken } from "@reduxjs/toolkit/query";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ReportEntity, ReportRevision } from "store/src/lib/services/types";
import PublishedReportLayout from './published/published-layout';
import PublishedReportHeader from './published/published-report-header';
import UserDetailsForm from './published/user-details-form';
import { useAuth } from "@/app/_components/privateRoute";
import {isAPIError} from "@app.raytd.com/store";
import { ReportLinkExpiredAlert } from "./published/report-token-expired";
import ReportErrorAlert from "./published/report-error-alert";

const PublicReportViewer = () => {
    const { uuid } = useParams();
    const dispatch = useAppDispatch();

    // State to track if we have a valid token
    const [hasToken, setHasToken] = useState<boolean>(false);
    // State to track if we need to show the user details form
    const [showUserForm, setShowUserForm] = useState<boolean>(false);

    const { isAuthenticated } = useAuth();
    const navigate = useNavigate();

    // Create token mutation
    const [createTokenForReport, { isLoading: isCreatingToken }] = useCreateTokenForReportMutation();

    useEffect(() => {
        const storedToken = sessionStorage.getItem('sharedReportToken');
        const storedReportData = sessionStorage.getItem('sharedReportData');

        if (storedToken && storedReportData) {
            // Restore from session storage
            dispatch(setSharedReportAccess(JSON.parse(storedReportData)));
            setHasToken(true);
        } else {
            // No token in storage, we'll need to validate the UUID first
            setHasToken(false);
        }
    }, [dispatch, uuid]);

    const [isSubmitted, setIsSubmitted] = useState(false);

    const {
        data: report,
        isLoading: isValidatingReport,
        error: reportError,
        isError: isReportError,
        refetch: refetchReport
    } = useGetReportByUuidQuery(
        uuid ? { uuid } : skipToken,
        {
            skip: !uuid,
        }
    );

    useEffect(() => {
        /** @ts-ignore */
        if (isReportError && reportError?.status === 401 && reportError?.data?.errorCode === "NO_TOKEN_PROVIDED") {
            setShowUserForm(true);
        }

    }, [reportError]);
    //need to check it's a valid uuid

    console.debug('report viewer', report, reportError);

    const handleUserFormSubmit = useCallback(async (name: string, email: string) => {
        try {
            const response = await createTokenForReport({
                uuid: uuid as string,
                name,
                email
            }).unwrap();

            // Store token data
            const tokenData = {
                token: response.token,
                report_id: response.report_id,
                revision_id: response.revision_id
            };

            console.debug('token data', tokenData);

            // Save to session storage
            sessionStorage.setItem('sharedReportToken', response.token);
            sessionStorage.setItem('sharedReportData', JSON.stringify(tokenData));

            // Update Redux state
            dispatch(setSharedReportAccess(tokenData));

            // Update component state
            setHasToken(true);
            setShowUserForm(false);
            await refetchReport();
        } catch (error) {
            console.error('Error creating token for report', error);
        }
    }, [refetchReport]);


    // Determine what to render based on current state
    const renderContent = useMemo(() => {
        // Show loading state while validating report or creating token
        if (isValidatingReport || isCreatingToken) {
            return <LoadingComponent isLoading={true} />;
        }

        // Handle errors
        if (reportError && isAPIError(reportError)) {
            // Not found error
            // @ts-ignore
            if (reportError.status === 404) {

                if (reportError?.data?.errorCode === 'REPORT_LINK_EXPIRED') {
                    return <ReportLinkExpiredAlert />;
                }

                return <ReportErrorAlert message="Report not found" />;
            }

            // If we need to show the user form (401 with NO_TOKEN_PROVIDED)
            if (showUserForm) {
                return <UserDetailsForm onSubmit={handleUserFormSubmit} />;
            }

            // Other errors
            return (
                <ReportErrorAlert message="An unexpected error occurred" />
            );
        }

        // If we have a report, show it
        if (report) {

            if (isAuthenticated) {
                //navigate to the report
                navigate(`/reports/${report.report.id}/published/${report.revision.id}`);
            }

            console.debug('PublicReportViewer - renderContent - report to render', report);
            return <PublicReportLayoutContent report={report.report} revision={report.revision} />;
        }

        // Fallback - should not reach here in normal flow
        return <div>Something went wrong</div>;
    }, [isValidatingReport, isCreatingToken, reportError, showUserForm, report, isAuthenticated]);

    const handleError = (error: Error, info: { componentStack: string }) => {
        console.error('Report viewer error:', error)
        console.error('Component stack:', info.componentStack)
    }

    return (<ErrorBoundary
        FallbackComponent={ReportErrorFallback}
        onError={handleError}
        onReset={() => {
            // Reset any state that might have caused the error
            setHasToken(false)
            setShowUserForm(false)
            refetchReport()
        }}
    >
        {renderContent}
    </ErrorBoundary>)

}

export default PublicReportViewer;

interface PublicReportLayoutContentProps {
    report: ReportEntity;
    revision: ReportRevision;
}

export const PublicReportLayoutContent = ({ report, revision }: PublicReportLayoutContentProps) => {
    // const { reportId } = useParams<{ reportId: string }>();

    if (!report || !revision || !revision?.id) {
        // return <Navigate to="/" />
        return <div>No revision id</div>
    }

    const contextValue = useMemo(() => ({
        showSidebar: false,
        showHeader: false,
        mainPageElement: null,
        isCompact: true,
        setScrollingElement: () => { }
    }), []);

    return (
        <div className="flex min-h-full flex-col px-1 md:px-6">
            <Toaster />
            <PhotoViewerProvider>
                <AlertProvider>
                    <PageLayoutContext.Provider value={contextValue}>
                        <ReportDataProvider provider={publishedReportDataProvider}>
                            <PublishedReportProvider revisionId={revision?.id}>
                                <NavProvider>
                                    <BreadcrumbProvider>
                                        <PublishedReportHeader />
                                        <PublishedReportLayout />
                                    </BreadcrumbProvider>
                                </NavProvider>
                            </PublishedReportProvider>
                        </ReportDataProvider>
                    </PageLayoutContext.Provider>
                </AlertProvider>
            </PhotoViewerProvider>
        </div>

    )

}
