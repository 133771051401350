import ReportEditModeToggle from '@/app/features/reports/components/edit-mode-toggle'
import { ReportSectionProvider } from '@/app/features/reports/components/report-section-context'
import BusyButton from '@/components/raytd/busy-button'
import SubPageLayout from '@/components/raytd/sub-page-layout'
import { Button } from "@/components/ui/button"
import { useUnsavedChanges } from '@/hooks/useUnsavedChanges'
import { useCallback, useEffect } from 'react'
import { toast } from 'sonner'
import { useNav } from '../profile/NavContext'
import { ManagedCustomSectionContainer } from './components/custom-section-container'
import { useReportSectionPage } from './components/report-section-context'

function TermsPage() {
    const { sectionManager, editMode, isEditorActive } = useReportSectionPage();
    const { sections, saveSections, isBusy, isDirty } = sectionManager;

    useEffect(() => {
        console.debug('TermsPage active', { editMode, isEditorActive });
    }, [editMode, isEditorActive]);

    const reset = useCallback(() => {
        console.log('reset');
    }, []);

    const { AlertDialogComponent } = useUnsavedChanges(isDirty, reset, false);
    

    const handleButtonClick = (url: string) => {
        window.open(url, '_blank');
    };

    const { setNavButtons } = useNav();

    const onSubmit = useCallback(async () => {
        console.log('submit', sections);
        try {
            await saveSections();
            toast.success('Page content saved');
        } catch (error) {
            console.error('Error saving sections:', error);
            toast.error('Error saving page content');
        }

    }, [sections]);

    useEffect(() => {
        setNavButtons(
                editMode && <BusyButton
                    onClick={onSubmit}
                    disabled={!isDirty || isEditorActive}
                    isBusy={isBusy}
                    busyText="Saving..."
            >
                Save
                </BusyButton>
        );

        // Clean up function to reset buttons when component unmounts
        return () => setNavButtons(null);
    }, [onSubmit, isDirty, isBusy, editMode, isEditorActive]);

    return (
        <SubPageLayout title="Terms and Conditions">
            <SubPageLayout.Header>
                <ReportEditModeToggle />
            </SubPageLayout.Header>

            <div className="space-y-0">

                <ManagedCustomSectionContainer
                    position="terms-and-conditions"
                />

                <div className="mt-0 space-y-6 border-y py-6">
                    <h2 className="text-xl font-semibold">Raytd</h2>
                    <div className="flex flex-wrap gap-4">
                        <Button variant="outline" onClick={() => handleButtonClick('https://raytd.com/conditions')}>Raytd Terms and Conditions</Button>
                        <Button variant="outline" onClick={() => handleButtonClick('https://raytd.com/privacy')}>Raytd Privacy Policy</Button>
                        <Button variant="outline" onClick={() => handleButtonClick('https://raytd.com/security')}>Raytd Security Policy</Button>
                    </div>

                </div>
            </div>
            <AlertDialogComponent />
        </SubPageLayout>
    )
}

export default function Page() {

    return (
        <ReportSectionProvider
            page="terms-and-conditions"
        >
            <TermsPage />
        </ReportSectionProvider>
    )
}