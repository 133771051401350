import { FilterItem, TestCounts } from '../utils/entities';
import { FacetCounts } from '../utils/facets';

export const filterNames = [
  'space',
  'rating',
  'testSuite',
  'status',
  'elementName',
  'elementId',
  'element',
  'excluded'
] as const;

export const FilterLabels = {
  space: 'Location',
  element: 'Element',
  elementName: 'Element Name',
  elementId: 'Element ID',
  testSuite: 'Test Suite',
  rating: 'Result',
  status: 'Status',
  excluded: 'Excluded Tests',
} as const;

export type FilterTypes = (typeof filterNames)[number];

// export type FilterKeys = (typeof filterKeys)[number];
export type FilterKeys = FilterTypes;

export type Filters = Record<FilterTypes, FilterItem[]>;
export type SelectedFilters = Record<FilterTypes, string[]>;

export type FilterCounts = Record<FilterTypes, FacetCounts> & {
  tests: TestCounts;
  filtered: TestCounts;
};
