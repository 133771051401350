'use client';

import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { useMemo, useState } from 'react';
import { insertNodes } from '@udecode/plate-common';
import { useEditorRef } from '@udecode/plate-common/react';
import { useGetReportBlocksQuery } from '@app.raytd.com/store';
import { ReportBlock } from 'store/src/lib/services/types';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Badge } from '@/components/ui/badge';
import { Search, Clock, Archive } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';

interface ReportBlockComboboxProps {
    isOpen: boolean;
    onClose: () => void;
}

export function ReportBlockCombobox({ isOpen, onClose }: ReportBlockComboboxProps) {
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState<'all' | 'active' | 'archived'>('active');
    const editor = useEditorRef();
    const { data: allItems, isLoading } = useGetReportBlocksQuery();

    const reports = useMemo(() => {
        if (!allItems) return [];

        return allItems
            .filter(item => {
                const matchesSearch = item.name?.toLowerCase().includes(search.toLowerCase()) ||
                    item.description?.toLowerCase().includes(search.toLowerCase());
                const matchesFilter = filter === 'all' || item.status === filter;
                return matchesSearch && matchesFilter;
            })
            .sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());
    }, [allItems, search, filter]);

    const handleSelect = (report: ReportBlock) => {
        const reportBlock = {
            type: 'report-block',
            children: report.body,
            reportData: report
        };

        insertNodes(editor, reportBlock);
        onClose();
    };

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-[600px]">
                <DialogHeader>
                    <DialogTitle className="text-xl font-semibold">Insert Report Block</DialogTitle>
                </DialogHeader>

                <div className="space-y-4">
                    {/* Search and Filters */}
                    <div className="flex gap-4">
                        <div className="relative flex-1">
                            <Search className="absolute left-3 top-2.5 h-4 w-4 text-muted-foreground" />
                            <Input
                                placeholder="Search reports..."
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                className="pl-9"
                            />
                        </div>
                        <div className="flex gap-2">
                            <Badge
                                variant={filter === 'all' ? 'default' : 'outline'}
                                className="cursor-pointer"
                                onClick={() => setFilter('all')}
                            >
                                All
                            </Badge>
                            <Badge
                                variant={filter === 'active' ? 'default' : 'outline'}
                                className="cursor-pointer"
                                onClick={() => setFilter('active')}
                            >
                                Active
                            </Badge>
                            <Badge
                                variant={filter === 'archived' ? 'default' : 'outline'}
                                className="cursor-pointer"
                                onClick={() => setFilter('archived')}
                            >
                                Archived
                            </Badge>
                        </div>
                    </div>

                    {/* Results */}
                    <ScrollArea className="h-[400px] rounded-md border">
                        {isLoading ? (
                            <div className="flex items-center justify-center h-full">
                                <div className="animate-spin">Loading...</div>
                            </div>
                        ) : reports.length === 0 ? (
                            <div className="p-4 text-center text-muted-foreground">
                                No reports found
                            </div>
                        ) : (
                            <div className="p-4 space-y-2">
                                {reports.map((report) => (
                                    <button
                                        key={report.id}
                                        onClick={() => handleSelect(report)}
                                        className="w-full p-4 text-left hover:bg-muted rounded-lg transition-colors group relative"
                                    >
                                        <div className="flex items-start justify-between">
                                            <div className="space-y-1">
                                                <h3 className="font-medium group-hover:text-primary">
                                                    {report.name}
                                                </h3>
                                                <p className="text-sm text-muted-foreground line-clamp-2">
                                                    {report.description}
                                                </p>
                                            </div>
                                            {report.status === 'archived' && (
                                                <Archive className="h-4 w-4 text-muted-foreground" />
                                            )}
                                        </div>
                                        <div className="mt-2 flex items-center gap-4 text-xs text-muted-foreground">
                                            <span className="flex items-center gap-1">
                                                <Clock className="h-3 w-3" />
                                                Updated {formatDistanceToNow(new Date(report.updated_at))} ago
                                            </span>
                                            {report.organisation && (
                                                <span>
                                                    {report.organisation.name}
                                                </span>
                                            )}
                                        </div>
                                    </button>
                                ))}
                            </div>
                        )}
                    </ScrollArea>
                </div>
            </DialogContent>
        </Dialog>
    );
}
