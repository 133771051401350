import { Badge } from "@/components/ui/badge"
import { ProcessedTestData } from '../utils/processTestData'
import { convertRatingToIndex } from '@/app/features/reports/ratingHelpers'
import Rating, { RatingMixedScore } from '@/components/raytd/rating-tool'
import { getOverallRatingLabel, getMixedRatingLabel } from '../ratingHelpers';
import { SectionText } from "@/app/features/reports/components/section-text"

type TestDistributionSectionProps = {
    filteredData: ProcessedTestData | null;
    unfilteredData: ProcessedTestData | null;
    filtersActive: boolean;
}

export function TestDistributionSection({ filteredData, unfilteredData, filtersActive }: TestDistributionSectionProps) {
    if (!unfilteredData) {
        return (
            <section className="space-y-6">
                <div className="flex items-center justify-between">
                    <h2 className="text-xl font-semibold">Rating Distribution</h2>
                    <Badge variant="secondary">No Data Available</Badge>
                </div>
                <p>No test distribution data available. Please ensure that test data is properly loaded.</p>
            </section>
        );
    }

    return (
        <section className="space-y-6">
            <div className="flex items-center justify-between">
                <h2 className="text-xl font-semibold">Rating Distribution</h2>
                {filtersActive && <Badge variant="secondary">Filter Applied</Badge>}
            </div>

            <SectionText
                position="summary-test-distribution-before"
            />

            <RatingTestDistributionGrid filteredData={filteredData} unfilteredData={unfilteredData} filtersActive={filtersActive} />

            <SectionText
                position="summary-test-distribution-after"
            />
        </section>
    )
}

function getBgColor(scaleType: string | undefined) {

    switch (scaleType) {
        case 'compliance':
        case 'compliance_b':
            return 'bg-compliance-500';
        case 'condition':
            return 'bg-condition-500';
        case 'generic':
            return 'bg-gray-400';
        default:
            return 'bg-pink-400';
    }
};

type GroupData = {
    count: number;
    scaleTypes: Record<string, number>;
}

function RatingTestDistributionGrid({ filteredData, unfilteredData, filtersActive }: { filteredData: ProcessedTestData | null, unfilteredData: ProcessedTestData, filtersActive: boolean }) {
    const totalTests = unfilteredData.totalTests || 0;
    const groups = [
        { score: 1, rating: 'very_poor', label: 'Very Poor' },
        { score: 2, rating: 'poor', label: 'Poor' },
        { score: 3, rating: 'fair', label: 'Fair' },
        { score: 4, rating: 'good', label: 'Good' },
        { score: 5, rating: 'very_good', label: 'Very Good' },
        { score: undefined, rating: 'no_rating', label: 'No Rating' },
    ];

    return (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-2 items-end auto-rows-min">
            {groups.map((group, index) => {
                const unfilteredGroupData = unfilteredData.testDistribution[group.rating] || { count: 0, scaleTypes: {} };
                const filteredGroupData = filteredData?.testDistribution[group.rating] || { count: 0, scaleTypes: {} };
                const unfilteredGroupCount = unfilteredGroupData.count || 0;

                return (
                    <RatingCard
                        group={group}
                        index={index}
                        filteredData={filteredData}
                        unfilteredGroupData={unfilteredGroupData}
                        filteredGroupData={filteredGroupData}
                        unfilteredGroupCount={unfilteredGroupCount}
                        totalTests={totalTests}
                        filtersActive={filtersActive}
                    />
                );
            })}
        </div>
    )
}

const RatingCard = ({
    group,
    index,
    filteredData,
    unfilteredGroupData,
    filteredGroupData,
    unfilteredGroupCount,
    totalTests,
    filtersActive
}: {
    group: { score: number, rating: string, label: string },
    index: number,
    filteredData: ProcessedTestData | null,
    unfilteredGroupData: GroupData,
    filteredGroupData: GroupData,
    unfilteredGroupCount: number,
    totalTests: number,
    filtersActive: boolean
}) => {

    return (
        <div key={index} className="flex flex-col items-center bg-zinc-100 p-4 space-y-6 h-full">

            <div className="space-y-2 text-center">
                <div className="flex flex-col items-center space-y-3">
                    <div className="font-medium h-16 justify-center flex flex-col text-base">
                        {getMixedRatingLabel(group.rating as any, filteredData.ratingTypes as any).map((label: string, index: number) => (
                            <div key={index} className={index === 2 && 'text-sm'}>{label}</div>
                        )
                        )}
                    </div>

                    <RatingMixedScore
                        width={200}
                        height={40}
                        type={filteredData.ratingTypes as any}
                        score={group.score}

                    />

                    <div className="flex flex-col items-center">
                        <p className="text-xs font-medium py-1">Tests</p>
                        <p className="text-xl font-bold tracking-wider">
                            {filteredData ? filteredGroupData.count : unfilteredGroupCount}
                        </p>
                        <div className="text-base font-light text-accent-foreground">
                            ({filteredData && filteredData.totalTests > 0
                                ? ((filteredGroupData.count / filteredData.totalTests) * 100).toFixed(0)
                                : (totalTests > 0 ? ((unfilteredGroupCount / totalTests) * 100).toFixed(0) : 0)}%)
                        </div>
                    </div>

                </div>
            </div>

            <TestWaffle2
                unfilteredGroupData={unfilteredGroupData}
                filteredGroupData={filteredGroupData}
                unfilteredGroupCount={unfilteredGroupCount}
                totalTests={totalTests}
                filtersActive={filtersActive}
            />

        </div>
    );
}

const TestWaffle = ({
    unfilteredGroupData,
    filteredGroupData,
    unfilteredGroupCount,
    totalTests,
    filtersActive
}: {
    unfilteredGroupData: GroupData;
    filteredGroupData: GroupData;
    unfilteredGroupCount: number;
    totalTests: number;
    filtersActive: boolean;
}) => {
    return (
        <div className="w-32 h-32 grid grid-cols-10 gap-0.5 mb-4">
            {Array.from({ length: 100 }).map((_, index) => {
                const row = Math.floor(index / 10);
                const col = index % 10;
                const adjustedIndex = (9 - row) * 10 + col; // This reverses the row order
                const percentage = (unfilteredGroupCount / totalTests) * 100;
                const isFilled = adjustedIndex < Math.round(percentage);
                const scaleType = Object.keys(unfilteredGroupData.scaleTypes).find((_, i) =>
                    adjustedIndex < Math.round((Object.values(unfilteredGroupData.scaleTypes)[i] / unfilteredGroupCount) * percentage)
                );
                const isFiltered = !filtersActive || (filteredGroupData.scaleTypes[scaleType] && filteredGroupData.scaleTypes[scaleType] > 0);
                return (
                    <div
                        key={index}
                        className={`w-full h-full rounded-sm ${isFilled ? (isFiltered ? getBgColor(scaleType) : 'bg-zinc-200') : 'bg-zinc-50'}`}
                    />
                );
            })}
        </div>
    )
}

const TestWaffle2 = ({
    unfilteredGroupData,
    filteredGroupData,
    unfilteredGroupCount,
    totalTests,
    filtersActive
}: {
    unfilteredGroupData: GroupData;
    filteredGroupData: GroupData;
    unfilteredGroupCount: number;
    totalTests: number;
    filtersActive: boolean;
}) => {
    const requiredRows = Math.ceil(unfilteredGroupCount / 10);
    const totalRows = Math.max(requiredRows);

    const testToScaleType = Object.entries(unfilteredGroupData.scaleTypes).reduce((acc: string[], [scaleType, count]) => {
        return [...acc, ...Array(count).fill(scaleType)];
    }, []);

    return (
        <div
            className="w-32 flex flex-col mb-4 h-full"
            // style={{ height: `${Math.max(8, totalRows * 3.2)}rem` }}
        >
            {/* Spacer div that takes up remaining space */}
            <div className="flex-grow" />

            {/* Grid container with no flex-grow */}
            <div className="grid grid-cols-10 gap-0.5">
                {Array.from({ length: totalRows * 10 }).map((_, index) => {
                    const row = Math.floor(index / 10);
                    const col = index % 10;
                    const adjustedIndex = ((totalRows - 1) - row) * 10 + col;

                    const isFilled = adjustedIndex < unfilteredGroupCount;
                    const scaleType = isFilled ? testToScaleType[adjustedIndex] : null;
                    const isFiltered = !filtersActive || (
                        scaleType &&
                        filteredGroupData.scaleTypes[scaleType] &&
                        filteredGroupData.scaleTypes[scaleType] > 0
                    );

                    return (
                        <div
                            key={index}
                            className={`aspect-square rounded-sm ${isFilled
                                    ? (isFiltered ? getBgColor(scaleType) : 'bg-zinc-200')
                                    : 'bg-transparent'
                                }`}
                        />
                    );
                })}
            </div>
        </div>
    );
}
