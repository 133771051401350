import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "@/lib/utils"

export interface ValuePillProps extends VariantProps<typeof valuePillVariants> {
    value: number
    label: string
    icon?: React.ReactNode
    onClick?: () => void
}

const valuePillVariants = cva(
    "flex items-center justify-between rounded-md px-2 py-1.5 text-xs",
    {
        variants: {
            variant: {
                default: "bg-zinc-100 text-zinc-950",
                dark: "bg-zinc-500 text-white",
                inverted: "bg-white text-zinc-500 border border-zinc-300",
            },
            iconOnly: {
                true: "h-6 md:h-6",
                false: "",
            },
            size: {
                tiny: "text-xs py-1 px-1",
                small: "text-sm",
                default: "py-2 px-1",
            }
        },
        defaultVariants: {
            variant: "default",
            iconOnly: false,
            size: "default",
        },
    }
)

export interface ValuePillProps extends VariantProps<typeof valuePillVariants> {
    value: number
    label: string
    icon?: React.ReactNode
    onClick?: () => void
    className?: string
}

export function ValuePill({
    value,
    label,
    variant,
    size,
    icon,
    iconOnly = false,
    onClick,
    className
}: ValuePillProps) {
    return (
        <button
            className={cn(valuePillVariants({ variant, iconOnly, size }))}
            onClick={onClick}
            type="button"
            aria-label={`${value} ${label}`}
        >
            {!iconOnly && (
                <span className={cn("truncate", icon && "hidden lg:inline")}>
                    <span className={cn(className, "font-medium mr-1")}>{value}</span>
                    {label}
                </span>
            )}
            {icon && (
                <span
                    className={cn(
                        "flex items-center",
                        !iconOnly && "lg:hidden"
                    )}
                >
                    {icon}
                    <span className={cn(className, "font-medium text-xs md:text-sm ml-1")}>{value}</span>
                </span>
            )}
        </button>
    )
}