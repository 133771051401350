import { Button } from "@/components/ui/button"
import { Plus } from 'lucide-react'

interface AddSectionProps {
  onAdd: () => void;
}

export function AddSection({ onAdd }: AddSectionProps) {
  return (
    <div className="flex items-center w-full">
      <div className="flex-grow border-t border-gray-300"></div>
      <Button 
        variant="outline" 
        size="sm" 
        className="ml-4"
        onClick={onAdd}
      >
        <Plus className="h-4 w-4 mr-2" />
        Add Section
      </Button>
    </div>
  )
}

