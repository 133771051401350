import { TestCase, TestSuite } from "store/src/lib/tests/entity";

export interface TestSuiteResult {
    suite: TestSuite;
    specification: TestCase;
}

const getTestSuiteForSpecification = (
    testSuites: TestSuite[],
    specificationId: number
): TestSuiteResult | undefined => {
    // Create a Map for O(1) lookup if function is called multiple times
    const suiteMap = new Map<number, TestSuiteResult>();

    // Early return for invalid inputs
    if (!testSuites?.length || !specificationId) {
        return undefined;
    }

    // Return cached result if exists
    if (suiteMap.has(specificationId)) {
        return suiteMap.get(specificationId);
    }

    // Single pass through suites
    const result = testSuites.find(suite =>
        suite.specifications?.some(spec => spec.id === specificationId)
    );

    if (result) {
        const specification = result.specifications.find(
            spec => spec.id === specificationId
        )!;
        const found = { suite: result, specification };
        suiteMap.set(specificationId, found);
        return found;
    }

    return undefined;
};

export default getTestSuiteForSpecification;