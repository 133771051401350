import { lazy, Suspense } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// eslint-disable-next-line
import '../../store/src/lib/mocks';

import TestPage from './utils/TestPage';
import { PrivateRoute } from './_components';
import LoadingFallback from '../components/layout/loading-fallback';
//import ProfileLoader from './layout2/ProfileLoader';
import { Logout } from './layout/logout';
import { PublicReportViewer } from '@/app/features/public-link/PublicReportViewer';
// const PublicReportViewer = lazy(() => import('./features/public-link/PublicReportViewer'));

// Lazy load layouts
const AppLayout = lazy(() => import('./layout2/AppLayout'));
const AuthLayout = lazy(() => import('@/../ui/src/lib/login/AuthLayout'));

// Lazy load route modules
const AuthRoutes = lazy(() => import('./features/auth'));
const DashboardPage = lazy(() => import('./features/dashboard/DashboardPage'));
const ProfilePage = lazy(() => import('./features/profile/ProfilePage'));
const OrganisationPage = lazy(() => import('./features/organisation/OrganisationPage'));
const AssetRoutes = lazy(() => import('./features/assets'));
const ElementRoutes = lazy(() => import('./features/elements'));
const TestSuiteRoutes = lazy(() => import('./features/testsuites'));
const BlockRoutes = lazy(() => import('./features/blocks'));
const InspectionRoutes = lazy(() => import('./features/inspections'));
const ReportRoutes = lazy(() => import('./features/reports'));
const AdminRoutes = lazy(() => import('./features/admin'));

// const ErrorThrowingComponent: React.FC = () => {
//   throw new Error('This is a simulated error');
// };

const router = createBrowserRouter([
  // Public auth routes

  {
    path: '/report/view/:uuid/*',
    element: <PublicReportViewer />,
  },

  {
    element: (
      <Suspense fallback={<LoadingFallback />}>
        <AuthLayout />
      </Suspense>
    ),
    children: [
      {
        path: '/*',
        element: (
          <Suspense fallback={<LoadingFallback />}>
            <AuthRoutes />
          </Suspense>
        )
      }
    ],
    errorElement: (
      <div className="flex flex-col items-center justify-center min-h-screen p-4 text-center">
        <h1 className="text-2xl font-bold text-red-600 mb-4">Something went wrong</h1>
        <p className="mb-4 text-gray-700">We encountered an error</p>
      </div>
    )
  },
  // Protected routes
  {
    element: <PrivateRoute />,
    children: [
      {
        path: '/',
        element: (
          <Suspense fallback={<LoadingFallback />}>
            <AppLayout />
          </Suspense>
        ),
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<LoadingFallback />}>
                <DashboardPage />
              </Suspense>
            )
          },
          {
            path: 'profile/*',
            element: (
              <Suspense fallback={<LoadingFallback />}>
                <ProfilePage />
              </Suspense>
            )
          },
          {
            path: 'organisation/*',
            element: (
              <Suspense fallback={<LoadingFallback />}>
                <OrganisationPage />
              </Suspense>
            )
          },
          // Feature routes
          {
            path: 'assets/*',
            element: (
              <Suspense fallback={<LoadingFallback />}>
                <AssetRoutes />
              </Suspense>
            )
          },
          {
            path: 'elements/*',
            element: (
              <Suspense fallback={<LoadingFallback />}>
                <ElementRoutes />
              </Suspense>
            )
          },
          {
            path: 'test-suites/*',
            element: (
              <Suspense fallback={<LoadingFallback />}>
                <TestSuiteRoutes />
              </Suspense>
            )
          },
          {
            path: 'report-blocks/*',
            element: (
              <Suspense fallback={<LoadingFallback />}>
                <BlockRoutes />
              </Suspense>
            )
          },
          {
            path: 'inspections/*',
            element: (
              <Suspense fallback={<LoadingFallback />}>
                <InspectionRoutes />
              </Suspense>
            )
          },
          {
            path: 'reports/*',
            element: (
              <Suspense fallback={<LoadingFallback />}>
                <ReportRoutes />
              </Suspense>
            )
          },
          {
            path: 'test',
            element: (
              <Suspense fallback={<LoadingFallback />}>
                <TestPage />
              </Suspense>
            )
          }
        ]
      },
      // Admin routes
      {
        path: 'admin/*',
        element: (
          <Suspense fallback={<LoadingFallback />}>
            <AdminRoutes />
          </Suspense>
        )
      }
    ]
  },
  {
    path: 'logout',
    element: (
      <Suspense fallback={<LoadingFallback />}>
        <Logout />
      </Suspense>
    )
  },
  // Catch-all route for 404
  {
    path: '*',
    element: (
      <div className="flex h-screen items-center justify-center">
        <h1 className="text-2xl font-semibold">404 - Page Not Found</h1>
      </div>
    )
  }
]);

export const App = () => {
  
  return (
    <RouterProvider router={router} />
  )
}

export default App;
