import { CenteredCard } from "@/components/raytd/centered-content-card";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { useNavigate } from "react-router-dom";

const NoDraftRevisionError = () => {
    const navigate = useNavigate();

    return (

        <CenteredCard className="max-h-[600px]">
            <CenteredCard.Header>No Draft Revision</CenteredCard.Header>
            
            <div className="text-sm text-zinc-500">
                Create a new revision to begin editing.
            </div>
            <Button
                variant="default"
                onClick={() => {
                    navigate('../publish', { relative: 'path' });
                }}
            >
                Create New Revision
            </Button>
        </CenteredCard>
    );
};

export default NoDraftRevisionError;