import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { useAppSelector } from '@app.raytd.com/store';
import { useReportPresence } from '@/hooks/use-presence';

const PresenceIndicator = ({ reportId }: { reportId: string }) => {
    // const activeUsers = useAppSelector(
    //     state => state.presence.activeUsers
    // );

    const presenceByReport = useAppSelector(
        state => state.presence.presenceByReport[reportId] ?? {}
    );

    console.log('Active users in component:', presenceByReport);
    console.log('Number of active users:', Object.keys(presenceByReport)?.length);

    return (
        <div className="flex gap-2">
            {Object.values(presenceByReport).map(user => (
                <div key={user.id} className="relative">
                    <Avatar className="w-8 h-8">
                        <AvatarImage src={user.avatar} />
                        <AvatarFallback>
                            {user.name[0].toUpperCase()}
                        </AvatarFallback>
                    </Avatar>
                    <div className={`absolute bottom-0 right-0 w-3 h-3 rounded-full 
                        ${user.status === 'editing' ? 'bg-yellow-500' : 'bg-green-500'}`}
                    />
                </div>
            ))}
        </div>
    );
};

export default PresenceIndicator;