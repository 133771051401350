import React, { useCallback, useEffect, useRef } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { v4 as uuidv4 } from 'uuid';
import { TextSection } from '../types/report';
import { AddSection } from './add-section';
import { CustomSection } from './custom-section';
import { useReportSectionPage } from './report-section-context';
import { GripVertical } from 'lucide-react';

interface CustomSectionContainerProps {
  position: string;
  onEmpty: () => void;
  editMode: boolean;
  reportSections?: TextSection[];
  onSectionsChange: (sections: TextSection[]) => void;
  onEdit: (active: boolean) => void;
}

export function CustomSectionContainer({
  position,
  onEmpty,
  editMode,
  reportSections = [],
  onSectionsChange,
  onEdit
}: CustomSectionContainerProps) {

  const filteredSections = reportSections.filter(section =>
    section.position === position && !section.deleted
  );

  useEffect(() => {
    if (filteredSections.length === 0) {
      onEmpty()
    }
  }, [filteredSections, onEmpty])

  const addSection = useCallback(() => {
    const newSection: TextSection = {
      id: uuidv4(),
      content: '',
      position,
      report_id: undefined,
      deleted: false
    }
    onSectionsChange([...reportSections, newSection])
  }, [position, reportSections, onSectionsChange])

  const removeSection = useCallback((id: string) => {
    const updatedSections = reportSections.map(section =>
      section.id === id
        ? { ...section, deleted: true }
        : section
    )
    onSectionsChange(updatedSections)
  }, [reportSections, onSectionsChange])

  const updateSection = useCallback((id: string, newContent: string) => {
    const updatedSections = reportSections.map(section =>
      section.id === id ? { ...section, content: newContent } : section
    )
    if (JSON.stringify(updatedSections) !== JSON.stringify(reportSections)) {
      onSectionsChange(updatedSections)
    }
  }, [reportSections, onSectionsChange])

  const moveSection = useCallback((dragIndex: number, hoverIndex: number) => {
    console.log('dragIndex', dragIndex, 'hoverIndex', hoverIndex);

    // Create a reordered copy of filteredSections (non-deleted sections)
    const newFilteredSections = [...filteredSections];
    const [removed] = newFilteredSections.splice(dragIndex, 1);
    newFilteredSections.splice(hoverIndex, 0, removed);

    // Reconstruct reportSections by replacing non-deleted sections with reordered ones
    let nonDeletedIndex = 0;
    const updatedSections = reportSections.map(section => {
      if (section.deleted) {
        // Preserve deleted sections as they are
        return section;
      }
      // Replace non-deleted sections with their reordered counterparts
      const reorderedSection = newFilteredSections[nonDeletedIndex];
      nonDeletedIndex++;
      return reorderedSection;
    });

    // Trigger onSectionsChange with the updated sections
    onSectionsChange(updatedSections);
  }, [filteredSections, reportSections, onSectionsChange]);

  if (!editMode && filteredSections.length === 0) {
    return (
      <div className="flex items-center w-full my-6">
        <div className="flex-grow border-t border-zinc-200"></div>
      </div>
    );
  }

  if (!editMode) {
    return (
      <div className="space-y-4 py-3 border-t border-zinc-200">
        {filteredSections.map((section, index) => (
          <CustomSection
            key={section.id}
            id={section.id}
            content={section.content}
            onDelete={() => { /** */ }}
            onUpdate={() => { /** */ }}
            editMode={false}
            onEdit={onEdit}
            handleRef={null}
          />
        ))}
      </div>
    )
  }

  return (
    <div className="space-y-4 py-8 border-zinc-200">
      {editMode && <h3 className="font-medium text-zinc-200 text-xs text-right">{position}</h3>}
      <DndProvider backend={HTML5Backend}>
        {filteredSections.map((section, index) => (
          <DraggableSection
            key={section.id}
            id={section.id}
            index={index}
            content={section.content}
            onDelete={() => removeSection(section.id)}
            onUpdate={updateSection}
            moveSection={moveSection}
            editMode={editMode}
            onEdit={onEdit}
          />
        ))}
      </DndProvider>
      {editMode && <AddSection onAdd={addSection} />}
    </div>
  )
}

interface DraggableSectionProps {
  id: string
  index: number
  content: string
  onDelete: () => void
  onUpdate: (id: string, content: string) => void
  moveSection: (dragIndex: number, hoverIndex: number) => void
  editMode: boolean
  onEdit: (active: boolean) => void
}

const DraggableSection = React.memo(({ id, index, content, onDelete, onUpdate, moveSection, editMode, onEdit }: DraggableSectionProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const handlerRef = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop({
    accept: 'SECTION',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: { id: string; index: number }, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset!.y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      moveSection(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'SECTION',
    item: () => {
      return { id, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => {
      return true
    }
  })

  const opacity = isDragging ? 0 : 1
  
  drag(handlerRef)
  preview(ref)
  drop(ref)

  return (
    <div ref={ref} style={{ opacity }} data-handler-id={handlerId} className="mb-4">
      <CustomSection
        id={id}
        content={content}
        onDelete={onDelete}
        onUpdate={onUpdate}
        editMode={editMode}
        onEdit={onEdit}
        handleRef={handlerRef}
      />
      <div className="h-1 w-full bg-zinc-200" />
    </div>
  )
});

export const ManagedCustomSectionContainer = ({ position }: {
  position: string;
}) => {

  const { editMode, sectionManager, setIsEditorActive } = useReportSectionPage();

  const sections = sectionManager.getSectionsByPosition(position as any);

  const onSectionsChange = useCallback((newSections: TextSection[]) => {
    sectionManager.updateSectionsByPosition(position as any, newSections)
  }, [position, sectionManager])

  return (
      <CustomSectionContainer
        position={position}
        onEmpty={() => { /** */ }}
        editMode={editMode}
        reportSections={sections}
        onSectionsChange={onSectionsChange}
        onEdit={setIsEditorActive}
      />
  )
}
