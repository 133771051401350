"use client"

import { useState } from "react"

export function useExpandableOptions() {
  const [expandedValue, setExpandedValue] = useState<string | null>(null)

  const handleToggle = (value: string) => {
    setExpandedValue((prev) => (prev === value ? null : value))
  }

  console.debug("expandedValue", expandedValue)

  const isExpanded = (value: string) => expandedValue === value

  const isActive = (value: string) => expandedValue === value || expandedValue === null || expandedValue === undefined

  return {
    handleToggle,
    isExpanded,
    isActive,
  }
}

