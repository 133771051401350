import { FormControl, FormDescription, FormField, FormItem, FormMessage } from "@/components/ui/form"
import { FormLabelWithIndicator } from "../form-label-with-indicator"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { CustomFieldProps } from '../custom-fields-container';
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";

type CustomRadioFieldProps = CustomFieldProps & {
  options: { id: string; displayName: string }[]
}

export function CustomRadioField({
  control,
  name,
  label,
  description,
  showDescription,
  info,
  required,
  options,
  isValid,
  showFormMessage,
}: CustomRadioFieldProps) {

  const { trigger, setValue } = useFormContext();

  const handleOnChange = useCallback((value: string) => {
    console.debug('handleOnChange', name, value);
    setValue(name, value, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
    trigger();
  }, [setValue, trigger, name]);

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabelWithIndicator
            label={label}
            isValid={isValid}
            required={required}
            info={info}
          />
          <FormControl>
            <RadioGroup
              onValueChange={handleOnChange}
              value={field.value}
              className="flex flex-col space-y-0.5"
              aria-label={label}
              aria-describedby={description ? `${name}-description` : undefined}
            >
              {options.map((option) => (
                <div key={option.id} className="flex items-center space-x-2">
                  <RadioGroupItem value={option.id} id={`${name}-${option.id}`} />
                  <label
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    htmlFor={`${name}-${option.id}`}
                  >
                    {option.displayName}
                  </label>
                </div>
              ))}
            </RadioGroup>
          </FormControl>
          {showDescription && (
            <FormDescription id={`${name}-description`}>{description}</FormDescription>
          )}
          {showFormMessage && <FormMessage />}
        </FormItem>
      )}
    />
  )
}