import { useAuth } from "../_components";
import { useEffect } from "react";

const ProfileLoader = ({ children }) => {
    const {refreshUser} = useAuth();

    useEffect(() => {
        const initializeProfile = async () => {
            await refreshUser();
            const module = await import('../../lib/pusher');
            module.initializeEcho();
        };
        initializeProfile();
    }, [refreshUser]);
  
    return children;
  };

  export default ProfileLoader;