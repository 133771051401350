import useLiveReportData from '@/app/features/reports/hooks/use-live-report-data';
import { useReportDataResult } from '@/app/features/reports/hooks/useReportData';
import { createContext, useContext, useMemo, type PropsWithChildren } from 'react';

// 1. Define the provider interface
export interface ReportDataProvider {
  type: 'live' | 'mock' | 'other' | 'published';
  useReportData: (
    reportId: string,
    includedTestsOnly: boolean
  ) => useReportDataResult;
}

export interface ReportDataProviderProps {
  provider: ReportDataProvider;
  additionalProps?: Record<string, unknown>;
}

// 2. Create context with a default provider
export const ReportDataProviderContext = createContext<ReportDataProvider>({
  type: 'live',
  useReportData: useLiveReportData
});

// 3. Create provider component with memoization
export function ReportDataProvider({
  provider,
  additionalProps,
  children
}: PropsWithChildren<ReportDataProviderProps>) {
  // Memoize the provider value to prevent unnecessary re-renders
  const value = useMemo(() => ({
    ...provider,
    ...additionalProps
  }), [provider.type, additionalProps]);
  
  return (
    <ReportDataProviderContext.Provider value={value}>
      {children}
    </ReportDataProviderContext.Provider>
  );
}

export const useReportDataProvider = () => {
  return useContext(ReportDataProviderContext);
}

// // 4. Create the main hook with memoization
// function useReportData(reportId: string, includedTestsOnly = false, initialFilters?: SelectedFilters) {
//   const provider = useContext(ReportDataProviderContext);
  
//   // Use the provider's implementation
//   const result = provider.useReportData(reportId, includedTestsOnly, initialFilters);
  
//   // Memoize expensive computations and returned object
//   const filters = useReportItemFilters(result.enrichedTestItems, result.report, initialFilters);
  
//   return useMemo(() => ({
//     ...result,
//     filters,
//     updateGroupedData: /* existing implementation */
//   }), [result, filters]);
// }

// 5. Example provider implementations
export const liveDataProvider: ReportDataProvider = {
  type: 'live',
  useReportData: (reportId, includedTestsOnly) => {
    return useLiveReportData(reportId, includedTestsOnly);
  }
};

// export const mockDataProvider: ReportDataProvider = {
//   type: 'mock',
//   useReportData: (reportId, includedTestsOnly, initialFilters) => {
//     // Mock implementation
//     return useMockReportData(reportId, includedTestsOnly, initialFilters);
//   }
// };
