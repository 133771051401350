import { useSaveReport } from '@/app/features/reports/hooks/use-save-report'
import BusyButton from '@/components/raytd/busy-button'
import { CheckIcon, DownloadIcon } from 'lucide-react'

interface SaveReportButtonProps {
    revisionId: number
    isSaved: boolean
    canSave: boolean
    className?: string
}

export function SaveReportButton({
    revisionId,
    isSaved,
    canSave,
    className
}: SaveReportButtonProps) {
    const { handleSave, isSaving } = useSaveReport({
        revisionId,
    })

    if (!canSave) {
        // return null
    }

    return (
        <BusyButton
            variant={isSaved ? "outlinelight" : "default"}
            onClick={handleSave}
            className={`flex flex-row items-center ${className}`}
            busyText="Saving..."
            isBusy={isSaving}
            disabled={isSaved || !canSave}
        >
            {isSaved ? (
                <><CheckIcon className="w-4 h-4 mr-1" />Saved</>
            ) : (
                <><DownloadIcon className="w-4 h-4 mr-1" />Save</>
            )}
        </BusyButton>
    )
}