import { ExcludedTestsBanner } from "@/app/features/reports/components/excluded-tests-badge";
import { useReportViewContext } from "@/app/features/reports/components/report-viewer-context";
import { CheckCircleIcon } from "lucide-react";

const PublishSummary = () => {
    const { filters: { filteredCounts } } = useReportViewContext();
    const includedTests = filteredCounts?.tests?.included ?? 0;
    const excludedTests = (filteredCounts?.tests?.total ?? 0) - includedTests;

    return (
        <>
            <div className="flex justify-start items-center bg-green-400/25 p-4 rounded-md gap-4">
                <CheckCircleIcon className="w-4 h-4 text-green-500" />
                <div className="text-sm text-foreground"><span className="font-medium">{includedTests}</span>&nbsp;Tests will be included in the published report</div>
            </div>
            <div className="flex justify-start items-center bg-orange-400/10 p-4 rounded-md gap-4">
                {excludedTests > 0 ? (
                    <ExcludedTestsBanner
                        tests={filteredCounts?.tests}
                        text={`${excludedTests} Tests will be excluded from the published report`}
                        vertical
                        showEmpty={false}
                    />
                ) : (
                    <div className="text-sm text-foreground">No tests have been excluded from the published report.</div>
                )}
            </div>
        </>
    )
}

export default PublishSummary;