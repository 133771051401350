import { createPlatePlugin } from "@udecode/plate-common/react"
import { ReportBlockElement } from '../../plate-ui/report-block-element';

export const ELEMENT_REPORT_BLOCK = 'report-block'

export const ReportBlockPlugin = createPlatePlugin({
  key: ELEMENT_REPORT_BLOCK,
  node: {
    isElement: true,
    component: ReportBlockElement,
    type: 'div'
  },
})
