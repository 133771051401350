import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { SignupFormData, SignupSchema, Step1Data } from '@/app/features/auth/signup/schema';
import Step1Form from '@/app/features/auth/signup/step-1-user-details';
import Step2 from '@/app/features/auth/signup/step-2-password';
import Step3 from '@/app/features/auth/signup/step-3-select-org';
import Step4 from '@/app/features/auth/signup/step-4-create-org';
import BusyButton from '@/components/raytd/busy-button';
import ErrorAlert from '@/components/raytd/form-error';
import SignupCardTitle from '@/components/raytd/sign-up-card-title';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardFooter, CardHeader } from '@/components/ui/card';
import { api, authActions, isAPIError, useAppDispatch, UsersEntity } from '@app.raytd.com/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader2 } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router-dom';
import APIError from 'store/src/lib/api/apiError';


const Step5 = () => {

    return (
        <div className="flex items-center justify-center">
            <Loader2 className="animate-spin w-10 h-10 text-zinc-400" />
        </div>
    )
}

type CreateUserResponse = {
    body: {
        user: UsersEntity;
        token: string;
    }
}

// Main Signup component that handles the steps
export function SignupPage({ useCard = true, onComplete }: { useCard?: boolean, onComplete?: () => void }) {


    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const prefill = location.state?.invite || {};
    const [step, setStep] = useState(location.state?.invite?.token ? 2 : 1);
    const hasToken = Boolean(prefill?.token);
    const [isBusy, setBusy] = useState(false);
    const [step1Data, setStep1Data] = useState<Step1Data | null>(null);
    const [error, setError] = useState<React.ReactElement | string | null>(null);
    const [postSignupRedirect, setPostSignupRedirect] = useState<string | null>(null);

    console.debug('SignupPage', {
        prefill,
        step,
        hasToken,
        isBusy,
        step1Data,
        error
    });

    const { register, handleSubmit, formState: { errors }, watch, reset } = useForm({
        resolver: zodResolver(SignupSchema),
        defaultValues: {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            password_confirmation: '',
        },
        mode: 'onBlur'
    });

    useEffect(() => {
        console.debug('prefill effect', prefill);
        if (prefill?.token) {

            reset({
                first_name: prefill?.user?.first_name || '',
                last_name: prefill?.user?.last_name || '',
                email: prefill?.user?.email || '',
                password: '',
                password_confirmation: '',
            });
            setStep1Data({
                first_name: prefill?.user?.first_name || '',
                last_name: prefill?.user?.last_name || '',
                email: prefill?.user?.email || '',
            });
        }
    }, [prefill?.token, reset]);

    const onStep1Complete = useCallback(async (data: Step1Data) => {
        setStep1Data(data);

        // Skip email check for invite users since we already know their email exists
        if (hasToken) {
            reset({
                ...data
            });
            setStep(2);
            return;
        }

        //confirm that the email is not already in use.
        // POST api/v1/email/check
        try {
            const response = await api.post('/api/v1/email/check', {
                email: data.email
            });
            reset({
                ...data
            });
            setStep(2);
            setError(null);
        } catch (error) {
            //verify 422
            if (error.response.status === 422) {
                setError('Account already exists. Have you forgotten your password?');
            } else {
                setError('Failed to check email');
            }
        }
    }, [reset, hasToken]);

    const handleGoBack = useCallback(() => {
        setError(null);
        setStep(step - 1);
    }, [step]);

    const handleSignupComplete = useCallback(() => {
        console.debug('handleSignupComplete', { onComplete, postSignupRedirect });
        setStep(5);
        setBusy(true);

        setTimeout(() => {
            if (onComplete) {
                onComplete();
            } else if (postSignupRedirect) {
                navigate(postSignupRedirect);
            } else {
                navigate('/profile');
            }
        }, 1000);
    }, [onComplete, navigate, postSignupRedirect]);

    const handleInviteSignup = useCallback(async (data: SignupFormData) => {
        console.info('Invite signup', data);

        setBusy(true);
        setError(null);

        const tokenType = prefill.metadata?.token_type || 'organization';

        try {
            const response: CreateUserResponse = await api.post('/api/v1/signup/invite', {
                token: prefill.token,
                password: data.password,
                password_confirmation: data.password_confirmation,
                email: data.email,
                first_name: prefill.user?.first_name || null,
                last_name: prefill.user?.last_name || null,
                type: tokenType
            });

            console.info('User created', response);
            dispatch(authActions.loginUser({ user: response.body.user, token: response.body.token }));
            await dispatch(authActions.getUser()).unwrap();

            if (tokenType === 'report') {
                const reportPath = `/reports/${prefill.metadata.report.report_id}/published/${prefill.metadata.report.revision_id}`;
                setPostSignupRedirect(reportPath);
                setStep(3);
            } else {
                // user already has an organisation, so we can navigate to the profile page.
                handleSignupComplete();
            }
        } catch (error) {
            console.debug('Failed to create user', { error });
            // Reset sensitive fields on error
            reset({
                ...data,
                password: '',
                password_confirmation: ''
            });
            if (error.response?.status === 401 &&
                error.response?.body?.errorCode === 'INVALID_OR_EXPIRED_INVITATION_TOKEN') {
                setError('Invalid or expired invite link. Please request a new invite.');
            } else if (error.response?.status === 422) {
                setError('Password requirements not met. Please try again.');
            } else if (error.response?.status === 409) {
                setError('This email is already registered. Please login instead.');
            } else {
                setError('An error occurred while creating your account. Please try again.');
            }
        } finally {
            setBusy(false);
        }
    }, [dispatch, navigate, prefill, reset, handleSignupComplete]);

    const handleRegisterUser = useCallback(async (data: SignupFormData) => {
        try {
            setError(null);
            setBusy(true);
            const response = await api.post('/api/v1/register', {
                ...data
            });

            console.info('register', response);

            if (response.status === 201) {
                console.debug('User created, logging in...');

                dispatch(authActions.loginUser({
                    user: response.body.user,
                    token: response.body.token
                }));

                await dispatch(authActions.getUser()).unwrap();
            }

            setStep(3);

        } catch (error) {
            console.error(error);
            setError((error as APIError)?.message);
        } finally {
            setBusy(false);
        }
    }, [dispatch]);

    const onSubmit = useCallback(async (data: SignupFormData) => {
        if (step === 2) {
            // Validate password and confirm password match
            // and attempt to create a user.
            if (hasToken) {
                await handleInviteSignup(data);
            } else {
                await handleRegisterUser(data);
            }

        } else if (step >= 3) {
            // Submit final form data here
            setStep((step) => step + 1);
        }
    }, [step, hasToken, handleInviteSignup, handleRegisterUser]);

    const handleSkipOrganisation = useCallback(async () => {
        setStep(5);
        console.log('Creating personal organisation');
        setError(null);
        setBusy(true);

        // Create org name from user name
        //const orgName = `${step1Data.first_name} ${step1Data.last_name}`;

        try {
            const orgResponse = await api.post('/api/v1/organisations/join', {
                //title: orgName,
            });

            console.log('Organisation created', orgResponse);
            await dispatch(authActions.getUser()).unwrap(); // Pull in the company created.
            handleSignupComplete();
        } catch (error) {
            console.error('Failed to create organisation', error);
            setError((error as APIError)?.message);
            setStep(3);
        } finally {
            setBusy(false);
        }
    }, [step1Data, dispatch, navigate, handleSignupComplete]);

    const renderStep = useCallback(() => {
        switch (step) {
            case 1:
                return <Step1Form onComplete={onStep1Complete} prefill={step1Data} />;
            case 2:
                return (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Step2 register={register} errors={errors} watch={watch} />
                        <BusyButton type="submit" className="w-full" isBusy={isBusy}>
                            Next
                        </BusyButton>
                    </form>
                );
            case 3:
                return (
                    <form onSubmit={handleSubmit(onSubmit)} className="flex-col flex space-y-6">
                        <Step3 completeSignUp={handleSkipOrganisation} />
                        <BusyButton type="submit" className="w-full" isBusy={isBusy}>
                            Create a new Organisation
                        </BusyButton>
                    </form>
                );
            case 4:
                return <Step4 onSuccess={handleSignupComplete} />;
            case 5:
                return <Step5 />;
            default:
                return null;
        }
    }, [step, step1Data, isBusy, handleGoBack, handleSkipOrganisation, handleSubmit, onSubmit, handleSignupComplete]);

    return (
        useCard ? (
            <Card className="w-96 border-none shadow-none">
                <CardHeader className="mb-4">
                    <h1 className="text-3xl font-bold text-zinc-900">Welcome to Raytd.</h1>
                    <SignupCardTitle className="text-left text-xl text-zinc-800">
                        {step === 1 && "Create your free account"}
                        {step === 2 && "Choose your password"}
                        {step === 3 && "Your Organisation"}
                        {step === 4 && "Create a new Organisation"}
                        {step === 5 && "Creating your profile..."}
                    </SignupCardTitle>
                </CardHeader>
                <CardContent>

                    {error && (<ErrorAlert description={error} />)}
                    {renderStep()}
                </CardContent>

                <CardFooter className="flex justify-center">
                    {(step === 2 && error) || step === 3 || step === 4 && (
                        <Button variant='ghost' className="w-full" onClick={handleGoBack} >
                            Back
                        </Button>
                    )}

                    {step === 3 && (
                        <Button variant="ghost" className="w-full" onClick={handleSkipOrganisation}>
                            Skip
                        </Button>
                    )}
                </CardFooter>
            </Card>
        ) : (
            <div className="flex flex-col space-y-6">
                {error && (<ErrorAlert description={error} />)}
                {renderStep()}
                <div className="flex justify-center mt-4">
                    {(step === 2 && error) || step === 3 || step === 4 && (
                        <Button variant='ghost' className="w-full" onClick={handleGoBack} >
                            Back
                        </Button>
                    )}

                    {step === 3 && (
                        <Button variant="ghost" className="w-full" onClick={handleSkipOrganisation}>
                            Skip
                        </Button>
                    )}
                </div>

            </div>
        )
    );
}

export default SignupPage;