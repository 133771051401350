import {
  ReportTestItem,
  TestSpecification,
} from '@/app/features/reports/utils/entities';
import { useCallback, useMemo } from 'react';

export type TextComparison = ReportTestItem & {
  observations: string;
  recommendations: string;
  observations_unmatched?: string[];
  recommendations_unmatched?: string[];
  hasUnmatchedSegments?: boolean;
  [key: string]: any;
};

export interface ComparisonConfig {
  field: string;
  predefinedTexts?: string[];
}

/**
 * Removes punctuation and normalizes text for comparison
 */
const cleanText = (text: string): string => {
  return text
    .toLowerCase()
    .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '')
    .replace(/\s+/g, ' ')
    .trim();
};

export const useUnmatchedSegments = (
  createComparisonConfig: (testSpecification: TestSpecification) => ComparisonConfig[],
  getPredefinedTexts: (
    field: string,
    testSpecification: TestSpecification
  ) => string[]
) => {

  const segmentCache = useMemo(() => new Map<string, string[]>(), []);

  const boundaryRegex = /([.!?]+[\s\n]+|\n+|\r\n+|(?<=[.!?])\s+)/;

  const findUnmatchedSegments = useCallback(
    (text: string, predefinedTexts: string[]): string[] => {
      if (!text || predefinedTexts.length === 0) return [];

      const cleanedPredefinedTexts = predefinedTexts.map(cleanText);
      // const segments = text.split(/([.!?]+\s+)/);
      const segments = text.split(boundaryRegex).filter(Boolean);
      const unmatchedSegments: string[] = [];

      segments.forEach((segment) => {
        const cleanedSegment = cleanText(segment);
        if (!cleanedSegment) return; // Skip empty segments

        // Check if this segment matches any predefined text
        const hasMatch = cleanedPredefinedTexts.some(
          (predefinedText) =>
            cleanedSegment === predefinedText ||
            predefinedText.includes(cleanedSegment)
        );

        if (!hasMatch && cleanedSegment.length > 0) {
          unmatchedSegments.push(segment);
        }
      });

      return unmatchedSegments;
    },
    []
  );

  const configCache = useMemo(() => new Map<string, ComparisonConfig[]>(), []);

  const getComparisonConfig = useCallback(
    (testSpecification: TestSpecification) => {
      const cacheKey = JSON.stringify(testSpecification);
      if (!configCache.has(cacheKey)) {
        const config = createComparisonConfig(testSpecification);
        // console.log('getComparisonConfig', config);
        if (config) {
          configCache.set(cacheKey, config);
        }
      }
      return configCache.get(cacheKey)!;
    },
    [configCache, createComparisonConfig]
  );

  const checkFieldForUnmatchedSegments = useCallback(
    (field: string, test: TextComparison) => {

      const fieldValue = field.startsWith('custom.') ? test['custom']?.[field.split('.')[1]] : test[field];
      // console.log('comparisoncon beforecomparison fieldValue', {test,field, fieldValue});

      if (fieldValue) {
        const predefinedTexts = getPredefinedTexts(
          field,
          test.testSpecification
        );
        //console.log('comparisoncon beforecomparison predefinedTexts', {field,fieldValue, predefinedTexts});

        const unmatched = findUnmatchedSegments(fieldValue, predefinedTexts);
        return unmatched;
      }

      return [];
    },
    [findUnmatchedSegments, getPredefinedTexts]
  );

  const checkTestForUnmatchedSegments = useCallback(
    (test: TextComparison) => {
      if (!test) return test;

      const comparisonConfigs = getComparisonConfig(test.testSpecification);

      if (!comparisonConfigs) return test;

      const enriched = { ...test };

      comparisonConfigs.forEach(({ field }) => {
        const fieldValue = field.startsWith('custom.') ? test['custom']?.[field.split('.')[1]] : test[field];

        test.assessmentTestId === 2393 && console.log('comparisoncon beforecomparison fieldValue', {test,field, fieldValue});

        const unmatched = checkFieldForUnmatchedSegments(field, test);
        enriched[`${field}_unmatched`] = unmatched;
        enriched.hasUnmatchedSegments = enriched.hasUnmatchedSegments
          ? true
          : unmatched.length > 0;

      });

      return enriched;
    },
    [createComparisonConfig, findUnmatchedSegments, getPredefinedTexts]
  );

  const enrichTests = useCallback(
    (tests: TextComparison[]) => {
      if (!tests) return [];
      return tests?.map((test) => {
        return checkTestForUnmatchedSegments(test);
      });
    },
    [
      createComparisonConfig,
      findUnmatchedSegments,
      checkTestForUnmatchedSegments,
      getPredefinedTexts,
    ]
  );

  return {
    enrichTests,
    checkTestForUnmatchedSegments,
    checkFieldForUnmatchedSegments,
  };
};

export default useUnmatchedSegments;
