import { useCallback, useMemo } from 'react';
import useUnmatchedSegments, { TextComparison } from './use-unmatched-segments';

import { ValidationErrors } from '@/app/features/reports/types/missing-data';
import { TestSuite } from 'store/src/lib/tests/entity';
import usePredefinedTexts from './use-predefined-texts';
import getExclusionReason from '@/app/features/reports/utils/getExclusionReason';

function useEnrichedTestItems(
  allTestItems: any[],
  testSuites: TestSuite[],
  missingData: Map<number, ValidationErrors>,
  includedTestsOnly = false
) {
  const { getPredefinedTexts, createComparisonConfig } =
    usePredefinedTexts(testSuites);

  const { checkTestForUnmatchedSegments } = useUnmatchedSegments(
    createComparisonConfig,
    getPredefinedTexts
  );

  const enrichTestItem = useCallback(
    (testItem: TextComparison) => {
      const testItemWithUnmatchedSegments =
        checkTestForUnmatchedSegments(testItem);
      const hasFastFillErrors =
        testItemWithUnmatchedSegments.hasUnmatchedSegments &&
        !testItem.switches?.fast_fill_accepted;
      const hasMissingData = missingData.has(testItem.assessmentTestId);

      const reason = getExclusionReason(
        hasMissingData,
        hasFastFillErrors,
        testItem.status,
        testItem.switches.include === false
      );

      return {
        ...testItemWithUnmatchedSegments,
        hasUnmatchedSegments:
          testItemWithUnmatchedSegments.hasUnmatchedSegments,
        hasFastFillQA: hasFastFillErrors,
        hasMissingData,
        exclusionReason: reason,
      };
    },
    [checkTestForUnmatchedSegments, missingData]
  );

  return useMemo(() => {
    if (!allTestItems) return [];
    const items = allTestItems.map((testItem) =>
      enrichTestItem(testItem as TextComparison)
    );
    return includedTestsOnly
      ? items.filter((item) => item.exclusionReason === 'included')
      : items;
  }, [allTestItems, enrichTestItem, includedTestsOnly]);
}

export default useEnrichedTestItems;
