import React from 'react';
import ToggleWithBadge from '@/components/raytd/toggle-with-badge';
import { Button } from '@/components/ui/button';
import BulkEditDropdown from './bulk-actions-button';
import EditModeComponent from './edit-component';
import { ExcludedTestsBadge } from './excluded-tests-badge';
import FilterFacets from './filter-facets';
import FilterInput from './filter-input';
import { useReportViewContext } from './report-viewer-context';

interface ReportFilterBarProps {
    updateTestStatus: (id: string, status: string) => Promise<void>;
    handleFilterChange: (value: string) => void;
    showFastQA: boolean;
    setShowFastQA: React.Dispatch<React.SetStateAction<boolean>>;
    showMissingData: boolean;
    setShowMissingData: React.Dispatch<React.SetStateAction<boolean>>;
    missingSegments: number;
    missingDataCount: number;
    scrollToTest: (id: number) => void;
}

const ReportFilterBar: React.FC<ReportFilterBarProps> = ({
    updateTestStatus,
    handleFilterChange,
    showFastQA,
    setShowFastQA,
    showMissingData,
    setShowMissingData,
    scrollToTest,
}) => {

    const {
        filteredData,
        filters,
        missingDataCount,
        missingSegments,
    } = useReportViewContext();

    return (
        <div className="flex flex-row items-center gap-4 mb-2 pb-2 border-b border-zinc-200">
            <div className=''>
                {process.env.NODE_ENV === 'development' && (
                    <Button variant='outline' onClick={() => scrollToTest(3050)}>
                        Scroll to Test 1
                    </Button>
                )}
            </div>

            <EditModeComponent>
                <div className="flex items-center">
                    <BulkEditDropdown
                        filteredData={filteredData}
                        updateTestStatus={updateTestStatus}
                    />
                </div>
            </EditModeComponent>

            <FilterInput
                onFilterChange={handleFilterChange}
                placeholder="Search..."
            />

            <FilterFacets />

            <div className="flex-1"></div>

            <EditModeComponent>
                <ExcludedTestsBadge counts={filters?.filteredCounts?.tests} />

                <ToggleWithBadge
                    label="Fast Fill"
                    count={missingSegments}
                    isActive={showFastQA}
                    onToggle={() => setShowFastQA(prev => !prev)}
                    badgeClassName="bg-tests-fastfill"
                    activeClassName="data-[state=on]:bg-tests-fastfill text-white"
                />

                <ToggleWithBadge
                    label="Missing Data"
                    count={missingDataCount}
                    isActive={showMissingData}
                    onToggle={() => setShowMissingData(prev => !prev)}
                    badgeClassName="bg-red-400"
                    activeClassName="data-[state=on]:bg-red-400 text-white"
                />
            </EditModeComponent>
        </div>
    );
};

export default ReportFilterBar;