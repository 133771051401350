import FullScreenAlert from "@/components/raytd/full-screen-alert";
import { AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";

interface ErrorCardProps {
    title: string
    message?: string
    action?: () => void
    actionLabel?: string
}

function ErrorCard({ title, message, action, actionLabel = 'Go Back' }: ErrorCardProps) {
    return (
        <FullScreenAlert
            border
            buttons={
                action && (
                    <Button variant="outline" onClick={action}>
                        {actionLabel}
                    </Button>
                )
            }>
            <AlertTitle className="text-lg font-medium mb-6">
                {title}
            </AlertTitle>
            {message && (
                <AlertDescription>
                    {message}
                </AlertDescription>
            )}
        </FullScreenAlert>
    )
}

export default ErrorCard;