import { useAlert } from "@/app/contexts/AlertContext"
import { FastFillText } from "@/app/features/reports/components/fast-fill-text"
import { useFormErrorNavigation } from "@/app/features/reports/components/form-error-nav"
import TestFormDebugger from "@/app/features/reports/components/test-form/debugger"
import { BuildingNode, LevelNode } from "@/app/features/reports/types/spaces"
import { TestSuites } from "@/app/features/reports/types/test-specification"
import convertFormToTestItem from "@/app/features/reports/utils/convertFormToTestItem"
import convertInspectionToFormValues from "@/app/features/reports/utils/convertInspectionToFormValues"
import { FileUploader } from "@/components/image-upload/FileUploader"
import { MediaInfo } from "@/components/image-upload/types"
import RaytdResultPicker from "@/components/raytd/raytd-result-picker"
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { ScrollArea } from "@/components/ui/scroll-area"
import findRegistrationIssues from "@/utils/findRegistrationIssues"
import { getDefaultFieldSettings } from '@/utils/getDefaultFieldSettings'
import { Assessment, AssetEntity, Building, ClassificationTree, Floor, TestSpecificationsEntity, useAddAssetAreaMutation, useAddAssetLevelMutation, useAddBuildingMutation, useAddTestSpecificationFastFillMutation, useCreateClassificationItemMutation, useCreateTestItemMutation, useGetElementGroupsQuery, useUpdateReportTestItemMutation, useUpdateTestItemMutation } from '@app.raytd.com/store'
import { skipToken } from "@reduxjs/toolkit/query"
import { AnimatePresence, motion } from "framer-motion"
import { Loader2 } from 'lucide-react'
import React, { createContext, forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react"
import { FormProvider, useForm, UseFormReturn, useFormState, useWatch } from "react-hook-form"
import { toast } from "sonner"
import { TestItem, TestSuite } from 'store/src/lib/tests/entity'
import { v4 as uuid } from 'uuid'
import { useInspectionData } from "../hooks/useInspectionData"
import { useSpacesData } from "../hooks/useSpacesData"
import defaultValues from "../types/form-default-values"
import { type TestFormValues, type TestStatus } from '../types/test-form'
import filterActiveCustomFields from '../utils/filterActiveCustomFields'
import { useEnchancedDynamicResolver } from '../utils/testFormValidator'
import { AssetDisplay } from "./asset-display"
import { CustomFieldsContainer } from "./custom-fields-container"
import { ClassificationItem, ElementSelector } from "./element-selector"
import { FormLabelWithIndicator } from './form-label-with-indicator'
import TestSpecificationSelector from "./hierarchical-test-select"
import { InspectionSelector } from "./inspection-selector"
import { ReportViewerContext } from "./report-viewer-context"
import { SpaceSelector } from "./space-selector"
import { StatusIndicator } from "./status-indicator"
import FieldContainer from "./test-form/field-container"
import TestFormHeader, { TestFormHeaderProps } from "./test-form/header"
import InspectionFormSkeleton from './test-loading-skeleton'
import { useSpaceCreation } from "@/app/features/reports/components/test-form/useSpaceCreation"
import { SelectedTestSuite } from "@/app/features/reports/types/selected-test-suite"
import TestFormContext from "@/app/features/reports/components/test-form/context"

const getTestSuiteForSpecification = (testSuites: TestSuites[], specificationId: number): SelectedTestSuite | undefined => {
  console.debug('selectedTestSpecificationId', specificationId);

  if (!testSuites) {
    return undefined;
  }

  for (const suite of testSuites) {
    const specification = suite.specifications.find(spec => spec.id === specificationId);
    if (specification) {
      return { suite: suite, specification: specification } as unknown as { suite: TestSuite, specification: TestSpecificationsEntity };
    }
  }
  return undefined;
}

interface TestEditFormProps {
  inspections: Assessment[],
  testItem: TestItem,
  assets: AssetEntity[],
  testSuites: TestSuites[],
  onSubmit: (data: TestFormValues) => void
  onDirtyChange?: (isDirty: boolean) => void
  initialData?: Partial<TestFormValues>
  saveStatus?: string
  enableRightContent?: boolean
  rightContent?: React.ReactNode,
  HeaderComponent?: React.ComponentType<TestFormHeaderProps>
  onErrors?: (errors: any) => void
}

const TestEditForm = forwardRef<UseFormReturn<TestFormValues>, TestEditFormProps>(({
  inspections,
  assets,
  testSuites,
  testItem,
  onSubmit,
  onDirtyChange,
  initialData,
  saveStatus,
  enableRightContent,
  rightContent,
  onErrors,
  HeaderComponent = TestFormHeader
}, ref) => {

  const [testState, setTestState] = useState({
    selectedTestSpecificationId: null as number | null,
    isTestSelected: false
  });

  const [isFormLoading, setFormIsLoading] = useState(false);

  const reportId = useContext(ReportViewerContext)?.reportId ?? null;

  const { showAlertDialog } = useAlert();

  const formId = useRef<string>(undefined);
  const scrollAreaRef = useRef<HTMLDivElement>(null);

  const [isFormValid, setIsFormValid] = useState(false);

  const [isFormDirty, setIsFormDirty] = useState(false);

  const dirtyFlagRef = useRef(isFormDirty);

  // Update the ref whenever isFormDirty changes
  useEffect(() => {
    dirtyFlagRef.current = isFormDirty;
  }, [isFormDirty]);

  const [areRequiredFieldsFilled, setAreRequiredFieldsFilled] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const [isExistingTest, setIsExistingTest] = useState(false);

  const [createTest, { isLoading: isCreatingTest }] = useCreateTestItemMutation();
  const [updateTest, { isLoading: isUpdatingTest }] = useUpdateTestItemMutation();
  const [updateReportTest, { isLoading: isUpdatingReportTest }] = useUpdateReportTestItemMutation();

  // const resolver = useDynamicResolver(testSuites);
  const resolver = useEnchancedDynamicResolver(testSuites);

  const methods = useForm<TestFormValues>({
    // resolver: createThrottledResolver(createDynamicResolver(testSuites)),
    resolver: resolver,
    defaultValues: {
      ...defaultValues
    },
    mode: "onChange",
  });

  const asset_id = useWatch({
    control: methods.control,
    name: 'assetId',
  });

  const { groupedInspections } = useInspectionData(inspections as any, assets);
  const { spaces: organizedSpaces, isLoading: isSpacesLoading } = useSpacesData(asset_id);

  const isLoadingTestSuiteData = false;

  const selectedTestSuite = useMemo(() => {
    if (!testSuites) return undefined;
    return getTestSuiteForSpecification(testSuites, testState.selectedTestSpecificationId);
  }, [testSuites, testState.selectedTestSpecificationId]);

  const previousTestSuiteRef = useRef<number>(null);

  useEffect(() => {
    if (selectedTestSuite && selectedTestSuite.suite.id !== previousTestSuiteRef.current) {
      console.debug('Selected test suite changed', selectedTestSuite);

      if (!testItem?.assessmentTestId) {
        const customFields = filterActiveCustomFields({}, selectedTestSuite.suite);
        methods.setValue('custom', customFields.custom);
      }

      setTimeout(() => {
        methods.trigger();
      }, 100);

      previousTestSuiteRef.current = selectedTestSuite.suite.id;
    }
  }, [selectedTestSuite]);

  useEffect(() => {
    console.debug('isFormLoading', isFormLoading);
  }, [isFormLoading]);

  const assessmentId = useWatch({
    control: methods.control,
    name: "assessmentId",
  });

  const selectedAssessment = useMemo(() => {
    return inspections?.find(i => i.id === assessmentId);
  }, [inspections, assessmentId]);

  // useEffect(() => {
  //   console.debug('form state dirty', methods.formState.dirtyFields);
  // }, [methods.formState.dirtyFields]);

  // const { data: assessmentElementGroups, isLoading: elementGroupsAreLoading } = useGetElementTreesQuery(
  //   selectedAssessment?.elementGroupId ?? skipToken
  // );

  const { data: assessmentElementTree, isLoading: elementTreeIsLoading } = useGetElementGroupsQuery(
    selectedAssessment?.elementGroupId ?? skipToken
  );

  const elementGroups = useMemo(() => {
    if (!assessmentElementTree || !selectedTestSuite || !selectedAssessment) {
      return [];
    }

    const allowedGroups = Object.values(assessmentElementTree).filter(group =>
      group.test_suite_types?.includes(selectedTestSuite.suite.type)
    );

    console.debug('Allowed groups', allowedGroups);

    return allowedGroups;

    // const treeIds = allowedGroups.map(group => group.id);
    // return treeIds;
  }, [assessmentElementTree, selectedTestSuite, selectedAssessment]);

  const [isFormReady, setIsFormReady] = useState(false);

  const {
    currentErrorIndex, errorCount, handleNextError, handlePrevError, ErrorNavigation
  } = useFormErrorNavigation({ methods, formRef, isFormReady });

  // Add this effect to track when the form is fully ready
  useEffect(() => {
    if (
      !isLoadingTestSuiteData &&
      selectedTestSuite &&
      !isSpacesLoading &&
      !elementTreeIsLoading &&
      formRef.current
    ) {
      // Give React a chance to finish rendering all fields
      const timeoutId = setTimeout(() => {
        setIsFormReady(true);
      }, 100);

      return () => clearTimeout(timeoutId);
    }
  }, [isLoadingTestSuiteData, isSpacesLoading, elementTreeIsLoading, selectedTestSuite]);

  const resetForm = useCallback((
    initialData: Partial<TestFormValues & { formId: string; }> = {},
    options: { preserveDirtyState?: boolean } = {}
  ) => {

    const testSuite = getTestSuiteForSpecification(testSuites, initialData?.test_specification_id);
    console.debug('Resetting form', { initialData, testSuite });

    if (testSuite) {
      const custom = filterActiveCustomFields(initialData, testSuite.suite);

      console.debug('Resetting form with custom fields', { custom });

      methods.reset({
        ...defaultValues,
        ...custom,
      }, {
        keepDirty: options.preserveDirtyState ?? false
      });

    } else {

      methods.reset({
        ...defaultValues,
        ...initialData,

      }, {
        keepDirty: options.preserveDirtyState ?? false
      });
    }

    setTestState({
      selectedTestSpecificationId: initialData?.test_specification_id ? Number(initialData?.test_specification_id) : null,
      isTestSelected: !!initialData?.test_specification_id
    });

    formId.current = initialData?.formId ?? uuid();

    methods.trigger();

  }, [methods, testSuites]);

  const handleSave = useCallback(async (data: TestFormValues) => {

    const saveValues = {
      ...convertFormToTestItem(data),
      reportId: reportId ?? undefined,
      assessmentTestId: testItem?.assessmentTestId ?? undefined,
      formId: formId.current,
      fastFillChanged: Array.from(fastFillChangedRef.current)
    }
    console.debug('Saving:', data.status, { formId, draftData: data, saveValues });

    try {

      let result = undefined;

      if (isExistingTest) {

        if (reportId) {
          result = await updateReportTest(saveValues).unwrap();
        } else {
          result = await updateTest(saveValues).unwrap();
        }
        console.debug('Test updated', result);
        toast.success('Test updated');

      } else {
        result = await createTest(saveValues).unwrap();
        console.debug('Test created', result);
        toast.success('Test created');

      }
      resetForm(convertInspectionToFormValues(result));
      onSubmit?.(result);
      fastFillChangedRef.current.clear();

      return true;
    } catch (error) {

      console.debug('Error saving test', error);
      showAlertDialog({
        title: 'Error saving test',
        description: 'An error occurred while saving the test. Please try again later.',
        confirmText: 'OK',
        cancelText: undefined
      });
    }
  }, [methods, testItem, isExistingTest, createTest, resetForm, onSubmit, showAlertDialog, reportId]);

  const handleStatusChange = useCallback(async (status: TestStatus) => {
    methods.setValue("status", status);

    if (status === 'published' || status === 'qa') {
      methods.handleSubmit(handleSave)();
    } else {
      const data = methods.getValues();
      await handleSave(data);
    }

  }, [methods, handleSave]);

  const handleTestSelect = useCallback((value: number | null) => {
    const originalTestSpecId = methods.formState.defaultValues?.test_specification_id;
    console.debug('handleTestSelect', { originalTestSpecId, value });
    setTestState(prevState => ({
      ...prevState,
      selectedTestSpecificationId: value,
      isTestSelected: !!value
    }));
    if (value) {
      methods.setValue("test_specification_id", value, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
      //clear the result
      if (originalTestSpecId !== value) {
        methods.setValue("result", null);
      }
    } else {
      methods.setValue("test_specification_id", undefined);
    }
  }, [methods]);

  // useImperativeHandle(ref, () => ({
  //   resetForm: resetForm
  // }), [resetForm]);

  useImperativeHandle(ref, () => methods);

  useEffect(() => {
    onDirtyChange?.(isFormDirty);
  }, [isFormDirty, onDirtyChange]);

  useEffect(() => {

    if (dirtyFlagRef.current) {
      // Don't reset the form if it's dirty; Reset can be triggered by the test or test suite updating
      // Due to new options added to the underlying data
      return;
    }

    setFormIsLoading(true);

    console.debug('Form reset effect', { testItem, isDirty: dirtyFlagRef.current });

    if (testItem) {

      const initialData = convertInspectionToFormValues(testItem);
      resetForm(initialData);

    } else {
      resetForm();
    }

    setIsExistingTest(!!testItem?.assessmentTestId);

    console.debug('Form reset', { testItem, initialData });
    methods.trigger();
    fastFillChangedRef.current.clear();

    setTimeout(() => {
      setFormIsLoading(false);
    }, 50);

  }, [testItem, methods, resetForm]);

  const formState = useFormState({
    control: methods.control
  });

  const previousErrors = useRef<any>({});

  useEffect(() => {
    const { errors, isValid, dirtyFields, touchedFields, isDirty } = formState;
    const values = methods.getValues();

    const requiredFieldsFilled = !!values.assessmentId &&
      !!values.test_specification_id &&
      !!values.assetId;

    const issues = findRegistrationIssues(formState.defaultValues, values);

    if (Object.keys(issues)?.length > 0) {
      console.debug('Registration issues', issues);
    }

    console.debug('Are reqd fields filled', {
      isValid, requiredFieldsFilled, errors,
      isDirty, dirtyFields,
      values: values,
      defaultValues: formState.defaultValues
    });

    setIsFormValid(isValid);
    setIsFormDirty(isDirty);
    setAreRequiredFieldsFilled(requiredFieldsFilled);

  }, [formState, methods]);

  // Throttle error updates to avoid excessive calls
  useEffect(() => {
    if (!onErrors) return;

    const timeoutId = setTimeout(() => {
      if (JSON.stringify(formState?.errors) !== JSON.stringify(previousErrors.current)) {
        onErrors?.(formState?.errors);
        previousErrors.current = formState?.errors;
      }
    }, 500); // 500ms throttle

    return () => clearTimeout(timeoutId);
  }, [formState?.errors, onErrors]);



  const [addTestSpecificationFastFill, { isLoading: isAddingFastFill }] = useAddTestSpecificationFastFillMutation();

  const handleFastFillAdd = useCallback(async (type: 'observations' | 'recommendations', text: string) => {
    if (!selectedTestSuite.suite.allow_add_fast_fills) {
      toast.error('Fast fill is not allowed for this test suite');
      return;
    }
    try {
      await addTestSpecificationFastFill({
        testSpecificationId: selectedTestSuite.specification.id,
        data: {
          type,
          description: text
        }
      }).unwrap();

      toast.success('Fast fill added');
    } catch (err) {
      console.error('Error adding fast fill', err);
      toast.error('Error adding fast fill');
    }

  }, [selectedTestSuite, addTestSpecificationFastFill]);

  const getFieldVisibility = useCallback((fieldName: string) => {
    if (!selectedTestSuite || !selectedTestSuite.suite.settings) return true;
    const defaultSettings = getDefaultFieldSettings(fieldName, selectedTestSuite?.suite?.type);
    const field = selectedTestSuite.suite.settings.find(s => s.field_name === fieldName);
    // return field ? (field.active ?? true) : true;
    return field ? field.active : defaultSettings.active;
  }, [selectedTestSuite]);

  const getFieldRequirement = useCallback((fieldName: string) => {

    if (['assessmentId', 'assetId', 'test_specification_id'].includes(fieldName)) return true;

    if (!selectedTestSuite || !selectedTestSuite.suite.settings) return false;

    //if fieldName starts with 'custom.' then return true
    if (fieldName.startsWith('custom.')) {
      //get the field name without the custom. prefix
      const customFieldId = fieldName.replace('custom.', '');
      const field = selectedTestSuite.suite.custom_fields.find(s => s.field_name === customFieldId);
      return field ? field.required : false;

    } else {
      const defaultSettings = getDefaultFieldSettings(fieldName, selectedTestSuite?.suite?.type);
      const field = selectedTestSuite.suite.settings.find(s => s.field_name === fieldName);
      return field ? field.required : defaultSettings.required
    }

  }, [selectedTestSuite]);


  const preventEnterSubmit = useCallback((e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === "Enter" && e.target instanceof HTMLInputElement) {
      e.preventDefault();
    }
  }, []);

  const handleTriggerValidation = useCallback(() => {
    methods.trigger();
  }, [methods.trigger]);

  const handleMediaChange = useCallback((updatedMedia: MediaInfo[]) => {
    const currentFiles = methods.getValues('images').filter(img => img instanceof File);
    methods.setValue('images', [...updatedMedia, ...currentFiles], {
      shouldValidate: true,
      shouldDirty: true
    });
  }, [methods]);

  const {
    handleCreateBuilding,
    handleCreateLevel,
    handleCreateArea
  } = useSpaceCreation({ asset_id })

  const [createElement, { isLoading: isAddingElement, isSuccess: isElementCreated, isError: isCreationError }] = useCreateClassificationItemMutation();

  const handleCreateElement = useCallback(async (type: 'item' | 'tree', node: ClassificationItem | ClassificationTree, element: string) => {
    console.debug('Creating element', { node, type, element });
    const result = await createElement({
      name: element,
      parent_id: type === 'item' ? (node as ClassificationItem).id : null,
      classification_tree_id: type === 'item' ? (node as ClassificationItem).classification_tree_id : (node as ClassificationTree).id
    }).unwrap();

    toast.success('Element created');

    return result;

  }, [createElement]);

  const fastFillChangedRef = useRef<Set<string>>(new Set());

  const handleFastFillChanged = useCallback((field_id: string) => {
    fastFillChangedRef.current.add(field_id);
  }, []);

  const contextValue = useMemo(() => ({
    formId: formId.current,
    isExistingTest,
    isFormDirty,
    isFormValid,
    isFormReady,
    isRequiredFieldsFilled: areRequiredFieldsFilled,
    selectedTestSuite,
    viewportRef: scrollAreaRef,
    getFieldVisibility,
    getFieldRequirement,
    onStatusChange: handleStatusChange,
    onFastFillChanged: handleFastFillChanged
  }), [
    formId.current,
    isExistingTest,
    isFormDirty,
    isFormValid,
    isFormReady,
    areRequiredFieldsFilled,
    selectedTestSuite,
    getFieldVisibility,
    getFieldRequirement,
    handleStatusChange,
    handleFastFillChanged
    // scrollAreaRef is a ref so it doesn't need to be in the dependency array
  ]);


  if (isSpacesLoading || elementTreeIsLoading) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        <InspectionFormSkeleton />
      </motion.div>
    )
  }


  if (!inspections || !testSuites || !assets) {
    return null;
  }

  return (
    (
      <TestFormContext.Provider value={contextValue}>
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "100%" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.2 }}
          className="h-full"
        >
          <FormProvider {...methods}>
            <Form {...methods}>
              <form ref={formRef}
                className="flex flex-col h-full"
                onKeyDown={preventEnterSubmit}
                onSubmit={methods.handleSubmit(onSubmit)}>

                <HeaderComponent
                  testItem={testItem}
                  enableRightContent={enableRightContent}
                  saveStatusComponent={<StatusIndicator />}
                  errorNavigationComponent={<ErrorNavigation />}
                />

                <ScrollArea className="flex-1 h-full pb-10" ref={scrollAreaRef}>
                  <div className=" p-6 space-y-6">

                    <FieldContainer
                      field_id="assessmentId"
                      showRightComponent={enableRightContent}
                    >
                      <InspectionSelector
                        name="assessmentId"
                        label="Inspection"
                        required={true}
                        inspections={groupedInspections}
                        editable={!isExistingTest} />
                    </FieldContainer>

                    <FieldContainer
                      field_id="assessmentId"
                      showRightComponent={enableRightContent}
                    >
                      <AssetDisplay
                        name="assetId"
                        label="Asset"
                        required={true}
                        inspections={groupedInspections} />
                    </FieldContainer>

                    <FieldContainer
                      field_id="test_specification_id"
                      showRightComponent={enableRightContent}
                    >
                      <TestSpecificationSelector
                        name="test_specification_id"
                        label="Test"
                        testSuites={testSuites}
                        onSelect={handleTestSelect}
                        labelComponent={<FormLabelWithIndicator label="Test" isValid={!!methods.watch("test_specification_id")} required={true} />}
                      />
                    </FieldContainer>

                    {isLoadingTestSuiteData && (
                      <div className="flex justify-center items-center py-4">
                        <Loader2 className="h-6 w-6 animate-spin" />
                      </div>
                    )}

                    <AnimatePresence>
                      {selectedTestSuite && areRequiredFieldsFilled && !isLoadingTestSuiteData && (
                        <motion.div
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: "auto" }}
                          exit={{ opacity: 0, height: 0 }}
                          transition={{ duration: .5 }}
                          className="space-y-6"
                        >
                          {/* Render form fields based on visibility and requirement */}

                          {getFieldVisibility('location') && (
                            <FieldContainer
                              field_id="location"
                              showRightComponent={enableRightContent}
                            >
                              <FormField
                                control={methods.control}
                                name="building_id"
                                render={({ field, fieldState }) => (
                                  <FormItem>
                                    <FormLabelWithIndicator
                                      label="Location"
                                      isValid={!fieldState.invalid}
                                      required={getFieldRequirement('location')}
                                    />
                                    <FormControl>
                                      <SpaceSelector
                                        spaces={organizedSpaces as any}
                                        disabled={!!!asset_id}
                                        isLoading={false}
                                        defaultValues={{
                                          building_id: field.value,
                                          level_id: methods.watch('level_id'),
                                          area_id: methods.watch('area_id')
                                        }}
                                        onSelect={handleTriggerValidation}
                                        onCreateBuilding={handleCreateBuilding}
                                        onCreateLevel={handleCreateLevel}
                                        onCreateArea={handleCreateArea}
                                        canCreate={true}
                                      />

                                    </FormControl>
                                  </FormItem>
                                )} />
                            </FieldContainer>
                          )}

                          {getFieldVisibility('element') && (
                            <FieldContainer
                              field_id="element"
                              showRightComponent={enableRightContent}
                            >
                              <FormField
                                control={methods.control}
                                name="element"
                                render={({ field }) => (
                                  <FormItem>
                                    <FormLabelWithIndicator label="Element" isValid={!!field.value} required={getFieldRequirement('element')} />
                                    <FormControl>
                                      <ElementSelector
                                        name="element"
                                        elements={elementGroups}
                                        onSelect={(value) => field.onChange(value)}
                                        isLoading={false}
                                        onCreateElement={handleCreateElement}
                                      />

                                    </FormControl>
                                  </FormItem>
                                )} />
                            </FieldContainer>
                          )}

                          {getFieldVisibility('element_name') && (
                            <FieldContainer
                              field_id="element_name"
                              showRightComponent={enableRightContent}
                            >
                              <FormField
                                control={methods.control}
                                name="element_name"
                                render={({ field }) => (
                                  <FormItem>
                                    <FormLabelWithIndicator label="Element Name" isValid={!!field.value} required={getFieldRequirement('element_name')} />
                                    <FormControl>
                                      <Input {...field} placeholder="Enter element name" />
                                    </FormControl>
                                  </FormItem>
                                )} />
                            </FieldContainer>
                          )}

                          {getFieldVisibility('element_id') && (
                            <FieldContainer
                              field_id="element_id"
                              showRightComponent={enableRightContent}
                            >
                              <FormField
                                control={methods.control}
                                name="element_id"
                                render={({ field }) => (
                                  <FormItem>
                                    <FormLabelWithIndicator label="Element ID" isValid={!!field.value} required={getFieldRequirement('element_id')} />
                                    <FormControl>
                                      <Input {...field} placeholder="Enter element ID" />
                                    </FormControl>
                                  </FormItem>
                                )} />
                            </FieldContainer>
                          )}

                          {getFieldVisibility('rating') && (
                            <FieldContainer
                              field_id="rating"
                              showRightComponent={enableRightContent}
                            >
                              <FormField
                                control={methods.control}
                                name="result"
                                render={({ field }) => (
                                  <FormItem>
                                    <FormLabelWithIndicator label="Result" isValid={!!field.value} required={getFieldRequirement('rating')} />
                                    <RaytdResultPicker
                                      name="result"
                                      onChange={(value) => field.onChange(value)}
                                      onBlur={field.onBlur}
                                      value={field.value}
                                      testSuite={selectedTestSuite.suite} />
                                  </FormItem>
                                )} />
                            </FieldContainer>
                          )}

                          {getFieldVisibility('observations') && (
                            <FieldContainer
                              field_id="observations"
                              showRightComponent={enableRightContent}
                              isFastFill={selectedTestSuite?.suite.type === 'compliance' && selectedTestSuite?.specification?.observations?.length > 0}
                            >
                              <FormField
                                control={methods.control}
                                name="observations"
                                render={({ field }) => (
                                  <FormItem>
                                    <FormLabelWithIndicator label="Observations" isValid={!!field.value} required={getFieldRequirement('observations')} />
                                    <FormControl>

                                      <FastFillText
                                        control={methods.control}
                                        name="observations"
                                        fastFills={selectedTestSuite?.specification?.observations ?? []}
                                        enableFastFill={selectedTestSuite?.suite.type === 'compliance'}
                                        allowAdd={selectedTestSuite?.suite.allow_add_fast_fills}
                                        onCreateOption={(value) => handleFastFillAdd('observations', value)}
                                      />
                                    </FormControl>
                                  </FormItem>
                                )} />
                            </FieldContainer>
                          )}

                          {getFieldVisibility('recommendations') && (
                            <FieldContainer
                              field_id="recommendations"
                              showRightComponent={enableRightContent}
                              isFastFill={selectedTestSuite?.suite.type === 'compliance' && selectedTestSuite?.specification?.recommendations?.length > 0}
                            >
                              <FormField
                                control={methods.control}
                                name="recommendations"
                                render={({ field }) => (
                                  <FormItem>
                                    <FormLabelWithIndicator label="Recommendations" isValid={!!field.value} required={getFieldRequirement('recommendations')} />
                                    <FormControl>
                                      <FastFillText
                                        control={methods.control}
                                        name="recommendations"
                                        fastFills={selectedTestSuite?.specification?.recommendations ?? []}
                                        enableFastFill={selectedTestSuite?.suite.type === 'compliance'}
                                        allowAdd={selectedTestSuite?.suite.allow_add_fast_fills}
                                        onCreateOption={(value) => handleFastFillAdd('recommendations', value)}
                                      />
                                    </FormControl>
                                  </FormItem>
                                )} />
                            </FieldContainer>
                          )}

                          {getFieldVisibility('photographs') && (
                            <FieldContainer
                              field_id="photographs"
                              showRightComponent={enableRightContent}
                            >
                              <FormField
                                control={methods.control}
                                name="images"
                                render={({ field }) => (
                                  <FormItem>
                                    <FormLabelWithIndicator
                                      label="Photographs"
                                      // isValid={!!methods.watch("images")} 
                                      isValid={!methods.getFieldState("images").invalid}
                                      required={getFieldRequirement('photographs')}
                                    />
                                    <FormControl>

                                      <FileUploader
                                        ref={field.ref}
                                        formId={formId.current}
                                        value={field.value.filter((img): img is MediaInfo => 'id' in img)}
                                        // onChange={field.onChange}
                                        onChange={handleMediaChange}
                                        maxFiles={50}
                                        maxSize={20000000}
                                        acceptedTypes={["image/jpeg", "image/jpg", "image/png"]}
                                        disabled={methods.formState.isSubmitting}
                                      />
                                    </FormControl>
                                  </FormItem>
                                )}
                              />
                            </FieldContainer>
                          )}

                          {testState.selectedTestSpecificationId && (
                            <CustomFieldsContainer
                              name="custom"
                              testSuite={selectedTestSuite.suite}
                              enableRightComponent={enableRightContent}
                            />
                          )}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                </ScrollArea>

              </form>
            </Form>
          </FormProvider>
          <TestFormDebugger
            methods={methods}
            resetForm={resetForm}
            formId={formId.current}
            selectedTestSuite={selectedTestSuite}
          />
        </motion.div>
      </TestFormContext.Provider>
    )
  );
});

export { TestEditForm }
