import { SectionManager } from '../types/section';
import { createContext, useContext, useState } from 'react';
import { useSectionManager } from './useSecltionManager';
import { useReportContext } from '@/app/features/reports/contexts/report-context';

interface SectionContextType {
    sectionManager: SectionManager; // Replace 'any' with your actual session manager type
    editMode: boolean;
    isEditorActive: boolean; // Whether the editor is active
    setIsEditorActive: (active: boolean) => void;
}

const SectionContext = createContext<SectionContextType | undefined>(undefined);

export function ReportSectionProvider({ page, children }: { page: string; children: React.ReactNode }) {

    const { report, editMode } = useReportContext();

    const [isEditorActive, setIsEditorActive] = useState(false);
    const sectionManager = useSectionManager(report, page);

    const value = {
        sectionManager,
        editMode,
        isEditorActive,
        setIsEditorActive
    };

    return (
        <SectionContext.Provider value={value}>
            {children}
        </SectionContext.Provider>
    );
}

export function useReportSectionPage() {
    const context = useContext(SectionContext);
    if (context === undefined) {
        throw new Error('usePage must be used within a ReportSectionProvider');
    }
    return context;
}
