import { RatingScale } from './rating-scale'
import { RatingScale as RatingScaleType } from '../types/report'
import { SectionText } from './section-text';
import { ReportEntity } from 'store/src/lib/services/types';
import { useMemo } from 'react';

type RatingSystemSectionProps = {
  report: ReportEntity;
}

export type ReportScaleWithTitles = RatingScaleType & { titles: string };

export function RatingSystemSection({
  report,
}: RatingSystemSectionProps) {

  console.debug('RatingSystemSection', report);

  const ratingTypes: ReportScaleWithTitles[] = useMemo(() => {

    if (!report?.testSuites) return [];

    const ratingScaleMap = new Map();

    report.testSuites.forEach(testSuite => {
      const ratingScale = testSuite.ratingScale;
      if (ratingScale) {
        if (!ratingScaleMap.has(ratingScale.id)) {
          ratingScaleMap.set(ratingScale.id, {
            ...ratingScale,
            titles: []
          });
        }
        ratingScaleMap.get(ratingScale.id).titles.push(testSuite.title);
      }
    });

    const combinedRatingScales = Array.from(ratingScaleMap.values()).map(ratingScale => ({
      ...ratingScale,
      titles: ratingScale.titles.join(', ')
    }));

    console.debug('Combined rating scales:', {combinedRatingScales, report});

    return combinedRatingScales.sort((a, b) => a.type.localeCompare(b.type));
  }, [report]);

  return (
    <section className="space-y-4">
      <h3 className="font-medium mb-2">Rating Systems</h3>
      <div className='text-sm mb-4'>Results shown in this report use one, or more, Raytd standardised Rating Systems.</div>

      <SectionText
        position="introduction-rating-system"
      />

      {ratingTypes.map((scale) => (
        <div key={scale.id} className="space-y-4">
          <RatingScale
            key={scale.id}
            scale={scale as any}
          // customDescription={customDescriptions[scale.id] || ''}
          // onUpdateDescription={onUpdateRatingDescription}
          // editMode={editMode}
          />
          <SectionText
            position={`introduction-rating-system-${scale.id}`}
          />
        </div>
      ))}
    </section>
  )
}

