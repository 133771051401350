import Rating, { RatingTypes } from '@/components/raytd/rating-tool';
import { convertRatingToIndex } from '@/app/features/reports/ratingHelpers';
import { ReportScaleWithTitles } from './rating-system-section';

interface RatingScaleProps {
  scale: ReportScaleWithTitles;
}

const NonRateableComponent = () => (
  <div className="col-span-5 p-4 rounded bg-gray-100 text-center h-full">
    Ratings not applicable
  </div>
);

function RatingTile({
  rating,
  label,
  description,
  score,
  type
}: {
  rating: 'very_poor' | 'poor' | 'fair' | 'good' | 'very_good';
  label: string | null;
  description: string | null;
  score: number;
  type: RatingTypes;
}) {
  if (!description) return null;

  const getBgColor = () => {
    switch (type) {
      case 'compliance':
      case 'compliance_b':
        return 'bg-green-50';
      case 'condition':
        return 'bg-blue-50';
      case 'generic':
        return 'bg-gray-50';
      default:
        return 'bg-white';
    }
  };

  return (
    <div className={`${getBgColor()} p-4 rounded-lg space-y-4 h-full flex flex-col`}>
      <div className="flex justify-between items-center">
        <h5 className="text-base font-medium capitalize">
          {label || rating.split('_').join(' ')}
        </h5>
        <div className="text-gray-500 text-sm border-2 border-gray-200 rounded px-2 py-1">
          {score.toFixed(2)}
        </div>
      </div>
      <Rating type={type} selected={convertRatingToIndex(rating)} width={240} height={40} />
      <div className="space-y-2 text-sm flex-grow whitespace-pre-wrap">

        {description.replace(/\\n/g, '\n').split('\n').map((str, index) => (
          <p key={index}>{str}</p>
        ))}

      </div>
    </div>
  );
}

const getRatingScore = (rating: string): number => {
  const scores: Record<string, number> = {
    'very_poor': 1,
    'poor': 2,
    'fair': 3,
    'good': 4,
    'very_good': 5
  };
  return scores[rating] || 0;
};

export function RatingScale({ scale }: RatingScaleProps) {
  const ratings = [
    { key: 'very_poor', label: scale.very_poor_label, description: scale.very_poor },
    { key: 'poor', label: scale.poor_label, description: scale.poor },
    { key: 'fair', label: scale.fair_label, description: scale.fair },
    { key: 'good', label: scale.good_label, description: scale.good },
    { key: 'very_good', label: scale.very_good_label, description: scale.very_good },
  ] as const;

  return (
    <div className="space-y-4">
      <div>
      <h3 className="font-medium mb-1">{scale.label}</h3>
      <h4 className="text-sm text-gray-500">This Rating System is used by the following Test Suites: {scale.titles}</h4>
      </div>
      {scale.rateable ? (
        <div className="grid grid-cols-5 gap-4 overflow-x-auto pb-2 min-h-72">
          {['very_poor', 'poor', 'fair', 'good', 'very_good'].map((key) => (
            <div key={key} className="h-full">
              {ratings.find(r => r.key === key) ? (
                <RatingTile
                  key={key}
                  rating={key as 'very_poor' | 'poor' | 'fair' | 'good' | 'very_good'}
                  label={ratings.find(r => r.key === key)?.label || null}
                  description={ratings.find(r => r.key === key)?.description || null}
                  score={getRatingScore(key)}
                  type={scale.type}
                />
              ) : (
                <div className="bg-gray-100 h-full rounded-lg"></div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <NonRateableComponent />
      )}
    </div>
  )
}