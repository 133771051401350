import { cn } from "@/lib/utils";

interface CenteredContentCardProps {
    children: React.ReactNode;
    className?: string;
}

const CenteredCard = ({ children, className }: CenteredContentCardProps) => {
    return <div className={cn("flex flex-col gap-2 h-full w-full justify-center items-center place-content-center min-h-[500px]")}>
        <div className={cn("flex flex-col gap-4 items-center border border-zinc-200 rounded-md p-4 flex-1 w-full h-full place-content-center bg-zinc-100", className)}>
            {children}
        </div>
    </div>
}

interface ContentCardHeaderProps {
    children: React.ReactNode;
    className?: string;
}

const CardHeader = ({ children, className }: ContentCardHeaderProps) => {
    return <h1 className={cn("text-2xl font-semibold", className)}>{children}</h1>;
}

CenteredCard.displayName = 'CenteredContentCard';
CardHeader.displayName = 'ContentCardHeader';
CenteredCard.Header = CardHeader;

export { CenteredCard };