import PlateEditor, { BlockEditorRef, PlateViewer } from '@/components/editor/plate-editor';
import { SettingsProvider } from "@/components/editor/settings";
import { Button } from "@/components/ui/button";
import { Pencil } from 'lucide-react';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
interface TextEditorProps {
  initialContent: string;
  onSave: (content: string) => void;
  editMode: boolean;
  isEditing?: boolean;
  onEdit: (editing: boolean) => void;
}

export const TextEditor = forwardRef<HTMLDivElement, TextEditorProps>(
  ({ initialContent, onSave, editMode, isEditing: initialEditing = false, onEdit }, ref) => {
    const [isEditing, setIsEditing] = useState(initialEditing)
    const [text, setText] = useState(initialContent)
    const textareaRef = useRef<HTMLTextAreaElement>(null)
    const editorRef = useRef<BlockEditorRef>(null)
    const viewRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef(initialContent);

  console.debug('Initial content:', initialContent);

  useEffect(() => {
    console.debug('Initial content:', initialContent);
    if (isEditing && textareaRef.current) {

      textareaRef.current.focus()
    }
  }, [isEditing])

  const handleChange = useCallback((content) => {
    contentRef.current = content;
  }, []);

  const handleSave = useCallback(() => {
    console.debug('Saving content', contentRef.current);
    onSave(contentRef.current);
    setText(contentRef.current);
    setIsEditing(false);
  }, [onSave]);

  const handleEdit = useCallback(() => {
    setIsEditing(true);
  }, []);

  useEffect(() => {
    if (isEditing && viewRef.current) {
      console.debug('Editing text', viewRef.current);
      viewRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      });
    }
  }, [isEditing]);

  const discardEdit = useCallback(() => {
    console.debug('Discarding edit', initialContent);
    setText(initialContent)
    setIsEditing(false)
  }, [initialContent]);

  useEffect(() => {
    onEdit?.(isEditing);
  }, [isEditing]);

  if (!isEditing && !text) {
    return (
      <Button
        variant="ghost"
        onClick={handleEdit}
        className="bg-muted text-muted-foreground text-sm hover:bg-muted/80"
      >
        + Text
      </Button>
    )
  }

  return (
    <div className="w-full relative my-4" ref={ref}>
      {isEditing ? (
        <div className="space-y-2" ref={viewRef}>
          <SettingsProvider>
            <PlateEditor
              ref={editorRef}
              initialValue={text as any}
              onChange={handleChange}
            />
          </SettingsProvider>

          <div className="justify-between flex flex-row">
            <Button onClick={handleSave}>Save</Button>
            {/* <Button onClick={handleExportHtml}>Export</Button> */}
            <Button variant='secondary' onClick={discardEdit}>Discard</Button>
          </div>
        </div>
      ) : (
        <div className="relative h-full flex flex-row items-center border-b border-zinc-200">
          <div className="flex-1" onClick={handleEdit}>
            <PlateViewer initialValue={text} />
          </div>
          <Button
            variant="ghost"
            size="icon"
            className="w-10"
            onClick={handleEdit}
          >
            <Pencil className="h-4 w-4" />
          </Button>
        </div>
      )}
    </div>
  )
})

