import { ArrowRight } from "lucide-react"
import { cn } from "@/lib/utils"
import type { ExpandableOptionProps } from "../types/welcome"
import { motion } from "framer-motion"

export function ExpandableOption({
  value,
  title,
  isExpanded,
  isActive,
  onToggle,
  children,
}: ExpandableOptionProps) {
  return (
    <div className="border-t border-zinc-200">
      <div
        className="py-4 px-6 flex justify-between cursor-pointer"
        onClick={() => onToggle(isExpanded ? undefined : value)}
      >
        <motion.span
          className="text-base font-medium"
          animate={{ 
            color: isActive === undefined ? "#18181B" : isActive ? "#18181B" : "#A1A1AA"
          }}
          transition={{ duration: 0.2 }}
        >
          {title}
        </motion.span>
        <motion.div
          animate={{
            color: isActive === undefined ? "#18181B" : isActive ? "#18181B" : "#A1A1AA" 
          }}
          transition={{ duration: 0.2 }}
        >
          <ArrowRight className="h-5 w-5 shrink-0" />
        </motion.div>
      </div>

      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{ 
          height: isExpanded ? "auto" : 0,
          opacity: isExpanded ? 1 : 0
        }}
        transition={{ 
          duration: 0.3,
          ease: "easeInOut"
        }}
        style={{ overflow: "hidden" }}
      >
        {children}
      </motion.div>
    </div>
  )
}

