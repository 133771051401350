import { Card, CardContent, CardHeader } from "@/components/ui/card"
import { ExpandableOption } from "./components/ExpandableOption"
import { GuestForm } from "./components/GuestForm"
import { LoginForm } from "./components/LoginForm"
import { useExpandableOptions } from "./hooks/useExpandableOptions"
import { GuestFormData, LoginFormData, WelcomeCardProps } from "./types/welcome"
import { Outlet, useNavigate } from "react-router-dom"
import SignupPage from "@/app/features/auth/SignupPage"

export default function WelcomeCard({
  onGuestSubmit = (data) => console.log("Guest form submitted:", data),
  onLoginSuccess = (data) => console.log("Login form submitted:", data),
  onCreateAccount = () => console.log("Create account clicked"),
}: WelcomeCardProps) {
  const { handleToggle, isExpanded, isActive } = useExpandableOptions()
  const navigate = useNavigate()

  const handleGuestSubmit = (data: GuestFormData) => {
    onGuestSubmit(data)
  }

  const handleLoginSubmit = (data: LoginFormData) => {
    onLoginSuccess(data)
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-zinc-50 p-4">
      <Card className="w-full max-w-2xl rounded-3xl border-zinc-200 shadow-sm space-y-6">
        <CardHeader className="pb-0 space-y-2">
          <h1 className="text-3xl font-bold text-zinc-900">Welcome to Raytd.</h1>
          <p className="text-base text-zinc-800">How would you like to view your report?</p>
        </CardHeader>
        <CardContent className="p-0">
          <div className="flex flex-col">
            {/* Continue as Guest */}
            <ExpandableOption
              value="guest"
              title="Continue as a Guest"
              isExpanded={isExpanded("guest")}
              isActive={isActive("guest")}
              onToggle={handleToggle}
            >
              <GuestForm onSubmit={handleGuestSubmit} />
            </ExpandableOption>

            {/* Log in to your account */}
            <ExpandableOption
              value="login"
              title="Log in to your account and save this report"
              isExpanded={isExpanded("login")}
              isActive={isActive("login")}
              onToggle={handleToggle}
            >
              <LoginForm onLoginSuccess={handleLoginSubmit} />
            </ExpandableOption>

            {/* Create a free account */}
            <ExpandableOption
              value="create"
              title="Create a free account and save this report"
              isExpanded={isExpanded("create")}
              isActive={isActive("create")}
              onToggle={handleToggle}
            >
              <div className="px-6 pb-6">
                <SignupPage 
                  useCard={false} 
                  onComplete={onCreateAccount}
                />
               
              </div>
            </ExpandableOption>

            <div className="border-t border-zinc-200"></div>
          </div>
        </CardContent>
      </Card>
    </div>
  )
}

