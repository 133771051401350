import { NavProvider } from "@/app/features/profile/NavContext";
import { PropsWithChildren } from "react";

const PageWithTopBarNavigation: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <NavProvider>
            <div className="h-full" test-id="page-with-top-bar-navigation">
                {children}
            </div>
        </NavProvider>
    );

}

export default PageWithTopBarNavigation;