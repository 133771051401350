import AuthorTimestamp from "@/app/features/reports/components/author-timestamp";
import { useTestFormContext } from '@/app/features/reports/components/test-form/context';
import { TestStatus } from "@/app/features/reports/types/test-form";
import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils";
import React, { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { TestItem } from 'store/src/lib/tests/entity';
import { getStatusLabel } from "../../utils/testStatus";

const isDevelopment = process.env.NODE_ENV === 'development';

export interface TestFormHeaderProps {
  testItem: TestItem;
  enableRightContent: boolean;
  saveStatusComponent: React.ReactNode;
  errorNavigationComponent: React.ReactNode;
}

const TestFormHeader = ({
  testItem,
  enableRightContent,
  saveStatusComponent,
  errorNavigationComponent
}: TestFormHeaderProps) => {
  return (
    <>
      <TestFormTitle
        testItem={testItem}
        enableRightContent={enableRightContent}
        className="pt-6 px-6"
      >
        {saveStatusComponent}
      </TestFormTitle>

      {errorNavigationComponent}

      <TestAuthors testItem={testItem} />
    </>
  )
}

export default TestFormHeader;



interface TestFormTitleProps {
  testItem: TestItem;
  enableRightContent: boolean;
  children?: React.ReactNode;
  title?: string;
  className?: string;
}

export const TestFormTitle = ({
  testItem,
  enableRightContent,
  children,
  title = 'Test',
  className
}: TestFormTitleProps) => {

  const { trigger } = useFormContext();
  const { isFormDirty, isExistingTest } = useTestFormContext();

  const handleModifyClick = useCallback(() => {
    if (isDevelopment) {
      trigger();
    }
  }, [trigger]);

  return (
    <div className={cn("pb-0 flex flex-row justify-between items-center", className)}>
      <div className="text-2xl font-semibold leading-none tracking-tight">
        {isExistingTest ? `${title} (${getStatusLabel(testItem?.status as TestStatus)})` : 'New Test '}
      </div>
      {isFormDirty && (<Badge className="ml-2" variant="secondary" onClick={handleModifyClick}>Modified</Badge>)}
      {isDevelopment && isExistingTest && (<Badge className="opacity-50 ml-2" variant="outline">{testItem.assessmentTestId}</Badge>)}

      {!enableRightContent && children && (
        children
      )}

    </div>
  )
}

interface TestAuthorsProps {
  testItem: TestItem;
}

export const TestAuthors = ({ testItem }: TestAuthorsProps) => {

  const { isExistingTest } = useTestFormContext();

  return (
    <div className={cn(
      "border-b border-zinc-200 py-4 ",
      !isExistingTest && "py-1"
    )}>

      {isExistingTest && (
        <div className="flex flex-row justify-between gap-2 px-6 text-zinc-600">

          <AuthorTimestamp
            author={testItem.creator}
            timestamp={testItem.created_at}
            label="Created by"
          />

          {testItem.created_at !== testItem.updated_at && (
            <AuthorTimestamp
              author={testItem.updater}
              timestamp={testItem.updated_at}
              label="Last updated"
            />
          )}

        </div>
      )}

    </div>
  )
}