import { TextComparison } from '@/app/utils/TextComparison';
import Highlighter from 'react-highlight-words';
import useReportViewerContext from '../report-viewer-context';
import MissingValue from './missing-value';
import TextTile from './text-with-label';
import { useTestTileContext } from '@/app/features/reports/components/test-tile/context';

const FastFillText = ({ label, field_id, item: passedItem }: { label: string; field_id: string; item?: TextComparison }) => {

    const { showFastFillQA, showMissingData } = useReportViewerContext();

    const { item: testItem } = useTestTileContext();
    const item = passedItem ?? testItem;

    const fieldName = field_id ? field_id.toLowerCase() : label.toLowerCase();
    const value = fieldName.startsWith('custom.') ? item['custom']?.[fieldName.split('.')[1]] : item[fieldName];

    if (showFastFillQA && item.hasFastFillQA) {

        fieldName.startsWith('custom.') && console.debug('comparisoncon item', fieldName, value, item[`${fieldName}_unmatched`]);

        return (
            <TextTile title={label} >
                <div style={{ whiteSpace: 'pre-wrap' }}>
                    <Highlighter
                        highlightStyle={{ backgroundColor: 'rgba(140, 65, 208, 0.25)' }} // 25% opacity
                        searchWords={item[`${fieldName}_unmatched`] || []}
                        textToHighlight={value?.replace(/\\n/g, '\n').trim()}
                        autoEscape={true}
                    />
                </div>
            </TextTile>
        )

    }

    if (!value && showMissingData) {
        return (
            <TextTile title={label} >
                <MissingValue field={field_id} />
            </TextTile>
        )

    }

    return (
        <TextTile title={label} newLines={value?.includes('\n')}>
            {value ?? ''}
        </TextTile>
    )

}

export default FastFillText;