import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { UsersEntity, api, authActions, useAppDispatch } from '@app.raytd.com/store';
import BusyButton from '@/components/raytd/busy-button';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardFooter, CardHeader } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { zodResolver } from '@hookform/resolvers/zod';
import { useLocation, useNavigate } from 'react-router-dom';
import * as z from 'zod';
import SignupCardTitle from '@/components/raytd/sign-up-card-title';
import ErrorAlert from '@/components/raytd/form-error';
import APIError from 'store/src/lib/api/apiError';
import { Loader2 } from 'lucide-react';
import { SignupFormData, SignupSchema, Step1Data } from '@/app/features/auth/signup/schema';
import Step1Form from '@/app/features/auth/signup/step-1-user-details';

interface Step2Props {
    register: any;
    errors: any;
    watch: any;
    isBusy?: boolean;
}

const Step2: React.FC<Step2Props> = ({ register, errors, watch, isBusy }) => {

    const password = watch('password');
    const email = watch('email');

    return (
        <>
            <div className="mb-4">
                <Input
                    type="email"
                    className="border-none shadow-none"
                    readOnly
                    value={email}
                />
            </div>
            <div className="mb-4">
                <Label htmlFor="password" className="sr-only">Password</Label>
                <Input
                    id="password"
                    type="password"
                    placeholder="Start typing..."
                    disabled={isBusy}
                    {...register('password', { required: true })}
                />
                {errors.password && <p className="text-red-600 text-sm">{errors.password.message ?? 'Password is required'}</p>}
            </div>
            <div className="mb-4">
                <Label htmlFor="password_confirmation" className="sr-only">Confirm Password</Label>
                <Input
                    id="password_confirmation"
                    type="password"
                    autoComplete='new-password'
                    placeholder="Confirm Password"
                    disabled={isBusy}
                    {...register('password_confirmation', {
                        required: true,
                        validate: (value) => value === password || 'Passwords do not match',
                    })}
                />
                {errors.password_confirmation && <p className="text-red-600 text-sm">{errors.password_confirmation.message ?? 'Your passwords do not match'}</p>}
            </div>
        </>
    )
};

export default Step2;