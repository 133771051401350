import usePredefinedTexts from "@/app/features/reports/hooks/use-predefined-texts";
import useUnmatchedSegments, { TextComparison } from "@/app/features/reports/hooks/use-unmatched-segments";
import getTestSuiteForSpecification, { TestSuiteResult } from "@/app/features/reports/utils/getTestSpecification";
import { createContext, useCallback, useContext, useMemo, useState } from "react";
import { ReportTestItem } from "store/src/lib/services/types";
import { TestSuite } from "store/src/lib/tests/entity";

type UnmatchedSegment = {
    field: string;
    segments: string[];
}

type RapidQAContextType = {
    unmatchedSegments: UnmatchedSegment[];
    updateUnmatchedSegments: (values: any) => void;
    getFieldUnmatchedSegments: (field: string, values: any) => string[];
    checkTestForUnmatchedSegments: (values: any) => TextComparison | undefined;
    selectedTestSpecification: TestSuiteResult;
    errors: any;
}

const RapidQAContext = createContext<RapidQAContextType>({
    unmatchedSegments: [],
    updateUnmatchedSegments: () => { },
    getFieldUnmatchedSegments: () => [],
    checkTestForUnmatchedSegments: () => undefined,
    selectedTestSpecification: null,
    errors: {}
});

export const useRapidQA = () => {
    const context = useContext(RapidQAContext);
    if (!context) {
        throw new Error('useRapidQA must be used within a RapidQAProvider');
    }
    return context;
}

type RapidQAProviderProps = {
    children: React.ReactNode;
    testItem: ReportTestItem;
    testSuites: TestSuite[];
    errors: any;
}

const RapidQAProvider = ({ children, testItem, testSuites, errors }: RapidQAProviderProps) => {

    const [unmatchedSegments, setUnmatchedSegments] = useState<UnmatchedSegment[]>([]);

    const { getPredefinedTexts, createComparisonConfig } = usePredefinedTexts(
        testSuites
    );

    const { checkTestForUnmatchedSegments, checkFieldForUnmatchedSegments } = useUnmatchedSegments(
        createComparisonConfig,
        getPredefinedTexts
    );

    const selectedTestSpecification = useMemo(() =>
        //testSuites is an array of TestSuite, but we need a TestSpecification which is a array property of TestSuite
        testItem?.testSpecificationId ?
            getTestSuiteForSpecification(testSuites, testItem.testSpecificationId)
            : null,
        [testSuites, testItem?.testSpecificationId]);

    const updateUnmatchedSegments = useCallback((values: any) => {
        // console.log('updateUnmatchedSegments', values);

        const unmatchedSegments = checkTestForUnmatchedSegments({
            ...values,
            testSpecification: selectedTestSpecification?.specification
        } as unknown as TextComparison);
        // console.log('unmatchedSegments 1', unmatchedSegments);
        if (unmatchedSegments) {
            //filter all properties that end with _unmatched
            const unmatchedSegmentsFiltered = Object.keys(unmatchedSegments)
                .filter((key) => key.endsWith('_unmatched'))
                .map((key) => ({
                    field: key.replace('_unmatched', ''),
                    segments: unmatchedSegments[key]
                }));

            setUnmatchedSegments(unmatchedSegmentsFiltered);
        }
    }, [testItem, selectedTestSpecification]);

    const getFieldUnmatchedSegments = useCallback((field: string, values: any) => {
        console.log('getUnmatchedSegments', values);

        const unmatchedSegments = checkFieldForUnmatchedSegments(field, {
            ...values,
            testSpecification: selectedTestSpecification.specification
        });
        return unmatchedSegments;
    }, [selectedTestSpecification, checkFieldForUnmatchedSegments]);

    // useEffect(() => {
    //     if (testItem) {
    //         const unmatchedSegments = checkTestForUnmatchedSegments({
    //             ...testItem,
    //             testSpecification: selectedTestSpecification.specification
    //          } as unknown as TextComparison);
    //         console.log('unmatchedSegments 1', unmatchedSegments);
    //         if (unmatchedSegments) {
    //             //filter all properties that end with _unmatched
    //             const unmatchedSegmentsFiltered = Object.keys(unmatchedSegments)
    //                 .filter((key) => key.endsWith('_unmatched'))
    //                 .map((key) => ({
    //                     field: key.replace('_unmatched', ''),
    //                     segments: unmatchedSegments[key]
    //                 }));

    //             setUnmatchedSegments(unmatchedSegmentsFiltered);
    //         }
    //     }
    // }, [testItem, selectedTestSpecification]);

    // useEffect(() => {
    //     console.log('unmatchedSegments 2', unmatchedSegments);
    // }, [unmatchedSegments]);


    // const enrichedTestItems = useMemo(() => {
    //     if (!testItem) return null;



    //     const items = allTestItems.map((testItem) => {
    //         const testItemWithUnmatchedSegments = checkTestForUnmatchedSegments(
    //             testItem as unknown as TextComparison
    //         );
    //         const hasFastFillErrors =
    //             testItemWithUnmatchedSegments.hasUnmatchedSegments &&
    //             !testItem.switches?.fast_fill_accepted;
    //         const hasMissingData = missingData.has(testItem.assessmentTestId);

    //         let reason: ExclusionReasons = 'included';
    //         if (hasMissingData) reason = 'missing';
    //         else if (hasFastFillErrors) reason = 'fastfill';
    //         else if (testItem.status.toLowerCase() !== 'published') reason = 'status';
    //         else if (testItem.switches.include === false) reason = 'excluded';

    //         return {
    //             ...testItemWithUnmatchedSegments,
    //             hasUnmatchedSegments:
    //                 testItemWithUnmatchedSegments.hasUnmatchedSegments,
    //             hasFastFillQA: hasFastFillErrors,
    //             hasMissingData,
    //             exclusionReason: reason,
    //         };
    //     });

    //     return editMode
    //         ? items
    //         : items.filter((item) => item.exclusionReason === 'included');
    // }, [
    //     allTestItems,
    //     missingData,
    //     checkTestForUnmatchedSegments,
    //     // dataUpdatedAt,
    //     editMode,
    // ]);

    const context = useMemo(() => ({
        unmatchedSegments,
        updateUnmatchedSegments,
        getFieldUnmatchedSegments,
        checkTestForUnmatchedSegments,
        selectedTestSpecification,
        errors
    }), [unmatchedSegments, updateUnmatchedSegments, getFieldUnmatchedSegments, checkTestForUnmatchedSegments, selectedTestSpecification, errors]);

    return (
        <RapidQAContext.Provider
            value={context}
        >
            {children}
        </RapidQAContext.Provider>
    )
}

export default RapidQAProvider;