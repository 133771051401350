import React from 'react';

import {
  BoldPlugin,
  CodePlugin,
  ItalicPlugin,
  StrikethroughPlugin,
  UnderlinePlugin,
} from '@udecode/plate-basic-marks/react';
import { useEditorReadOnly } from '@udecode/plate-common/react';

import { Icons } from '@/components/icons';

import { InsertDropdownMenu } from './insert-dropdown-menu';
import { MarkToolbarButton } from './mark-toolbar-button';
import { ModeDropdownMenu } from './mode-dropdown-menu';
import { ToolbarGroup } from './toolbar';
import { TurnIntoDropdownMenu } from './turn-into-dropdown-menu';
import { AlignDropdownMenu } from './align-dropdown-menu';
import { IndentListToolbarButton } from './indent-list-toolbar-button';
import { ListStyleType } from '@udecode/plate-indent-list';
import { IndentToolbarButton } from './indent-toolbar-button';
import { OutdentToolbarButton } from './outdent-toolbar-button';
import { TableDropdownMenu } from './table-dropdown-menu';
import { MediaToolbarButton } from '@/components/plate-ui/media-toolbar-button';
import { ImagePlugin } from '@udecode/plate-media/react';
import { LinkToolbarButton } from './link-toolbar-button';
import { BlockquotePlugin } from '@udecode/plate-block-quote/react';
import { ReportBlockToolbarButton } from '@/components/plate-ui/report-block-toolbar-button';

export function FixedToolbarButtons() {
  const readOnly = useEditorReadOnly();

  return (
    <div className="w-full overflow-hidden">
      <div
        className="flex flex-wrap"
        style={{
          transform: 'translateX(calc(-1px))',
        }}
      >
        {!readOnly && (
          <>
            <ToolbarGroup>
              <InsertDropdownMenu />
              <TurnIntoDropdownMenu />
            </ToolbarGroup>

            <ToolbarGroup>
              <MarkToolbarButton nodeType={BoldPlugin.key} tooltip="Bold (⌘+B)">
                <Icons.bold />
              </MarkToolbarButton>
              <MarkToolbarButton
                nodeType={ItalicPlugin.key}
                tooltip="Italic (⌘+I)"
              >
                <Icons.italic />
              </MarkToolbarButton>
              <MarkToolbarButton
                nodeType={UnderlinePlugin.key}
                tooltip="Underline (⌘+U)"
              >
                <Icons.underline />
              </MarkToolbarButton>

              <MarkToolbarButton
                nodeType={StrikethroughPlugin.key}
                tooltip="Strikethrough (⌘+⇧+M)"
              >
                <Icons.strikethrough />
              </MarkToolbarButton>
              {/* <MarkToolbarButton nodeType={CodePlugin.key} tooltip="Code (⌘+E)">
                <Icons.code />
              </MarkToolbarButton>
              <MarkToolbarButton nodeType="code_block" tooltip="Code Block">
                <Icons.codeblock />
              </MarkToolbarButton>

              <MarkToolbarButton nodeType={BlockquotePlugin.key} tooltip="Blockquote">
                <Icons.blockquote />
              </MarkToolbarButton> */}
            </ToolbarGroup>

            <ReportBlockToolbarButton />

            <ToolbarGroup>
              <AlignDropdownMenu />

              <IndentListToolbarButton nodeType={ListStyleType.Disc} />
              <IndentListToolbarButton nodeType={ListStyleType.Decimal} />

              <OutdentToolbarButton />
              <IndentToolbarButton />
            </ToolbarGroup>

            <ToolbarGroup>
              <TableDropdownMenu />
              <LinkToolbarButton />
              <MediaToolbarButton nodeType={ImagePlugin.key} />
            </ToolbarGroup>

          </>
        )}

        <div className="grow" />

        <ToolbarGroup>
          <ModeDropdownMenu />
        </ToolbarGroup>
      </div>
    </div>
  );
}
