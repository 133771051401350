import React, { useCallback } from 'react';
import { CustomField } from 'store/src/lib/tests/entity';

import { Badge } from '@/components/ui/badge';
import { TestSuite } from 'store/src/lib/tests/entity';
import { ReportTestItem } from '../../utils/entities';
import MissingValue from './missing-value';
import TextTile from './text-with-label';
import FastFillText from '@/app/features/reports/components/test-tile/fast-fill-text';

const formatNumber = (value, decimalPlaces) => {

    if (value === null || value === undefined) return null;

    return new Intl.NumberFormat('en-AU', {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
    }).format(value);
};


const formatDate = (dateString, includeTime: boolean = false) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return dateString;

    if (includeTime) {
        return new Intl.DateTimeFormat('en-AU', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        }).format(date);
    }

    return new Intl.DateTimeFormat('en-AU', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    }).format(date);
};



type CustomFieldWithValue = CustomField & { value: string | number | boolean | string[] | null };

// components/FieldValue.tsx
interface CustomFieldValue {
    field: CustomFieldWithValue;
}

const CustomFieldValue: React.FC<CustomFieldValue> = ({ field }) => {
    switch (field.type) {
        case 'textarea':
             return field.value ? field.value : undefined;
        case 'select':
        case 'radio':
            if (field.selection_type === 'multiple') {
                
                const values = (field.value as string[])?.map((value) => {
                    const option = field.data?.find((opt) => opt.id === value);
                    return option ? (
                        <div key={option.id} className="text-sm">
                            {option.displayName}
                        </div>
                    ) : (
                        undefined
                    );
                });

                return <div className="flex flex-col items-start space-y-1">{values}</div>;
            }
            const option = field.data?.find((opt) => opt.id === field.value);
            return option ? <>{option.displayName}</> : undefined;

        case 'switch':
        case 'checkbox':
            return field.value ? (
                <Badge variant="secondary">Yes</Badge>
            ) : (
                <Badge variant="outline">No</Badge>
            );

        case 'number':
            return formatNumber(field.value as number, field.decimal_places ?? 0) || undefined;

        case 'date':
            return formatDate(field.value as string) || undefined;

        default:
            return field.value ? <>{field.value}</> : undefined;
    }
};

// components/CustomFields.tsx
interface CustomFieldsProps {
    customFields: CustomFieldWithValue[];
    showMissingFields?: boolean;
}

export const CustomFields: React.FC<CustomFieldsProps> = ({
    customFields,
    showMissingFields = false
}) => {
    const visibleFields = customFields.filter(
        field => field.value || (field.required || showMissingFields)
    );

    if (!visibleFields.length) {
        return null;
    }

    const renderField = useCallback((field: CustomFieldWithValue) => {

        const isBoolean = field.validations === 'boolean';
        const isEmpty = !field.value
            || field.value === ''
            || field.value === 'undefined'
            || field.value === 'null'
            || (Array.isArray(field.value) && field.value.length === 0)
            || (field.value === null)
            || (field.value === undefined)
            || (isBoolean && Boolean(field.value) === false)
            ;

        const isEmptyNonBoolean = isEmpty &&
            (field.required || showMissingFields) &&
            !isBoolean;

        const commonProps = {
            key: field.field_id,
            title: field.label || 'missing',
            className: ""
        };

        // if (isEmpty && field.type === 'radio') {
        //     console.debug('renderField empty', { field, isEmptyNonBoolean, isEmpty, isBoolean, required: field.required, showMissingFields });  
        // }

        if (isEmptyNonBoolean) {
            return (
                <TextTile {...commonProps}>
                    <MissingValue field={`custom.${field.field_id}`} />
                </TextTile>
            );
        }

        // If the field is a boolean and empty and not required, don't render it
        if (isBoolean && isEmpty && !field.required) {
            return null;
        }

        // If the field is not required and not empty, don't render it
        if (isEmpty && !field.required && !showMissingFields) {
            return null;
        }

        if (field.type === 'textarea') {
            return <FastFillText label={field.label} field_id={`custom.${field.field_id}`} />
        }

        return (
            <TextTile
                {...commonProps}
                // newLines={field.type === 'textarea' && Boolean(field.value)}
            >
                <CustomFieldValue field={field} />
            </TextTile>
        );
    }, [showMissingFields]);

    return (
        <>
            {visibleFields.map(renderField)}
        </>
    );
};

const CustomFieldContainer: React.FC<{ item: ReportTestItem, testSuite: TestSuite }> = ({ item, testSuite }) => {
    // console.debug('test suite custom fields', testSuite?.custom_fields, item);
    if (!testSuite?.custom_fields || testSuite?.custom_fields?.length === 0) {
        return null;
    }

    const custom_fields = testSuite.custom_fields
        .filter((field) => field.active)
        .map((field) => {
            const value = item.custom[field.field_id];
            return {
                ...field,
                value: value
            };

        });

    if (!custom_fields.length) {
        return null;
    }

    return (
        <div className="flex-1 columns-2 space-y-4">
            <CustomFields customFields={custom_fields} />
        </div>
    );
}

export default CustomFieldContainer;

