import { TestFormValues } from './test-form';

const defaultValues: TestFormValues = {
  status: "draft",
  assetId: null,
  test_specification_id: null,
  building_id: null,
  level_id: null,
  area_id: null,
  assessmentId: null,
  element: null,
  element_name: null,
  element_id: null,
  observations: "",
  recommendations: "",
  images: [],
  result: null,
  custom: {}
};

export default defaultValues;