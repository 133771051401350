import {
    useGetReportItemsQuery,
    useGetReportQuery,
    useGetReportTestSuitesQuery
} from '@app.raytd.com/store';
import { useEffect } from 'react';
import { ReportTestItem } from '../utils/entities';

import { skipToken } from '@reduxjs/toolkit/query';

function useReportQueries(reportId: string) {

    const {
      allTestItems,
      error,
      isLoading,
    } = useGetReportItemsQuery(reportId, {
      selectFromResult: (result) => {
        // Create a derived value specifically for tracking switches
        const switchesState = result.data 
          ? JSON.stringify(result.data.map(item => item.switches)) 
          : '';

        return {
          allTestItems: result.data,
          switchesState, // Add this tracked value
          error: result.error,
          isLoading: result.isLoading,
        };
      },
    });
  
    const {
      data: report,
      error: reportError,
      isLoading: reportLoading,
    } = useGetReportQuery(reportId ? reportId : skipToken);
  
    const {
      data: testSuites,
      isLoading: isTestSuitesLoading,
      error: testSuitesError,
    } = useGetReportTestSuitesQuery(reportId ? parseInt(reportId) : skipToken);
  
    // useEffect(() => {
    //   console.debug('useReportQueries toggleTestSwitch testItems changed', allTestItems);
    // }, [allTestItems]);
  
    return {
      allTestItems: allTestItems as unknown as ReportTestItem[],
      report,
      testSuites,
      isLoading: isLoading || reportLoading || isTestSuitesLoading,
      error: error || reportError || testSuitesError,
    };
  }
  
  export default useReportQueries;