import * as z from 'zod';

export const Step1Schema = z.object({
    first_name: z.string().min(2, { message: 'Name must be at least 2 characters' }),
    last_name: z.string().min(2, { message: 'Name must be at least 2 characters' }),
    email: z.string().email({ message: 'Please enter a valid email address' }),
});

export const Step2Schema = z.object({
    password: z.string().min(8, { message: 'Password must have at least 8 characters' }),
    password_confirmation: z.string(),
});

export const SignupSchema = Step1Schema.merge(Step2Schema).refine(
    (data) => data.password === data.password_confirmation,
    {
        message: 'Your passwords do not match',
        path: ['password_confirmation'],
    }
);

export const createOrganisationSchema = z.object({
    title: z.string().nonempty('Organisation name is required'),
});

export type SignupFormData = z.infer<typeof SignupSchema>;
export type Step1Data = z.infer<typeof Step1Schema>;
export type Step2Data = z.infer<typeof Step2Schema>;
export type createOrgData = z.infer<typeof createOrganisationSchema>;